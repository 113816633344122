/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONS from "../action-types/chc-orderDetails";
import initStates from "./initStates";

/**
 * Order Details reducer
 * @param state State object managed by catalog reducer
 * @param action The dispatched action
 */
const chcOrderDetailsReducer = createReducer(initStates.chcOrderDetails, (builder) => {
  builder.addCase(
    ACTIONS.FETCH_CHC_ORDER_DETAILS_SUCCESS,
    (state, action: AnyAction) => {
      state.chcJDEOrderDetails = action.response;
      state.status = "success";
    }
  );
  builder.addCase(ACTIONS.FETCH_CHC_ORDER_DETAILS, (state, action: AnyAction) => {
      state.status = "pending";
    }
  );
  builder.addCase(ACTIONS.FETCH_CHC_ORDER_DETAILS_FAIL, (state, action: AnyAction) => {
    state.status = "error";
  }
);
  builder.addCase(
    ACTIONS.FETCH_CHC_ORDER_DETAILS_RESET,
    (state, action: AnyAction) => {
      state.chcJDEOrderDetails = [];
      state.status = "idle";
    }
  );
});

export default chcOrderDetailsReducer;
