/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */

export const axiosHeaderIgnoredServices = { adminLookup: {}, online_store: {} };
export const axiosESIgnoredService = { "api/v2/urls": {}, "https://content-addressvalidation.googleapis.com/v1:validateAddress": {} };