/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { Notifications } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get notifications.
         * @summary Get notifications.
         * @param {string} storeId The store identifier.
         * @param {string} langId The active language identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [offset] The offset of the result set.
         * @param {string} [limit] The limit of the result set.
         * @param {string} [unreadOnly] Only return unread notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCnotification: async (storeId: string, langId: string, responseFormat?: string, offset?: string, limit?: string, unreadOnly?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCnotification', 'storeId', storeId)
            // verify required parameter 'langId' is not null or undefined
            assertParamExists('cHCnotification', 'langId', langId)
            const localVarPath = `/store/{storeId}/CHCnotification/GetNotifications`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (unreadOnly !== undefined) {
                localVarQueryParameter['unreadOnly'] = unreadOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get notifications.
         * @summary Get notifications.
         * @param {string} storeId The store identifier.
         * @param {string} langId The active language identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [offset] The offset of the result set.
         * @param {string} [limit] The limit of the result set.
         * @param {string} [unreadOnly] Only return unread notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCnotification(storeId: string, langId: string, responseFormat?: string, offset?: string, limit?: string, unreadOnly?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notifications>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCnotification(storeId, langId, responseFormat, offset, limit, unreadOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Get notifications.
         * @summary Get notifications.
         * @param {string} storeId The store identifier.
         * @param {string} langId The active language identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [offset] The offset of the result set.
         * @param {string} [limit] The limit of the result set.
         * @param {string} [unreadOnly] Only return unread notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCnotification(storeId: string, langId: string, responseFormat?: string, offset?: string, limit?: string, unreadOnly?: string, options?: any): AxiosPromise<Notifications> {
            return localVarFp.cHCnotification(storeId, langId, responseFormat, offset, limit, unreadOnly, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Get notifications.
     * @summary Get notifications.
     * @param {string} storeId The store identifier.
     * @param {string} langId The active language identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [offset] The offset of the result set.
     * @param {string} [limit] The limit of the result set.
     * @param {string} [unreadOnly] Only return unread notifications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public cHCnotification(storeId: string, langId: string, responseFormat?: string, offset?: string, limit?: string, unreadOnly?: string, options?: any) {
        return NotificationsApiFp(this.configuration).cHCnotification(storeId, langId, responseFormat, offset, limit, unreadOnly, options).then((request) => request(this.axios, this.basePath));
    }
}
