import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC } from "react";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { SvgIconTypeMap } from "@material-ui/core/SvgIcon";
import { StyledIcon } from "../../../elements/icon";
import { usePriceDisplay } from "../../../_foundation/hooks/use-price-display-chc";

const DetailLine: FC<{
  label: string;
  value: string;
  Icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
}> = ({ label, value, Icon }) => {
  const priceDisplay = usePriceDisplay({ hideDecimal: true });
  return (
    <Stack direction="row" spacing={3} alignItems="center">
      <Stack flexShrink={0}>
        <StyledIcon icon={<Icon className="full-center" />} backgroundSize={50} iconSize={20} />
      </Stack>
      <Stack flex={1}>
        <StyledTypography variant="h5" color="primary">
          <strong>{priceDisplay(value)}</strong>
        </StyledTypography>
        <StyledTypography variant="body1">{label}</StyledTypography>
      </Stack>
    </Stack>
  );
};

export default DetailLine;
