//Standard libraries
import { ChangeEvent, FC, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";

//Custom libraries
import AccountPopperContent from "./AccountPopperContent";
import * as ROUTES from "../../constants/routes";

//UI
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { ClickAwayListener } from "@material-ui/core";
import {
  StyledAccountPopper,
  StyledLink,
  StyledHeaderActions,
  StyledTypography,
  StyledGrid,
  StyledPaper,
} from "@hcl-commerce-store-sdk/react-component";
//CUSTOM UI
import { useWinDimsInEM } from "../../_foundation/hooks/use-win-dims-in-em";
import { Buttonify } from "./Buttonify";
import { Stack } from "@mui/system";
import { DashboardButton } from "../widgets/dashboard-button";
import { Person } from "@material-ui/icons";
import { StyledIcon } from "../../elements/icon";
import { startCase, truncate } from "lodash-es";
import { HELLO_USER_TRUNCATE_LENGTH } from "../../constants/uset";

/**
 * Header component
 * displays Header, Mini Cart and Mega Menu
 * @param props
 */
export const AccountButton: FC<{
  myAccountElRef: MutableRefObject<HTMLButtonElement | null>;
  handleMyAccountClick: () => void;
  handleMyAccountPopperClose: () => void;
  handleOrgChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  handleContractChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  handleLogout: (event: any) => void;
  myAccountPopperOpen: boolean;
  firstName: string;
  lastName: string;
  isB2B: boolean;
  loggedIn: boolean;
}> = ({
  myAccountElRef,
  handleMyAccountClick,
  handleMyAccountPopperClose,
  handleOrgChange,
  handleContractChange,
  handleLogout,
  myAccountPopperOpen,
  firstName,
  lastName,
  isB2B,
  loggedIn,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = !useMediaQuery(theme.breakpoints.up("sm"));
  const { w } = useWinDimsInEM();
  const fullName = truncate(startCase(`${firstName} ${lastName}`), {length: HELLO_USER_TRUNCATE_LENGTH,  omission: "..."});

  return loggedIn ? (
    <StyledGrid item>
      <DashboardButton
        ref={myAccountElRef}
        variant="contained"
        color="secondary"
        size="small"
        onClick={handleMyAccountClick}
      >
        <StyledHeaderActions>
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledTypography variant="body1" component="p">
                {isMobile ? t("Header.Actions.Account") : t("Header.Actions.HelloFullName", {name: fullName})}
            </StyledTypography>
            <StyledIcon
              iconSize={22}
              icon={<Person />}
              iconColor="#ffffff"
              backgroundColor="#27251f"
              backgroundSize={32}
              shape="square"
              style={{ marginRight: "-0.6rem" }}
            />
          </Stack>
        </StyledHeaderActions>
      </DashboardButton>
      <StyledAccountPopper
        id="HEADER_MY_ACCOUNT_Popper"
        open={myAccountPopperOpen}
        anchorEl={myAccountElRef.current}
        onClose={handleMyAccountPopperClose}
        placement={w <= 40 ? "bottom" : "bottom-end"}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
          hide: {
            enabled: false,
          },
        }}
        className="account-popper"
      >
        <ClickAwayListener onClickAway={handleMyAccountPopperClose}>
          <StyledPaper className="horizontal-padding-2">
            <StyledTypography variant="body1" component="div">
              <AccountPopperContent
                handleClose={handleMyAccountPopperClose}
                handleOrgChange={handleOrgChange}
                handleContractChange={handleContractChange}
                handleLogout={handleLogout}
                isB2B={isB2B}
                userName={{ firstName, lastName }}
              />
            </StyledTypography>
          </StyledPaper>
        </ClickAwayListener>
      </StyledAccountPopper>
    </StyledGrid>
  ) : (
    <StyledGrid item style={{ display: "flex", alignItems: "center" }}>
      <StyledLink to={ROUTES.SIGNIN}>
        <Buttonify testId="header-sign-in">
          <>
            {isMobile ? (
              <>
                <StyledIcon
                  iconSize={22}
                  icon={<Person />}
                  iconColor="#ffffff"
                  backgroundColor="#27251f"
                  backgroundSize={32}
                  shape="square"
                />
                <StyledTypography variant="body1">{t("Header.Actions.SignIn")}</StyledTypography>
              </>
            ) : (
              <StyledTypography variant="body1" component="p">
                {t("Header.Actions.SignInRegister")}
              </StyledTypography>
            )}
          </>
        </Buttonify>
      </StyledLink>
    </StyledGrid>
  );
};
