/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * UpdateActionDateApi - axios parameter creator
 * @export
 */
export const UpdateActionDateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update the next order date for standing Order
         * @summary Update ActionDate
         * @param {string} storeId The store identifier.
         * @param {string} actionDate Action Date
         * @param {string} subscriptionId Subscription Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNextActionDate: async (storeId: string, actionDate: string, subscriptionId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('updateNextActionDate', 'storeId', storeId)
            // verify required parameter 'actionDate' is not null or undefined
            assertParamExists('updateNextActionDate', 'actionDate', actionDate)
            // verify required parameter 'subscriptionId' is not null or undefined
            assertParamExists('updateNextActionDate', 'subscriptionId', subscriptionId)
            const localVarPath = `/store/{storeId}/CHCorder/UpdateActionDate`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (actionDate !== undefined) {
                localVarQueryParameter['actionDate'] = actionDate;
            }

            if (subscriptionId !== undefined) {
                localVarQueryParameter['subscriptionId'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpdateActionDateApi - functional programming interface
 * @export
 */
export const UpdateActionDateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpdateActionDateApiAxiosParamCreator(configuration)
    return {
        /**
         * Update the next order date for standing Order
         * @summary Update ActionDate
         * @param {string} storeId The store identifier.
         * @param {string} actionDate Action Date
         * @param {string} subscriptionId Subscription Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNextActionDate(storeId: string, actionDate: string, subscriptionId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNextActionDate(storeId, actionDate, subscriptionId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpdateActionDateApi - factory interface
 * @export
 */
export const UpdateActionDateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpdateActionDateApiFp(configuration)
    return {
        /**
         * Update the next order date for standing Order
         * @summary Update ActionDate
         * @param {string} storeId The store identifier.
         * @param {string} actionDate Action Date
         * @param {string} subscriptionId Subscription Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNextActionDate(storeId: string, actionDate: string, subscriptionId: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.updateNextActionDate(storeId, actionDate, subscriptionId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpdateActionDateApi - object-oriented interface
 * @export
 * @class UpdateActionDateApi
 * @extends {BaseAPI}
 */
export class UpdateActionDateApi extends BaseAPI {
    /**
     * Update the next order date for standing Order
     * @summary Update ActionDate
     * @param {string} storeId The store identifier.
     * @param {string} actionDate Action Date
     * @param {string} subscriptionId Subscription Id
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpdateActionDateApi
     */
    public updateNextActionDate(storeId: string, actionDate: string, subscriptionId: string, responseFormat?: string, options?: any) {
        return UpdateActionDateApiFp(this.configuration).updateNextActionDate(storeId, actionDate, subscriptionId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
