import { FC, useContext } from "react";
import HTMLReactParser from "html-react-parser";
import { Stack, Divider, Box } from "@mui/material";
import { StyledPaper, StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { NotificationsContext } from "../../../_foundation/context/notifications-context";
import { getDateFormatter } from "../../../utils/getDateFormatter";
import { useTranslation } from "react-i18next";
import { DashboardSectionHeading } from "../dashboard-section-heading";

const NotificationDetails: FC = () => {
  const { selected } = useContext(NotificationsContext);
  const { i18n, t } = useTranslation();
  const dateFormatter = getDateFormatter(i18n);
  return (
    <StyledPaper>
      {selected ? (
        <Stack spacing={3} sx={{ py: 2 }}>
          <Box px={2}>
            <DashboardSectionHeading
              title={selected.title}
              subtitle={dateFormatter.format(new Date(selected.date))}
            />
          </Box>
          <Divider variant="fullWidth" sx={{ borderBottomStyle: "dashed" }} />
          <StyledTypography component="div">
            <Box
              sx={{
                px: 2,
                "& > :first-child": {
                  mt: "0 !important",
                },
              }}
            >
              {HTMLReactParser(selected.content || "")}
            </Box>
          </StyledTypography>
        </Stack>
      ) : (
        <Box p={2}>
          <StyledTypography variant="h6">
            {t("Dashboard.Sections.Notifications.SelectNotification")}
          </StyledTypography>
        </Box>
      )}
    </StyledPaper>
  );
};

export default NotificationDetails;
