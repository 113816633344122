/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
} from "@hcl-commerce-store-sdk/react-component";
import ThreeTierMenu from "./ThreeTierMenu-chc";
import { isEmpty } from "lodash-es";
import { Stack, Divider } from "@mui/material";
import { StyledLink } from "../../elements/link";
import StaticMenu from "./StaticMenu";

interface AllCategoriesExpandedMenuProps {
  pages?: any;
}

/**
 * AllCategoriesExpandedMenu component
 * displays all categories in the expanded menu on desktop/tablet
 * @param props
 */
const AllCategoriesExpandedMenu: React.FC<AllCategoriesExpandedMenuProps> = (
  props: any
) => {
  const pages = props.pages ? props.pages : [];
  const [selectedPage, setSelectedPage] = useState();

  //Remove hidden Category
  const resultPages = pages.filter((page) => page.name !== "hidden");
  return (
    <StyledPaper className="expanded-menu-paper">
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem light />}
      >
        <StyledBox sx={{ flexShrink: 0 }}>
          <Stack
            flexWrap="wrap"
            justifyContent="flex-start"
            alignContent="flex-start"
            m={3}
          >
            {resultPages &&
              resultPages.map((page: any, index: number) => (
                <StyledBox
                  key={page.id}
                  mr={5}
                  onMouseEnter={() => setSelectedPage(page.id)}
                >
                  <StyledLink
                    testId={`header-${page.id}`}
                    to={page.seo?.href}
                    key={"Link_" + index}
                    state={{
                      breadCrumbTrailEntryView: [
                        { label: page.name, value: page.id, seo: page.seo },
                      ],
                    }}
                  >
                    <StyledMenuTypography
                      variant="body1"
                      className="expanded-menu-bold"
                    >
                      {page.name}
                    </StyledMenuTypography>
                  </StyledLink>
                </StyledBox>
              ))}
            <StyledBox mr={5} onMouseEnter={() => setSelectedPage(undefined)}>
            <StaticMenu/>
            </StyledBox>
          </Stack>
        </StyledBox>
        <StyledBox>
          {!isEmpty(selectedPage) && (
            <ThreeTierMenu
              page={resultPages?.find((p: any) => p?.id === selectedPage)}
            />
          )}
        </StyledBox>
      </Stack>
    </StyledPaper>
  );
};

export default AllCategoriesExpandedMenu;
