import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";
import styled from "styled-components";

const StyledCard = styled(({ ...props }) => <StyledAccelerateCard {...props} />)`
  ${({ theme }) => `
  
.icn-xs {
  height: 18px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-sm {
  height: 18px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-md {
  height: 28px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-lg {
  height: 38px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-badge {
  height: 100px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }
  
.tier-rewards-section {
	background-color: ${theme.palette.info.main}; 
	h2 {
		padding: 15px;
		margin: 0 -15px; 
	}
	h3 {
		margin: 0 -15px;
		padding: 15px;
		font-size: 16px;
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 1px;
	}
	.card {
		margin-bottom: 10px;
		border: 0;
		h2 {
			font-weight: 600;
			padding: 0;
			margin: 10px 0;
			color: ${theme.palette.success.main};
		}
		h6 {
			font-size: 14px;
			margin: 0;
		}
		.card-header {
			padding: 5px 15px;
			border: 0;
			background-color: ${theme.palette.neutral.light};
			color: #fff;
			.btn-link {
				color: #fff;
				&:hover {
					color: #ceebf2;
				}
			}
		}
		.card-body {
			text-align: center;
			padding: 10px 15px;
		}
	}
}
      
    	/*template styles*/
	.gia-chart-wrapper {
		max-width: 620px;
		margin: 0 auto;
	}
	/*chart styles*/
	.y.axis line {
	    fill: none;
	    stroke: #dcdcdc;
	    stroke-dasharray: 1px 1px;
	    shape-rendering: crispEdges;
	    stroke-width: 1px;
	}
	.x.axis line {
	    fill: none;
	    stroke: #333333;
	    shape-rendering: crispEdges;
	    stroke-width: 1px;
	}
	.tick.g-baseline line {
	  	stroke: #333333;
	  	stroke-dasharray: 0;
	  	stroke-width: 1px;
	} 
	.axis text {
	    font-family: sans-serif;
	    font-size: 12px;
	    pointer-events: none;
	    fill: #bdbdbd;
	}
	.y.axis text {
	    text-anchor: end !important;
	    font-size:12px;
	    fill: #bdbdbd;
	}
	.domain {
	    display: none;
	}
	.area {
	  	fill: green;
	  	opacity: 0.3;
	}
	.area-negative {
		fill: #f0cd1a;
		opacity: 0.3;
	}
	.g-label-text {
	    font-family: sans-serif;
	    font-size: 14px;
	}
	.g-label-circle {
	    fill: #4bc6df;
	}
	.blueLine {
	    stroke: #64c8ce;
	    stroke-width: 2px;
	    fill: none;
	}
	.redLine {
	    stroke: #e41f35;
	    stroke-width: 2px;
	    fill: none;
	}
	
	.statusLine {
	    stroke: #6cb551;
	    stroke-width: 2px;
	    fill: none;
	}
	
	.labelBlue {
	    font-family: sans-serif;
	    font-size: 14px;
	    font-weight: 700;
	    fill: #005689;
	    text-anchor: end;
	}
	.labelRed {
	    font-family: sans-serif;
	    font-size: 14px;
	    font-weight: 700;
	    fill: #d61d00;
	    text-anchor: start;
	}
	
	.key {
			text-align : center;
			margin-top : 10px;
		}
		
	/* .key-box {
		margin : 0 30;
	} */
		
	.key-box::before {
		content : "";
		width : 10px;
		height : 10px;
		display : inline-block;
		margin-right : 5px;
	}
	
	.key-box.red::before {
		background-color : #e41f35;
	}
	
	.key-box.blue::before {
		background-color : #64c8ce;
	}
	
	.projected-box {
		background-color : #6cb551;
		color : white;
		width : 200px;
		position: relative;
	  	left: 40%;
	  	transform: translate(50%);
	  	text-align : center;
	  	margin-bottom : -20px;
	}
	
	.motivation-text {
		font-weight : bold;
		font-size : 22px;
	}
	
	h6.text-primary {
		font-weight : bold;
		font-size : 22px;
	}
	
	.chart-green-circle {
		fill : #6cb551;
	}
	
	.status-banner {
		background-color : #E41F35;
		visibility : hidden;
		text-align : center;
		color : #fff;
		padding-top : 8px;
		padding-bottom : 8px;
		margin-bottom : 8px;
	}
	
	.status-banner.active {
		visibility : visible;
	}

  `}
`;

export default StyledCard;
