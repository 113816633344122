/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
export const GENERIC = "GENERIC";
export const GUEST = "GUEST";
export const REGISTERED = "REGISTERED";

export const HELLO_USER_TRUNCATE_LENGTH = 23;