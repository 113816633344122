/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CHCPriceAndAvailabilityForSavedListsItemsApi - axios parameter creator
 * @export
 */
export const CHCPriceAndAvailabilityForSavedListsItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Price and availability for Saved Lists Items.
         * @summary Get price and availability for Saved Lists Items.
         * @param {string} storeId The store identifier.
         * @param {string} orderItemId orderItem Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcItemPriceAndAvailability: async (storeId: string, orderItemId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcItemPriceAndAvailability', 'storeId', storeId)
            // verify required parameter 'orderItemId' is not null or undefined
            assertParamExists('chcItemPriceAndAvailability', 'orderItemId', orderItemId)
            const localVarPath = `/store/{storeId}/CHCprice/getUpdatedItemPriceAndAvailability`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderItemId !== undefined) {
                localVarQueryParameter['orderItemId'] = orderItemId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCPriceAndAvailabilityForSavedListsItemsApi - functional programming interface
 * @export
 */
export const CHCPriceAndAvailabilityForSavedListsItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCPriceAndAvailabilityForSavedListsItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * Price and availability for Saved Lists Items.
         * @summary Get price and availability for Saved Lists Items.
         * @param {string} storeId The store identifier.
         * @param {string} orderItemId orderItem Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcItemPriceAndAvailability(storeId: string, orderItemId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcItemPriceAndAvailability(storeId, orderItemId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCPriceAndAvailabilityForSavedListsItemsApi - factory interface
 * @export
 */
export const CHCPriceAndAvailabilityForSavedListsItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCPriceAndAvailabilityForSavedListsItemsApiFp(configuration)
    return {
        /**
         * Price and availability for Saved Lists Items.
         * @summary Get price and availability for Saved Lists Items.
         * @param {string} storeId The store identifier.
         * @param {string} orderItemId orderItem Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcItemPriceAndAvailability(storeId: string, orderItemId: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcItemPriceAndAvailability(storeId, orderItemId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCPriceAndAvailabilityForSavedListsItemsApi - object-oriented interface
 * @export
 * @class CHCPriceAndAvailabilityForSavedListsItemsApi
 * @extends {BaseAPI}
 */
export class CHCPriceAndAvailabilityForSavedListsItemsApi extends BaseAPI {
    /**
     * Price and availability for Saved Lists Items.
     * @summary Get price and availability for Saved Lists Items.
     * @param {string} storeId The store identifier.
     * @param {string} orderItemId orderItem Id
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCPriceAndAvailabilityForSavedListsItemsApi
     */
    public chcItemPriceAndAvailability(storeId: string, orderItemId: string, responseFormat?: string, options?: any) {
        return CHCPriceAndAvailabilityForSavedListsItemsApiFp(this.configuration).chcItemPriceAndAvailability(storeId, orderItemId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
