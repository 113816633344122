/* tslint:disable */
/* eslint-disable */
/**
 * Approval Status
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AddressRequest } from './address-request';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * The unique numeric ID for identifying the user.
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {AddressRequest}
     * @memberof User
     */
    address?: AddressRequest;
    /**
     * The registrant\'s age.
     * @type {number}
     * @memberof User
     */
    age?: number | null;
    /**
     * The organizational entity\'s approval status. Available values: <br>* 0 - pending approval.<br>* 1 - approved.<br>* 2 - rejected.<br>* null - approval not applicable, so considered approved.
     * @type {number}
     * @memberof User
     */
    approvalState?: number;
    /**
     * The date and time that the registrant was registered.
     * @type {string}
     * @memberof User
     */
    createdDateTime?: string | null;
    /**
     * The registrant\'s date of birth.
     * @type {string}
     * @memberof User
     */
    dateOfBirth?: string;
    /**
     * The department identifier for the registrant.
     * @type {string}
     * @memberof User
     */
    departmentNumber?: string;
    /**
     * A description of the registrant.
     * @type {string}
     * @memberof User
     */
    description?: string;
    /**
     * Name that is used when the name of the registrant displays in a summary list.
     * @type {string}
     * @memberof User
     */
    displayName?: string;
    /**
     * Distinguished name (DN) of the registrant, for LDAP use.
     * @type {string}
     * @memberof User
     */
    distinguishedName?: string;
    /**
     * The registrant\'s ID with their employer.
     * @type {string}
     * @memberof User
     */
    employeeId?: string;
    /**
     * The registrant\'s status as an employee (for example, regular, permanent, contractor, or part time).
     * @type {string}
     * @memberof User
     */
    employeeType?: string;
    /**
     * The registrant\'s gender.
     * @type {string}
     * @memberof User
     */
    gender?: string;
    /**
     * The date and time representing the most recent update to the registrant\'s information.
     * @type {string}
     * @memberof User
     */
    updatedDateTime?: string | null;
    /**
     * The date and time representing the most recent login time of the user.
     * @type {string}
     * @memberof User
     */
    lastSession?: string;
    /**
     * The registrant\'s logon ID. Leading and trailing spaces are trimmed. When the system is configured with LDAP, the following restrictions apply, since the logonId is part of the distinguished name: <br>* Must not begin with a \'#\'.<br>* Must not contain equals sign, comma, plus sign, quotation mark, backslash, angle brackets or semicolon.
     * @type {string}
     * @memberof User
     */
    logonId?: string;
    /**
     * The name of the registrant\'s manager.
     * @type {string}
     * @memberof User
     */
    manager?: string;
    /**
     * The identifier of the registrant\'s company. Can be set when the user is created, but is otherwise read-only.
     * @type {string}
     * @memberof User
     */
    parentOrganizationId?: string;
    /**
     * The name of the organization that the registrant represents.
     * @type {string}
     * @memberof User
     */
    parentOrganizationName?: string;
    /**
     * The registrant\'s password. In database mode, the password is encrypted before it is saved in the database. In LDAP mode, the password is only stored on the LDAP server. This parameter is only specified during user creation/update and will never be returned in the response.
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * The registrant\'s password, entered a second time. This parameter is only specified during user creation/update and will never be returned in the response.
     * @type {string}
     * @memberof User
     */
    passwordVerify?: string;
    /**
     * The title of the user whose address is being entered (for example, Dr., Rev., Mr., or Ms.).
     * @type {string}
     * @memberof User
     */
    personTitle?: string;
    /**
     * The registrant\'s preferred currency for transactions.
     * @type {string}
     * @memberof User
     */
    preferredCurrency?: string;
    /**
     * The unique numeric ID for identifying registrant\'s preferred language.
     * @type {number}
     * @memberof User
     */
    preferredLanguageId?: number | null;
    /**
     * The registrant\'s preferred method of communication.
     * @type {string}
     * @memberof User
     */
    preferredCommunication?: string;
    /**
     * The registrant\'s challenge question.
     * @type {string}
     * @memberof User
     */
    challengeQuestion?: string;
    /**
     * The registrant\'s challenge answer.
     * @type {string}
     * @memberof User
     */
    challengeAnswer?: string;
    /**
     * The registrant\'s user profile type. This parameter can only be set during user registration. Available values: <br> * B - A business user profile.<br> * C - A customer user profile.
     * @type {string}
     * @memberof User
     */
    profileType?: UserProfileTypeEnum;
    /**
     * Indicates whether the user wants to receive order notification SMS text messages.
     * @type {boolean}
     * @memberof User
     */
    receiveSmsNotification?: boolean;
    /**
     * The registrant\'s registration type. Available values: <br>* A - Sell-side Administrator.<br> * G - Guest Customer.<br> * R - Registered Customer.<br> * S - Site Administrator.<br>
     * @type {string}
     * @memberof User
     */
    registrationType?: string;
    /**
     * The name of the registrant\'s secretary.
     * @type {string}
     * @memberof User
     */
    secretary?: string;
    /**
     * Whether or not the user\'s account is enabled. Available values: <br> * 0 - Disabled.<br> * 1 - Enabled.
     * @type {number}
     * @memberof User
     */
    status?: number;
    /**
     * The unique numeric ID for identifying a user account policy.
     * @type {number}
     * @memberof User
     */
    userAccountPolicyId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum UserProfileTypeEnum {
    B = 'B',
    C = 'C'
}



