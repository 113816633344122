/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import Axios, { Canceler } from "axios";
import React, { ChangeEvent, useEffect, useRef } from "react";
import getDisplayName from "react-display-name";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//Custom libraries
//Redux
import { LOGIN_REQUESTED_ACTION } from "../../../redux/actions/user";
import { siteSelector } from "../../../redux/selectors/site";
import { forUserIdSelector, logonIdSelector } from "../../../redux/selectors/user";
//UI
import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
  StyledTextField,
} from "@hcl-commerce-store-sdk/react-component";
//Foundation
import { APP_POPUPS } from "../../../constants/common";
import { CLOSE_POPUP_ACTION } from "../../../redux/actions/popup";
import { popupSelector } from "../../../redux/selectors/popup";

export const SignInDialog = () => {
  const un = useSelector(logonIdSelector);
  const forUserId = useSelector(forUserIdSelector);
  const popup = useSelector(popupSelector);
  const open = popup.key === APP_POPUPS.SIGN_IN && popup.open;
  const mySite = useSelector(siteSelector);
  const formRef = useRef<HTMLFormElement>(null);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);
  const [logonInputProps, setLogonInputProps] = React.useState<any>({});
  const { t } = useTranslation();
  const widgetName = getDisplayName(SignInDialog);
  const dispatch = useDispatch();
  const usernameInput = React.createRef<HTMLInputElement>();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if (mySite) {
      if (mySite.isB2B) {
        setLogonInputProps({
          maxLength: 100,
          type: "text",
          label: t(`SignIn.Label.B2B`),
        });
      } else {
        setLogonInputProps({
          maxLength: 100,
          type: "email",
          placeholder: "name@domain.com",
          label: t(`SignIn.Label.Email`),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, mySite]);

  useEffect(() => {
    setUsername(un);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [un]);

  useEffect(() => {
    setDisabled(!formRef.current || !formRef.current.checkValidity());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closePopup = () => dispatch(CLOSE_POPUP_ACTION({ open: false, key: APP_POPUPS.SIGN_IN }));

  const handleEntering = () => {
    if (usernameInput.current != null) {
      usernameInput.current.focus();
    }
  };

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>, type: string) => {
    evt.persist();
    const value: string | null = evt.target.value;
    if (type === "username") {
      setUsername(value || "");
    }
    if (type === "password") {
      setPassword(value || "");
    }
    setDisabled(!formRef.current || !formRef.current.checkValidity());
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();
    dispatch(
      LOGIN_REQUESTED_ACTION({
        body: {
          logonId: username,
          logonPassword: password,
        },
        isDialog: true, // Reloads the window on success
        ...payloadBase,
      })
    );
  };

  return (
    <>
      <StyledDialog
        onClose={() => false}
        TransitionProps={{
          onEntering: handleEntering,
        }}
        maxWidth="sm"
        open={open}
      >
        <form onSubmit={handleSubmit} name="signInForm" noValidate ref={formRef}>
          <StyledDialogTitle title={t("SignIn.SignInButton")} onClickHandler={closePopup} />
          <StyledDialogContent>
            <StyledTextField
              name="logonId"
              onChange={(e) => handleOnChange(e, "username")}
              innerRef={usernameInput}
              value={username}
              required
              margin="normal"
              fullWidth
              inputProps={logonInputProps}
              label={logonInputProps.label}
            />
            <StyledTextField
              label={t("SessionError.Password")}
              type="password"
              onChange={(e) => handleOnChange(e, "password")}
              name="logonPassword"
              required
              margin="normal"
              fullWidth
            />
            <StyledDialogActions>
              <StyledButton
                testId="session-error-cancel"
                color="secondary"
                size="small"
                onClick={closePopup}
              >
                {t("SessionError.CancelButton")}
              </StyledButton>
              <StyledButton
                testId="session-error-submit"
                color="primary"
                size="small"
                type="submit"
                disabled={disabled}
              >
                {t("SessionError.SubmitButton")}
              </StyledButton>
            </StyledDialogActions>
          </StyledDialogContent>
        </form>
      </StyledDialog>
    </>
  );
};
