/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import {
  ENTITLED_ORG_REQUESTED,
  ENTITLED_ORG_REQUEST_SUCCESS,
  ENTITLED_ORG_REQUEST_ERROR,
  ORG_SWITCH_REQUESTED,
  ORG_SWITCH_REQUEST_ERROR,
  GET_ORGANIZATION_ADDRESS,
  GET_ORGANIZATION_ADDRESS_SUCCESS,
  GET_ORGANIZATION_ADDRESS_ERROR,
  GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS,
  GET_ORGANIZATION_BILLING_ADDRESS_ERROR,
  ENTITLED_ORG_SEGMENT_REQUEST_SUCCESS,
  ENTITLED_ORG_SEGMENT_REQUEST_ERROR,
  ENTITLED_ORG_LOYALTY_REQUEST_SUCCESS,
  ENTITLED_ORG_LOYALTY_REQUEST_ERROR,
  GET_ACCELERATEPGMDATA_SUCCESS,
  GET_ACCELERATEPGMDATA_ERROR,
  GET_STANDING_ORDER_SUCCESS,
  GET_STANDING_ORDER_ERROR,
  GET_EMPLOYEE_PURCHASE_SUCCESS,
  GET_EMPLOYEE_PURCHASE_ERROR,

  SET_USER_ASSGINED_ROLES,
  ORG_SWITCH_REQUEST_SUCCESS,
  ENTITLED_ORG_CUSTOMER_PN_REQUEST_SUCCESS,
  ENTITLED_ORG_CUSTOMER_PN_REQUEST_ERROR,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_ERROR,
  NOTIFICATIONS_UPDATE,
  NOTIFICATIONS_REQUEST,
} from "../action-types/organization";

const ENTITLED_ORG_ACTION = createAction<any, string>(ENTITLED_ORG_REQUESTED);

const ENTITLED_ORG_SUCCESS_ACTION = createAction<any, string>(ENTITLED_ORG_REQUEST_SUCCESS);

const ENTITLED_ORG_ERROR_ACTION = createAction<any, string>(ENTITLED_ORG_REQUEST_ERROR);

const ENTITLED_ORG_SEGMENT_SUCCESS_ACTION = createAction<any, string>(ENTITLED_ORG_SEGMENT_REQUEST_SUCCESS);

const SET_ASSGINED_ROLES = createAction<any, string>(SET_USER_ASSGINED_ROLES);

const ENTITLED_ORG_SEGMENT_ERROR_ACTION = createAction<any, string>(ENTITLED_ORG_SEGMENT_REQUEST_ERROR);

const ENTITLED_ORG_LOYALTY_SUCCESS_ACTION = createAction<any, string>(ENTITLED_ORG_LOYALTY_REQUEST_SUCCESS);

const ENTITLED_ORG_LOYALTY_ERROR_ACTION = createAction<any, string>(ENTITLED_ORG_LOYALTY_REQUEST_ERROR);

const ENTITLED_ORG_CPN_SUCCESS_ACTION = createAction<any, string>(ENTITLED_ORG_CUSTOMER_PN_REQUEST_SUCCESS);
const ENTITLED_ORG_CPN_ERROR_ACTION = createAction<any, string>(ENTITLED_ORG_CUSTOMER_PN_REQUEST_ERROR);

const ORG_SWITCH_ACTION = createAction<any, string>(ORG_SWITCH_REQUESTED);
const ORG_SWITCH_SUCCESS_ACTION = createAction<any, string>(ORG_SWITCH_REQUEST_SUCCESS);
const ORG_SWITCH_ERROR_ACTION = createAction<any, string>(ORG_SWITCH_REQUEST_ERROR);

const GET_ORGANIZATION_ADDRESS_ACTION = createAction<any, string>(GET_ORGANIZATION_ADDRESS);
const GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION = createAction<any, string>(GET_ORGANIZATION_ADDRESS_SUCCESS);
const GET_ORGANIZATION_ADDRESS_ERROR_ACTION = createAction<any, string>(GET_ORGANIZATION_ADDRESS_ERROR);

const GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION = createAction<any, string>(GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS);
const GET_ORGANIZATION_BILLING_ADDRESS_ERROR_ACTION = createAction<any, string>(GET_ORGANIZATION_BILLING_ADDRESS_ERROR);

const GET_ACCELERATEPGMDATA_SUCCESS_ACTION = createAction<any, string>(GET_ACCELERATEPGMDATA_SUCCESS);
const GET_ACCELERATEPGMDATA_ERROR_ACTION = createAction<any, string>(GET_ACCELERATEPGMDATA_ERROR);

const GET_STANDING_ORDER_SUCCESS_ACTION = createAction<any, string>(GET_STANDING_ORDER_SUCCESS);
const GET_STANDING_ORDER_ERROR_ACTION = createAction<any, string>(GET_STANDING_ORDER_ERROR);

const GET_EMPLOYEE_PURCHASE_SUCCESS_ACTION = createAction<any, string>(GET_EMPLOYEE_PURCHASE_SUCCESS);
const GET_EMPLOYEE_PURCHASE_ERROR_ACTION = createAction<any, string>(GET_EMPLOYEE_PURCHASE_ERROR);

const NOTIFICATIONS_SUCCESS_ACTION = createAction<any, string>(NOTIFICATIONS_SUCCESS);
const NOTIFICATIONS_ERROR_ACTION = createAction<any, string>(NOTIFICATIONS_ERROR);
const NOTIFICATIONS_UPDATE_ACTION = createAction<any, string>(NOTIFICATIONS_UPDATE);
const NOTIFICATIONS_REQUEST_ACTION = createAction<any, string>(NOTIFICATIONS_REQUEST);

export {
  ENTITLED_ORG_ACTION,
  ENTITLED_ORG_SUCCESS_ACTION,
  ENTITLED_ORG_ERROR_ACTION,
  ORG_SWITCH_ACTION,
  ORG_SWITCH_ERROR_ACTION,
  ORG_SWITCH_SUCCESS_ACTION,
  GET_ORGANIZATION_ADDRESS_ACTION,
  GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION,
  GET_ORGANIZATION_ADDRESS_ERROR_ACTION,
  ENTITLED_ORG_SEGMENT_SUCCESS_ACTION,
  ENTITLED_ORG_SEGMENT_ERROR_ACTION,
  ENTITLED_ORG_LOYALTY_SUCCESS_ACTION,
  ENTITLED_ORG_LOYALTY_ERROR_ACTION,
  ENTITLED_ORG_CPN_ERROR_ACTION,
  ENTITLED_ORG_CPN_SUCCESS_ACTION,
  GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION,
  GET_ORGANIZATION_BILLING_ADDRESS_ERROR_ACTION,
  GET_ACCELERATEPGMDATA_SUCCESS_ACTION,
  GET_ACCELERATEPGMDATA_ERROR_ACTION,
  GET_STANDING_ORDER_SUCCESS_ACTION,
  GET_STANDING_ORDER_ERROR_ACTION,
  GET_EMPLOYEE_PURCHASE_SUCCESS_ACTION,
  GET_EMPLOYEE_PURCHASE_ERROR_ACTION,
  SET_ASSGINED_ROLES,
  NOTIFICATIONS_SUCCESS_ACTION,
  NOTIFICATIONS_ERROR_ACTION,
  NOTIFICATIONS_UPDATE_ACTION,
  NOTIFICATIONS_REQUEST_ACTION
};