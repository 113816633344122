/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CHCGetUserIdApi - axios parameter creator
 * @export
 */
export const CHCGetUserIdApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get userid.
         * @summary Get user id.
         * @param {string} storeId The store identifier.
         * @param {string} firstName first Name
         * @param {string} lastName last Name
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcPersonGetChcUserId: async (storeId: string, firstName: string, lastName: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcPersonGetChcUserId', 'storeId', storeId)
            // verify required parameter 'firstName' is not null or undefined
            assertParamExists('chcPersonGetChcUserId', 'firstName', firstName)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('chcPersonGetChcUserId', 'lastName', lastName)
            const localVarPath = `/store/{storeId}/chc_person/getChcUserId`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCGetUserIdApi - functional programming interface
 * @export
 */
export const CHCGetUserIdApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCGetUserIdApiAxiosParamCreator(configuration)
    return {
        /**
         * Get userid.
         * @summary Get user id.
         * @param {string} storeId The store identifier.
         * @param {string} firstName first Name
         * @param {string} lastName last Name
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcPersonGetChcUserId(storeId: string, firstName: string, lastName: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcPersonGetChcUserId(storeId, firstName, lastName, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCGetUserIdApi - factory interface
 * @export
 */
export const CHCGetUserIdApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCGetUserIdApiFp(configuration)
    return {
        /**
         * Get userid.
         * @summary Get user id.
         * @param {string} storeId The store identifier.
         * @param {string} firstName first Name
         * @param {string} lastName last Name
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcPersonGetChcUserId(storeId: string, firstName: string, lastName: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcPersonGetChcUserId(storeId, firstName, lastName, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCGetUserIdApi - object-oriented interface
 * @export
 * @class CHCGetUserIdApi
 * @extends {BaseAPI}
 */
export class CHCGetUserIdApi extends BaseAPI {
    /**
     * Get userid.
     * @summary Get user id.
     * @param {string} storeId The store identifier.
     * @param {string} firstName first Name
     * @param {string} lastName last Name
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCGetUserIdApi
     */
    public chcPersonGetChcUserId(storeId: string, firstName: string, lastName: string, responseFormat?: string, options?: any) {
        return CHCGetUserIdApiFp(this.configuration).chcPersonGetChcUserId(storeId, firstName, lastName, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
