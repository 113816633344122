/* tslint:disable */
/* eslint-disable */
/**
 * Approval Status
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleAssignmentPermission } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleAssignmentPermissionCollection } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * RoleAssignmentPermissionsApi - axios parameter creator
 * @export
 */
export const RoleAssignmentPermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a role assignment permission.
         * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleAssignmentPermission: async (roleAssignmentPermission: RoleAssignmentPermission, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleAssignmentPermission' is not null or undefined
            assertParamExists('createRoleAssignmentPermission', 'roleAssignmentPermission', roleAssignmentPermission)
            const localVarPath = `/role-assignment-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleAssignmentPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a role assignment permission.
         * @param {number} id The role assignment permission ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleAssignmentPermissionById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRoleAssignmentPermissionById', 'id', id)
            const localVarPath = `/role-assignment-permissions/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a role assignment permission ID.
         * @param {number} id The role assignment permission ID.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignmentPermissionById: async (id: number, fields?: string, expand?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRoleAssignmentPermissionById', 'id', id)
            const localVarPath = `/role-assignment-permissions/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a collection of role assignment permissions.
         * @param {number} [id] The role assignment permission ID.
         * @param {number} [assigningRoleId] The assigning role ID
         * @param {number} [assignableRoleId] The assignable role ID
         * @param {number} [organizationId] The organization ID.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignmentPermissions: async (id?: number, assigningRoleId?: number, assignableRoleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/role-assignment-permissions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (assigningRoleId !== undefined) {
                localVarQueryParameter['assigningRoleId'] = assigningRoleId;
            }

            if (assignableRoleId !== undefined) {
                localVarQueryParameter['assignableRoleId'] = assignableRoleId;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id The role assignment permission ID.
         * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleAssignmentPermissionById: async (id: number, roleAssignmentPermission: RoleAssignmentPermission, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateRoleAssignmentPermissionById', 'id', id)
            // verify required parameter 'roleAssignmentPermission' is not null or undefined
            assertParamExists('updateRoleAssignmentPermissionById', 'roleAssignmentPermission', roleAssignmentPermission)
            const localVarPath = `/role-assignment-permissions/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleAssignmentPermission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleAssignmentPermissionsApi - functional programming interface
 * @export
 */
export const RoleAssignmentPermissionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleAssignmentPermissionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a role assignment permission.
         * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleAssignmentPermission(roleAssignmentPermission: RoleAssignmentPermission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleAssignmentPermission(roleAssignmentPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a role assignment permission.
         * @param {number} id The role assignment permission ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleAssignmentPermissionById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleAssignmentPermissionById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a role assignment permission ID.
         * @param {number} id The role assignment permission ID.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleAssignmentPermissionById(id: number, fields?: string, expand?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAssignmentPermission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleAssignmentPermissionById(id, fields, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a collection of role assignment permissions.
         * @param {number} [id] The role assignment permission ID.
         * @param {number} [assigningRoleId] The assigning role ID
         * @param {number} [assignableRoleId] The assignable role ID
         * @param {number} [organizationId] The organization ID.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleAssignmentPermissions(id?: number, assigningRoleId?: number, assignableRoleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleAssignmentPermissionCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleAssignmentPermissions(id, assigningRoleId, assignableRoleId, organizationId, offset, limit, fields, expand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id The role assignment permission ID.
         * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoleAssignmentPermissionById(id: number, roleAssignmentPermission: RoleAssignmentPermission, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoleAssignmentPermissionById(id, roleAssignmentPermission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleAssignmentPermissionsApi - factory interface
 * @export
 */
export const RoleAssignmentPermissionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleAssignmentPermissionsApiFp(configuration)
    return {
        /**
         * Create a role assignment permission.
         * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleAssignmentPermission(roleAssignmentPermission: RoleAssignmentPermission, options?: any): AxiosPromise<void> {
            return localVarFp.createRoleAssignmentPermission(roleAssignmentPermission, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a role assignment permission.
         * @param {number} id The role assignment permission ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleAssignmentPermissionById(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRoleAssignmentPermissionById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a role assignment permission ID.
         * @param {number} id The role assignment permission ID.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignmentPermissionById(id: number, fields?: string, expand?: string, options?: any): AxiosPromise<RoleAssignmentPermission> {
            return localVarFp.getRoleAssignmentPermissionById(id, fields, expand, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a collection of role assignment permissions.
         * @param {number} [id] The role assignment permission ID.
         * @param {number} [assigningRoleId] The assigning role ID
         * @param {number} [assignableRoleId] The assignable role ID
         * @param {number} [organizationId] The organization ID.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleAssignmentPermissions(id?: number, assigningRoleId?: number, assignableRoleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any): AxiosPromise<RoleAssignmentPermissionCollection> {
            return localVarFp.getRoleAssignmentPermissions(id, assigningRoleId, assignableRoleId, organizationId, offset, limit, fields, expand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id The role assignment permission ID.
         * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleAssignmentPermissionById(id: number, roleAssignmentPermission: RoleAssignmentPermission, options?: any): AxiosPromise<void> {
            return localVarFp.updateRoleAssignmentPermissionById(id, roleAssignmentPermission, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleAssignmentPermissionsApi - object-oriented interface
 * @export
 * @class RoleAssignmentPermissionsApi
 * @extends {BaseAPI}
 */
export class RoleAssignmentPermissionsApi extends BaseAPI {
    /**
     * Create a role assignment permission.
     * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentPermissionsApi
     */
    public createRoleAssignmentPermission(roleAssignmentPermission: RoleAssignmentPermission, options?: any) {
        return RoleAssignmentPermissionsApiFp(this.configuration).createRoleAssignmentPermission(roleAssignmentPermission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a role assignment permission.
     * @param {number} id The role assignment permission ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentPermissionsApi
     */
    public deleteRoleAssignmentPermissionById(id: number, options?: any) {
        return RoleAssignmentPermissionsApiFp(this.configuration).deleteRoleAssignmentPermissionById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a role assignment permission ID.
     * @param {number} id The role assignment permission ID.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentPermissionsApi
     */
    public getRoleAssignmentPermissionById(id: number, fields?: string, expand?: string, options?: any) {
        return RoleAssignmentPermissionsApiFp(this.configuration).getRoleAssignmentPermissionById(id, fields, expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a collection of role assignment permissions.
     * @param {number} [id] The role assignment permission ID.
     * @param {number} [assigningRoleId] The assigning role ID
     * @param {number} [assignableRoleId] The assignable role ID
     * @param {number} [organizationId] The organization ID.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentPermissionsApi
     */
    public getRoleAssignmentPermissions(id?: number, assigningRoleId?: number, assignableRoleId?: number, organizationId?: number, offset?: number, limit?: number, fields?: string, expand?: string, options?: any) {
        return RoleAssignmentPermissionsApiFp(this.configuration).getRoleAssignmentPermissions(id, assigningRoleId, assignableRoleId, organizationId, offset, limit, fields, expand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id The role assignment permission ID.
     * @param {RoleAssignmentPermission} roleAssignmentPermission The role assignment permission.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleAssignmentPermissionsApi
     */
    public updateRoleAssignmentPermissionById(id: number, roleAssignmentPermission: RoleAssignmentPermission, options?: any) {
        return RoleAssignmentPermissionsApiFp(this.configuration).updateRoleAssignmentPermissionById(id, roleAssignmentPermission, options).then((request) => request(this.axios, this.basePath));
    }
}
