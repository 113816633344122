/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * AccelerateEligibilityApi - axios parameter creator
 * @export
 */
export const AccelerateEligibilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Accelerate Pgm Eligibility.
         * @summary Get Accelerate Eligibility.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} profileName Profile Name.
         * @param {string} checkBuyerAdminInBillToOrg Check BuyerAdmin In BillToOrg.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceleratePgmEligibility: async (storeId: string, organizationId: string, profileName: string, checkBuyerAdminInBillToOrg: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('acceleratePgmEligibility', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('acceleratePgmEligibility', 'organizationId', organizationId)
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('acceleratePgmEligibility', 'profileName', profileName)
            // verify required parameter 'checkBuyerAdminInBillToOrg' is not null or undefined
            assertParamExists('acceleratePgmEligibility', 'checkBuyerAdminInBillToOrg', checkBuyerAdminInBillToOrg)
            const localVarPath = `/store/{storeId}/chc_organization/{organizationId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"organizationId"}}`, String(organizationId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (checkBuyerAdminInBillToOrg !== undefined) {
                localVarQueryParameter['checkBuyerAdminInBillToOrg'] = checkBuyerAdminInBillToOrg;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccelerateEligibilityApi - functional programming interface
 * @export
 */
export const AccelerateEligibilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccelerateEligibilityApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Accelerate Pgm Eligibility.
         * @summary Get Accelerate Eligibility.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} profileName Profile Name.
         * @param {string} checkBuyerAdminInBillToOrg Check BuyerAdmin In BillToOrg.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceleratePgmEligibility(storeId: string, organizationId: string, profileName: string, checkBuyerAdminInBillToOrg: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceleratePgmEligibility(storeId, organizationId, profileName, checkBuyerAdminInBillToOrg, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccelerateEligibilityApi - factory interface
 * @export
 */
export const AccelerateEligibilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccelerateEligibilityApiFp(configuration)
    return {
        /**
         * Get Accelerate Pgm Eligibility.
         * @summary Get Accelerate Eligibility.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} profileName Profile Name.
         * @param {string} checkBuyerAdminInBillToOrg Check BuyerAdmin In BillToOrg.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceleratePgmEligibility(storeId: string, organizationId: string, profileName: string, checkBuyerAdminInBillToOrg: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.acceleratePgmEligibility(storeId, organizationId, profileName, checkBuyerAdminInBillToOrg, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccelerateEligibilityApi - object-oriented interface
 * @export
 * @class AccelerateEligibilityApi
 * @extends {BaseAPI}
 */
export class AccelerateEligibilityApi extends BaseAPI {
    /**
     * Get Accelerate Pgm Eligibility.
     * @summary Get Accelerate Eligibility.
     * @param {string} storeId store Id
     * @param {string} organizationId Organization Id.
     * @param {string} profileName Profile Name.
     * @param {string} checkBuyerAdminInBillToOrg Check BuyerAdmin In BillToOrg.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccelerateEligibilityApi
     */
    public acceleratePgmEligibility(storeId: string, organizationId: string, profileName: string, checkBuyerAdminInBillToOrg: string, responseFormat?: string, options?: any) {
        return AccelerateEligibilityApiFp(this.configuration).acceleratePgmEligibility(storeId, organizationId, profileName, checkBuyerAdminInBillToOrg, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
