/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CHCPaymentMethodShareCreditCardApi - axios parameter creator
 * @export
 */
export const CHCPaymentMethodShareCreditCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user\'s shared credit card.
         * @summary Get user\'s shared credit card.
         * @param {string} storeId The store identifier.
         * @param {string} orderId order Id
         * @param {string} share share status
         * @param {string} uRL URL
         * @param {string} errorView errorView
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcPersonShareCreditCard: async (storeId: string, orderId: string, share: string, uRL: string, errorView: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcPersonShareCreditCard', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('chcPersonShareCreditCard', 'orderId', orderId)
            // verify required parameter 'share' is not null or undefined
            assertParamExists('chcPersonShareCreditCard', 'share', share)
            // verify required parameter 'uRL' is not null or undefined
            assertParamExists('chcPersonShareCreditCard', 'uRL', uRL)
            // verify required parameter 'errorView' is not null or undefined
            assertParamExists('chcPersonShareCreditCard', 'errorView', errorView)
            const localVarPath = `/store/{storeId}/chc_person/shareCreditCard`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (share !== undefined) {
                localVarQueryParameter['share'] = share;
            }

            if (uRL !== undefined) {
                localVarQueryParameter['URL'] = uRL;
            }

            if (errorView !== undefined) {
                localVarQueryParameter['errorView'] = errorView;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCPaymentMethodShareCreditCardApi - functional programming interface
 * @export
 */
export const CHCPaymentMethodShareCreditCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCPaymentMethodShareCreditCardApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user\'s shared credit card.
         * @summary Get user\'s shared credit card.
         * @param {string} storeId The store identifier.
         * @param {string} orderId order Id
         * @param {string} share share status
         * @param {string} uRL URL
         * @param {string} errorView errorView
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcPersonShareCreditCard(storeId: string, orderId: string, share: string, uRL: string, errorView: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcPersonShareCreditCard(storeId, orderId, share, uRL, errorView, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCPaymentMethodShareCreditCardApi - factory interface
 * @export
 */
export const CHCPaymentMethodShareCreditCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCPaymentMethodShareCreditCardApiFp(configuration)
    return {
        /**
         * Get user\'s shared credit card.
         * @summary Get user\'s shared credit card.
         * @param {string} storeId The store identifier.
         * @param {string} orderId order Id
         * @param {string} share share status
         * @param {string} uRL URL
         * @param {string} errorView errorView
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcPersonShareCreditCard(storeId: string, orderId: string, share: string, uRL: string, errorView: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcPersonShareCreditCard(storeId, orderId, share, uRL, errorView, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCPaymentMethodShareCreditCardApi - object-oriented interface
 * @export
 * @class CHCPaymentMethodShareCreditCardApi
 * @extends {BaseAPI}
 */
export class CHCPaymentMethodShareCreditCardApi extends BaseAPI {
    /**
     * Get user\'s shared credit card.
     * @summary Get user\'s shared credit card.
     * @param {string} storeId The store identifier.
     * @param {string} orderId order Id
     * @param {string} share share status
     * @param {string} uRL URL
     * @param {string} errorView errorView
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCPaymentMethodShareCreditCardApi
     */
    public chcPersonShareCreditCard(storeId: string, orderId: string, share: string, uRL: string, errorView: string, responseFormat?: string, options?: any) {
        return CHCPaymentMethodShareCreditCardApiFp(this.configuration).chcPersonShareCreditCard(storeId, orderId, share, uRL, errorView, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
