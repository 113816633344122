/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ShipFromDetail } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCCartApi - axios parameter creator
 * @export
 */
export const CHCCartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get ShipFrom center from HCL DB
         * @summary Get Ship From Details.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [storeLocId] 
         * @param {number} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCShipFrom: async (storeId: string, responseFormat?: string, storeLocId?: string, langId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCShipFrom', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/CHCorder/ShipFrom`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (storeLocId !== undefined) {
                localVarQueryParameter['storeLocId'] = storeLocId;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCCartApi - functional programming interface
 * @export
 */
export const CHCCartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCCartApiAxiosParamCreator(configuration)
    return {
        /**
         * Get ShipFrom center from HCL DB
         * @summary Get Ship From Details.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [storeLocId] 
         * @param {number} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCShipFrom(storeId: string, responseFormat?: string, storeLocId?: string, langId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShipFromDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCShipFrom(storeId, responseFormat, storeLocId, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCCartApi - factory interface
 * @export
 */
export const CHCCartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCCartApiFp(configuration)
    return {
        /**
         * Get ShipFrom center from HCL DB
         * @summary Get Ship From Details.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [storeLocId] 
         * @param {number} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCShipFrom(storeId: string, responseFormat?: string, storeLocId?: string, langId?: number, options?: any): AxiosPromise<ShipFromDetail> {
            return localVarFp.cHCShipFrom(storeId, responseFormat, storeLocId, langId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCCartApi - object-oriented interface
 * @export
 * @class CHCCartApi
 * @extends {BaseAPI}
 */
export class CHCCartApi extends BaseAPI {
    /**
     * Get ShipFrom center from HCL DB
     * @summary Get Ship From Details.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml.
     * @param {string} [storeLocId] 
     * @param {number} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCCartApi
     */
    public cHCShipFrom(storeId: string, responseFormat?: string, storeLocId?: string, langId?: number, options?: any) {
        return CHCCartApiFp(this.configuration).cHCShipFrom(storeId, responseFormat, storeLocId, langId, options).then((request) => request(this.axios, this.basePath));
    }
}
