/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CHCRequisitionListAddApi - axios parameter creator
 * @export
 */
export const CHCRequisitionListAddApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add to Requisition list from PDP.
         * @summary Add to Requisition list.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcAddRequisitionLists: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcAddRequisitionLists', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/chc_requisition_list/CHCRequisitionListDataBean_Self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCRequisitionListAddApi - functional programming interface
 * @export
 */
export const CHCRequisitionListAddApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCRequisitionListAddApiAxiosParamCreator(configuration)
    return {
        /**
         * Add to Requisition list from PDP.
         * @summary Add to Requisition list.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcAddRequisitionLists(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcAddRequisitionLists(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCRequisitionListAddApi - factory interface
 * @export
 */
export const CHCRequisitionListAddApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCRequisitionListAddApiFp(configuration)
    return {
        /**
         * Add to Requisition list from PDP.
         * @summary Add to Requisition list.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcAddRequisitionLists(storeId: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcAddRequisitionLists(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCRequisitionListAddApi - object-oriented interface
 * @export
 * @class CHCRequisitionListAddApi
 * @extends {BaseAPI}
 */
export class CHCRequisitionListAddApi extends BaseAPI {
    /**
     * Add to Requisition list from PDP.
     * @summary Add to Requisition list.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCRequisitionListAddApi
     */
    public chcAddRequisitionLists(storeId: string, responseFormat?: string, options?: any) {
        return CHCRequisitionListAddApiFp(this.configuration).chcAddRequisitionLists(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
