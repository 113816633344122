/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import * as ACTIONS from "../action-types/invoice";


/**
 * Invoice List reducer
 * handles states used by invoice related components
 * @param state State object managed by invoice list reducer
 * @param action The dispatched action
 */
const invoiceReducer = createReducer(initStates.invoice, (builder) => {
  builder.addCase(ACTIONS.INVOICE_GET_REQUESTED, (state, action: AnyAction) => {
    state.status = "pending";
  });
   builder.addCase(ACTIONS.INVOICE_GET_ERROR, (state, action: AnyAction) => {
    state.status = "error";
  });
  builder.addCase(ACTIONS.INVOICE_GET_SUCCESS, (state, action: AnyAction) => {
    state.invoiceItems = action.response;
    state.status = "success";
  });

});
export default invoiceReducer;
