/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries

//Foundation libraries
import { getSite } from "../../hooks/useSite";
import {AccelerateProgramDipBalanceApi } from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../../constants/site";

const accelerateProgramDipBalanceApiInstance = new AccelerateProgramDipBalanceApi(
  undefined,
  site.transactionContext
);

const chcAccelerateDipBalanceService = {
  /**
   * This APi fetches ship from Detail from HCL DB.
   * `@method`
   * `@name getShipFromDetail`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get ship from details.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to ship from details.
   */
   accelerateProgramDipBalance(parameters: {storeId?: string, abk: string, responseFormat?: string}) {
    const siteInfo = getSite();
    const { storeId, abk, responseFormat } = parameters;
    return accelerateProgramDipBalanceApiInstance.cHCAccelerateProgramDipBalance(storeId || siteInfo?.storeID || '',  
    abk,
        responseFormat, 
        {
          abk,
        }
    );
  },  
};
export default chcAccelerateDipBalanceService;
