/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * ValidateCaptchaApi - axios parameter creator
 * @export
 */
export const ValidateCaptchaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Validate Google captcha.
         * @summary Validate captcha.
         * @param {string} storeId store Id
         * @param {string} captchaToken Google Captcha.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGoogleCaptcha: async (storeId: string, captchaToken: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('validateGoogleCaptcha', 'storeId', storeId)
            // verify required parameter 'captchaToken' is not null or undefined
            assertParamExists('validateGoogleCaptcha', 'captchaToken', captchaToken)
            const localVarPath = `/store/{storeId}/chc_person/validateCaptcha`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (captchaToken !== undefined) {
                localVarQueryParameter['captchaToken'] = captchaToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ValidateCaptchaApi - functional programming interface
 * @export
 */
export const ValidateCaptchaApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ValidateCaptchaApiAxiosParamCreator(configuration)
    return {
        /**
         * Validate Google captcha.
         * @summary Validate captcha.
         * @param {string} storeId store Id
         * @param {string} captchaToken Google Captcha.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateGoogleCaptcha(storeId: string, captchaToken: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateGoogleCaptcha(storeId, captchaToken, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ValidateCaptchaApi - factory interface
 * @export
 */
export const ValidateCaptchaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ValidateCaptchaApiFp(configuration)
    return {
        /**
         * Validate Google captcha.
         * @summary Validate captcha.
         * @param {string} storeId store Id
         * @param {string} captchaToken Google Captcha.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateGoogleCaptcha(storeId: string, captchaToken: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.validateGoogleCaptcha(storeId, captchaToken, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ValidateCaptchaApi - object-oriented interface
 * @export
 * @class ValidateCaptchaApi
 * @extends {BaseAPI}
 */
export class ValidateCaptchaApi extends BaseAPI {
    /**
     * Validate Google captcha.
     * @summary Validate captcha.
     * @param {string} storeId store Id
     * @param {string} captchaToken Google Captcha.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidateCaptchaApi
     */
    public validateGoogleCaptcha(storeId: string, captchaToken: string, responseFormat?: string, options?: any) {
        return ValidateCaptchaApiFp(this.configuration).validateGoogleCaptcha(storeId, captchaToken, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
