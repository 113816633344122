/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { CHCPaymentMethodAddCardApi, CHCPaymentMethodRemoveSharedCardApi, CHCPaymentMethodShareCreditCardApi } from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../../constants/site";

const addCardApiInstance = new CHCPaymentMethodAddCardApi(
  undefined,
  site.transactionContext
);
const removeCardApiInstance = new CHCPaymentMethodRemoveSharedCardApi(
  undefined,
  site.transactionContext
);
const shareCardApiInstance = new CHCPaymentMethodShareCreditCardApi(
  undefined,
  site.transactionContext
);

const chcPaymentMethodService = {
  /**
   * This allows a user to find his own entitled organizations.
   * `@method`
   * `@name addCard`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   */
   addCard(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, acctNumber, expMonth, expYear, name, cvv, address1, city, state, country, zipCode, responseFormat, uRL, errorView, ...options } = parameters;
    
    return addCardApiInstance.chcPersonAddNewCard(
        storeId, acctNumber, expMonth, expYear, name, cvv, address1, city, state, country, zipCode, responseFormat, uRL, errorView, options
      
    );
  },

  /**
   * This allows a user to add organization to the user profile.
   * `@method`
   * `@name RemoveCard`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} accountNumber (required)` Account number to add to profile
   ** `@property {string} postalCode (required)` Postal code of the account number
   */
   removeCard(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, orderId, uRL, errorView, responseFormat, ...options } = parameters;
    
    return removeCardApiInstance.chcPersonRemoveSharedCard(
        storeId, orderId, uRL, errorView, responseFormat, options
    );
  },

  /**
   * This allows a user to send a request to create an organization lead.
   * `@method`
   * `@name shareCard`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} billToAcount ` BillTo Account to be created.
   ** `@property {string} shipToAccountName ` Name of the account.
   ** `@property {string} shipToAddress_1 ` ShipTo Address.
   ** `@property {string} address_city ` ShipTo city.
   ** `@property {string} address_province ` ShipTo province.
   ** `@property {string} address_postal_code ` ShipTo postal code.
   ** `@property {string} phone ` Contact number.
   */
   sharedCard(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, orderId, share, uRL, errorView, responseFormat, ...options } = parameters;
    
    return shareCardApiInstance.chcPersonShareCreditCard(
        storeId, orderId, share, uRL, errorView, responseFormat, options
    );
  },
}

  

export default chcPaymentMethodService;
