/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CheckActiveOrgReportViewerRoleApi - axios parameter creator
 * @export
 */
export const CheckActiveOrgReportViewerRoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check Report Viewer role of active organization.
         * @summary Check Report Viewer Role.
         * @param {string} storeId store Id
         * @param {string} activeOrgId Active Organization Id.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcActiveOrgRole: async (storeId: string, activeOrgId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcActiveOrgRole', 'storeId', storeId)
            // verify required parameter 'activeOrgId' is not null or undefined
            assertParamExists('chcActiveOrgRole', 'activeOrgId', activeOrgId)
            const localVarPath = `/store/{storeId}/chc_organization/checkActiveOrgReportViewerRole`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckActiveOrgReportViewerRoleApi - functional programming interface
 * @export
 */
export const CheckActiveOrgReportViewerRoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckActiveOrgReportViewerRoleApiAxiosParamCreator(configuration)
    return {
        /**
         * Check Report Viewer role of active organization.
         * @summary Check Report Viewer Role.
         * @param {string} storeId store Id
         * @param {string} activeOrgId Active Organization Id.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcActiveOrgRole(storeId: string, activeOrgId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcActiveOrgRole(storeId, activeOrgId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckActiveOrgReportViewerRoleApi - factory interface
 * @export
 */
export const CheckActiveOrgReportViewerRoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckActiveOrgReportViewerRoleApiFp(configuration)
    return {
        /**
         * Check Report Viewer role of active organization.
         * @summary Check Report Viewer Role.
         * @param {string} storeId store Id
         * @param {string} activeOrgId Active Organization Id.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcActiveOrgRole(storeId: string, activeOrgId: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcActiveOrgRole(storeId, activeOrgId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckActiveOrgReportViewerRoleApi - object-oriented interface
 * @export
 * @class CheckActiveOrgReportViewerRoleApi
 * @extends {BaseAPI}
 */
export class CheckActiveOrgReportViewerRoleApi extends BaseAPI {
    /**
     * Check Report Viewer role of active organization.
     * @summary Check Report Viewer Role.
     * @param {string} storeId store Id
     * @param {string} activeOrgId Active Organization Id.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckActiveOrgReportViewerRoleApi
     */
    public chcActiveOrgRole(storeId: string, activeOrgId: string, responseFormat?: string, options?: any) {
        return CheckActiveOrgReportViewerRoleApiFp(this.configuration).chcActiveOrgRole(storeId, activeOrgId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
