/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { debounce } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../action-types/price-server";
import * as WORKERS from "../workers/price-server";

/**
 * Catalog watch saga
 * watchers to intercept catalog actions
 */
export function* watchSaga() {
  yield debounce(50, ACTIONS.PRODUCT_LIST_PRICE_FOR_PDP_GET_REQUESTED, WORKERS.fetchProductListPriceForPDP);
  
}
