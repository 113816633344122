/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { CHCOrganizationApi, CHCAddOrganizationApi, CHCCreateOrganizationApi, CHCRemoveOrganizationApi, PickdayApi, OrgAttributeApi, ShipToBillToApi, SendNewResidentFormApi, AccelerateEligibilityApi, OrganizationSummaryApi, CheckEmployeeOrgApi, SetPhysicalStoreCookieApi, CheckActiveOrgReportViewerRoleApi } from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../../constants/site";

const organizationApiInstance = new CHCOrganizationApi(
  undefined,
  site.transactionContext
);
const addOrganizationApiInstance = new CHCAddOrganizationApi(
  undefined,
  site.transactionContext
);
const createOrganizationApiInstance = new CHCCreateOrganizationApi(
  undefined,
  site.transactionContext
);
const removeOrganizationApiInstance = new CHCRemoveOrganizationApi(
  undefined,
  site.transactionContext
);

const shipToBillToApiInstance = new ShipToBillToApi(
  undefined,
  site.transactionContext
);

const pickdayListApiInstance = new PickdayApi(
  undefined,
  site.transactionContext
);

const orgAttrApiInstance = new OrgAttributeApi(
  undefined,
  site.transactionContext
);

const newResidentApiInstance = new SendNewResidentFormApi(
  undefined,
  site.transactionContext
);

const acceleratePgmApiInstance = new AccelerateEligibilityApi(
  undefined,
  site.transactionContext
);

const chcOrgShortSummaryApiInstance = new OrganizationSummaryApi(
  undefined,
  site.transactionContext
);

const checkEmployeeOrgApiInstance = new CheckEmployeeOrgApi(
  undefined,
  site.transactionContext
);

const setPhysicalStoreCookieApiInstance = new SetPhysicalStoreCookieApi
(
  undefined,
  site.transactionContext
);

const checkActiveOrgReportViewerRoleApiInstance = new CheckActiveOrgReportViewerRoleApi(
  undefined,
  site.transactionContext
);
const chcOrganizationService = {
  /**
   * This allows a user to find his own entitled organizations.
   * `@method`
   * `@name Organization#getEntitledOrganizations`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   */
   getEntitledOrganizations(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, accountCheck, explicitEntitlement, responseFormat, ...options } = parameters;
    
    return organizationApiInstance.chcOrganizationGetEntitledOrganizations(
      storeId,
      responseFormat,
      accountCheck,
      explicitEntitlement,
      options
    );
  },

  /**
   * This allows a user to add organization to the user profile.
   * `@method`
   * `@name AddOrganization`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} accountNumber (required)` Account number to add to profile
   ** `@property {string} postalCode (required)` Postal code of the account number
   */
   addOrganization(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, accountNumber, postalCode, uRL, responseFormat, ...options } = parameters;
    
    return addOrganizationApiInstance.chcAddOrganization(
      storeId,
      accountNumber,
      postalCode,
      uRL,
      responseFormat,
      options
    );
  },

  /**
   * This allows a user to send a request to create an organization lead.
   * `@method`
   * `@name CreateOrganization`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} billToAcount ` BillTo Account to be created.
   ** `@property {string} shipToAccountName ` Name of the account.
   ** `@property {string} shipToAddress_1 ` ShipTo Address.
   ** `@property {string} address_city ` ShipTo city.
   ** `@property {string} address_province ` ShipTo province.
   ** `@property {string} address_postal_code ` ShipTo postal code.
   ** `@property {string} phone ` Contact number.
   */
   createOrganization(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, billToAcount, shipToAccountName, shipToAddress1, addressCity, addressProvince, addressPostalCode, addressBuzz, phone, isbusiness, responseFormat, ...options } = parameters;
    return createOrganizationApiInstance.chcCreateOrganization(
      storeId,
      billToAcount, 
      shipToAccountName, 
      shipToAddress1, 
      addressCity, 
      addressProvince, 
      addressPostalCode, 
      addressBuzz,
      phone,
      isbusiness,
      responseFormat,
      options
    );
  },

  /**
   * This allows a user to remove an organization from user profile.
   * `@method`
   * `@name RemoveOrganization`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} removeAccount ` BillTo Account to be created.
   ** `@property {string} shipToOrgId ` BillTo Account to be created.
   ** `@property {string} shipToAddress ` BillTo Account to be created.
   ** `@property {string} addressType ` BillTo Account to be created.
   ** `@property {string} shipToOrgName ` BillTo Account to be created.
   ** `@property {string} reasonForClosure ` BillTo Account to be created.
   */
   removeOrganization(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, removeAccount, shipToOrgId, shipToAddress, addressType, shipToOrgName, reasonForClosure, responseFormat, ...options } = parameters;
    
    return removeOrganizationApiInstance.chcRemoveOrganization(
      storeId,
      removeAccount, 
      shipToOrgId, 
      shipToAddress, 
      addressType, 
      shipToOrgName, 
      reasonForClosure, 
      responseFormat,
      options
    );
  },
  /**
   * This allows a user to get pickdays in shipping Address Checkout Section
   * `@method`
   * `@name pickdayList`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get pickdays in checkout section
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} organizationId ` Selected Org Id
  
   */
   pickDays(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, organizationId, responseFormat, ...options } = parameters;
    
    return pickdayListApiInstance.pickdayList(
      storeId,
      organizationId,
      responseFormat,
      options
    );
  },

  orgAttribute(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, organizationId, attribute, responseFormat, ...options } = parameters;
    return orgAttrApiInstance.organizationAttribute(
      storeId,
      organizationId,
      attribute,
      responseFormat,
      options
    );
  },

  getShipToBillTo(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, userId, responseFormat, ...options } = parameters;
    
    return shipToBillToApiInstance.shipToBillToOrgId(
      storeId,
      userId,
      responseFormat,
      options
    );
  },

  sendNewResidentForm(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, residentName, suiteNumber, phoneNumber, residenceAcctNumber, contactName, contactEmail, address, city, province, postalCode, cardType, nameOnCard, cardNumber, cvv, expMonth, expYear, billingEmail,chainValue, responseFormat, ...options } = parameters;
    
    return newResidentApiInstance.sendNewResidentFormToSF(
      storeId,
      residentName, 
      suiteNumber, 
      phoneNumber, 
      residenceAcctNumber, 
      contactName, 
      contactEmail, 
      address, 
      city, 
      province, 
      postalCode, 
      cardType, 
      nameOnCard, 
      cardNumber, 
      cvv, 
      expMonth, 
      expYear, 
      billingEmail, 
      chainValue,
      responseFormat,
      options
    );
  },

  /**
   * This function fetches the details for accelerate program for the current organization.
   * @param parameters 
   * @returns 
   */
  getAcceleratePgmData(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, organizationId, profileName, checkBuyerAdminInBillToOrg = "true", responseFormat, ...options } = parameters;
    
    return acceleratePgmApiInstance.acceleratePgmEligibility(
      storeId,
      organizationId,
      profileName,
      checkBuyerAdminInBillToOrg,
      responseFormat,
      options
    );
  },

  getChcOrgShortSummary(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, organizationId, profileName, responseFormat, ...options } = parameters;
    
    return chcOrgShortSummaryApiInstance.chcOrganizationSummary(
      storeId,
      organizationId,
      profileName,
      responseFormat,
      options
    );
  },

  checkEmployeeOrganization(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, organizationId, responseFormat, ...options } = parameters;
    
    return checkEmployeeOrgApiInstance.chcEmployeeOrg(
      storeId,
      organizationId,
      responseFormat,
      options
    );
  },

  setPhysicalStoreCookie(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, activeOrgId, responseFormat, ...options } = parameters;
    
    return setPhysicalStoreCookieApiInstance.chcSetPhysicalStoreCookie(
      storeId,
      activeOrgId,
      responseFormat,
      options
    );
  },
  
  getActiveOrgReportViewerRole(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, activeOrgId, responseFormat, ...options } = parameters;
    
    return checkActiveOrgReportViewerRoleApiInstance.chcActiveOrgRole(
      storeId,
      activeOrgId,
      responseFormat,
      options
    );
  }

};

export default chcOrganizationService;
