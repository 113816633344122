import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useSite } from "./useSite";

type Props = {
  currency?: string;
  hideDecimal?: boolean;
};

export const usePriceDisplay = ({ currency, hideDecimal }: Props) => {
  const { mySite } = useSite();
  const { i18n } = useTranslation();
  const language = i18n.languages[0];

  return useCallback(
    (value?: number | string) =>
      value
        ? Intl.NumberFormat(language, {
            style: "currency",
            currencyDisplay: "narrowSymbol",
            currency: currency ? currency : mySite ? mySite.defaultCurrencyID : "",
            maximumFractionDigits: hideDecimal ? 0 : 2,
          }).format(Number(value))
        : "",
    [currency, hideDecimal, language, mySite]
  );
};
