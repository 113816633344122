/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * SavePOApi - axios parameter creator
 * @export
 */
export const SavePOApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Save PurchaseOrder number
         * @summary Save PO.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} poNumber Purchase Order Number
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [orderType] Order Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePurchaseOrder: async (storeId: string, orderId: string, poNumber: string, responseFormat?: string, orderType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('savePurchaseOrder', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('savePurchaseOrder', 'orderId', orderId)
            // verify required parameter 'poNumber' is not null or undefined
            assertParamExists('savePurchaseOrder', 'poNumber', poNumber)
            const localVarPath = `/store/{storeId}/CHCorder/SavePO`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (poNumber !== undefined) {
                localVarQueryParameter['poNumber'] = poNumber;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SavePOApi - functional programming interface
 * @export
 */
export const SavePOApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SavePOApiAxiosParamCreator(configuration)
    return {
        /**
         * Save PurchaseOrder number
         * @summary Save PO.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} poNumber Purchase Order Number
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [orderType] Order Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async savePurchaseOrder(storeId: string, orderId: string, poNumber: string, responseFormat?: string, orderType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.savePurchaseOrder(storeId, orderId, poNumber, responseFormat, orderType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SavePOApi - factory interface
 * @export
 */
export const SavePOApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SavePOApiFp(configuration)
    return {
        /**
         * Save PurchaseOrder number
         * @summary Save PO.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} poNumber Purchase Order Number
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [orderType] Order Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePurchaseOrder(storeId: string, orderId: string, poNumber: string, responseFormat?: string, orderType?: string, options?: any): AxiosPromise<string> {
            return localVarFp.savePurchaseOrder(storeId, orderId, poNumber, responseFormat, orderType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SavePOApi - object-oriented interface
 * @export
 * @class SavePOApi
 * @extends {BaseAPI}
 */
export class SavePOApi extends BaseAPI {
    /**
     * Save PurchaseOrder number
     * @summary Save PO.
     * @param {string} storeId The store identifier.
     * @param {string} orderId Order Id
     * @param {string} poNumber Purchase Order Number
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [orderType] Order Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SavePOApi
     */
    public savePurchaseOrder(storeId: string, orderId: string, poNumber: string, responseFormat?: string, orderType?: string, options?: any) {
        return SavePOApiFp(this.configuration).savePurchaseOrder(storeId, orderId, poNumber, responseFormat, orderType, options).then((request) => request(this.axios, this.basePath));
    }
}
