import { Button } from "@material-ui/core";
import styled from "styled-components";

const StyledToggleButton = styled(({ ...props }) => <Button {...props} />)`
  ${({ theme, selected }) => {
    return `
        &.MuiButtonBase-root{
           color: ${selected ? theme.palette.primary.main : theme.palette.background.contrastText};
        font-weight: 400;
        padding: 0;
        box-shadow: none;
        min-width: unset;
        background: none;
        width: auto; 
        }
        &.Mui-selected {
            background: none;
        }
     `;
  }}
`;

export { StyledToggleButton };
