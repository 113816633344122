/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * OrderUpdateApi - axios parameter creator
 * @export
 */
export const OrderUpdateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Post Order Update
         * @summary Post OrderUpdate.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [orderDesc] order Desc
         * @param {string} [orderComment] order Comment
         * @param {string} [field3] field3
         * @param {string} [yearEnd] yearEnd
         * @param {string} [customDate] yearEnd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdate: async (storeId: string, orderId: string, responseFormat?: string, orderDesc?: string, orderComment?: string, field3?: string, yearEnd?: string, customDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderUpdate', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderUpdate', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/CHCorder/OrderUpdate`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (orderDesc !== undefined) {
                localVarQueryParameter['orderDesc'] = orderDesc;
            }

            if (orderComment !== undefined) {
                localVarQueryParameter['orderComment'] = orderComment;
            }

            if (field3 !== undefined) {
                localVarQueryParameter['field3'] = field3;
            }

            if (yearEnd !== undefined) {
                localVarQueryParameter['yearEnd'] = yearEnd;
            }

            if (customDate !== undefined) {
                localVarQueryParameter['customDate'] = customDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderUpdateApi - functional programming interface
 * @export
 */
export const OrderUpdateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderUpdateApiAxiosParamCreator(configuration)
    return {
        /**
         * Post Order Update
         * @summary Post OrderUpdate.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [orderDesc] order Desc
         * @param {string} [orderComment] order Comment
         * @param {string} [field3] field3
         * @param {string} [yearEnd] yearEnd
         * @param {string} [customDate] yearEnd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderUpdate(storeId: string, orderId: string, responseFormat?: string, orderDesc?: string, orderComment?: string, field3?: string, yearEnd?: string, customDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderUpdate(storeId, orderId, responseFormat, orderDesc, orderComment, field3, yearEnd, customDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderUpdateApi - factory interface
 * @export
 */
export const OrderUpdateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderUpdateApiFp(configuration)
    return {
        /**
         * Post Order Update
         * @summary Post OrderUpdate.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [orderDesc] order Desc
         * @param {string} [orderComment] order Comment
         * @param {string} [field3] field3
         * @param {string} [yearEnd] yearEnd
         * @param {string} [customDate] yearEnd
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderUpdate(storeId: string, orderId: string, responseFormat?: string, orderDesc?: string, orderComment?: string, field3?: string, yearEnd?: string, customDate?: string, options?: any): AxiosPromise<string> {
            return localVarFp.orderUpdate(storeId, orderId, responseFormat, orderDesc, orderComment, field3, yearEnd, customDate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderUpdateApi - object-oriented interface
 * @export
 * @class OrderUpdateApi
 * @extends {BaseAPI}
 */
export class OrderUpdateApi extends BaseAPI {
    /**
     * Post Order Update
     * @summary Post OrderUpdate.
     * @param {string} storeId The store identifier.
     * @param {string} orderId Order Id
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [orderDesc] order Desc
     * @param {string} [orderComment] order Comment
     * @param {string} [field3] field3
     * @param {string} [yearEnd] yearEnd
     * @param {string} [customDate] yearEnd
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderUpdateApi
     */
    public orderUpdate(storeId: string, orderId: string, responseFormat?: string, orderDesc?: string, orderComment?: string, field3?: string, yearEnd?: string, customDate?: string, options?: any) {
        return OrderUpdateApiFp(this.configuration).orderUpdate(storeId, orderId, responseFormat, orderDesc, orderComment, field3, yearEnd, customDate, options).then((request) => request(this.axios, this.basePath));
    }
}
