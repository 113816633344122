import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import ContentRecommendationWidget from "../../commerce-widgets/content-recommendation-widget/content-recommendation-widget";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import StyledCard from "./StyledCard";

export const AccelerateResources: FC = () => {
  const { data } = useContext(AccelerateProgramContext);
  const eligibilityType = data?.EligibilityType;
  const status = data?.Status;
  
  return (
    <StyledCard color="info">
      <Stack>
      <ContentRecommendationWidget
          {...{
            widget: {
              id: `AcceleratePgmResources_${data?.EligibilityType}_Espot`,
              widgetName: "content-recommendation-widget",
              name: `AcceleratePgmResources_${data?.EligibilityType}_Espot`,
              properties: {
                emsName: `AcceleratePgmResources_${data?.EligibilityType}_Espot`,
              },
            },
            page: { name: "" },
          }}
        /> 
        {(eligibilityType === "DE") && status  && (
          <ContentRecommendationWidget
          {...{
            widget: {
              id: `AcceleratePgmResources_${eligibilityType}_${status}_Espot`,
              widgetName: "content-recommendation-widget",
              name: `AcceleratePgmResources_${eligibilityType}_${status}_Espot`,
              properties: {
                emsName: `AcceleratePgmResources_${eligibilityType}_${status}_Espot`,
              },
            },
            page: { name: "" },
          }}
        /> 
        )}
      </Stack>
    </StyledCard>
  );
};
