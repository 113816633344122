import { PopupReducerState } from './reducerStateInterface';
/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import { CLOSE_POPUP_ACTION, OPEN_POPUP_ACTION } from "../actions/popup";
/**
 * confirmation reducer
 */
const confirmationReducer = createReducer(initStates.popup, (builder) => {
  builder.addCase(OPEN_POPUP_ACTION, (state: PopupReducerState, action: AnyAction) => {
    return ({ ...state, ...action?.payload, open: true });
  });
  builder.addCase(CLOSE_POPUP_ACTION, (state: PopupReducerState, action: AnyAction) => {
    return ({ ...state, ...action?.payload, open: false });
  });
});
export default confirmationReducer;
