import styled from "styled-components";

const StyledIconWrapper = styled(
  ({ icon, iconSize, backgroundSize, iconColor, backgroundColor, shape, shade, ...props }) => (
    <div {...props} />
  )
)`
  ${({ theme, iconSize, backgroundSize, iconColor, backgroundColor, shape, shade }) => `
    position: relative;
    display: inline-block;
    height: ${backgroundSize}px;
    width: ${backgroundSize}px;
    background-color: ${
      backgroundColor
        ? backgroundColor
        : shade === "dark"
        ? theme.palette.primary.dark
        : shade === "gray"
        ? theme.palette.neutral.light
        : theme.palette.primary.light
    };
    border-radius: ${!shape || shape === "circle" ? "50%" : `${theme.shape.borderRadius}px`};
    
    opacity: 0.9;
    &:hover {
      opacity: 1;
      svg.MuiSvgIcon-root {
        color: ${
          iconColor
            ? iconColor
            : shade === "dark"
            ? theme.palette.primary.light
            : shade === "gray"
            ? theme.palette.neutral.contrastTextDark
            : theme.palette.primary.main
        };
      }
    }

    svg.MuiSvgIcon-root {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${
        iconColor
          ? iconColor
          : shade === "dark"
          ? theme.palette.primary.light
          : shade === "gray"
          ? theme.palette.neutral.contrastTextDark
          : theme.palette.primary.main
      } !important;
      font-size: ${iconSize}px;
    }
  `}
`;

function StyledIcon({ icon, ...props }: any) {
  return (
    <StyledIconWrapper className="StyledIcon-root" {...props}>
      {icon}
    </StyledIconWrapper>
  );
}

export { StyledIcon };
