import { FC, useContext, ReactNode } from "react";
import { Stack, Divider, Box } from "@mui/material";
import {
  StyledProgressPlaceholder,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { Search } from "@material-ui/icons";
import { NotificationsContext } from "../../../_foundation/context/notifications-context";
import { getDateFormatter } from "../../../utils/getDateFormatter";
import { useTranslation } from "react-i18next";
import { StyledRowCard } from "../../../elements/row-card";
import { StyledIndicator } from "../../../elements/indicator";
import { StyledIcon } from "../../../elements/icon";

const NotificationsList: FC<{ selectedContent?: ReactNode }> = ({ selectedContent }) => {
  const { notifications, setSelected, selectedId, loading } = useContext(NotificationsContext);
  const { i18n, t } = useTranslation();
  const dateFormatter = getDateFormatter(i18n);
  return (
    <Stack spacing={1} divider={<Divider />}>
      {notifications.map(({ id, read, title, date }) => (
        <StyledRowCard
          key={id}
          onClick={() => setSelected(id)}
          className={selectedId === id ? "selected" : ""}
        >
          <Stack spacing={3} sx={{ p: 0 }}>
            <Stack direction="row" spacing={3}>
              <StyledIndicator className={read ? "" : "active"} />
              <Stack spacing={1} sx={{ flex: 1 }}>
                <StyledTypography variant="h5">{title}</StyledTypography>
                <StyledTypography variant="subtitle2">
                  {dateFormatter.format(new Date(date))}
                </StyledTypography>
              </Stack>
              <Stack alignItems="center" justifyContent="center">
                <StyledIcon
                  icon={<Search className="full-center" />}
                  backgroundSize={32}
                  iconSize={18}
                  shape="square"
                  shade="dark"
                />
              </Stack>
            </Stack>
            {selectedId === id && selectedContent ? selectedContent : null}
          </Stack>
        </StyledRowCard>
      ))}
      {notifications.length === 0 && !loading ? (
        <Box>
          <StyledTypography variant="h6">
            {t("Dashboard.Sections.Notifications.AllRead")}
          </StyledTypography>
        </Box>
      ) : null}
      {loading ? <StyledProgressPlaceholder style={{ backgroundColor: "transparent" }} /> : null}
    </Stack>
  );
};

export default NotificationsList;