/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import initStates from "./initStates";
import { OrganizationReducerState } from "./reducerStateInterface";
import {
  ENTITLED_ORG_SUCCESS_ACTION,
  GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION,
  GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION,
  ENTITLED_ORG_SEGMENT_SUCCESS_ACTION,
  ENTITLED_ORG_LOYALTY_SUCCESS_ACTION,
  GET_ACCELERATEPGMDATA_SUCCESS_ACTION,
  GET_STANDING_ORDER_SUCCESS_ACTION,
  GET_EMPLOYEE_PURCHASE_SUCCESS_ACTION,
  SET_ASSGINED_ROLES,
  ORG_SWITCH_ACTION,
  ORG_SWITCH_SUCCESS_ACTION,
  ORG_SWITCH_ERROR_ACTION,
  ENTITLED_ORG_CPN_SUCCESS_ACTION,
  ENTITLED_ORG_CPN_ERROR_ACTION,
  NOTIFICATIONS_SUCCESS_ACTION,
  NOTIFICATIONS_ERROR_ACTION
} from "../actions/organization";
/**
 * Organization reducer
 */
const organizationReducer = createReducer(
  initStates.organization,
  (builder) => {
    builder.addCase(
      ENTITLED_ORG_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(
      ORG_SWITCH_ACTION,
      (state: any, action: AnyAction) => {
        state.changeOrg = {
          status: "pending",
          data: { ...action?.payload?.query }
        };
      }
    );
    builder.addCase(
      ORG_SWITCH_SUCCESS_ACTION,
      (state: any, action: AnyAction) => {
        state.changeOrg = {
          status: "success",
          data: { ...action?.payload }
        };
      }
    );
    builder.addCase(
      ORG_SWITCH_ERROR_ACTION,
      (state: any, action: AnyAction) => {
        state.changeOrg = {
          status: "error",
          data: { ...action?.payload }
        };
      }
    );
    builder.addCase(
      ENTITLED_ORG_SEGMENT_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(
      ENTITLED_ORG_LOYALTY_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(
      ENTITLED_ORG_CPN_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(
      ENTITLED_ORG_CPN_ERROR_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(
      GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        state.organizationDetails = action.payload;
      }
    );
    builder.addCase(
      GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        state.organizationDetails.billing = action.payload;
      }
    );
    builder.addCase(
      GET_ACCELERATEPGMDATA_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(
      GET_STANDING_ORDER_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(
      GET_EMPLOYEE_PURCHASE_SUCCESS_ACTION,
      (state: OrganizationReducerState | any, action: AnyAction) => {
        Object.assign(state, action.payload);
      }
    );
    builder.addCase(SET_ASSGINED_ROLES, (state: any, action: AnyAction) => {
      state.assignedRoles = action?.payload;
    });
    builder.addCase(NOTIFICATIONS_SUCCESS_ACTION, (state: any, action: AnyAction) => {
      state.NotificationsSummary = action?.payload;
    });
    builder.addCase(NOTIFICATIONS_ERROR_ACTION, (state: any, action: AnyAction) => {
      state.NotificationsSummary = action?.payload;
    });
  }
);
export default organizationReducer;
