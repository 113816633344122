import { ATTRIBUTE_TYPE, NO_IMAGE } from './../constants/common';
import { flattenDeep, get, toLower } from "lodash-es";
import { CATALOG_ENTRY_TYPE, CHC_RIBBON, CHC_RIBBONS, PRODUCT_ATTRS } from "../constants/product";
import { ProductSuggestionEntry } from "@hcl-commerce-store-sdk/typescript-axios-es";

const productUtil = {
  /**
     * Utility method used in sorting.
     * @param a
     * @param b
     */
  compareFn: (a, b): any => {
    return a.partNumber.localeCompare(b.partNumber, "en", {
      numeric: true,
    });
  },

  cookieVal: () => {
    const b = document.cookie.match(
      "(^|;)\\s*" + "WC_PhysicalStores" + "\\s*=\\s*([^;]+)"
    );
    return b ? b.pop() : "";
  },

  getPartNumberForPrice: (defaultPN: string = "", pricingData: Array<any> = []) => {
    const productPN = defaultPN?.replace("_", "");
    return pricingData?.find(i => i?.partNumber === productPN) ? productPN : pricingData?.[0]?.partNumber;
  },

  getUOMInfo: (myArray, t?: any) => {
    const b = document.cookie.match(
      "(^|;)\\s*" + "WC_PhysicalStores" + "\\s*=\\s*([^;]+)"
    );
    const cookieValue = b?.pop() || "";
    if (!cookieValue) return [];
    const nameKey = cookieValue.substring(11, 0);
    const backOrderIndex = cookieValue.length - 2;
    const backOrderAllowed = cookieValue.substring(
      backOrderIndex + 1,
      backOrderIndex + 2
    );
    let boIndex = 0;
    if (backOrderAllowed === "1") {
      boIndex = 0;
    } else {
      boIndex = 2;
    }
    let partNumber = "";
    let UOM = "";
    let uomUnitValue = "";
    let uomValue = "";
    let id = "";
    let availMsg = "";
    let x_field3_d = "";
    const uomValues: object[] = [];

    for (let i = 0; i < myArray.length; i++) {
      if (myArray?.[i]?.stlocs?.[nameKey]) {
        partNumber = myArray[i].partNumber;
        const parentCatalogEntryID = myArray?.[i]?.parentCatalogEntryID;
        UOM = myArray[i].stlocs[nameKey];
        id = myArray[i].id;
        x_field3_d = myArray[i]?.x_field3_d;
        const entryAll = UOM.split("|");
        const entry = entryAll[0].split("_");
        uomValue = entry[0];
        uomUnitValue = entry[2];
        availMsg = entry[1].substring(boIndex + 0, boIndex + 2);
        
        if (entryAll.length > 0) {
          for (let i = 0; i < entryAll.length; i++) {
            const entry = entryAll[i].split("_");
            const obj = {
              partNumber: partNumber,
              id: id,
              uom: entryAll[i],
              uomDisplay: entry[0] + "_" + entry[2], 
              uomValue: entry[0],
             uomUnitValue: uomUnitValue,
              availability: t ? t("InventoryAvailability.INVENTORY_" + availMsg) : availMsg,
              x_field3_d: x_field3_d,
              parentCatalogEntryID,
            };
            uomValues.push(obj);
          }
        }
        else {
          const obj = {
            partNumber: partNumber,
            id: id,
            uom: UOM,
            uomValue: uomValue,
            uomUnitValue: uomUnitValue,
            availability: t ? t("InventoryAvailability.INVENTORY_" + availMsg) : availMsg,
            x_field3_d: x_field3_d,
            parentCatalogEntryID
          };
          uomValues.push(obj);
        }


      }
    }
    return uomValues;
  },
  // Returns availability details for the given item
  getProductAvailability: (item: any, t?: any) => {
    const b = document.cookie.match(
      "(^|;)\\s*" + "WC_PhysicalStores" + "\\s*=\\s*([^;]+)"
    );
    const cookieValue = b?.pop() || "";
    if (!cookieValue) return {};
    const nameKey = cookieValue.substring(11, 0);
    const backOrderIndex = cookieValue.length - 2;
    const backOrderAllowed = cookieValue.substring(backOrderIndex + 1, backOrderIndex + 2);
    let boIndex = 0;
    if (backOrderAllowed === "1") { boIndex = 0; }
    else { boIndex = 2; }
    const partNumber = item?.partNumber, id = item?.id, NAMsg = "CommerceEnvironment.inventoryStatusOnline.OOS";
    if (item?.stlocs?.[nameKey]) {
      const UOM = item?.stlocs[nameKey],
        entryAll = UOM.split("|"),
        entry = entryAll[0].split("_"),
        availMsg = entry[1].substring(boIndex + 0, boIndex + 2);
      return ({ partNumber, id, availability: t ? t("InventoryAvailability.INVENTORY_" + availMsg) : availMsg })
    }
    return ({ partNumber, id, availability: t ? t(NAMsg) : NAMsg });
  },
  // Returns finalized array of values
  finalizeAttributeValues: (values: Array<any>, type: string) => {
    if(type !== ATTRIBUTE_TYPE.PARENT_PRODUCT) return values;
    else{
      const filteredValues = values?.filter(v => {
        if (v?.identifier?.includes(PRODUCT_ATTRS.FEATURE)) {
          const partNumber: string = v?.identifier?.split("|")?.[1]
          if (partNumber?.startsWith('_')) return true;
          return false;
        } return true;
      });
      return filteredValues;
    }
  },

  // Returns alternative productIDs which are unique from parent product SKUs
  getSubstituteIds: (SKUs: Array<any>, segment: string, defaultSegment: string) => {
    const parentSKUs = SKUs?.map(({ id }) => id);
    const substituteIds = flattenDeep(SKUs?.map((s: any) => s?.[`substitution_${segment}`] || s?.[`substitution_${defaultSegment}`] || []));
    return substituteIds?.filter(i => !parentSKUs?.includes(i));
  },

  // Moves the categories which don't have any children to the last in the array
  finalizedL2Categories: (categories: Array<any> = []) => {
    return categories?.sort((a, b) => {
      if (!a?.children && b?.children) return 1;
      else if (a?.children && !b?.children) return -1;
      return 0;
    })
  },

  /**
   * filter skulist based on the attribut selected
   */
  filterUniqueSkus: (allSkus: Array<any>, attributeState: any) => {
    const skus: any[] = [];
    const keys = Object.keys(attributeState);

    for (const s of allSkus) {
      const values = s.attributes.reduce((value: any, a: any) => {
        value[a.identifier] = get(a, "values[0].identifier");
        return value;
      }, {});

      if (keys.every((k) => attributeState[k] === values[k])) {
        skus.push(s);
      }
    }

    return skus;
  },

  /**
   * Helper method to convert a map to an object
   * @param inputMap
   * @returns object
   */
  mapToObj: (inputMap: any[] | Map<any, any>) => {
    const obj = {};
    inputMap.forEach((value, key) => (obj[key] = value));
    return obj;
  },

  filterProductSuggestions: (suggestions: Array<ProductSuggestionEntry & { catalogEntryTypeCode: CATALOG_ENTRY_TYPE }> = [], catalogEntryTypeCode?: CATALOG_ENTRY_TYPE) => {
    return catalogEntryTypeCode ? suggestions?.filter(s => s?.catalogEntryTypeCode === catalogEntryTypeCode) : [...suggestions];
  },

  getRibbonImg: (ribbon: CHC_RIBBON) => {
    return window.location.origin + CHC_RIBBONS[ribbon];
  },

  getSpotlightProducts: (attributes: Array<any> = []) => {
    const items = attributes?.filter(a => a?.identifier  === PRODUCT_ATTRS.SPOTLIGHT_PRODUCT)?.map(a => a.values[0]?.value ?? "");
    return [...items];
  },

  finalizedSpotlightProducts: (items: Array<any> = [], t?: any) => {
    return items?.map(i => ({...i, isSpotlightProduct: true, infoTextKey: "productDetail.SpotlightTooltipInfo" }))
  },

  getProductSlides: (uniqueSkuList: Array<any> =[]) => {
    const slides = flattenDeep(uniqueSkuList?.map(i => i?.images?.map((im: any) => ({ ...im, partNumber: i?.partNumber }))));
    return slides?.filter(s => !toLower(s?.fullImage).includes(NO_IMAGE) && !toLower(s?.thumbnail).includes(NO_IMAGE)); // Shows slides with actual images only
  }

}

export default productUtil;