/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { PaymentMethodList } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * PaymentMethodListApi - axios parameter creator
 * @export
 */
export const PaymentMethodListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get order details from JDE
         * @summary Get PaymentMethodList.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCPaymentMethodList: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCPaymentMethodList', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/CHCorder/PaymentMethodList`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentMethodListApi - functional programming interface
 * @export
 */
export const PaymentMethodListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentMethodListApiAxiosParamCreator(configuration)
    return {
        /**
         * Get order details from JDE
         * @summary Get PaymentMethodList.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCPaymentMethodList(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCPaymentMethodList(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentMethodListApi - factory interface
 * @export
 */
export const PaymentMethodListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentMethodListApiFp(configuration)
    return {
        /**
         * Get order details from JDE
         * @summary Get PaymentMethodList.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCPaymentMethodList(storeId: string, responseFormat?: string, options?: any): AxiosPromise<PaymentMethodList> {
            return localVarFp.cHCPaymentMethodList(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentMethodListApi - object-oriented interface
 * @export
 * @class PaymentMethodListApi
 * @extends {BaseAPI}
 */
export class PaymentMethodListApi extends BaseAPI {
    /**
     * Get order details from JDE
     * @summary Get PaymentMethodList.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodListApi
     */
    public cHCPaymentMethodList(storeId: string, responseFormat?: string, options?: any) {
        return PaymentMethodListApiFp(this.configuration).cHCPaymentMethodList(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
