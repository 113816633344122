/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/invoice";

export const GET_INVOICE_ACTION = createAction<any, string>(ACTIONTYPES.INVOICE_GET_REQUESTED);
export const GET_INVOICE_FIND_ACTION = createAction<any, string>(ACTIONTYPES.INVOICE_FIND_GET_REQUESTED);
export const GET_INVOICE_DOWNLOAD_ACTION = createAction<any, string>(ACTIONTYPES.INVOICE_GET_DOWNLOAD_REQUESTED);
