import request from './request';
import { chunk } from "lodash-es";

export const searchIBInvoicesRequest = async (
  customerNumbers,
  invoiceNumber,
  poNumber,
  salesOrderNumber,
  dateFrom,
  dateTo
) => {
  // Split customer numbers into chunks
  const chunkSize = 100;
  const customerChunks = chunk(customerNumbers.split(','), chunkSize);
  
  // Create an array of promises for parallel requests
  const requestPromises = customerChunks.map((chunk) =>
  request.get("/searchIBInvoices", {
      params: {
        shipToNumber: chunk.toString(),
        dateFrom,
        dateTo,
        invoiceTotalFrom: 0.0,
        invoiceNumber,
        invoiceTotalTo: 0.0,
        poNumber,
        salesOrderNumber,
        sortKey: "invoiceDate",
        sortOrder: "DESC",
        page: 0,
        pageSize: 10000,
        dateRangeParam: true,
      },
    })
  );

  // Use Promise.all to execute all requests in parallel
  const responses = await Promise.all(requestPromises);
  const reducedData = responses.reduce((accumulator: any, response) => {
    const responseData = response.data.content;
    return accumulator.concat(responseData);
  },[])
  return reducedData;
};
