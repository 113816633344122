import { StyledTypography } from '@hcl-commerce-store-sdk/react-component';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { isEmpty } from 'lodash-es';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ProductAttributesTableProps {
    data: Array<{ label: string, value: any }>
}

const ProductAttributesTable: FC<ProductAttributesTableProps> = ({ data }) => {
    const {t } = useTranslation();
    return (
        <Table component={'div'}>
            <TableBody component={'div'}>
                {!isEmpty(data) ?
                    data?.map((attribute, index: number) => {
                        return (
                            <TableRow component={'div'} key={`attribute_${index}`}>
                                <TableCell size="small" sx={{ py: 1, width: { xs: 130, sm: 200, lg: 300 } }} component={'div'} variant="head">
                                    <StyledTypography style={{ fontWeight: 600 }}>{attribute?.label}</StyledTypography>
                                </TableCell>
                                <TableCell size="small" sx={{ py: 1 }} component={'div'}>
                                    <StyledTypography>{attribute?.value}</StyledTypography>
                                </TableCell>
                            </TableRow>
                        )
                    })
                    : <TableRow component={'div'} key={`attribute_${1}`}>
                        <TableCell align='center'><StyledTypography>{t("RequisitionListItems.EmptyDetails")}</StyledTypography></TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    );
}

export default ProductAttributesTable;