/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { takeLatest } from "redux-saga/effects";
//Redux

import * as ACTIONS from "../../actions/chc-orderDetails";
import * as WORKERS from "../workers/chc-orderDetails";

export function* watchSaga() {
yield takeLatest(ACTIONS.FETCH_CHC_ORDER_DETAILS_ACTION, WORKERS.getChcOrderDetail);
}
