/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { AccelerateProgram } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * AccelerateProgramDipBalanceApi - axios parameter creator
 * @export
 */
export const AccelerateProgramDipBalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Accelerate program dip balance information.
         * @summary Get Accelerate program dip balance information.
         * @param {string} storeId The store identifier.
         * @param {string} abk The active organization identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCAccelerateProgramDipBalance: async (storeId: string, abk: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCAccelerateProgramDipBalance', 'storeId', storeId)
            // verify required parameter 'abk' is not null or undefined
            assertParamExists('cHCAccelerateProgramDipBalance', 'abk', abk)
            const localVarPath = `/store/{storeId}/chc_organization/dip_balance`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (abk !== undefined) {
                localVarQueryParameter['abk'] = abk;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccelerateProgramDipBalanceApi - functional programming interface
 * @export
 */
export const AccelerateProgramDipBalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccelerateProgramDipBalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Accelerate program dip balance information.
         * @summary Get Accelerate program dip balance information.
         * @param {string} storeId The store identifier.
         * @param {string} abk The active organization identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCAccelerateProgramDipBalance(storeId: string, abk: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccelerateProgram>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCAccelerateProgramDipBalance(storeId, abk, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccelerateProgramDipBalanceApi - factory interface
 * @export
 */
export const AccelerateProgramDipBalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccelerateProgramDipBalanceApiFp(configuration)
    return {
        /**
         * Get Accelerate program dip balance information.
         * @summary Get Accelerate program dip balance information.
         * @param {string} storeId The store identifier.
         * @param {string} abk The active organization identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCAccelerateProgramDipBalance(storeId: string, abk: string, responseFormat?: string, options?: any): AxiosPromise<AccelerateProgram> {
            return localVarFp.cHCAccelerateProgramDipBalance(storeId, abk, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccelerateProgramDipBalanceApi - object-oriented interface
 * @export
 * @class AccelerateProgramDipBalanceApi
 * @extends {BaseAPI}
 */
export class AccelerateProgramDipBalanceApi extends BaseAPI {
    /**
     * Get Accelerate program dip balance information.
     * @summary Get Accelerate program dip balance information.
     * @param {string} storeId The store identifier.
     * @param {string} abk The active organization identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccelerateProgramDipBalanceApi
     */
    public cHCAccelerateProgramDipBalance(storeId: string, abk: string, responseFormat?: string, options?: any) {
        return AccelerateProgramDipBalanceApiFp(this.configuration).cHCAccelerateProgramDipBalance(storeId, abk, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
