/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/user-management-admin";
export const UPDATE_USER_STATUS_REQUEST_ACTION = createAction<any, string>(ACTIONTYPES.UPDATE_USER_STATUS_REQUEST);
export const RESET_USER_PASSWORD_REQUEST_ACTION = createAction<any, string>(ACTIONTYPES.RESET_USER_PASSWORD_REQUEST);
export const UPDATE_USER_STATUS_REQUEST_SUCCESS = createAction<any, string>(ACTIONTYPES.UPDATE_USER_STATUS_REQUEST_SUCCESS);

