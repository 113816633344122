/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const heroConfig = {
  page: {
    name: "homeHeroSpot",
  },
  espot: {
    eSpotName: "homeHeroSpot_Content",
  },
};


