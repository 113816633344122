/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//Custom libraries
import { DEFAULT_LANG_ID, HYPHEN, UNDERSCORE } from "../../constants/common";

//Redux
import { CommerceEnvironment } from "../../constants/common";

//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
import { LOCALE, URL_LANG_REJECTED } from "../../_foundation/constants/common";
import { localStorageUtil } from "../../_foundation/utils/storageUtil";
import { useDispatch } from "react-redux";
import * as lsActions from "../../redux/actions/local-storage";
import { StyledToggleButton } from "../../elements/toggle-button/styled-toggle-button";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { langSelector } from "../../redux/selectors/language";

/**
 * LanguageToggle component
 * displays the supported languages and allows for changing the language of the store
 * @param props
 */
const LanguageToggle: FC = () => {
  const { mySite } = useSite();
  const { i18n, t } = useTranslation();

  const defaultLanguageID = mySite ? mySite.defaultLanguageID : DEFAULT_LANG_ID;
  const supportedLanguages = mySite ? mySite.supportedLanguages : [defaultLanguageID]; //obtain langid(s) of supported languages

  const localLocaleCode = localStorageUtil.get(LOCALE); //obtain locale from local storage code
  const languageId = localLocaleCode
    ? CommerceEnvironment.reverseLanguageMap[localLocaleCode.split(HYPHEN).join(UNDERSCORE)]
    : defaultLanguageID; // if locale code, set languageName to local locale language name, else default locale language name
  const [language, setLanguage] = useState<string>(languageId); // Initially set as default locale/language
  const dispatch = useDispatch();
  const languageFromSelector = useSelector(langSelector);

  // Get the selected Language as a callback from LanguageTogglePopperContent
  // when user clicks a supported language option
  const onLanguageClick = (newLangId: string) => {
    const newLocale = CommerceEnvironment.languageMap[newLangId].split(UNDERSCORE).join(HYPHEN);
    const subTag = CommerceEnvironment.languageMap[newLangId].split(UNDERSCORE)?.[0];
    const HTMLElement = document.querySelector('html');
    HTMLElement?.setAttribute('lang', subTag);

    if (newLocale !== i18n.languages[0]) {
      const rejected = localStorageUtil.get(URL_LANG_REJECTED) ?? {};
      const langName = CommerceEnvironment.languageMap[newLangId];

      // remove the language from set of previously rejected languages -- so that we can prompt again in future
      delete rejected[langName];
      localStorageUtil.set(URL_LANG_REJECTED, rejected);
      setLanguage(newLangId);
      i18n.changeLanguage(newLocale);
      dispatch(lsActions.LS_LANG_CHANGE_ACTION({ newLangId }));
    }
  };

  useEffect(() => {
    const languageId = CommerceEnvironment.reverseLanguageMap[languageFromSelector];
    const subTag = CommerceEnvironment?.languageMap?.[languageId]?.split(UNDERSCORE)?.[0];
    const HTMLElement = document.querySelector('html');
    HTMLElement?.setAttribute('lang', subTag);
    setLanguage(languageId);
  }, [languageFromSelector]);

  
  return (
    <Stack direction="row" spacing={{ xs: 1, md: 2 }}>
      {supportedLanguages.map((lid) => {
        return (
          <StyledToggleButton
            key={lid}
            // testId={`language-toggle-select-language-${lid}`}
            variant="text"
            selected={lid === language}
            onClick={() => onLanguageClick(lid)}
          >
            {t(`LanguageAbr.${lid}`)}
          </StyledToggleButton>
        );
      })}
    </Stack>
  );
};

export default LanguageToggle;
