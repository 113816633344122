/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  StyledBox,
  StyledTooltip,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import HTMLReactParser from "html-react-parser";
import ContentRecommendationWidget from "../../commerce-widgets/content-recommendation-widget/content-recommendation-widget";
import { useScript } from "../../../_foundation/hooks/use-script-chc";
import StyledCard from "./StyledCard";
import { usePriceDisplay } from "../../../_foundation/hooks/use-price-display-chc";
import { useDateFormatter } from "../../../_foundation/hooks/use-date-formatter-chc";

declare module "react" {
  interface HTMLAttributes<T> extends React.AriaAttributes, React.DOMAttributes<T> {
    dojotype?: string;
    connectid?: string;
    widgetid?: string;
  }
}

export const AccelerateEarningTracker: FC = () => {
  const { t } = useTranslation();
  const priceDisplay = usePriceDisplay({ hideDecimal: true });
  const dateFormatter = useDateFormatter();
  const { data, imgDir } = useContext(AccelerateProgramContext);
  const startDate = data?.CurrentYearStart;
  const endDate = data?.CurrentYearStart;
  const startDateFormatted = dateFormatter(startDate);
  const endDateYear = parseInt(endDate?.split("-")[0] ?? "2000") + 1;
  const currentYearXAxisLabel = t("DealerIncentiveProgram.EARNING_TRACKER_CURRENT_YEAR", [
    startDateFormatted,
    endDateYear,
  ]);
  const lastYearXAxisLabel = t("DealerIncentiveProgram.EARNING_TRACKER_LAST_YEAR");
  const cardinalBrandYAxisLabel = t(
    "DealerIncentiveProgram.EARNING_TRACKER_TOTAL_CARDINAL_BRAND_SPEND"
  );
  const nationalBrandYAxisLabel = t(
    "DealerIncentiveProgram.EARNING_TRACKER_TOTAL_NATIONAL_BRAND_SPEND"
  );
  const chartXAxisLabel = t("DealerIncentiveProgram.EARNING_TRACKER_TIMELINE");
  const youAreHereImage = t("DealerIncentiveProgram.EARNING_TRACKER_YOU_ARE_HERE_IMAGE_NAME");

  const projectedCHDOnCB = data?.ProjectedCHDCB || 0;
  const projectedCHDOnNB = data?.ProjectedCHDNB || 0;

  const projectedCHDOnCBFormatted = priceDisplay(projectedCHDOnCB);
  const projectedCHDOnNBFormatted = priceDisplay(projectedCHDOnNB);

  const { loaded: d3jsLoaded } = useScript({ url: "/chc/scripts/d3js.js" });
  const { loaded: diLoaded } = useScript({
    url: d3jsLoaded ? "/chc/scripts/di_chart.js" : undefined,
  });

  useScript({
    body:
      diLoaded && data
        ? `
 (function(){
		
		var memberStatus = "${data?.Status?.replace(/[\W_]+/g, "").toLowerCase() ?? ""}";
		
		if(document.querySelectorAll('.status-banner.'+memberStatus).length > 0){
			document.querySelector('.status-banner.'+memberStatus).classList.add('active');
		}
		
		var di_base_options = {
			width : 425,
			height : 425,
			margin : 75,
			padding : 10,
			adj : 3,
			xAxisLabel : ""
		};
		
		var diStartDate = new Date("${startDate}");
		var diCurrentDate = new Date();
		
		var diMonthCount = monthDiff(diStartDate, diCurrentDate);
		var projectedMonthCount = diMonthCount == 0 ? 1 : diMonthCount;
		
		var cardinalData = [
			{
				month : 0,
				currentDollar : 0,
				previousDollar : 0,
				location : 0
			},
			{
				currentDollar : ${data?.CurrentYearYTDSpendCB},
				previousDollar :  ${data?.PreviousYearYTDSpendCB},
				month : diMonthCount,
				location : 1,
				image : "${imgDir}dealerIncentive/${youAreHereImage}"
			},
			{
				currentDollar :(${data?.CurrentYearYTDSpendCB} * 12) / projectedMonthCount,
				previousDollar : ${data?.PreviousYearSpendCB},
				month : 12,
				location : 2
			}
		];
		
		
		var cardinal_spend_options = Object.assign({},di_base_options);
		cardinal_spend_options.data = cardinalData;
		cardinal_spend_options.yAxisLabel = "${cardinalBrandYAxisLabel}";
		cardinal_spend_options.xAxisLabel = "${chartXAxisLabel}";
		var cardinalSpendChart = DIChart.initializeDIChart('#di-cardinal-spend-chart',cardinal_spend_options);
		
		var nationalData = [
			{
				month : 0,
				currentDollar : 0,
				previousDollar : 0,
				location : 0
			},
			{
				currentDollar : ${data?.CurrentYearYTDSpendNB},
				previousDollar :  ${data?.PreviousYearYTDSpendNB},
				month : diMonthCount,
				location : 1,
				image : "${imgDir}dealerIncentive/${youAreHereImage}"
			},
			{
				currentDollar :(${data?.CurrentYearYTDSpendNB} * 12) / projectedMonthCount,
				previousDollar : ${data?.PreviousYearSpendNB},
				month : 12,
				location : 2
			}
		];
		
		
		var national_spend_options = Object.assign({},di_base_options);
		national_spend_options.data = nationalData;
		national_spend_options.yAxisLabel = "${nationalBrandYAxisLabel}";
		national_spend_options.xAxisLabel = "${chartXAxisLabel}";
		var nationalSpendChart = DIChart.initializeDIChart('#di-national-spend-chart',national_spend_options);
	})();
  `
        : undefined,
  });

  return (
    <StyledCard color="neutral" outline="true">
      <Stack spacing={4}>
        <StyledTypography variant="h3" color="primary" align="center">
          {t("DealerIncentiveProgram.EARNING_TRACKER_TITLE")}
        </StyledTypography>
        <StyledBox>
          <Stack direction={{ md: "row" }} spacing={{ xs: 2 }}>
            <StyledBox flex={1}>
              <StyledTypography variant="h6" color="primary" align="center">
                {t("DealerIncentiveProgram.EARNING_TRACKER_CARDINAL_BRAND")}
              </StyledTypography>
              <div className="projected-box">
                {HTMLReactParser(
                  t("DealerIncentiveProgram.EARNING_TRACKER_PROJECTED_CHDOLLARS_EARNED", [
                    projectedCHDOnCBFormatted,
                  ])
                )}
                <StyledTooltip
                  title={
                    <ContentRecommendationWidget
                      {...{
                        widget: {
                          id: `DIPEarningTracker_Progress_CB_Tooltip`,
                          widgetName: "content-recommendation-widget",
                          name: `DIPEarningTracker_Progress_CB_Tooltip`,
                          properties: {
                            emsName: `DIPEarningTracker_Progress_CB_Tooltip`,
                          },
                        },
                        page: { name: "" },
                      }}
                    />
                  }
                >
                  <a>
                    <img
                      src={`${imgDir}/dealerIncentive/icn-info.svg`}
                      className="icn-xs"
                      alt="information"
                    />
                  </a>
                </StyledTooltip>
              </div>
              <div id="di-cardinal-spend-chart" className="px-3 di-chart"></div>
              <Stack direction="row" spacing={2} justifyContent="space-around">
                <StyledBox className="key-box red">{currentYearXAxisLabel}</StyledBox>
                <StyledBox className="key-box blue">{lastYearXAxisLabel}</StyledBox>
              </Stack>
            </StyledBox>
            <StyledBox flex={1}>
              <StyledTypography variant="h6" align="center" color="primary">
                {t("DealerIncentiveProgram.EARNING_TRACKER_NATIONAL_BRAND")}
              </StyledTypography>
              <div className="projected-box">
                {HTMLReactParser(
                  t("DealerIncentiveProgram.EARNING_TRACKER_PROJECTED_CHDOLLARS_EARNED", [
                    projectedCHDOnNBFormatted,
                  ])
                )}
                <StyledTooltip
                  title={
                    <ContentRecommendationWidget
                      {...{
                        widget: {
                          id: `DIPEarningTracker_Progress_NB_Tooltip`,
                          widgetName: "content-recommendation-widget",
                          name: `DIPEarningTracker_Progress_NB_Tooltip`,
                          properties: {
                            emsName: `DIPEarningTracker_Progress_NB_Tooltip`,
                          },
                        },
                        page: { name: "" },
                      }}
                    />
                  }
                >
                  <a>
                    <img
                      src={`${imgDir}/dealerIncentive/icn-info.svg`}
                      className="icn-xs"
                      alt="information"
                    />
                  </a>
                </StyledTooltip>
              </div>
              <div id="di-national-spend-chart" className="px-3 di-chart"></div>
              <Stack direction="row" spacing={2} justifyContent="space-around">
                <StyledBox className="key-box red">{currentYearXAxisLabel}</StyledBox>
                <StyledBox className="key-box blue">{lastYearXAxisLabel}</StyledBox>
              </Stack>
            </StyledBox>
          </Stack>
          <section>
            <ContentRecommendationWidget
              {...{
                widget: {
                  id: `DIPEarningTracker_Espot1`,
                  widgetName: "content-recommendation-widget",
                  name: `DIPEarningTracker_Espot1`,
                  properties: {
                    emsName: `DIPEarningTracker_Espot1`,
                  },
                },
                page: { name: "" },
              }}
            />
          </section>
          <section>
            <ContentRecommendationWidget
              {...{
                widget: {
                  id: `DIPEarningTracker_Espot2`,
                  widgetName: "content-recommendation-widget",
                  name: `DIPEarningTracker_Espot2`,
                  properties: {
                    emsName: `DIPEarningTracker_Espot2`,
                  },
                },
                page: { name: "" },
              }}
            />
          </section>
        </StyledBox>
      </Stack>
    </StyledCard>
  );
};
