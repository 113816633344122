/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//UI
import {
  StyledPaper,
  StyledMenuTypography,
  StyledBox,
} from "@hcl-commerce-store-sdk/react-component";
import { StyledLink } from "../../elements/link";

interface OneTierMenuProps {
  page?: any;
}

/**
 * OneTierMenu component
 * expanded menu two tier submenu
 * @param props
 */
const OneTierMenu: React.FC<OneTierMenuProps> = (props: any) => {
  const page = props.page ? props.page : [];

  return (
    <>
      {page.children && (
        <StyledPaper className="expanded-menu-two-tier-submenu">
          <StyledBox m={3}>
            {page.children &&
              page.children.map((page2: any, i: number) => (
                <ul key={page2.id}>
                  <StyledLink
                    testId={`header-${page2.id}`}
                    to={page2.seo?.href}
                    state={{
                      breadCrumbTrailEntryView: [
                        { label: page.name, value: page.id, seo: page.seo },
                        { label: page2.name, value: page2.id, seo: page2.seo },
                      ],
                    }}
                  >
                    <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                      {page2.name}
                    </StyledMenuTypography>
                  </StyledLink>
                </ul>
              ))}
          </StyledBox>
        </StyledPaper>
      )}
    </>
  );
};

export default OneTierMenu;
