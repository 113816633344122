/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import {AccelerateProgramApi } from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../../constants/site";

const accelerateProgramApiInstance = new AccelerateProgramApi(
  undefined,
  site.transactionContext
);

const chcAccelerateService = {
  /**
   * This APi fetches ship from Detail from HCL DB.
   * `@method`
   * `@name getShipFromDetail`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get ship from details.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to ship from details.
   */
   accelerateProgram(parameters: {storeId?: string, activeOrgId: string, responseFormat?: string}) {
    const siteInfo = getSite();
    const { storeId, activeOrgId, responseFormat } = parameters;
    return accelerateProgramApiInstance.cHCAccelerateProgram(storeId || siteInfo?.storeID || '',  
        activeOrgId,
        responseFormat, 
        {
            activeOrgId,
        }
    );
  },  
};

export default chcAccelerateService;
