/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/recurringOrder";

const CANCEL_RECURRING_ACTION = createAction<any, string>(
  ACTIONTYPES.CANCEL_RECURRING_SUBSCRIPTION
);

const CANCEL_RECURRING_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.CANCEL_RECURRING_SUCCESS
);

const CANCEL_RECURRING_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.CANCEL_RECURRING_ERROR
);

const FETCH_RECURRING_ORDER_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_RECURRING_ORDER
);

const FETCH_RECURRING_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_RECURRING_ORDER_SUCCESS
);

const FETCH_RECURRING_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.FETCH_RECURRING_ORDER_ERROR
);

//Add remove items

const STANDING_ADD_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.STANDING_ITEM_ADD_REQUESTED
);
const STANDING_REMOVE_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.STANDING_ITEM_REMOVE_REQUESTED
);
const STANDING_UPDATE_ITEM_ACTION = createAction<any, string>(
  ACTIONTYPES.STANDING_ITEM_UPDATE_REQUESTED
);

// schedule

const SCHEDULE_STANDING_ORDER_ACTION = createAction<any, string>(
  ACTIONTYPES.SCHEDULE_STANDING_ORDER_REQUESTED
);

const SCHEDULE_STANDING_ORDER_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.SCHEDULE_STANDING_ORDER_SUCCESS
);

const SCHEDULE_STANDING_ORDER_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.SCHEDULE_STANDING_ORDER_ERROR
);

// pause

const PAUSE_STANDING_ORDER_ACTION = createAction<any, string>(
  ACTIONTYPES.PAUSE_STANDING_ORDER_REQUESTED
);

const PAUSE_STANDING_ORDER_SUCCESS_ACTION = createAction<any, string>(
  ACTIONTYPES.PAUSE_STANDING_ORDER_SUCCESS
);

const PAUSE_STANDING_ORDER_ERROR_ACTION = createAction<any, string>(
  ACTIONTYPES.PAUSE_STANDING_ORDER_ERROR
);

export {
  CANCEL_RECURRING_ACTION,
  CANCEL_RECURRING_SUCCESS_ACTION,
  FETCH_RECURRING_ORDER_ACTION,
  FETCH_RECURRING_SUCCESS_ACTION,
  CANCEL_RECURRING_ERROR_ACTION,
  FETCH_RECURRING_ERROR_ACTION,
  PAUSE_STANDING_ORDER_ACTION,
  PAUSE_STANDING_ORDER_SUCCESS_ACTION,
  PAUSE_STANDING_ORDER_ERROR_ACTION,
  STANDING_ADD_ITEM_ACTION,
  STANDING_REMOVE_ITEM_ACTION,
  STANDING_UPDATE_ITEM_ACTION,
  SCHEDULE_STANDING_ORDER_ACTION,
  SCHEDULE_STANDING_ORDER_SUCCESS_ACTION,
  SCHEDULE_STANDING_ORDER_ERROR_ACTION,
};
