import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";
import styled from "styled-components";

const StyledCard = styled(({ ...props }) => <StyledAccelerateCard {...props} />)`
  ${({ theme }) => `
  font-weight: 400;
  h2 {
    font-size: ${theme.typography.h3.fontSize};
    color: ${theme.palette.primary.main};
    font-weight: 700 !important;
    margin: 0;
  }

  .table {
    border-collapse: collapse;
    width: 100%;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

  
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.icn-xs {
  height: 18px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-sm {
  height: 18px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-md {
  height: 28px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-lg {
  height: 38px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-badge {
  height: 100px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

  #reward-comparison-table .current-level {
    font-size: 120%;
    background-color: #FEF2C5; }
  #reward-comparison-table .reward-col {
    width: 20%;
    max-width: 20%;
    min-width: 130px; }
  #reward-comparison-table th {
    text-align: center;
    border-top: 0;
    vertical-align: top; }
    #reward-comparison-table th small {
      font-size: 12px;
      text-align: center;
      font-weight: 700;
      background-color: #2E2E2F;
      display: block;
      padding: 5px;
      color: #fff; }
    #reward-comparison-table th h5 {
      text-align: center;
      padding: 10px;
      background-color: #346170;
      text-transform: uppercase;
      color: #fff; }
      #reward-comparison-table th h5.current {
        background-color: #35a6bf; }
    #reward-comparison-table th .details {
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      color: #777777; }
  #reward-comparison-table td {
    text-align: center;
    font-size: 18px;
    vertical-align: middle;
    color: #E41F35; }
  #reward-comparison-table td.reward-detail {
    color: #777777;
    text-align: left;
    font-size: 18px; }
  #reward-comparison-table .table-hover tbody tr:hover {
    background-color: #F4F2E6; }
    #reward-comparison-table .table-hover tbody tr:hover td {
      color: #000 !important; }
  `}
`;

export default StyledCard;
