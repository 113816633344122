import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";
import styled from "styled-components";

const StyledCard = styled(({ ...props }) => <StyledAccelerateCard {...props} />)`
  ${({ theme, color, outline }) => `
  	.container > div {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding-top: ${theme.spacing(2)}px;
		gap: ${theme.spacing(2)}px;
	}
    h4:not(.MuiTypography-h4) {
		font-weight: 700 !important;
		color: ${theme.palette.primary.main};
		margin: 0;
		flex-basis: 100%;
	}
	a {
		display: block;
		vertical-align: top;
		font-size: ${theme.typography.h5.fontSize};
		font-weight: 700;
		color: ${theme.palette.neutral.contrastText};
		background-color: ${theme.palette.neutral.light};
		border: 1px solid ${theme.palette.neutral.dark};
		border-radius: ${theme.shape.borderRadius}px;
		padding: ${theme.spacing(2)}px;
		text-align: center;
		flex-basis: 100%;
		@media (min-width: ${theme.breakpoints.values.sm}px) {
			flex-basis: calc(50% - ${theme.spacing(1)}px);
		}
		@media (min-width: ${theme.breakpoints.values.md}px) {
			flex-basis: calc(25% - ${theme.spacing(1.5)}px);
		}
		transition: background-color 0.3s ease-in-out;
		&:hover {
			background-color: ${theme.palette.neutral.dark};
		}
	}

  `}
`;

export default StyledCard;
