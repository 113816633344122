/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put, select } from "redux-saga/effects";
//Foundation libraries
import productsService from "../../../_foundation/apis/search/chc-products.service";
//Redux
import * as ACTIONS from "../../action-types/catalog";
import { getProductPricing } from "../../actions/catalog";
import { flattenDeep, isEmpty } from "lodash-es"
import productUtil from "../../../utils/productUtil";
import chcGetPriceService from "../../../_foundation/apis/transaction/chc-price-server.service";
import { PRICE_ENABLED_COMPONENTS } from "../../../constants/catalog";
import { loginStatusSelector } from "../../selectors/user";

/**
 * Saga worker to invoke get product list API
 */
export function* fetchProductList(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(productsService.findProductsUsingGET, payload.parameters);
    const loggedIn= yield select(loginStatusSelector);
    const shouldFetchPricing = PRICE_ENABLED_COMPONENTS.includes(payload?.parameters?.widget?.trim()) && loggedIn;
    const finalizePLRes = {
      ...response?.data,
      ...(shouldFetchPricing ? { contents: response?.data?.contents?.map(i => ({ ...i, pricing: { status: 'pending', data: {} } })) } : {})
    };
    yield put({
      type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
      response: { ...finalizePLRes },
      payload: payload,
    });
    if (shouldFetchPricing) {
      yield put(getProductPricing({ ...action.payload, productList: response?.data?.contents || [] }));
    }
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(productsService.findProductsUsingGET, payload.parameters);
    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

export function* fetchProductListDetails(action: any) {
  try {
    const payload = action.payload;
    const params = payload.parameters;
    const r = yield call(productsService.findProductsUsingGET, params);
    const response = r.data;
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_S, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_F, error });
  }
}

export function* fetchProductPricing(action: any) {
  try {
    const payload = action?.payload
    const { productList = [] } = payload

    /**
     * Fetches inventory(UOM) details for every SKUs of every product
     */
    const SKUs = productList?.map((p) => ((p?.items || [])));
    const finalizedInventoryData = flattenDeep(SKUs);
    const UOMInfo: Array<any> = productUtil.getUOMInfo(finalizedInventoryData || []) || [];
    if (isEmpty(UOMInfo)) throw new Error("Failed to fetch inventory details");

    /**
     * Fetches pricing details for all SKUs of the products using UOM
     */
    const uomValuesByPartNumber = {};
    UOMInfo.forEach((item) => {
      if (uomValuesByPartNumber[item.id]) {
        uomValuesByPartNumber[item.id].push(item?.uomDisplay);
      } else {
        uomValuesByPartNumber[item.id] = [item?.uomDisplay];
      }
    });
    const catalogEntryId = Object.entries(uomValuesByPartNumber).map(
      ([key, value]: any) => {
        return key;
      }
    );
    const uomInfo = Object.entries(uomValuesByPartNumber).map(
      ([key, value]: any) => {
        return value?.join("|");
      }
    );
    
    const { widget, cancelToken, contractId, storeId, currency = "CAD" } = payload.detailParams;
    const pricingParams: any = {
      q: "byCatalogEntryIds",
      checkentitlement: "false",
      catalogEntryId, uomInfo, currency, widget, cancelToken, storeId, contractId
    };
    const pricingRes = yield call(chcGetPriceService.getPricePDP, pricingParams);
    const finalizePricingRes = (pricingRes?.data?.EntitledPrice || [])?.map((pr) =>
      ({ ...pr, UOMInfo: UOMInfo.find(u => u.id === pr.productId && u?.uomValue === pr?.UnitPrice?.[0]?.quantity?.uom) })
    );
    yield put({
      type: ACTIONS.PRODUCT_PRICING_GET_SUCCESS,
      response: [...finalizePricingRes],
      payload: payload,
    });

  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_PRICING_GET_ERROR, error });
  }
}