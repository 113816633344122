import { useEffect, useState } from "react";

export const useScript = ({
  url,
  body,
  cleanup,
}: {
  url?: string;
  body?: string;
  cleanup?: string;
}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if ((!body && !url) || loaded) return;
    if (body) {
      try {
        // eslint-disable-next-line no-eval
        eval(body);
        setLoaded(true);
      } catch (e) {
        console.error(e);
      }
      return cleanup
        ? () => {
            try {
              // eslint-disable-next-line no-eval
              eval(cleanup);
            } catch (e) {
              console.error(e);
            }
          }
        : undefined;
    }
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url || "";
    script.async = true;
    script.onload = () => {
      setLoaded(true);
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [body, cleanup, loaded, url]);
  return {
    loaded,
  };
};
