import styled from "styled-components";

const StyledIndicator = styled(({ ...props }) => <div {...props} />)`
  ${({ theme }) => {
    return `
       width: 7px;
       border-radius: 7px;
       &.active {
        background-color: ${theme.palette.primary.main};
       }
     `;
  }}
`;

export { StyledIndicator };
