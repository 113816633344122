/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useContext, useMemo } from "react";
//Foundation libraries
import {
  Notifications,
  NotificationsApi,
  UpdateNotificationApi,
} from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../constants/site";
import { getSite } from "./useSite";
import { NotificationsSiteContext } from "../context/notifications-context";
import { CommerceEnvironment } from "../../constants/common";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { userIdSelector } from "../../redux/selectors/user";
import { AxiosResponse } from "axios";
import {
  NOTIFICATIONS_REQUEST_ACTION
} from "../../redux/actions/organization";

type Notification = {
  id: string;
  title: string;
  content: string;
  type: string;
  date: string;
  read: boolean;
};

const mapNotification = (
  notification: Exclude<
    Awaited<ReturnType<NotificationsApi["cHCnotification"]>>["data"]["Notifications"],
    undefined
  >[number]
): Notification => {
  return {
    id: notification.Salesforce_Notification_Id || "",
    title: notification.Subject || "",
    content: notification.Body || "",
    type: notification.Notification_Type || "",
    date: notification.Created_Date || "",
    read: notification.Read || false,
  };
};

const notificationsApiInstance = new NotificationsApi(undefined, site.transactionContext);
const updateNotificationApiInstance = new UpdateNotificationApi(undefined, site.transactionContext);


const notificationsFetcher = async ({
  storeId,
  langId,
  offset,
  limit,
  unreadOnly,
}: {
  storeId?: string;
  langId: string;
  offset: number;
  limit: number;
  unreadOnly: boolean;
}): Promise<{
  notifications: Notification[];
  total: number;
  unreadTotal: number;
}> => {
  const siteInfo = getSite();
  try {
    const notificationResults = await notificationsApiInstance.cHCnotification(
      storeId || siteInfo?.storeID || "",
      langId,
      "json",
      String(offset),
      String(limit),
      unreadOnly ? "true" : undefined
    ) as AxiosResponse<Notifications & {Unread_Count?: string}>

    return {
      notifications: notificationResults.data.Notifications?.map(mapNotification) || [],
      total: Number(notificationResults.data.Total_Count || 0),
      unreadTotal: Number(notificationResults.data?.Unread_Count || 0), 
    };
  } catch (error) {
    return {
      notifications: [],
      total: 0,
      unreadTotal: 0,
    };
  }
};


const notificationsMarkAsRead = async ({
  storeId,
  messageId,
}: {
  storeId?: string;
  messageId: string;
}) => {
  const siteInfo = getSite();
  
  await updateNotificationApiInstance.cHCnotificationUpdate(
    storeId || siteInfo?.storeID || "",
    messageId,
    "read"
  );
  return true;
};

export const useNotifications = ({ pageSize = 10, unreadOnly = false } = {}) => {
  const { i18n } = useTranslation();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [unreadTotal, setUnreadTotal] = useState<number>(0);
  const [selected, setSelected] = useState<Notification | undefined>(undefined);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const { setUnreadCount } = useContext(NotificationsSiteContext);
  const langId = useMemo(
    () => CommerceEnvironment.reverseLanguageMap[i18n.languages[0].split("-").join("_")],
    [i18n]
  );
  const userId = useSelector(userIdSelector);
  const dispatch = useDispatch();

  const markAsRead = (id: string) => {
    notificationsMarkAsRead({ messageId: id }).then(() => {
      setNotifications((notifications) => {
        const notification = notifications.find((notification) => notification.id === id);
        if (notification) {
          notification.read = true;
          setTimeout(() => {
            setUnreadCount((unreadCount) => unreadCount - 1);
            setUnreadTotal((ut) => ut - 1);
          }, 100);
        }
        return [...notifications];
      });
      dispatch(
        NOTIFICATIONS_REQUEST_ACTION({
          messageId: String(id)
        })
      );
    });
    
  };

  const loadMore = () => {
    const offset = page * pageSize;
    const limit = pageSize;
    setPage(page + 1);
    setLoading(true);
    notificationsFetcher({ langId, offset, limit, unreadOnly }).then(({ notifications, total, unreadTotal }) => {
      setNotifications((currentNotifications) => [...currentNotifications, ...notifications]);
      setTotal(total);
      setLoading(false);
      if (unreadOnly) {
        setUnreadCount(unreadTotal);
        setUnreadTotal(unreadTotal);
      }
    });
  };

  useEffect(() => {
    const offset = 0;
    const limit = pageSize;
    let mounted = true;
    if (!userId) return;
    setPage(1);
    setNotifications([]);
    setTotal(0);
    setLoading(true);
    notificationsFetcher({ langId, offset, limit, unreadOnly }).then(({ notifications, total, unreadTotal }) => {
      if (!mounted) {
        return;
      }
      setNotifications(notifications);
      setTotal(total);
      setLoading(false);
      if (unreadOnly) {
        setUnreadCount(unreadTotal);
        setUnreadTotal(unreadTotal);
      }
    });
    return () => {
      mounted = false;
    };
  }, [pageSize, setUnreadCount, unreadOnly, langId, userId]);

  return {
    notifications,
    setSelected: (id: string) => {
      const notification = notifications.find((notification) => notification.id === id);
      if (notification) {
        setSelected(notification);
        markAsRead(id);
        setSelectedId(id);
      }
    },
    selected,
    selectedId,
    total,
    loadMore,
    markAsRead,
    unreadTotal,
    loading,
  };
};