import { useState, useEffect, useMemo } from "react";

import { CHC_FALLBACK_IMG } from "../../../../constants/common";
import { isEmpty } from "lodash-es";

type Props = {
    src?: string;
    fallBackUrl?: string;
}
const ORIGIN = window.location.origin;
const FALLBACK = ORIGIN + CHC_FALLBACK_IMG;

export const useImageWithFallback = (props: Props) => {

    const { fallBackUrl = FALLBACK } = props;
    const [isAvailable, setIsAvailable] = useState<boolean>();
    const urlToTest = props?.src;
    const finalValue = urlToTest?.replace("/null/", "/CHCAS/");
    
    //const imgSrc = useMemo(() => origin + props?.src ?? '', [props?.src]);
    const imgSrc = useMemo(() => origin + finalValue ?? '', [urlToTest]);
    
    useEffect(() => {
        if (!isEmpty(imgSrc)) {
            const img = new Image();
            img.onload = () => {
                setIsAvailable(true);
            }
            img.onerror = () => {
                setIsAvailable(false);
            }
            img.src = imgSrc;
        }
    }, [imgSrc]);

    return {
        src: isAvailable ? imgSrc : fallBackUrl,
        isAvailable
    }
}