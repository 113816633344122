import { FC, useContext, ReactNode } from "react";
import { Stack, Divider, Box } from "@mui/material";
import {
  StyledProgressPlaceholder,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { Search } from "@material-ui/icons";
import { NotificationsContext } from "../../../_foundation/context/notifications-context";
import { getDateFormatter } from "../../../utils/getDateFormatter";
import { useTranslation } from "react-i18next";
import { StyledRowCard } from "../../../elements/row-card";
import { StyledIndicator } from "../../../elements/indicator";
import { StyledIcon } from "../../../elements/icon";
import { useSelector } from "react-redux";
import { notificationSelector } from "../../../redux/selectors/organization";
import * as ROUTES from "../../../constants/routes";
import { useNavigate } from "react-router";

const NotificationsListCHC: FC<{ selectedContent?: ReactNode }> = ({
  selectedContent,
}) => {
  const { setSelected, selectedId, loading } = useContext(NotificationsContext);
  const { i18n, t } = useTranslation();
  const notificationList = useSelector(notificationSelector);
  const notifications = notificationList?.Notifications ?? [];
  const dateFormatter = getDateFormatter(i18n);
  const navigate = useNavigate();

  const handleSetSelected = (id: string) => {
    navigate(ROUTES.NOTIFICATIONS, {
      state: { id },
    });
  };
  return (
    <Stack spacing={1} divider={<Divider />}>
      {notificationList && notifications.length > 0 && notifications.map(
        ({ Salesforce_Notification_Id, Read, Subject, Last_Modified_Date }) => (
          <StyledRowCard
            key={Salesforce_Notification_Id}
            onClick={() => handleSetSelected(Salesforce_Notification_Id)}
            className={
              selectedId === Salesforce_Notification_Id ? "selected" : ""
            }
          >
            <Stack spacing={3} sx={{ p: 0 }}>
              <Stack direction="row" spacing={3}>
                <StyledIndicator className={Read ? "" : "active"} />
                <Stack spacing={1} sx={{ flex: 1 }}>
                  <StyledTypography variant="h5">{Subject}</StyledTypography>
                  <StyledTypography variant="subtitle2">
                    {dateFormatter.format(new Date(Last_Modified_Date))}
                  </StyledTypography>
                </Stack>
                <Stack alignItems="center" justifyContent="center">
                  <StyledIcon
                    icon={<Search className="full-center" />}
                    backgroundSize={32}
                    iconSize={18}
                    shape="square"
                    shade="dark"
                  />
                </Stack>
              </Stack>
            </Stack>
          </StyledRowCard>
        )
      )}
      {notificationList && notifications.length === 0  ? (
        <Box>
          <StyledTypography variant="h6">
            {t("Dashboard.Sections.Notifications.AllRead")}
          </StyledTypography>
        </Box>
      ) : null}
      {!notificationList ? (
        <StyledProgressPlaceholder style={{ backgroundColor: "transparent" }} />
      ) : null}
    </Stack>
  );
};

export default NotificationsListCHC;
