/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { RemoveOrganizationValidation } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCRemoveOrganizationApi - axios parameter creator
 * @export
 */
export const CHCRemoveOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Request to remove organization from user profile.
         * @summary Request to remove organization.
         * @param {string} storeId The store identifier.
         * @param {string} removeAccount remove organizationId
         * @param {string} [shipToOrgId] 
         * @param {string} [shipToAddress] 
         * @param {string} [addressType] 
         * @param {string} [shipToOrgName] 
         * @param {string} [reasonForClosure] 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcRemoveOrganization: async (storeId: string, removeAccount: string, shipToOrgId?: string, shipToAddress?: string, addressType?: string, shipToOrgName?: string, reasonForClosure?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcRemoveOrganization', 'storeId', storeId)
            // verify required parameter 'removeAccount' is not null or undefined
            assertParamExists('chcRemoveOrganization', 'removeAccount', removeAccount)
            const localVarPath = `/store/{storeId}/chc_organization/CHC_RemoveOrganization`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (removeAccount !== undefined) {
                localVarQueryParameter['removeAccount'] = removeAccount;
            }

            if (shipToOrgId !== undefined) {
                localVarQueryParameter['shipToOrgId'] = shipToOrgId;
            }

            if (shipToAddress !== undefined) {
                localVarQueryParameter['shipToAddress'] = shipToAddress;
            }

            if (addressType !== undefined) {
                localVarQueryParameter['addressType'] = addressType;
            }

            if (shipToOrgName !== undefined) {
                localVarQueryParameter['shipToOrgName'] = shipToOrgName;
            }

            if (reasonForClosure !== undefined) {
                localVarQueryParameter['reasonForClosure'] = reasonForClosure;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCRemoveOrganizationApi - functional programming interface
 * @export
 */
export const CHCRemoveOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCRemoveOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Request to remove organization from user profile.
         * @summary Request to remove organization.
         * @param {string} storeId The store identifier.
         * @param {string} removeAccount remove organizationId
         * @param {string} [shipToOrgId] 
         * @param {string} [shipToAddress] 
         * @param {string} [addressType] 
         * @param {string} [shipToOrgName] 
         * @param {string} [reasonForClosure] 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcRemoveOrganization(storeId: string, removeAccount: string, shipToOrgId?: string, shipToAddress?: string, addressType?: string, shipToOrgName?: string, reasonForClosure?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RemoveOrganizationValidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcRemoveOrganization(storeId, removeAccount, shipToOrgId, shipToAddress, addressType, shipToOrgName, reasonForClosure, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCRemoveOrganizationApi - factory interface
 * @export
 */
export const CHCRemoveOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCRemoveOrganizationApiFp(configuration)
    return {
        /**
         * Request to remove organization from user profile.
         * @summary Request to remove organization.
         * @param {string} storeId The store identifier.
         * @param {string} removeAccount remove organizationId
         * @param {string} [shipToOrgId] 
         * @param {string} [shipToAddress] 
         * @param {string} [addressType] 
         * @param {string} [shipToOrgName] 
         * @param {string} [reasonForClosure] 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcRemoveOrganization(storeId: string, removeAccount: string, shipToOrgId?: string, shipToAddress?: string, addressType?: string, shipToOrgName?: string, reasonForClosure?: string, responseFormat?: string, options?: any): AxiosPromise<RemoveOrganizationValidation> {
            return localVarFp.chcRemoveOrganization(storeId, removeAccount, shipToOrgId, shipToAddress, addressType, shipToOrgName, reasonForClosure, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCRemoveOrganizationApi - object-oriented interface
 * @export
 * @class CHCRemoveOrganizationApi
 * @extends {BaseAPI}
 */
export class CHCRemoveOrganizationApi extends BaseAPI {
    /**
     * Request to remove organization from user profile.
     * @summary Request to remove organization.
     * @param {string} storeId The store identifier.
     * @param {string} removeAccount remove organizationId
     * @param {string} [shipToOrgId] 
     * @param {string} [shipToAddress] 
     * @param {string} [addressType] 
     * @param {string} [shipToOrgName] 
     * @param {string} [reasonForClosure] 
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCRemoveOrganizationApi
     */
    public chcRemoveOrganization(storeId: string, removeAccount: string, shipToOrgId?: string, shipToAddress?: string, addressType?: string, shipToOrgName?: string, reasonForClosure?: string, responseFormat?: string, options?: any) {
        return CHCRemoveOrganizationApiFp(this.configuration).chcRemoveOrganization(storeId, removeAccount, shipToOrgId, shipToAddress, addressType, shipToOrgName, reasonForClosure, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
