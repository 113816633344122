import { createContext } from "react";
import { useAccelerateProgram } from "../hooks/use-accelerate-program-chc";

export const AccelerateProgramContext = createContext<ReturnType<typeof useAccelerateProgram>>({
  data: undefined,
  imgDir: "",
  threshold: {
    silver: 0,
    gold: 0,
    diamond: 0,
  },
  loading: false,
});
export const AccelerateProgramProvider = AccelerateProgramContext.Provider;
