/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/popup";
import { PopupReducerState } from "../reducers";

const OPEN_POPUP_ACTION = createAction<PopupReducerState, string>(ACTIONTYPES.OPEN_POPUP);

const CLOSE_POPUP_ACTION = createAction<PopupReducerState, string>(ACTIONTYPES.CLOSE_POPUP);


export {
  OPEN_POPUP_ACTION,
  CLOSE_POPUP_ACTION
};
