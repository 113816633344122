/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { AnyAction } from "redux";
import { call, put, select } from "redux-saga/effects";
//Foundation libraries
import subscriptionService from "../../../_foundation/apis/transaction/subscription.service";
import chcSearchOrdersService from "../../../_foundation/apis/transaction/chc-order.service";
//Redux
import {
  FETCH_RECURRING_SUCCESS_ACTION,
  FETCH_RECURRING_ERROR_ACTION,
  CANCEL_RECURRING_SUCCESS_ACTION,
  CANCEL_RECURRING_ERROR_ACTION,
  PAUSE_STANDING_ORDER_SUCCESS_ACTION,
  SCHEDULE_STANDING_ORDER_SUCCESS_ACTION,
  SCHEDULE_STANDING_ORDER_ERROR_ACTION,
} from "../../actions/recurringOrder";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import { userIdSelector, forUserIdSelector } from "../../selectors/user";
import { ORDER_CONFIGS } from "../../../configs/order";
import cartService from "../../../_foundation/apis/transaction/cart.service";
import { activeInprogressOrderSelector } from "../../selectors/order";

import * as ACTIONS from "../../action-types/recurringOrder";
import { FETCH_ORDER_DETAILS_ACTION } from "../../actions/orderDetails";

export function* fetchRecurringOrders(action: AnyAction) {
  const subscriptionTypeCode = "RecurringOrder";
  const profileName = "IBM_Store_Summary";
  const payload = action.payload;
  try {    
    let buyerId = yield select(forUserIdSelector);
    if(buyerId) {
      console.log(buyerId);
    } else {
      buyerId = yield select(userIdSelector);
    }
    const response = yield call(
      subscriptionService.byBuyerIdAndSubscriptionType,
      {
        buyerId,
        subscriptionTypeCode,
        profileName,
        ...payload,
      }
    );
    const fetchRecurringOrderPayload = response.data;
    if (payload?.widget) {
      fetchRecurringOrderPayload["widget"] = payload.widget;
    }
    yield put(FETCH_RECURRING_SUCCESS_ACTION(fetchRecurringOrderPayload));
  } catch (e) {
    yield put(FETCH_RECURRING_ERROR_ACTION(e));
  }
}

export function* cancelRecurringOrder(action: AnyAction) {
  const payload = action.payload;
  try {
    const response = yield call(
      subscriptionService.cancelRecurringOrSubscription,
      payload
    );

    const cancelRecurringOrderPayload = response.data;
    if (payload?.widget) {
      cancelRecurringOrderPayload["widget"] = payload.widget;
    }
    yield put(CANCEL_RECURRING_SUCCESS_ACTION(cancelRecurringOrderPayload));
    const successMessage = {
      key: "success-message.RecurringOrderCancelled",
    };
    yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
  } catch (e) {
    yield put(CANCEL_RECURRING_ERROR_ACTION(e));
  }
}

//TODO: pause and schedule

export function* pauseRecurringOrder(action: AnyAction) {
  const payload = action.payload;
  try {
    const response = yield call(
      chcSearchOrdersService.UpdateOrderStatus,
      payload
    );

    const pauseStandingOrderPayload = response.data;
    if (payload?.widget) {
      pauseStandingOrderPayload["widget"] = payload.widget;
    }

    yield put(PAUSE_STANDING_ORDER_SUCCESS_ACTION(pauseStandingOrderPayload));
    // TODO: TRANSLATE TO FR

    const mesageKey =
      payload.orderStatus === 1
        ? "The order has been successfully resumed"
        : "The order has been successfully paused";
    const successMessage = {
      key: mesageKey,
    };
    yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
  } catch (e) {
    yield put(CANCEL_RECURRING_ERROR_ACTION(e));
  }
}

export function* scheduleRecurringOrder(action: AnyAction) {
  const payload = action.payload;
  try {
    const response = yield call(
      chcSearchOrdersService.UpdateActionDate,
      payload
    );

    const scheduleRecurringOrderPayload = response.data;
    if (payload?.widget) {
      scheduleRecurringOrderPayload["widget"] = payload.widget;
    }

    yield put(
      SCHEDULE_STANDING_ORDER_SUCCESS_ACTION(scheduleRecurringOrderPayload)
    );
    // TODO: TRANSLATE TO FR
    const successMessage = {
      //key: "success-message.RecurringOrderCancelled",
      key: "The order has been successfully rescheduled",
    };
    yield put(HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
  } catch (e) {
    yield put(SCHEDULE_STANDING_ORDER_ERROR_ACTION(e));
  }
}

// add remove items from standing orders

/**
 * Saga worker to invoke add item API
 */
export function* addItem(action: any) {
  try {
    const payload = action.payload;
    const cartPayload = {
      contractId: payload.contractId,
    };

    const _orderItems: any[] = [];
    let catentryIds: string[] = [];
    let partnumbers: string[] = [];
    let quantities: any[] = [];
    let uomValues: string[] = [];
    let physicalStoreIds: string[] = [];
    let shipModeIds: string[] = [];
    if (payload.partnumber) {
      partnumbers =
        payload.partnumber instanceof Array
          ? payload.partnumber
          : [payload.partnumber];
      quantities =
        payload.quantity instanceof Array
          ? payload.quantity
          : [payload.quantity];
    } else if (payload.catentryId) {
      catentryIds =
        payload.catentryId instanceof Array
          ? payload.catentryId
          : [payload.catentryId];
      quantities =
        payload.quantity instanceof Array
          ? payload.quantity
          : [payload.quantity];
    }
    if (payload.uom) {
      uomValues = payload.uom instanceof Array ? payload.uom : [payload.uom];
    }
    if (payload.physicalStoreId) {
      physicalStoreIds =
        payload.physicalStoreId instanceof Array
          ? payload.physicalStoreId
          : [payload.physicalStoreId];
    }
    if (payload.shipModeId) {
      shipModeIds =
        payload.shipModeId instanceof Array
          ? payload.shipModeId
          : [payload.shipModeId];
    }

    for (const i in partnumbers) {
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        partNumber: partnumbers[i],
        xitem_field2: uomValues[i],
        contractId: payload.contractId,
      };
      if (physicalStoreIds[i]) {
        Object.assign(_orderItems[i], { physicalStoreId: physicalStoreIds[i] });
      }
      if (shipModeIds[i]) {
        Object.assign(_orderItems[i], { shipModeId: shipModeIds[i] });
      }
    }
    for (const i in catentryIds) {
      _orderItems[i] = {
        quantity: quantities[i].toString(),
        productId: catentryIds[i],
        xitem_field2: uomValues[i],
        contractId: payload.contractId,
      };
      if (physicalStoreIds[i]) {
        Object.assign(_orderItems[i], { physicalStoreId: physicalStoreIds[i] });
      }
      if (shipModeIds[i]) {
        Object.assign(_orderItems[i], { shipModeId: shipModeIds[i] });
      }
    }
    let body = {};
    if (payload.widget) {
      body["widget"] = payload.widget;
      cartPayload["widget"] = payload.widget;
    }
    const activeInprogressOrder = yield select(activeInprogressOrderSelector);
    let response: any = {};

    if (!activeInprogressOrder) {
      body = {
        body: {
          orderId: payload.orderId,
          x_calculateOrder: ORDER_CONFIGS.calculateOrder,
          orderItem: _orderItems,
          x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
        },
      };
      response = yield call(cartService.addOrderItem, body);
    } else {
      const orderItems = {};
      for (const i in partnumbers) {
        Object.assign(orderItems, {
          [`quantity_${i}`]: quantities[i].toString(),
          [`partNumber_${i}`]: partnumbers[i],
          [`xitem_field2_${i}`]: uomValues[i],
          [`contractId_${i}`]: payload.contractId,
        });
      }
      for (const i in catentryIds) {
        Object.assign(orderItems, {
          [`quantity_${i}`]: quantities[i].toString(),
          [`catEntryId_${i}`]: catentryIds[i],
          [`xitem_field2_${i}`]: uomValues[i],
          [`contractId_${i}`]: payload.contractId,
        });
      }
      Object.assign(orderItems, {
        orderId: activeInprogressOrder.orderId,
        calculateOrder: ORDER_CONFIGS.calculateOrder,
        inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      });
      body = {
        body: orderItems,
        ...body,
      };

      response = yield call(cartService.addPreConfigurationOrderItem, body);
    }

    yield put({
      type: ACTIONS.STANDING_ITEM_ADD_SUCCESS,
      response: response.data,
      payload: payload,
    });

    yield put(
      FETCH_ORDER_DETAILS_ACTION({
        orderId: payload.orderId,
        currency: "CAD",
        skipErrorSnackbar: true,
      })
    );

    // }
  } catch (error) {
    yield put({ type: ACTIONS.STANDING_ITEM_ADD_ERROR, error });
  }
}

/**
 * Saga worker to invoke remove item API
 */
export function* removeItem(action: any) {
  try {
    const payload = action.payload;
    const { widget, orderItemId, orderId } = payload;
    const body = {
      orderId,
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      orderItemId,
    };

    const response = yield call(cartService.deleteOrderItem, { body, widget });
    yield put(
      FETCH_ORDER_DETAILS_ACTION({
        orderId: payload.orderId,
        currency: "CAD",
        skipErrorSnackbar: true,
      })
    );
    yield put({
      type: ACTIONS.STANDING_ITEM_REMOVE_SUCCESS,
      response,
      payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.STANDING_ITEM_REMOVE_ERROR, error });
  }
}

/**
 * Saga worker to invoke update item API
 */
export function* updateItem(action: any) {
  try {
    const payload = action.payload;
    const { orderItemId, quantity, widget, xitem_field2, orderId } = payload;

    const body = {
      orderId,
      x_calculateOrder: ORDER_CONFIGS.calculateOrder,
      x_calculationUsage: ORDER_CONFIGS.calculationUsage,
      x_inventoryValidation: ORDER_CONFIGS.inventoryValidation,
      orderItem: [
        {
          quantity: quantity,
          orderItemId: orderItemId,
          xitem_field2: xitem_field2,
        },
      ],
    };

    const response = yield call(cartService.updateOrderItem, { widget, body });
    yield put({
      type: ACTIONS.STANDING_ITEM_UPDATE_SUCCESS,
      response,
      payload,
    });
    yield put(
      FETCH_ORDER_DETAILS_ACTION({
        orderId: payload.orderId,
        currency: "CAD",
        skipErrorSnackbar: true,
      })
    );
  } catch (error) {
    yield put({ type: ACTIONS.STANDING_ITEM_UPDATE_ERROR, error });
  }
}
