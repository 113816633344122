/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { takeLatest } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../actions/user-management-admin";
import * as WORKERS from "../workers/user-management-admin";

/**
 * Invoice watch saga
 * watchers to intercept Invoice actions
 */
export function* watchSaga() {
  yield takeLatest(ACTIONS.UPDATE_USER_STATUS_REQUEST_ACTION, WORKERS.userUpdateRequest);
  yield takeLatest(ACTIONS.RESET_USER_PASSWORD_REQUEST_ACTION, WORKERS.userResetPasswordRequest);
}
