/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { CHCServerGetPricesApi, CHCPriceAndAvailabilityForSavedListsApi, CHCPriceAndAvailabilityForSavedListsItemsApi } from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../../constants/site";
import { chunk } from "lodash-es";

const PAGE_SIZE = 10;

const priceServerApiInstance = new CHCServerGetPricesApi(undefined, site.transactionContext);
const priceAvailabilityServerApiInstance = new CHCPriceAndAvailabilityForSavedListsApi(undefined, site.transactionContext);
const itemPriceAvailabilityServerApiInstance = new CHCPriceAndAvailabilityForSavedListsItemsApi(undefined, site.transactionContext);
const chcGetPriceService = {
  /**
   * This allows a user to get prices.
   * `@method`
   * `@name Organization#getEntitledOrganizations`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get prices from price server.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   */
  getPricePDP(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      catalogEntryId,
      q,
      currency,
      contractId,
      uomInfo,
      checkentitlement,
      responseFormat,
      ...options
    } = parameters;
    const chunkedUomInfo = chunk(uomInfo, PAGE_SIZE);
    return new Promise((resolve) =>
      Promise.all(
        chunk(catalogEntryId, PAGE_SIZE).map((catalogEntryId, index) =>
          priceServerApiInstance.cHCpriceGetPrice(
            storeId,
            catalogEntryId as any as string,
            q,
            currency,
            contractId,
            chunkedUomInfo[index] as any as string,
            checkentitlement,
            responseFormat,
            options
          )
        )
      ).then((responses: any) =>
        resolve({
          ...responses[0],
          data: responses.reduce(
            ({ EntitledPrice }, { data }) => ({
              EntitledPrice: [...EntitledPrice, ...data.EntitledPrice],
              resourceId: data.resourceId,
              resourceName: data.resourceName,
            }),
            { EntitledPrice: [], resourceId: "", resourceName: "" }
          ),
        })
      )
    );
  },

  /**
   * This fetches price and availability.
   * `@method`
   * `@name Price#fetchPriceAndAvailability`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   */
   fetchPriceAndAvailability(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, orderId, responseFormat, ...options } = parameters;
    return priceAvailabilityServerApiInstance.chcPriceAndAvailability(
      storeId,
      orderId,
      responseFormat,
      options
    );
  },

  /**
   * This fetches updated price and availability.
   * `@method`
   * `@name Price#fetchUpdatedPriceAndAvailability`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to register an organization, to get or update organization information.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to register an organization, to get or update organization information.
   */
   fetchUpdatedPriceAndAvailability(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, orderItemId, responseFormat, ...options } = parameters;
    return itemPriceAvailabilityServerApiInstance.chcItemPriceAndAvailability(
      storeId,
      orderItemId,
      responseFormat,
      options
    );
  },
};

export default chcGetPriceService;
