/* tslint:disable */
/* eslint-disable */
/**
 * Approval Status
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ResponseMessage } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { SiteMemberAttribute } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { SiteMemberAttributeCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { StoreMemberAttribute } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { StoreMemberAttributeCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { User } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { UserChangePassword } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { UserCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { UserLogon } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { UserRequest } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allows users to change password.
         * @summary Allows users to change password.
         * @param {UserChangePassword} userChangePassword Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword: async (userChangePassword: UserChangePassword, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userChangePassword' is not null or undefined
            assertParamExists('changePassword', 'userChangePassword', userChangePassword)
            const localVarPath = `/users/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userChangePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a user.
         * @summary Create a user.
         * @param {UserRequest} body Request body.
         * @param {number} [storeId] The unique numeric ID of the store used to create the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUser: async (body: UserRequest, storeId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersCreateUser', 'body', body)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a user site-level attribute.
         * @summary Create a user site-level attribute.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUserSiteAttribute: async (id: string, body: SiteMemberAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersCreateUserSiteAttribute', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersCreateUserSiteAttribute', 'body', body)
            const localVarPath = `/users/{id}/site-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a store-specific user attribute.
         * @summary Create a store-specific user attribute.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUserStoreMemberAttribute: async (id: string, body: StoreMemberAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersCreateUserStoreMemberAttribute', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersCreateUserStoreMemberAttribute', 'body', body)
            const localVarPath = `/users/{id}/store-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a site-level attribute of a user.
         * @summary Delete a site-level attribute of a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to be deleted.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserSiteAttribute: async (id: string, name: string, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersDeleteUserSiteAttribute', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('usersDeleteUserSiteAttribute', 'name', name)
            const localVarPath = `/users/{id}/site-attributes/{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a store-specific attribute of a user.
         * @summary Delete a store-specific attribute of a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserStoreMemberAttribute: async (id: string, storeId: number, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersDeleteUserStoreMemberAttribute', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('usersDeleteUserStoreMemberAttribute', 'storeId', storeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('usersDeleteUserStoreMemberAttribute', 'name', name)
            const localVarPath = `/users/{id}/store-attributes/storeId:{storeId},name:{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user by ID.
         * @summary Get a user by ID.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindByUserId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersFindByUserId', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user\'s site-level, store-independent attribute by user ID and attribute name.
         * @summary Get a user\'s site-level, store-independent attribute by user ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindSiteAttributeByUserId: async (id: string, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersFindSiteAttributeByUserId', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('usersFindSiteAttributeByUserId', 'name', name)
            const localVarPath = `/users/{id}/site-attributes/{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user\'s site-level, store-independent attributes.
         * @summary Get a user\'s site-level, store-independent attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindSiteAttributesByUserId: async (id: string, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersFindSiteAttributesByUserId', 'id', id)
            const localVarPath = `/users/{id}/site-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user\'s store-specific attribute by user ID, store ID and attribute name.
         * @summary Get a user\'s store-specific attribute by user ID, store ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindStoreMemberAttributeByUserId: async (id: string, storeId: number, name: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersFindStoreMemberAttributeByUserId', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('usersFindStoreMemberAttributeByUserId', 'storeId', storeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('usersFindStoreMemberAttributeByUserId', 'name', name)
            const localVarPath = `/users/{id}/store-attributes/storeId:{storeId},name:{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a user\'s store-specific attributes.
         * @summary Get a user\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindStoreMemberAttributesByUserId: async (id: string, storeId: number, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersFindStoreMemberAttributesByUserId', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('usersFindStoreMemberAttributesByUserId', 'storeId', storeId)
            const localVarPath = `/users/{id}/store-attributes`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a collection of users the currently logged in administrator user can manage.
         * @summary Get a collection of users the currently logged in administrator user can manage.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
         * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
         * @param {string} [logonId] Limits search results to only include users whose logon ID matches the value of this parameter.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
         * @param {string} [searchScope] Modifies the behaviour of searchString to limit the scope of the search. This is a comma separated list of field names. For example, \&#39;logonId\&#39; will limit the scope of the search to only check the logonId field.
         * @param {string} [searchType] Modifies the behaviour of searchString to control how the search string will be used. The following values are accepted:  * caseSensitiveStartsWith - Returns cases sensitive matches that start with the specified search string.  * caseSensitiveContains - Returns case sensitive matches that contain the specified search string.  * caseInsensitiveStartsWith - Returns case insensitive matches that start with the specified search string.  * caseInsensitiveContains - Returns case insensitive matches that contain the specified search string.  * caseSensitiveExactMatch - Returns case sensitive matches to the specified search string.  * caseInsensitiveExactMatch - Returns case insensitive matches to the specified search string. 
         * @param {number} [roleId] Limits search results to only include users whose role ID matches the value of this parameter.
         * @param {number} [organizationRoleId] Limits search results to only include users whose role ID matches the value of this parameter. This role ID can be qualified by the organization specified by roleOrganizationId.
         * @param {string} [roleOrganizationId] Qualifies organizationRoleId to limit search results to only include users who have a matching role ID in the organization that matches this parameter.
         * @param {string} [parentOrganizationId] Limits search results to only include users whose parent organization ID matches the value of this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetManageableUsers: async (offset?: number, limit?: number, lastName?: string, firstName?: string, logonId?: string, sort?: string, searchString?: string, searchScope?: string, searchType?: string, roleId?: number, organizationRoleId?: number, roleOrganizationId?: string, parentOrganizationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/manageable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (logonId !== undefined) {
                localVarQueryParameter['logonId'] = logonId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (searchScope !== undefined) {
                localVarQueryParameter['searchScope'] = searchScope;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            if (organizationRoleId !== undefined) {
                localVarQueryParameter['organizationRoleId'] = organizationRoleId;
            }

            if (roleOrganizationId !== undefined) {
                localVarQueryParameter['roleOrganizationId'] = roleOrganizationId;
            }

            if (parentOrganizationId !== undefined) {
                localVarQueryParameter['parentOrganizationId'] = parentOrganizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user roles by ID.
         * @summary Get user roles by ID.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserRoles: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersGetUserRoles', 'id', id)
            const localVarPath = `/users/{id}/roles`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logon the current user using their user name and password.
         * @summary Logon the current user using their user name and password.
         * @param {UserLogon} userLogon Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLogon: async (userLogon: UserLogon, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userLogon' is not null or undefined
            assertParamExists('usersLogon', 'userLogon', userLogon)
            const localVarPath = `/users/logon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userLogon, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Logout the current user.
         * @summary Logout the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLogout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/logoff`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reset a user\'s password.
         * @summary Reset a user\'s password.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store used to compose the password reset notification message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetUserPassword: async (id: string, storeId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersResetUserPassword', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('usersResetUserPassword', 'storeId', storeId)
            const localVarPath = `/users/{id}/password-reset`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user.
         * @summary Update a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {UserRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUser: async (id: string, body?: UserRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersUpdateUser', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user\'s site-level attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update a user\'s site-level attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to be updated.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserSiteAttribute: async (id: string, name: string, body: SiteMemberAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersUpdateUserSiteAttribute', 'id', id)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('usersUpdateUserSiteAttribute', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersUpdateUserSiteAttribute', 'body', body)
            const localVarPath = `/users/{id}/site-attributes/{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update a user\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of this attribute.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserStoreMemberAttribute: async (id: string, storeId: number, name: string, body: StoreMemberAttribute, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersUpdateUserStoreMemberAttribute', 'id', id)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('usersUpdateUserStoreMemberAttribute', 'storeId', storeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('usersUpdateUserStoreMemberAttribute', 'name', name)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('usersUpdateUserStoreMemberAttribute', 'body', body)
            const localVarPath = `/users/{id}/store-attributes/storeId:{storeId},name:{name}`
                .replace(`{${"id"}}`, String(id))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"name"}}`, String(name));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Allows users to change password.
         * @summary Allows users to change password.
         * @param {UserChangePassword} userChangePassword Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changePassword(userChangePassword: UserChangePassword, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changePassword(userChangePassword, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a user.
         * @summary Create a user.
         * @param {UserRequest} body Request body.
         * @param {number} [storeId] The unique numeric ID of the store used to create the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreateUser(body: UserRequest, storeId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreateUser(body, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a user site-level attribute.
         * @summary Create a user site-level attribute.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreateUserSiteAttribute(id: string, body: SiteMemberAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreateUserSiteAttribute(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a store-specific user attribute.
         * @summary Create a store-specific user attribute.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCreateUserStoreMemberAttribute(id: string, body: StoreMemberAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCreateUserStoreMemberAttribute(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a site-level attribute of a user.
         * @summary Delete a site-level attribute of a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to be deleted.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteUserSiteAttribute(id: string, name: string, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUserSiteAttribute(id, name, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a store-specific attribute of a user.
         * @summary Delete a store-specific attribute of a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDeleteUserStoreMemberAttribute(id: string, storeId: number, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDeleteUserStoreMemberAttribute(id, storeId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a user by ID.
         * @summary Get a user by ID.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFindByUserId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFindByUserId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a user\'s site-level, store-independent attribute by user ID and attribute name.
         * @summary Get a user\'s site-level, store-independent attribute by user ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFindSiteAttributeByUserId(id: string, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteMemberAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFindSiteAttributeByUserId(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a user\'s site-level, store-independent attributes.
         * @summary Get a user\'s site-level, store-independent attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFindSiteAttributesByUserId(id: string, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SiteMemberAttributeCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFindSiteAttributesByUserId(id, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a user\'s store-specific attribute by user ID, store ID and attribute name.
         * @summary Get a user\'s store-specific attribute by user ID, store ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFindStoreMemberAttributeByUserId(id: string, storeId: number, name: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreMemberAttribute>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFindStoreMemberAttributeByUserId(id, storeId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a user\'s store-specific attributes.
         * @summary Get a user\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersFindStoreMemberAttributesByUserId(id: string, storeId: number, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreMemberAttributeCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersFindStoreMemberAttributesByUserId(id, storeId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a collection of users the currently logged in administrator user can manage.
         * @summary Get a collection of users the currently logged in administrator user can manage.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
         * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
         * @param {string} [logonId] Limits search results to only include users whose logon ID matches the value of this parameter.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
         * @param {string} [searchScope] Modifies the behaviour of searchString to limit the scope of the search. This is a comma separated list of field names. For example, \&#39;logonId\&#39; will limit the scope of the search to only check the logonId field.
         * @param {string} [searchType] Modifies the behaviour of searchString to control how the search string will be used. The following values are accepted:  * caseSensitiveStartsWith - Returns cases sensitive matches that start with the specified search string.  * caseSensitiveContains - Returns case sensitive matches that contain the specified search string.  * caseInsensitiveStartsWith - Returns case insensitive matches that start with the specified search string.  * caseInsensitiveContains - Returns case insensitive matches that contain the specified search string.  * caseSensitiveExactMatch - Returns case sensitive matches to the specified search string.  * caseInsensitiveExactMatch - Returns case insensitive matches to the specified search string. 
         * @param {number} [roleId] Limits search results to only include users whose role ID matches the value of this parameter.
         * @param {number} [organizationRoleId] Limits search results to only include users whose role ID matches the value of this parameter. This role ID can be qualified by the organization specified by roleOrganizationId.
         * @param {string} [roleOrganizationId] Qualifies organizationRoleId to limit search results to only include users who have a matching role ID in the organization that matches this parameter.
         * @param {string} [parentOrganizationId] Limits search results to only include users whose parent organization ID matches the value of this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetManageableUsers(offset?: number, limit?: number, lastName?: string, firstName?: string, logonId?: string, sort?: string, searchString?: string, searchScope?: string, searchType?: string, roleId?: number, organizationRoleId?: number, roleOrganizationId?: string, parentOrganizationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetManageableUsers(offset, limit, lastName, firstName, logonId, sort, searchString, searchScope, searchType, roleId, organizationRoleId, roleOrganizationId, parentOrganizationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user roles by ID.
         * @summary Get user roles by ID.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGetUserRoles(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGetUserRoles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logon the current user using their user name and password.
         * @summary Logon the current user using their user name and password.
         * @param {UserLogon} userLogon Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLogon(userLogon: UserLogon, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserLogon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLogon(userLogon, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Logout the current user.
         * @summary Logout the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersLogout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reset a user\'s password.
         * @summary Reset a user\'s password.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store used to compose the password reset notification message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersResetUserPassword(id: string, storeId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersResetUserPassword(id, storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a user.
         * @summary Update a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {UserRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUser(id: string, body?: UserRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUser(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a user\'s site-level attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update a user\'s site-level attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to be updated.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUserSiteAttribute(id: string, name: string, body: SiteMemberAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserSiteAttribute(id, name, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a user\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update a user\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of this attribute.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUpdateUserStoreMemberAttribute(id: string, storeId: number, name: string, body: StoreMemberAttribute, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUpdateUserStoreMemberAttribute(id, storeId, name, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Allows users to change password.
         * @summary Allows users to change password.
         * @param {UserChangePassword} userChangePassword Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(userChangePassword: UserChangePassword, options?: any): AxiosPromise<void> {
            return localVarFp.changePassword(userChangePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a user.
         * @summary Create a user.
         * @param {UserRequest} body Request body.
         * @param {number} [storeId] The unique numeric ID of the store used to create the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUser(body: UserRequest, storeId?: number, options?: any): AxiosPromise<void> {
            return localVarFp.usersCreateUser(body, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a user site-level attribute.
         * @summary Create a user site-level attribute.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUserSiteAttribute(id: string, body: SiteMemberAttribute, options?: any): AxiosPromise<void> {
            return localVarFp.usersCreateUserSiteAttribute(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a store-specific user attribute.
         * @summary Create a store-specific user attribute.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCreateUserStoreMemberAttribute(id: string, body: StoreMemberAttribute, options?: any): AxiosPromise<void> {
            return localVarFp.usersCreateUserStoreMemberAttribute(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a site-level attribute of a user.
         * @summary Delete a site-level attribute of a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to be deleted.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserSiteAttribute(id: string, name: string, offset?: number, limit?: number, options?: any): AxiosPromise<void> {
            return localVarFp.usersDeleteUserSiteAttribute(id, name, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a store-specific attribute of a user.
         * @summary Delete a store-specific attribute of a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to be deleted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDeleteUserStoreMemberAttribute(id: string, storeId: number, name: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersDeleteUserStoreMemberAttribute(id, storeId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user by ID.
         * @summary Get a user by ID.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindByUserId(id: string, options?: any): AxiosPromise<User> {
            return localVarFp.usersFindByUserId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user\'s site-level, store-independent attribute by user ID and attribute name.
         * @summary Get a user\'s site-level, store-independent attribute by user ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindSiteAttributeByUserId(id: string, name: string, options?: any): AxiosPromise<SiteMemberAttribute> {
            return localVarFp.usersFindSiteAttributeByUserId(id, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user\'s site-level, store-independent attributes.
         * @summary Get a user\'s site-level, store-independent attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindSiteAttributesByUserId(id: string, offset?: number, limit?: number, options?: any): AxiosPromise<SiteMemberAttributeCollection> {
            return localVarFp.usersFindSiteAttributesByUserId(id, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user\'s store-specific attribute by user ID, store ID and attribute name.
         * @summary Get a user\'s store-specific attribute by user ID, store ID and attribute name.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of the attribute to use as the search term.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindStoreMemberAttributeByUserId(id: string, storeId: number, name: string, options?: any): AxiosPromise<StoreMemberAttribute> {
            return localVarFp.usersFindStoreMemberAttributeByUserId(id, storeId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a user\'s store-specific attributes.
         * @summary Get a user\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersFindStoreMemberAttributesByUserId(id: string, storeId: number, offset?: number, limit?: number, options?: any): AxiosPromise<StoreMemberAttributeCollection> {
            return localVarFp.usersFindStoreMemberAttributesByUserId(id, storeId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a collection of users the currently logged in administrator user can manage.
         * @summary Get a collection of users the currently logged in administrator user can manage.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
         * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
         * @param {string} [logonId] Limits search results to only include users whose logon ID matches the value of this parameter.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
         * @param {string} [searchScope] Modifies the behaviour of searchString to limit the scope of the search. This is a comma separated list of field names. For example, \&#39;logonId\&#39; will limit the scope of the search to only check the logonId field.
         * @param {string} [searchType] Modifies the behaviour of searchString to control how the search string will be used. The following values are accepted:  * caseSensitiveStartsWith - Returns cases sensitive matches that start with the specified search string.  * caseSensitiveContains - Returns case sensitive matches that contain the specified search string.  * caseInsensitiveStartsWith - Returns case insensitive matches that start with the specified search string.  * caseInsensitiveContains - Returns case insensitive matches that contain the specified search string.  * caseSensitiveExactMatch - Returns case sensitive matches to the specified search string.  * caseInsensitiveExactMatch - Returns case insensitive matches to the specified search string. 
         * @param {number} [roleId] Limits search results to only include users whose role ID matches the value of this parameter.
         * @param {number} [organizationRoleId] Limits search results to only include users whose role ID matches the value of this parameter. This role ID can be qualified by the organization specified by roleOrganizationId.
         * @param {string} [roleOrganizationId] Qualifies organizationRoleId to limit search results to only include users who have a matching role ID in the organization that matches this parameter.
         * @param {string} [parentOrganizationId] Limits search results to only include users whose parent organization ID matches the value of this parameter.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetManageableUsers(offset?: number, limit?: number, lastName?: string, firstName?: string, logonId?: string, sort?: string, searchString?: string, searchScope?: string, searchType?: string, roleId?: number, organizationRoleId?: number, roleOrganizationId?: string, parentOrganizationId?: string, options?: any): AxiosPromise<UserCollection> {
            return localVarFp.usersGetManageableUsers(offset, limit, lastName, firstName, logonId, sort, searchString, searchScope, searchType, roleId, organizationRoleId, roleOrganizationId, parentOrganizationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user roles by ID.
         * @summary Get user roles by ID.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGetUserRoles(id: string, options?: any): AxiosPromise<RoleCollection> {
            return localVarFp.usersGetUserRoles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Logon the current user using their user name and password.
         * @summary Logon the current user using their user name and password.
         * @param {UserLogon} userLogon Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLogon(userLogon: UserLogon, options?: any): AxiosPromise<UserLogon> {
            return localVarFp.usersLogon(userLogon, options).then((request) => request(axios, basePath));
        },
        /**
         * Logout the current user.
         * @summary Logout the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersLogout(options?: any): AxiosPromise<void> {
            return localVarFp.usersLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * Reset a user\'s password.
         * @summary Reset a user\'s password.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store used to compose the password reset notification message.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersResetUserPassword(id: string, storeId: number, options?: any): AxiosPromise<ResponseMessage> {
            return localVarFp.usersResetUserPassword(id, storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user.
         * @summary Update a user.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {UserRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUser(id: string, body?: UserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersUpdateUser(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user\'s site-level attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update a user\'s site-level attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {string} name The name of the attribute to be updated.
         * @param {SiteMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserSiteAttribute(id: string, name: string, body: SiteMemberAttribute, options?: any): AxiosPromise<void> {
            return localVarFp.usersUpdateUserSiteAttribute(id, name, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
         * @summary Update a user\'s store-specific attributes.
         * @param {string} id The unique numeric ID for identifying the user.
         * @param {number} storeId The unique numeric ID of the store where the attribute applies.
         * @param {string} name The name of this attribute.
         * @param {StoreMemberAttribute} body Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUpdateUserStoreMemberAttribute(id: string, storeId: number, name: string, body: StoreMemberAttribute, options?: any): AxiosPromise<void> {
            return localVarFp.usersUpdateUserStoreMemberAttribute(id, storeId, name, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Allows users to change password.
     * @summary Allows users to change password.
     * @param {UserChangePassword} userChangePassword Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(userChangePassword: UserChangePassword, options?: any) {
        return UsersApiFp(this.configuration).changePassword(userChangePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a user.
     * @summary Create a user.
     * @param {UserRequest} body Request body.
     * @param {number} [storeId] The unique numeric ID of the store used to create the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreateUser(body: UserRequest, storeId?: number, options?: any) {
        return UsersApiFp(this.configuration).usersCreateUser(body, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a user site-level attribute.
     * @summary Create a user site-level attribute.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {SiteMemberAttribute} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreateUserSiteAttribute(id: string, body: SiteMemberAttribute, options?: any) {
        return UsersApiFp(this.configuration).usersCreateUserSiteAttribute(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a store-specific user attribute.
     * @summary Create a store-specific user attribute.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {StoreMemberAttribute} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCreateUserStoreMemberAttribute(id: string, body: StoreMemberAttribute, options?: any) {
        return UsersApiFp(this.configuration).usersCreateUserStoreMemberAttribute(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a site-level attribute of a user.
     * @summary Delete a site-level attribute of a user.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {string} name The name of the attribute to be deleted.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDeleteUserSiteAttribute(id: string, name: string, offset?: number, limit?: number, options?: any) {
        return UsersApiFp(this.configuration).usersDeleteUserSiteAttribute(id, name, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a store-specific attribute of a user.
     * @summary Delete a store-specific attribute of a user.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {string} name The name of the attribute to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersDeleteUserStoreMemberAttribute(id: string, storeId: number, name: string, options?: any) {
        return UsersApiFp(this.configuration).usersDeleteUserStoreMemberAttribute(id, storeId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user by ID.
     * @summary Get a user by ID.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersFindByUserId(id: string, options?: any) {
        return UsersApiFp(this.configuration).usersFindByUserId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user\'s site-level, store-independent attribute by user ID and attribute name.
     * @summary Get a user\'s site-level, store-independent attribute by user ID and attribute name.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {string} name The name of the attribute to use as the search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersFindSiteAttributeByUserId(id: string, name: string, options?: any) {
        return UsersApiFp(this.configuration).usersFindSiteAttributeByUserId(id, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user\'s site-level, store-independent attributes.
     * @summary Get a user\'s site-level, store-independent attributes.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersFindSiteAttributesByUserId(id: string, offset?: number, limit?: number, options?: any) {
        return UsersApiFp(this.configuration).usersFindSiteAttributesByUserId(id, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user\'s store-specific attribute by user ID, store ID and attribute name.
     * @summary Get a user\'s store-specific attribute by user ID, store ID and attribute name.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {string} name The name of the attribute to use as the search term.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersFindStoreMemberAttributeByUserId(id: string, storeId: number, name: string, options?: any) {
        return UsersApiFp(this.configuration).usersFindStoreMemberAttributeByUserId(id, storeId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a user\'s store-specific attributes.
     * @summary Get a user\'s store-specific attributes.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersFindStoreMemberAttributesByUserId(id: string, storeId: number, offset?: number, limit?: number, options?: any) {
        return UsersApiFp(this.configuration).usersFindStoreMemberAttributesByUserId(id, storeId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a collection of users the currently logged in administrator user can manage.
     * @summary Get a collection of users the currently logged in administrator user can manage.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [lastName] Limits search results to only include users whose last name matches the value of this parameter.
     * @param {string} [firstName] Limits search results to only include users whose first name matches the value of this parameter.
     * @param {string} [logonId] Limits search results to only include users whose logon ID matches the value of this parameter.
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
     * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
     * @param {string} [searchScope] Modifies the behaviour of searchString to limit the scope of the search. This is a comma separated list of field names. For example, \&#39;logonId\&#39; will limit the scope of the search to only check the logonId field.
     * @param {string} [searchType] Modifies the behaviour of searchString to control how the search string will be used. The following values are accepted:  * caseSensitiveStartsWith - Returns cases sensitive matches that start with the specified search string.  * caseSensitiveContains - Returns case sensitive matches that contain the specified search string.  * caseInsensitiveStartsWith - Returns case insensitive matches that start with the specified search string.  * caseInsensitiveContains - Returns case insensitive matches that contain the specified search string.  * caseSensitiveExactMatch - Returns case sensitive matches to the specified search string.  * caseInsensitiveExactMatch - Returns case insensitive matches to the specified search string. 
     * @param {number} [roleId] Limits search results to only include users whose role ID matches the value of this parameter.
     * @param {number} [organizationRoleId] Limits search results to only include users whose role ID matches the value of this parameter. This role ID can be qualified by the organization specified by roleOrganizationId.
     * @param {string} [roleOrganizationId] Qualifies organizationRoleId to limit search results to only include users who have a matching role ID in the organization that matches this parameter.
     * @param {string} [parentOrganizationId] Limits search results to only include users whose parent organization ID matches the value of this parameter.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetManageableUsers(offset?: number, limit?: number, lastName?: string, firstName?: string, logonId?: string, sort?: string, searchString?: string, searchScope?: string, searchType?: string, roleId?: number, organizationRoleId?: number, roleOrganizationId?: string, parentOrganizationId?: string, options?: any) {
        return UsersApiFp(this.configuration).usersGetManageableUsers(offset, limit, lastName, firstName, logonId, sort, searchString, searchScope, searchType, roleId, organizationRoleId, roleOrganizationId, parentOrganizationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user roles by ID.
     * @summary Get user roles by ID.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGetUserRoles(id: string, options?: any) {
        return UsersApiFp(this.configuration).usersGetUserRoles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logon the current user using their user name and password.
     * @summary Logon the current user using their user name and password.
     * @param {UserLogon} userLogon Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersLogon(userLogon: UserLogon, options?: any) {
        return UsersApiFp(this.configuration).usersLogon(userLogon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Logout the current user.
     * @summary Logout the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersLogout(options?: any) {
        return UsersApiFp(this.configuration).usersLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reset a user\'s password.
     * @summary Reset a user\'s password.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {number} storeId The unique numeric ID of the store used to compose the password reset notification message.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersResetUserPassword(id: string, storeId: number, options?: any) {
        return UsersApiFp(this.configuration).usersResetUserPassword(id, storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user.
     * @summary Update a user.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {UserRequest} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUser(id: string, body?: UserRequest, options?: any) {
        return UsersApiFp(this.configuration).usersUpdateUser(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user\'s site-level attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
     * @summary Update a user\'s site-level attributes.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {string} name The name of the attribute to be updated.
     * @param {SiteMemberAttribute} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUserSiteAttribute(id: string, name: string, body: SiteMemberAttribute, options?: any) {
        return UsersApiFp(this.configuration).usersUpdateUserSiteAttribute(id, name, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user\'s store-specific attributes. Attribute values are merged at the attribute level, rather than the attribute value level. For example, if an attribute has the following values \'[a, b]\', after performing an update using values \'[c, d, e]\', the attribute values would be \'[c, d, e]\'.
     * @summary Update a user\'s store-specific attributes.
     * @param {string} id The unique numeric ID for identifying the user.
     * @param {number} storeId The unique numeric ID of the store where the attribute applies.
     * @param {string} name The name of this attribute.
     * @param {StoreMemberAttribute} body Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUpdateUserStoreMemberAttribute(id: string, storeId: number, name: string, body: StoreMemberAttribute, options?: any) {
        return UsersApiFp(this.configuration).usersUpdateUserStoreMemberAttribute(id, storeId, name, body, options).then((request) => request(this.axios, this.basePath));
    }
}
