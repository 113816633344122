import { StyledProductImage, StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";

export const AccelerateStatus: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useContext(AccelerateProgramContext);
  const noStatusImage=(image)=>{
    if(image.toLowerCase() === "no status"){
      return true;
    }
    else {
      return false;
    }
  }
  return (
    <StyledAccelerateCard>
      {loading ? null : (
        <Stack spacing={2}>
          <StyledTypography variant="h4" align="center">
            {t("DealerIncentiveProgram.CURRENT_STATUS")}
          </StyledTypography>
          <StyledTypography variant="h1" component="h5" color="primary">
            {data?.Status ? (
              <div style={{ textAlign: "center" }}>
                {!noStatusImage(data?.Status) ?(
                <StyledProductImage
                  {...{
                    className: "badge",
                    src: `/hclstore/CHSAS/images/dealerIncentive/badge-${data?.Status.toLocaleLowerCase()}.svg`,
                  }}
                />):(<StyledProductImage
                  {...{
                    className: "badge",
                    src: `/hclstore/CHSAS/images/dealerIncentive/badge-no-status.svg`,
                  }}
                />)}
              </div>
            ) : null}
          </StyledTypography>
        </Stack>
      )}
    </StyledAccelerateCard>
  );
};
