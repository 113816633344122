import { useTranslation } from "react-i18next";
import { getDateFormatter } from "../../utils/getDateFormatter";
import { useCallback } from "react";

export const useDateFormatter = () => {
  const { i18n } = useTranslation();
  return useCallback(
    (dateString?: string | null) =>
      dateString ? getDateFormatter(i18n).format(new Date(dateString.replaceAll("-", "/"))) : "",
    [i18n]
  );
};
