/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { BackOrderReport } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * BackordersApi - axios parameter creator
 * @export
 */
export const BackordersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get backorder report information.
         * @summary Get backorder report information.
         * @param {string} storeId The store identifier.
         * @param {string} userId 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCBackOrders: async (storeId: string, userId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCBackOrders', 'storeId', storeId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('cHCBackOrders', 'userId', userId)
            const localVarPath = `/store/{storeId}/CHCreport/BackOrders`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BackordersApi - functional programming interface
 * @export
 */
export const BackordersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BackordersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get backorder report information.
         * @summary Get backorder report information.
         * @param {string} storeId The store identifier.
         * @param {string} userId 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCBackOrders(storeId: string, userId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BackOrderReport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCBackOrders(storeId, userId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BackordersApi - factory interface
 * @export
 */
export const BackordersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BackordersApiFp(configuration)
    return {
        /**
         * Get backorder report information.
         * @summary Get backorder report information.
         * @param {string} storeId The store identifier.
         * @param {string} userId 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCBackOrders(storeId: string, userId: string, responseFormat?: string, options?: any): AxiosPromise<BackOrderReport> {
            return localVarFp.cHCBackOrders(storeId, userId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BackordersApi - object-oriented interface
 * @export
 * @class BackordersApi
 * @extends {BaseAPI}
 */
export class BackordersApi extends BaseAPI {
    /**
     * Get backorder report information.
     * @summary Get backorder report information.
     * @param {string} storeId The store identifier.
     * @param {string} userId 
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BackordersApi
     */
    public cHCBackOrders(storeId: string, userId: string, responseFormat?: string, options?: any) {
        return BackordersApiFp(this.configuration).cHCBackOrders(storeId, userId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
