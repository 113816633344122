/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useMemo } from "react";
//Foundation libraries
import chcAccelerateService from "../apis/transaction/chc-accelerate.service";
import chcAccelerateDipBalanceService from "../apis/transaction/chc-accelerateDipBalance.service";
import { useSite } from "./useSite";
import { useSelector } from "react-redux";
import { activeOrgSelector, entitledOrgSelector } from "../../redux/selectors/organization";

const fetcher = async ({
  storeId,
  activeOrgId,
}: {
  storeId?: string;
  activeOrgId: string;
}) => {
  const response = await chcAccelerateService.accelerateProgram({
    storeId,
    activeOrgId,
  });
  const response2 = await chcAccelerateDipBalanceService.accelerateProgramDipBalance({
    storeId,
    abk: activeOrgId,
  });
  const data = response.data.boomiResponse?.at(0)?.at(0);
  const CurrentBalanceValue: any = response2.data.balanceAvailable;
  const CurrentBalance = (Math.round(CurrentBalanceValue * 100) / 100).toFixed(2);
  return Promise.resolve({ data: { ...(data || {}), CurrentBalance } });
};

export const useAccelerateProgram = () => {
  const [data, setData] = useState<Awaited<ReturnType<typeof fetcher>>['data'] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { mySite } = useSite();
  const entitledOrgs = useSelector(entitledOrgSelector);
  const activeOrgId = useSelector(activeOrgSelector);
  const activeOrg = useMemo(
    () => entitledOrgs?.find((org) => org.organizationId === activeOrgId),
    [entitledOrgs, activeOrgId]
  );

  useEffect(() => {
    let mounted = true;
    if (!mySite.storeID || !activeOrgId || !activeOrg) {
      return;
    }
    setLoading(true);
    fetcher({ storeId: mySite.storeID, activeOrgId: activeOrg.legalId }).then(({ data }) => {
      if (!mounted) {
        return;
      }
      setData(data);
      setLoading(false);
    });
    return () => {
      mounted = false;
    };
  }, [mySite.storeID, activeOrgId, activeOrg]);
  return {
    data, // useMemo(() => data ? ( {...data, EligibilityType: segment}) : undefined, [data]),
    imgDir: "/hclstore/CHSAS/images/",
    threshold: {
      silver: 20000,
      gold: 100000,
      diamond: 400000,
    },
    loading,
  };
};
