import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";
import styled from "styled-components";

const StyledCard = styled(({ ...props }) => <StyledAccelerateCard {...props} />)`
  ${({ theme, color }) => `
    h5 {
 		font-size: ${theme.typography.h4.fontSize} !important;
		margin-top: 0;
	}
	a {
		display: block;
		position: relative;
		text-decoration: none;
		font-size: ${theme.typography.subtitle1.fontSize};
		color: ${theme.palette[color].contrastText};
		padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
		margin: ${theme.spacing(1)}px -${theme.spacing(2)}px;
		border-radius: ${theme.shape.borderRadius}px;
		transition: background-color 0.3s ease-in-out;
		&:hover {
			background-color: ${theme.palette[color].light};
		}
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: ${theme.spacing(2)}px;
			right: ${theme.spacing(2)}px;
			border-bottom: 1px solid ${theme.palette[color].light};
			margin-bottom: -${theme.spacing(0.5)}px;
		}
		&::after {
			content: "»";
			color: ${theme.palette.primary.main};
			display: inline-block;
			vertical-align: middle;
			margin-left: ${theme.spacing(1)}px;
		}
	}
  `}
`;

export default StyledCard;
