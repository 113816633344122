/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CheckEmployeeOrgApi - axios parameter creator
 * @export
 */
export const CheckEmployeeOrgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check if Employee organization.
         * @summary Get Employee Org.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcEmployeeOrg: async (storeId: string, organizationId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcEmployeeOrg', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('chcEmployeeOrg', 'organizationId', organizationId)
            const localVarPath = `/store/{storeId}/chc_organization/checkEmployeeOrg`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckEmployeeOrgApi - functional programming interface
 * @export
 */
export const CheckEmployeeOrgApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckEmployeeOrgApiAxiosParamCreator(configuration)
    return {
        /**
         * Check if Employee organization.
         * @summary Get Employee Org.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcEmployeeOrg(storeId: string, organizationId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcEmployeeOrg(storeId, organizationId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckEmployeeOrgApi - factory interface
 * @export
 */
export const CheckEmployeeOrgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckEmployeeOrgApiFp(configuration)
    return {
        /**
         * Check if Employee organization.
         * @summary Get Employee Org.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcEmployeeOrg(storeId: string, organizationId: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcEmployeeOrg(storeId, organizationId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckEmployeeOrgApi - object-oriented interface
 * @export
 * @class CheckEmployeeOrgApi
 * @extends {BaseAPI}
 */
export class CheckEmployeeOrgApi extends BaseAPI {
    /**
     * Check if Employee organization.
     * @summary Get Employee Org.
     * @param {string} storeId store Id
     * @param {string} organizationId Organization Id.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckEmployeeOrgApi
     */
    public chcEmployeeOrg(storeId: string, organizationId: string, responseFormat?: string, options?: any) {
        return CheckEmployeeOrgApiFp(this.configuration).chcEmployeeOrg(storeId, organizationId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
