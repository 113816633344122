/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import Axios, { Canceler } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
import { cloneDeep, isEmpty, includes } from "lodash-es";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import {
  CommerceEnvironment,
  KEY_CODES,
  PRODUCT_SEARCH_PROFILE,
  SEARCHTERM,
  MANUFACTURER,
} from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
import { resetProductListAction } from "../../../redux/actions/catalog";
//UI
import {
  StyledTextField,
  StyledIconButton,
  StyledMenuItem,
  StyledMenuTypography,
  StyledLink,
  StyledButton,
} from "@hcl-commerce-store-sdk/react-component";
import { InputAdornment, ClickAwayListener, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { useSKUSearch } from "../../../_foundation/hooks/use-sku-search";
import { StyledSearchBar } from "../../../elements/search-bar";
import { CATALOG_ENTRY_TYPE } from "../../../constants/product";

const SearchBar: React.FC<SearchBarProps> = ({ showSearchBar, openSearchBar, closeSearchBar }) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [productSuggestions, setProductSuggestions] = React.useState<Array<any>>([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<Array<any>>([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<any>>([]);
  const [sellerSuggestions, setSellerSuggestions] = React.useState<Array<any>>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location: any = useLocation();
  const { fetchOptions: fetchProductSuggestions } = useSKUSearch();

  const searchField = t("SearchBar.SearchField");
  const productTitle = t("SearchBar.ProductTitle");
  const categoryTitle = t("SearchBar.CategoryTitle");
  const brandTitle = t("SearchBar.BrandTitle");
  const sellerTitle = t("SearchBar.SellerTitle");
  const [input, setInput] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  let nameListIndex = 1;
  const { mySite } = useSite();
  const dispatch = useDispatch();
  const [showProducts, setShowProducts] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);
  const [showSellers, setShowSellers] = React.useState(false);
  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [brands, setBrands] = React.useState<Array<string>>([]);
  const [sellers, setSellers] = React.useState<Array<string>>([]);
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(() => new Map());

  const [inputDisabled, setinputDisabled] = React.useState(true);

  const clearSuggestions = () => {
    setIndex(0);
    setProductSuggestions([]);
    setCategorySuggestions([]);
    setBrandSuggestions([]);
    setSellerSuggestions([]);
    setShowProducts(false);
    setShowCategories(false);
    setShowBrands(false);
    setShowSellers(false);
  };

  const clearSuggestionsAndUpdateInputField = (str: string = "") => {
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setShowSearchBar(!showSearchBar);
    dispatch(resetProductListAction());
  };

  const clearSuggestionsAndInputField = () => {
    clearSuggestions();
    setInput("");
  };

  // Toggles search suggestions on click away
  const toggleSuggestions = (show: boolean) => {
    setShowProducts(!isEmpty(productSuggestions) && show ? true : false);
    setShowCategories(!isEmpty(categorySuggestions) && show ? true : false);
    setShowBrands(!isEmpty(brandSuggestions) && show ? true : false);
    setShowSellers(!isEmpty(sellerSuggestions) && show ? true : false);
  }

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  useEffect(() => {
    if (mySite && contractId) {
      const catalogId = mySite?.catalogID;
      const parameters: any = {
        responseFormat: "application/json",
        suggestType: ["Category", "Brand", "Seller"],

        contractId: contractId,
        catalogId: catalogId,
        ...payloadBase,
      };
      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {            
            const responseArray = res?.data?.suggestionView;
            for (let i = 0; i < responseArray.length; i++) {
             const allResponse = responseArray[i];
             if (allResponse.identifier === "Category") {
              generateCategoriesList(allResponse.entry);
              generateCategoriesURL(allResponse.entry);
            }else if (allResponse.identifier === "Brand") {
              generateBrandsList(allResponse.entry);
            }
            else if (allResponse.identifier === "Seller") {
              generateSellersList(allResponse.entry);
            }
            } 
          
            setinputDisabled(false);
          }
        })
        .catch((e) => {
          console.warn("fail to get category, brand and seller suggestions.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t, contractId]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      setInput("");
    }
    clearKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists = categoriesResponse.map((i) => i.fullPath);
    setCategories(lists);
  };
  const generateBrandsList = (brandsResponse: any[]) => {
    const lists = brandsResponse.map((i) => i.name);
    setBrands(lists);
  };
  const generateSellersList = (sellersResponse: any[]) => {
    const lists = sellersResponse.map((i) => i.name);
    setSellers(lists);
  };

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map();
    for (let i = 0; i < categoriesResponse.length; i++) {
      const url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : "";
      categoriesUrl.set(categoriesResponse[i].fullPath, url);
    }
    setCategoriesUrl(categoriesUrl);
  };

  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist();

    const element = event.currentTarget as HTMLInputElement;

    setInput(element.value);
    retrieveSuggestions(element.value);
  };

  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const catalogId = mySite.catalogID;

        const keywordParams: any = {
          responseFormat: "application/json",
          storeId: storeID,
          term: searchTerm,
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          ...payloadBase,
        };

        const promises: Promise<any>[] = [
          fetchProductSuggestions(searchTerm, PRODUCT_SEARCH_PROFILE, CATALOG_ENTRY_TYPE.PRODUCT),
          siteContentService.findKeywordSuggestionsByTermUsingGET(keywordParams),
        ];

        Promise.all(promises).then(([productSuggestions, keywordRes]) => {
          if (!isEmpty(productSuggestions)) {
            const list: string[] = [];
            generateSuggestionList(productSuggestions, searchTerm, list);
            generateCatgoriesBrandAndSellersSuggestions(searchTerm, list);
            setNameList(list);
          }

          if (keywordRes.status === OK) {
            const keywordSuggestions = keywordRes?.data.suggestionView[0].entry || [];
            if (!isEmpty(keywordSuggestions)) {
              const alternateTerms = keywordSuggestions?.map((kw) => kw?.term);
              // Sets alternative search keywords
              setKeywordsToLocalStorage(alternateTerms);
            }
          }
        });
      }, 300);
    }
    clearSuggestions();
  };

  const generateCatgoriesBrandAndSellersSuggestions = (userInput: string, listTemp: string[]) => {
    const inputQ = userInput?.trim()?.toLowerCase();
    const matchedCategories = categories?.filter((e) => includes(e?.toLowerCase(), inputQ)).slice(0, 4);
    const lists = matchedCategories.map((suggestion) => {
      const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
      suggestionSkeleton.arrIndex = nameListIndex.toString();
      suggestionSkeleton.id = "";
      suggestionSkeleton.name = suggestion;
      suggestionSkeleton.url = categoriesUrl.get(suggestion);
      nameListIndex++;
      listTemp.push(suggestion);
      return suggestionSkeleton;
    });
    setCategorySuggestions(lists);
    setShowCategories(true);

    const matchedBrands = brands?.filter((e) => includes(e?.toLowerCase(), inputQ)).slice(0, 4);
    const lists2 = matchedBrands.map((suggestion) => {
      const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
      suggestionSkeleton.arrIndex = nameListIndex.toString();
      suggestionSkeleton.id = "";
      suggestionSkeleton.name = suggestion;
      suggestionSkeleton.url =
        SEARCH + "?" + SEARCHTERM + "=" + encodeURIComponent(suggestion) + `&${MANUFACTURER}=true`;
      nameListIndex++;
      listTemp.push(suggestion);
      return suggestionSkeleton;
    });
    setBrandSuggestions(lists2);
    setShowBrands(true);

    if (sellers.length > 0) {
      const matchedSellers = sellers?.filter((e) => includes(e?.toLowerCase(), inputQ)).slice(0, 4);
      const lists3 = matchedSellers.map((suggestion) => {
        const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
        suggestionSkeleton.arrIndex = nameListIndex.toString();
        suggestionSkeleton.id = "";
        suggestionSkeleton.name = suggestion;
        suggestionSkeleton.url = SEARCH + "?" + SEARCHTERM + "=" + suggestion;
        nameListIndex++;
        listTemp.push(suggestion);
        return suggestionSkeleton;
      });
      setSellerSuggestions(lists3);
      setShowSellers(true);
    }
  };

  const generateSuggestionList = (
    productSuggestions: any[],
    userInput: string,
    listTemp: string[]
  ) => {
    listTemp.push(userInput);
    const listTemp2: string[] = [];

    const lists = productSuggestions.map((suggestion) => {
      const suggestionSkeleton = cloneDeep(CommerceEnvironment.suggestionSkeleton);
      const suggestionTitle = suggestion?.term || suggestion?.name;
      suggestionSkeleton.arrIndex = nameListIndex.toString();
      suggestionSkeleton.id = suggestion?.uniqueID;
      suggestionSkeleton.name = suggestionTitle;
      suggestionSkeleton.url =
        suggestion?.seo?.href || SEARCH + "?" + SEARCHTERM + "=" + suggestionTitle;
      listTemp.push(suggestionTitle);
      listTemp2.push(suggestionTitle);
      nameListIndex++;
      return suggestionSkeleton;
    });
    setProductSuggestions(lists);
    setShowProducts(true);
  };

  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    const len = nameList ? nameList.length : 0;
    let str = "";
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input && input.trim() !== "") {
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();
      const parameters: any = {
        storeId: storeID,
        searchTerm: searchTerm,
        ...payloadBase,
      };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            dispatch(resetProductListAction());
            url = res?.data.redirecturl;

            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
          redirectTo(url);
        });
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const clickAway = (prev) => {
    setShowSearchBar(!prev);
    toggleSuggestions(false);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <StyledSearchBar>
        <form onSubmit={submitSearch} noValidate >
          <StyledTextField
            margin="normal"
            size="small"
            autoFocus
            autoComplete="off"
            type="text"
            disabled={inputDisabled}
            placeholder={searchField}
            value={input}
            name="searchTerm"
            onChange={(e) => handleLookAheadSearch(e, "searchTerm")}
            onKeyDown={onKeyDown}
            onFocus={() => toggleSuggestions(true)}
            InputProps={{
              endAdornment: (
                <>
                  {showProducts || showCategories || showBrands || showSellers || !isEmpty(input) ? (
                    <InputAdornment className="sarch-bar-adornment" position="end"  >
                      <StyledIconButton
                        data-testid="button-clear-search-suggestions-input-fields"
                        onClick={clearSuggestionsAndInputField}
              
                      >
                        <CloseIcon titleAccess={t("SearchBar.Clear")} className="closeIcon" />
                      </StyledIconButton>
                     
                      <Button
                        type="submit"
                        data-testid="icon-toggle-search-bar"
                        onClick={toggleSearchBar}
                        style= { {backgroundColor: '#f44336',}}
                       
                      >
                        <SearchIcon   />
                      </Button>
                    </InputAdornment>
                  ) : (
                    <InputAdornment className="sarch-bar-adornment" position="start"  >
                      <StyledIconButton
                        type="submit"
                        data-testid="icon-toggle-search-bar"
                        onClick={toggleSearchBar}
                      >
                        <SearchIcon/>
                      </StyledIconButton>
                    </InputAdornment>
                  )}
                </>
              ),
              
            }}
          />
        </form>

        {(showProducts || showCategories || showBrands || showSellers) ? (
          <ul className="searchBar-results">
            {showProducts ? (
              <>
                <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                  {productTitle}
                </StyledMenuTypography>
                {productSuggestions?.map((e: any, i: number) => (
                  <StyledLink
                    key={`product-${i}`}
                    testId={`products-${e.name}`}
                    to={e.url}
                    onClick={() => clearSuggestionsAndUpdateInputField()}
                  >
                    <StyledMenuItem>
                      <StyledMenuTypography
                        className={e.arrIndex === index ? "active" : ""}
                        key={e.arrIndex}
                        id={`megamenu_department_${e.id}`}
                        title={e.name}
                      >
                        {e.name}
                      </StyledMenuTypography>
                    </StyledMenuItem>
                  </StyledLink>
                ))}
              </>
            ) : null}

            {showCategories ? (
              <>
                <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                  {categoryTitle}
                </StyledMenuTypography>
                {categorySuggestions?.map((e: any, i: number) => (
                  <StyledLink
                    key={`category-${i}`}
                    testId={`category-${e.url.split("/").filter(Boolean).join("-")}`}
                    to={e.url}
                    onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}
                  >
                    <StyledMenuItem>
                      <StyledMenuTypography
                        className={e.arrIndex === index ? "active" : ""}
                        key={e.arrIndex}
                        id={`megamenu_department_${e.id}`}
                        title={e.name}
                      >
                        {e.name}
                      </StyledMenuTypography>
                    </StyledMenuItem>
                  </StyledLink>
                ))}
              </>
            ) : null}

            {showBrands ? (
              <>
                <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                  {brandTitle}
                </StyledMenuTypography>
                {brandSuggestions?.map((e: any, i: number) => (
                  <StyledLink
                    key={`brand-${i}`}
                    testId={`brand-${e.name.toLowerCase()}`}
                    to={e.url}
                    onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}
                  >
                    <StyledMenuItem>
                      <StyledMenuTypography
                        className={e.arrIndex === index ? "active" : ""}
                        key={e.arrIndex}
                        id={`megamenu_department_${e.id}`}
                        title={e.name}
                      >
                        {e.name}
                      </StyledMenuTypography>
                    </StyledMenuItem>
                  </StyledLink>
                ))}
              </>
            ) : null}

            {showSellers ? (
              <>
                <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                  {sellerTitle}
                </StyledMenuTypography>
                {sellerSuggestions?.map((e: any, i: number) => (
                  <StyledLink
                    key={`seller-${i}`}
                    testId={`seller-${e.name.toLowerCase()}`}
                    to={e.url}
                    onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}
                  >
                    <StyledMenuItem>
                      <StyledMenuTypography
                        className={e.arrIndex === index ? "active" : ""}
                        key={e.arrIndex}
                        id={`megamenu_department_${e.id}`}
                        title={e.name}
                      >
                        {e.name}
                      </StyledMenuTypography>
                    </StyledMenuItem>
                  </StyledLink>
                ))}
              </>
            ) : null}
          </ul>
        ) : null}
      </StyledSearchBar>
    </ClickAwayListener>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };
