/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { call, put, select } from "redux-saga/effects";
import chunk from "lodash/chunk";
//Foundation libraries
import orderService from "../../../_foundation/apis/transaction/order.service";
import productsService from "../../../_foundation/apis/search/chc-products.service";
import chcGetPriceService from "../../../_foundation/apis/transaction/chc-price-server.service";
//Redux
import {
  FETCH_ORDER_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION,
  FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION,
  FETCH_ORDER_DETAILS_FAIL_ACTION,
} from "../../actions/orderDetails";
import { currentContractIdSelector } from "../../selectors/contract";
import { SELLER_PARAM } from "../../../_foundation/constants/common";
import { EMPTY_STRING } from "../../../constants/common";
import { REQUISITION_WIDGETS } from "../../../constants/requisition-list-items";
import { isEmpty } from "lodash-es";

export function* getOrderDetails(action: any) {
  const { orderId, currency, widget } = action.payload;
  const contractId = yield select(currentContractIdSelector);
  try {
    const response = yield call(orderService.findByOrderId, action.payload);
    const orderDetails = response.data;
    //const idType = "partNumber";
    //const idType = "productId";
    const idType = widget === "Requisition List detail" ? "partNumber" : "productId";
    yield put(FETCH_ORDER_DETAILS_SUCCESS_ACTION(orderDetails));
    try {
      const orderItems: any[] = orderDetails?.orderItem ?? [];
      if (isEmpty(orderItems)) {
        yield put(
          FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION({
            ...action.payload,
            items: [],
          })
        );
      } else {
        const contracts = orderItems.reduce((p, c) => {
          let localContractId = contractId;
          if (c.contractId !== "") {
            localContractId = c.contractId;
          }
          if (p[localContractId]) {
            if (p[localContractId].indexOf(c?.[idType]) === -1) {
              p[localContractId].push(c?.[idType]);
            }
          } else {
            p[localContractId] = [c?.[idType]];
          }
          return p;
        }, {});
        const itemDetailsParams = {
          currency,
          contracts,
          // add empty seller parameter -- don't filter when fetching order-item details
          query: { [SELLER_PARAM]: EMPTY_STRING },
          widget,
        };
        const contents = yield call(
          fetchOrderItemDetailsByIds,
          itemDetailsParams
        );
        yield put(
          FETCH_ORDER_ITEM_DETAILS_SUCCESS_ACTION({
            ...action.payload,
            items: contents,
          })
        );
      }
    } catch (error) {
      yield put(FETCH_ORDER_ITEM_DETAILS_FAIL_ACTION({ orderId, error }));
    }
  } catch (error) {
    yield put(FETCH_ORDER_DETAILS_FAIL_ACTION({ orderId, error }));
  }
}

export const fetchOrderItemDetailsByIds = (param: any) => {
  const promiseArray: Promise<any>[] = [];
  const { currency, widget, contracts, query } = param;
  const paramBase = { currency, widget };
  const idType = widget === "Requisition List detail" ? "partNumber" : "id";
  //const idType = "id";

  Object.keys(contracts).forEach((key) => {
    const ids = chunk(contracts[key], 50);
    ids.forEach((id) => {
      const param = Object.assign({}, paramBase, {
        [idType]: id,
        contractId: key,
        query,
      });
      promiseArray.push(productsService.findProductsUsingGET(param));
    });
  });

  return Promise.all(promiseArray).then((rs) => {
    let contents = [];
    rs.forEach((r) => {
      if (r.data?.contents) {
        contents = contents.concat(r.data.contents);
      }
    });
    return contents;
  });
};
