/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import chcOrderService from "../../../_foundation/apis/transaction/chc-order.service";
//Redux
import * as ACTIONS from "../../action-types/chc-orderDetails";

/**
 * Saga worker to invoke get Order Details from JDE
 */
export function* getChcOrderDetail(action: any) {
  try {
    const payload = action.payload;
    
    const response = yield call(
      chcOrderService.findJDEOrderDetails,
      action.payload
    );
    //console.log("response in worker-->"+response);
    //console.log(response);
    yield put({
      type: ACTIONS.FETCH_CHC_ORDER_DETAILS_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.FETCH_CHC_ORDER_DETAILS_FAIL, error });
  }
}
