//Foundation
import { NOTIFICATIONS } from "../../constants/routes";
import { Notifications } from "@material-ui/icons";
import { StyledHeaderIconButton } from "../../elements/header-icon-button/styled-header-icon-button";
import { useSelector } from "react-redux";
import { notificationSelector } from "../../redux/selectors/organization";

export const NotificationsButton = () => {
 const notificationList = useSelector(notificationSelector);
  const unreadCount = notificationList?.Unread_Count;
  return (
    <StyledHeaderIconButton icon={<Notifications />} unread={!!unreadCount} link={NOTIFICATIONS} />
  );
};
