import { flattenDeep } from "lodash-es";
import { STRING_TRUE } from "../constants/common";
import { PRODUCT_ATTRS } from "../constants/product";

const NOT_CART_ITEM_ATTRS = [PRODUCT_ATTRS.FEATURE, PRODUCT_ATTRS.PACKAGING]

const cartUtil = {
    getOrderItemAttrs: ({ attrs = [], orderItem, CPNAttribute = '', isCartItem = true }: { attrs: Array<any>, orderItem: any, CPNAttribute?: string, isCartItem?: boolean }) => {
        const a = isCartItem ? attrs.filter(a => !NOT_CART_ITEM_ATTRS.includes(a?.identifier)) : [...attrs];
        const MPN = orderItem?.mfPartNumber ? [{ name: 'MPN', values: [{ value: orderItem?.mfPartNumber }] }] : [];
        const CPN = orderItem?.[CPNAttribute] ? [{ name: 'CPN', values: [{ value: orderItem?.[CPNAttribute]?.[0] }] }] : [] // TODO Integrate real CPN data
        return [...a, ...MPN, ...CPN];
    },
    getUOMConversionFacts: (attrs: Array<any> = []) => {
        const displayableAttrs = (attrs ?? [])?.filter((a) => STRING_TRUE === a.displayable);
        const packagingInfo = displayableAttrs?.find(a => a?.identifier === PRODUCT_ATTRS.PACKAGING) ?? {};
        const conversionFactors = (packagingInfo?.values ?? [])?.map((p: any) => typeof p?.value === 'string' ? p?.value?.split(",") : p?.value);
        return flattenDeep([...conversionFactors]);
    }
}

export default cartUtil;