/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
// Admin 

export const UPDATE_USER_STATUS_REQUEST = "UPDATE_USER_STATUS_REQUEST";
export const UPDATE_USER_STATUS_REQUEST_SUCCESS = "UPDATE_USER_STATUS_REQUEST_SUCCESS";
export const UPDATE_USER_STATUS_REQUEST_ERROR = "UPDATE_USER_STATUS_REQUEST_ERROR";
export const RESET_USER_PASSWORD_REQUEST = "RESET_USER_PASSWORD_REQUEST";
export const RESET_USER_PASSWORD_REQUEST_SUCCESS = "RESET_USER_PASSWORD_REQUEST_SUCCESS";
export const RESET_USER_PASSWORD_REQUEST_ERROR = "RESET_USER_PASSWORD_REQUEST_ERROR";


