import { Paper } from "@material-ui/core";
import styled from "styled-components";

export const StyledAccelerateCard = styled(({ ...props }) => <Paper {...props} />)`
  ${({ theme, color, outline }) => `
    border-radius: ${theme.shape.borderRadius * 2}px;
    padding: 2rem;
    height: 100%;
    .MuiTypography-h4 {
      font-weight: 700;
    }
    .badge {
      width: 100%;
      max-width: 8rem;
    }
    ${
      color
        ? `
        background-color: ${theme.palette[color].main};
        color: ${theme.palette[color].contrastText};
        `
        : ""
    }
    ${
      outline
        ? `
        box-shadow: 0 0 0 1px ${
          color ? theme.palette[color].contrastText : theme.palette.primary.main
        };
        `
        : ""
    }
  `}
`;
