export const PRODUCT_ATTRS = {
    FEATURE: "CHC_Feature",
    PACKAGING: "CHC_Packaging",
    INVOICE_DESC: "CHC_InvoiceDescription",
    SPOTLIGHT_PRODUCT: "CHC_SpotlightProduct",
}

export enum CATALOG_ENTRY_TYPE {
    ITEM = "ItemBean",
    PRODUCT = "ProductBean",
}

export enum CHC_RIBBON {
    SPOTLIGHT = "spotlight"
}

export const CHC_RIBBONS = {
    [CHC_RIBBON.SPOTLIGHT]: "/hclstore/CHCAS/images/spotlight-ribbon.svg"
}