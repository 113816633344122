import { StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, StyledContainer, StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogContentText, StyledFormLabel, StyledGrid, StyledIconLabel, StyledLink, StyledTypography,StyledButton, StyledProgressPlaceholder } from "@hcl-commerce-store-sdk/react-component";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TitleLayout } from "../../widgets/title/TitleLayout";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Divider } from "@material-ui/core";
import { SELECTACCOUNT } from "../../../constants/routes";
import { useEffect, useState } from "react";
import getDisplayName from "react-display-name";
//Redux
import { logonIdSelector} from "../../../redux/selectors/user";
import { useSelector,useDispatch } from "react-redux";
import * as orderActions from "../../../redux/actions/order";
import { entitledOrgSelector, activeOrgSelector } from "../../../redux/selectors/organization";
import DialogAddCard from "../../widgets/my-card/dialog-add-card";
import { useTranslation } from "react-i18next";
import {
    personalPaymentMethod,
    cardOnFilePaymentMethod,
    sharedPaymentMethod,
  } from "../../../redux/selectors/order";
import { useSite } from "../../../_foundation/hooks/useSite";
import Axios,{ Canceler } from "axios";

import Cards from "react-credit-cards-2";
import "react-credit-cards-2/lib/styles.scss";
import { EMPTY_STRING } from "../../../constants/common";
import chcPaymentMethodService from "../../../_foundation/apis/transaction/chc-payment-method.service";
import DialogContent from "@material-ui/core/DialogContent";

const MyCard= (props: any) => {
    const { t } = useTranslation();
    const { mySite } = useSite();
    const CancelToken = Axios.CancelToken;
    const cancels: Canceler[] = [];
    const widgetName = getDisplayName("MyCard");
    const userId = useSelector(logonIdSelector);
    const [displayName, setDisplayName] = useState<string>(EMPTY_STRING);
    const [address, setAddress] = useState<string>(EMPTY_STRING);
    const [address2, setAddress2] = useState<string>(EMPTY_STRING);
    const entitledOrgs = useSelector(entitledOrgSelector);
    const activeOrgId = useSelector(activeOrgSelector);
    const [openAdd, setOpenAdd] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [openRemove, setOpenRemove] = useState(false);
    const personalPaymentRes = useSelector(personalPaymentMethod);
    const cardOnFilePaymentRes = useSelector(cardOnFilePaymentMethod);
    const sharedPaymentRes = useSelector(sharedPaymentMethod);
    const [personalPayment,setPersonalPayment]:any[] = useState([]);
    const [cardOnFilePayment,setCardOnFilePayment]:any[] = useState([]);
    const [sharedPayment, setSharedPayment]:any[] = useState([]);
    const [orderId, setOrderId] = useState<string>(EMPTY_STRING);
    const [share, setShare] = useState<string>(EMPTY_STRING);
    const [selCardType, setSelCardType] = useState<string>(EMPTY_STRING);
    const [selCardNum, setSelCardNum] = useState<string>(EMPTY_STRING);
    const [shareTitle, setShareTitle] = useState<string>(EMPTY_STRING);
    const [shareSubTitle, setShareSubTitle] = useState<string>(EMPTY_STRING);
    const [loading, setLoading] = useState<boolean>(false);
    const [openError, setOpenError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
    let finalSharedCard;
    function filterOrg(org){
        if (org?.orgId === activeOrgId){
            return org;
        }
    }
   if(sharedPaymentRes.length > 0){
    finalSharedCard = sharedPayment.filter(filterOrg);
   }
    const dispatch = useDispatch();
    const {
        i18n: { language },
      } = useTranslation();
    
   
    const addCard = () => {
        setOpenAdd(true);
       
    }
    const closeShare = () => {
        setOpenShare(false);
       
    }
    const closeRemove = () => {
        setOpenRemove(false);
       
    }
    const closeAndRedirect = () => {
        setOpenError(false);
        window.location.reload();
      };
    
    const shareCard = () => {
        setLoading(true);
      
       const responseFormat = 'json';
       const uRL = 'success';
       const errorView = "null";
      
       chcPaymentMethodService.sharedCard({
           orderId, share, uRL, errorView, responseFormat
       })
       .then((response) => { 
        
         if(Number(response.status) === 200) {   
            setLoading(false);     
            setOpenShare(false);

           window.location.reload();
         } else {
            setLoading(false);  
            setOpenError(true);
            setErrorMessage(t("Dashboard.AddCard.ServerError")+response.data.errorCode+"]");
         }
       });  
       
    }
    const removeCard =  (orderIdSen,cardTypeId,maskedAcctNumber) => {
       
        setOrderId(orderIdSen);
        setSelCardType(cardTypeId);
        setSelCardNum(maskedAcctNumber);
       
        setShareTitle( t("Dashboard.AddCard.RemoveTitle"));
        setShareSubTitle(t("Dashboard.AddCard.RemoveSubTitle"));
         
       
        setOpenRemove(true);
        
     }
    const payloadBase: any = {
        widget: widgetName,
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
    
    useEffect(() => {
    
        if (entitledOrgs && activeOrgId) {
            entitledOrgs.forEach((entitledOrg) =>
             {

                if (entitledOrg.organizationId === activeOrgId) {
                    
                  setDisplayName(entitledOrg.displayName);
                  setAddress(entitledOrg.address1);
                  const address2Temp = entitledOrg.city+","+entitledOrg.state+","+entitledOrg.zipCode;
                  setAddress2(address2Temp);
                } 
            }
              );
        }
      
        const persPay:any[] = [];
        personalPaymentRes.forEach((payment: any) => {
            if((parseInt(payment.expMonth, 10) >= (new Date().getMonth() + 2) &&
            parseInt(payment.expYear, 10) === new Date().getFullYear())||
            parseInt(payment.expYear, 10) > new Date().getFullYear())
                persPay.push(payment);
            });
        setPersonalPayment(persPay);
        const sharePay:any[] = [];
        sharedPaymentRes.forEach((payment: any) => {
           
            if((parseInt(payment.expMonth, 10) >= (new Date().getMonth() + 2) &&
            parseInt(payment.expYear, 10) === new Date().getFullYear())||
            parseInt(payment.expYear, 10) > new Date().getFullYear())
            sharePay.push(payment);
        });
        setSharedPayment(sharePay);
        const cardPay:any[] = [];
        cardOnFilePaymentRes.forEach((payment: any) => {
            if((parseInt(payment.expMonth, 10) >= (new Date().getMonth() + 2) &&
            parseInt(payment.expYear, 10) === new Date().getFullYear())||
            parseInt(payment.expYear, 10) > new Date().getFullYear())
            cardPay.push(payment);
        });
        setCardOnFilePayment(cardPay);
      }, [entitledOrgs, activeOrgId,personalPaymentRes,sharedPaymentRes,cardOnFilePaymentRes]);

      useEffect(() => {
        if (mySite) {
          const payload = {
            ...payloadBase,
          };
          dispatch(orderActions.GET_PAYMETHODSCARD_ACTION(payload));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [mySite, language]);
      const convertShared = (orderIdSen,shareSen,cardTypeId,maskedAcctNumber) => {
        setShare(shareSen);
        setOrderId(orderIdSen);
        setSelCardType(cardTypeId);
        setSelCardNum(maskedAcctNumber);
        if(shareSen){
            setShareTitle( t("Dashboard.AddCard.ShareTitle"));
            setShareSubTitle(t("Dashboard.AddCard.ShareSubTitle"));
        }else{
            setShareTitle(t("Dashboard.AddCard.UnShareTitle"));
            setShareSubTitle(t("Dashboard.AddCard.UnShareSubTitle"));
        }
       
        setOpenShare(true);
       
      };
      const removeAccount = () => {
        setLoading(true);  
        const responseFormat = 'json';
        const uRL = 'success';
        const errorView = "null";
        chcPaymentMethodService.removeCard({
            orderId, uRL, errorView, responseFormat
        })
        .then((response) => { 
          if(Number(response.status) === 200) {        
            setLoading(false);  
          
            window.location.reload();
          } else {
            setLoading(false);  
            setOpenError(true);
            setErrorMessage(t("Dashboard.AddCard.ServerError")+response.data.errorCode+"]");
          }
        });      
      };

    return(<>
    <StyledContainer cid="my-card">
        <TitleLayout title={t("Dashboard.AddCard.MyCard")} cid="my-card-title" />

        <StyledGrid container spacing={2}>
    
            <StyledGrid item xs={12} container spacing={1}  direction="column">
                <StyledGrid item container spacing={1} direction="column" >
                    <StyledAccordion  testId={`add-card-list`}  >
                        <StyledAccordionSummary
                            className="horizontal-padding-1 vertical-padding-1"
                           
                            expandIcon={<ExpandMoreIcon   />}>
                                   <StyledIconLabel 
                                        icon={<CreditCardIcon color="primary" className="full-center" />}
                                        label= {t("Dashboard.AddCard.MyCards")}
                                        />
                        </StyledAccordionSummary>
                        <Divider className="bottom-margin-3" />
                        <StyledAccordionDetails>
                            <StyledGrid item container xs={12}spacing={1}  >
                                <StyledGrid item xs={12} sm={6}>
                                    <StyledGrid container item spacing={1}>
                                        <StyledGrid item >
                                            <StyledFormLabel >{t("Dashboard.AddCard.MyCardDesc1")}</StyledFormLabel>
                                        </StyledGrid>
                                        <StyledGrid item >
                                            <StyledFormLabel >({userId})</StyledFormLabel>
                                        </StyledGrid>
                                    
                                    <StyledGrid  item >
                                        <StyledFormLabel >{t("Dashboard.AddCard.MyCardDesc2")}</StyledFormLabel>
                                    </StyledGrid>
                                    
                                    <StyledGrid item>
                                            
                                        <StyledButton
                                        color="primary"
                                        testId="add-card"
                                        onClick={addCard}>
                                        {t("Dashboard.AddCard.AddNewCard")}
                                        </StyledButton>
                                        
                                    </StyledGrid>
                                    </StyledGrid>
                                </StyledGrid>
                        
                                <StyledGrid container item xs={12} sm={6} spacing={1.0} className="bottom-margin-1" >

                                    {personalPayment.map((payment: any, index) => (
                                       <StyledGrid container item justifyContent="center" spacing={1} direction= "column" key={`${payment.cardTypeId}${index}`}>

                                            <StyledGrid item className="bottom-margin-1">
                                                <Cards
                                                    number={((payment.cardTypeId === "amex" || payment.cardTypeId === "AMEX")?("***********"+payment.maskedAcctNumber):"************"+payment.maskedAcctNumber)}
                                                    expiry={ payment.expMonth.length>1 ? (payment.expMonth+payment.expYear):
                                                        ("0"+payment.expMonth+payment.expYear)
                                                    }
                                                    preview
                                                    issuer ={(payment.cardTypeId === "MAST" || payment.cardTypeId === "mast")?("mastercard"):((payment.cardTypeId === "amex" || payment.cardTypeId === "AMEX")?("american-express"):payment.cardTypeId) }
                                                    name={payment.cardholderName}
                                                    cvc=""
                                                />
                                            </StyledGrid>
                                            
                                            <StyledGrid item className="bottom-margin-1">
                                                <StyledTypography variant = "h6">{t("Dashboard.AddCard.BillingAddress")}</StyledTypography>
                                                <StyledFormLabel >{payment.streetAddress},{payment.city},{payment.state},{payment.zipCode}</StyledFormLabel>
                                            </StyledGrid>
                                            <StyledGrid container item spacing= {2} justifyContent="center" direction="row"  className="bottom-margin-1">
                                                <StyledGrid item >
                                                    <StyledButton color="primary"  testId="convert-share-card"  onClick={() =>convertShared(payment.orderId,true,payment.cardTypeId,payment.maskedAcctNumber)}>{t("Dashboard.AddCard.ConvertShareButton")}</StyledButton>
                                                </StyledGrid>
                                                <StyledGrid item >
                                                    <StyledButton color="primary"  testId="remove-account"  onClick={() =>removeCard(payment.orderId,payment.cardTypeId,payment.maskedAcctNumber)}  >{t("Dashboard.AddCard.RemoveButton")}</StyledButton>
                                                </StyledGrid>
                                            </StyledGrid>
                                            <Divider className="bottom-margin-3" />
                                        </StyledGrid>
                                        ))}
                                </StyledGrid>
                            </StyledGrid>
                        </StyledAccordionDetails>
                    
                    </StyledAccordion>
                    <StyledAccordion  testId={`payment-card-list`}  >
                        <StyledAccordionSummary
                            className="horizontal-padding-1 vertical-padding-1"
                           
                            expandIcon={<ExpandMoreIcon   />}>
                                   <StyledIconLabel
                                        icon={<CreditCardIcon color="primary" className="full-center" />}
                                        label= {t("Dashboard.AddCard.SharedCards")}
                                        />
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>
                            <StyledGrid  container  item xs={12}  spacing={1}  >
                                 <StyledGrid container item xs={12} sm={6} spacing={1}>
                                <StyledGrid item xs={12}>
                                    <StyledFormLabel >{t("Dashboard.AddCard.SharedCardsDesc1")}</StyledFormLabel>
                                </StyledGrid>
                                <StyledGrid  container item spacing={1}>
                                    <StyledGrid item xs={12}>
                                        <StyledFormLabel ><strong>{t("Dashboard.AddCard.BillingAccount")}</strong></StyledFormLabel>
                                    </StyledGrid>
                                    <StyledGrid item xs={12}>
                                        <StyledFormLabel >{displayName}</StyledFormLabel>
                                    </StyledGrid>
                                    <StyledGrid item xs={12}>
                                        <StyledFormLabel >{address}</StyledFormLabel>
                                    </StyledGrid>
                                    <StyledGrid item xs={12} className="bottom-margin-1.0">
                                        <StyledFormLabel >{address2}</StyledFormLabel>
                                    </StyledGrid>
                                </StyledGrid>
                                 <StyledGrid  container item spacing={1}>
                                    <StyledTypography variant="body1">
                                        <StyledLink  to={SELECTACCOUNT}>
                                        {t("Dashboard.AddCard.ClickHere")}
                                        </StyledLink>
                                        &nbsp; {t("Dashboard.AddCard.ClickHereDesc")}
                                    </StyledTypography>
                                </StyledGrid>
                                <StyledGrid className="bottom-padding-1.0" item xs={12}>
                                    <StyledFormLabel >{t("Dashboard.AddCard.SharedCardsDesc2")}</StyledFormLabel>
                                </StyledGrid>
                                </StyledGrid>
                                <StyledGrid container item xs={12} sm={6} spacing={1.0} className="bottom-margin-1">
                                    {finalSharedCard?.map((payment: any, index) => (
                                        <StyledGrid container item justifyContent="center" spacing={1} direction="column" key={`${payment.cardTypeId}${index}`}>
                                            <StyledGrid item className="bottom-margin-1" >
                                                <Cards
                                                    number={((payment.cardTypeId === "amex" || payment.cardTypeId === "AMEX")?("***********"+payment.maskedAcctNumber):"************"+payment.maskedAcctNumber)}
                                                    expiry={ payment.expMonth.length>1 ? (payment.expMonth+payment.expYear):
                                                        ("0"+payment.expMonth+payment.expYear)
                                                    }
                                                    preview
                                                    issuer ={(payment.cardTypeId === "MAST" || payment.cardTypeId === "mast")?("mastercard"):((payment.cardTypeId === "amex" || payment.cardTypeId === "AMEX")?("american-express"):payment.cardTypeId) }
                                                    name={payment.cardholderName}
                                                    cvc=""
                                                
                                                />
                                            </StyledGrid>
                                            <StyledGrid item className="bottom-margin-1">
                                                <StyledTypography variant = "h6">{t("Dashboard.AddCard.BillingAddress")}</StyledTypography>
                                                <StyledFormLabel >{payment.streetAddress},{payment.city},{payment.state},{payment.zipCode}</StyledFormLabel>
                                            </StyledGrid>
                                            <StyledGrid container item spacing= {2} justifyContent="space-around" className="bottom-margin-1" >
                                            { payment.logonId == userId && (
                                                 <StyledGrid item >
                                                    <StyledButton color="primary"   testId="remove-card"  onClick={() =>convertShared(payment.orderId,false,payment.cardTypeId,payment.maskedAcctNumber)}>{t("Dashboard.AddCard.RemoveButton")}</StyledButton>
                                                    </StyledGrid>)}
                                                
                                               
                                            </StyledGrid>
                                            <Divider className="bottom-margin-3" />
                                        </StyledGrid>
                                        ))
                                        }{
                                            cardOnFilePayment.map((payment: any, index) => (
                                                <StyledGrid container item justifyContent="center" spacing={1} key={`${payment.cardTypeId}${index}`}>
                                                    <StyledGrid item className="bottom-margin-1">
                                                           
                                                        <Cards
                                                            number={((payment.cardTypeId === "amex" || payment.cardTypeId === "AMEX")?("***********"+payment.maskedAcctNumber):"************"+payment.maskedAcctNumber)}
                                                            expiry={ payment.expMonth.length>1 ? (payment.expMonth+payment.expYear):
                                                                ("0"+payment.expMonth+payment.expYear)
                                                            }
                                                            preview
                                                            issuer ={(payment.cardTypeId === "MAST" || payment.cardTypeId === "mast")?("mastercard"):((payment.cardTypeId === "amex" || payment.cardTypeId === "AMEX")?("american-express"):payment.cardTypeId) }
                                                            name={payment.cardholderName}
                                                            cvc=""
                                                        />
                                                    </StyledGrid>
                                                </StyledGrid>
                                                   
                                                      
                                                ))
                                        }
                                </StyledGrid>
                            </StyledGrid>
                        </StyledAccordionDetails>
                    </StyledAccordion>
                </StyledGrid>
                
            </StyledGrid>
        </StyledGrid>
    </StyledContainer>
    <StyledDialog
          open={openShare}
          onClose={onclose}
          aria-labelledby="my-card-share-dialog">
          <StyledDialogContent>
          <StyledTypography variant = "h6">
          {shareTitle} {selCardType} {t("Dashboard.AddCard.ShareTitleAfter")} {selCardNum}
          </StyledTypography>
          <StyledDialogContentText>
          {shareSubTitle}
          </StyledDialogContentText>
          <StyledGrid item >
            <StyledTypography variant = "h6">{t("Dashboard.AddCard.BillingAccount")}</StyledTypography>
            </StyledGrid>
            <StyledGrid item >
                <StyledFormLabel >{displayName},{address},{address2}</StyledFormLabel>
            </StyledGrid>
            <StyledDialogActions>
                {share ? (
                    <StyledGrid container item spacing= {2} justifyContent="space-around" >
                        <StyledButton testId="cancel-share-card" color = "default" onClick={closeShare} >
                        {t("Dashboard.AddCard.CancelButton")}
                        </StyledButton> 
                        <StyledButton  color="primary"
                                    testId="add-share-card" onClick={shareCard} >
                        {t("Dashboard.AddCard.ShareButton")}
                        </StyledButton>
                    </StyledGrid>
                    ):(<StyledGrid container item spacing= {2} justifyContent="space-around" >
                    <StyledButton testId="remove-share-card" color = "primary" onClick={shareCard} >
                    {t("Dashboard.AddCard.RemoveButton")}
                    </StyledButton> 
                    <StyledButton  color="default"
                                testId="cancel-share-card" onClick={closeShare} >
                    {t("Dashboard.AddCard.CancelButton")}
                    </StyledButton>
                </StyledGrid>)}
            </StyledDialogActions>
          </StyledDialogContent>
        </StyledDialog>
        <StyledDialog
          open={openRemove}
          onClose={onclose}
          aria-labelledby="my-card-remove-dialog">
          <StyledDialogContent>
          <StyledTypography variant = "h6">
          {shareTitle} {selCardType} {t("Dashboard.AddCard.ShareTitleAfter")} {selCardNum}
          </StyledTypography>
          <StyledDialogContentText>
          {shareSubTitle}
          </StyledDialogContentText>
          
            <StyledDialogActions>
                <StyledGrid container item spacing= {2} justifyContent="space-around" >
                    <StyledButton testId="cancel-remove-card" color = "default" onClick={closeRemove} >
                        {t("Dashboard.AddCard.CancelButton")}
                    </StyledButton> 
                    <StyledButton  color="primary"
                                    testId="delete-remove-card" onClick={removeAccount} >
                        {t("Dashboard.AddCard.RemoveButton")}
                    </StyledButton>
                </StyledGrid>
                    
            </StyledDialogActions>
          </StyledDialogContent>
        </StyledDialog>
        {   loading && (
                      <StyledGrid container justifyContent="center" className="top-margin-2">
                       <StyledProgressPlaceholder className="top-margin-2" />
                       </StyledGrid>
                     ) }
    <DialogAddCard  openAdd={openAdd} setOpenAdd={setOpenAdd} />
    <StyledDialog
                open={openError}
                onClose={closeAndRedirect}
                aria-labelledby="add-card-error-dialog"
                >
                   <DialogContent dividers >
                <StyledTypography variant="h6"> {t("Dashboard.AddCard.ErrorTitle")}</StyledTypography>
                <StyledDialogContent> {errorMessage}</StyledDialogContent>
             
                 
                    <StyledButton testId="add-card-error-ok" onClick={closeAndRedirect} color="primary">
                    {t("Dashboard.AddCard.Ok")}      
                    </StyledButton>
                 
                    </DialogContent>
              
              </StyledDialog>
    
    </>)
}
export default MyCard;

