import { FC } from "react";
import { StyledCircularProgress } from "../../../../elements/circular-progress";
import { StyledBox, StyledFormControl, StyledGrid, StyledIconButton, StyledSelect, StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { StyledTooltip } from "../../../../elements/tooltip/styled-tooltip";
import { StyledIcon } from "../../../../elements/icon";
import InfoIcon from '@material-ui/icons/Info';
import { isEmpty } from "lodash-es";

interface OrderItemUOMCellProps {
    loading: boolean;
    uom: Array<any>;
    uomType: string;
    onUomUpdate: (e: any, rd: any) => void;
    conversionFactors: Array<any>;
    rowData: any;
    t: any;
}

const OrderItemUOMCell: FC<OrderItemUOMCellProps> = ({ uom = [], uomType = '-1', loading = true, conversionFactors = [], onUomUpdate, rowData = {}, t }) => {
    const { partNumber } = rowData;
    return loading ? (
      <StyledCircularProgress />
    ) : (
      <StyledGrid container item style={{ width: "auto", alignItems: "center", minWidth: "100px" }}>
        {uom.length > 0 ? (
          <Stack gap={2} direction="row">
            <StyledFormControl variant="outlined" className="botton-margin-0">
              <StyledSelect
                data-testid="list-sort-option"
                id={`id-row-5` + partNumber}
                value={uomType}
                native
                onChange={(event) => onUomUpdate(event, rowData)}
                fullWidth
              >
                {uom.map((uom, i) => (
                  <option
                    value={uom}
                    key={`productGrid_option_${i}_${partNumber}_${uom}`}
                    id={`productGrid_option_${i}_${uom}`}
                  >
                    {t("UOM." + uom)}
                  </option>
                ))}
              </StyledSelect>
            </StyledFormControl>
            {isEmpty(conversionFactors) ? null : (
              <StyledTooltip
                arrow
                title={
                  <StyledBox>
                    <StyledTypography className="ships-from" variant="body2">
                      {t && t("OrderItemTable.Labels.ConvertionInfo")}:
                    </StyledTypography>
                    {conversionFactors?.map((crf, i) => (
                      <p className="ships-from" key={i}>{crf}</p>
                    ))}
                  </StyledBox>
                }
              >
                <StyledIconButton size="small">
                  <StyledIcon shade="gray" icon={<InfoIcon />} />
                </StyledIconButton>
              </StyledTooltip>
            )}
          </Stack>
        ) : (
          t("CommerceEnvironment.inventoryStatus.NA")
        )}
      </StyledGrid>
    );
}

export default OrderItemUOMCell;