/* tslint:disable */
/* eslint-disable */
/**
 * Approval Status
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleDescription } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { RoleDescriptionCollection } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * RoleDescriptionsApi - axios parameter creator
 * @export
 */
export const RoleDescriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a role description.
         * @param {RoleDescription} roleDescription The role description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleDescription: async (roleDescription: RoleDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleDescription' is not null or undefined
            assertParamExists('createRoleDescription', 'roleDescription', roleDescription)
            const localVarPath = `/role-descriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleDescriptionById: async (roleId: number, languageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('deleteRoleDescriptionById', 'roleId', roleId)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('deleteRoleDescriptionById', 'languageId', languageId)
            const localVarPath = `/role-descriptions/roleId:{roleId},languageId:{languageId}`
                .replace(`{${"roleId"}}`, String(roleId))
                .replace(`{${"languageId"}}`, String(languageId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDescriptionById: async (roleId: number, languageId: number, fields?: string, expand?: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('getRoleDescriptionById', 'roleId', roleId)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('getRoleDescriptionById', 'languageId', languageId)
            const localVarPath = `/role-descriptions/roleId:{roleId},languageId:{languageId}`
                .replace(`{${"roleId"}}`, String(roleId))
                .replace(`{${"languageId"}}`, String(languageId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a collection of role descriptions.
         * @param {number} [roleId] The unique numeric ID for identifying the role.
         * @param {number} [languageId] The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {string} [displayName] The name of the role.
         * @param {string} [description] The description of the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDescriptions: async (roleId?: number, languageId?: number, displayName?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/role-descriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            if (languageId !== undefined) {
                localVarQueryParameter['languageId'] = languageId;
            }

            if (displayName !== undefined) {
                localVarQueryParameter['displayName'] = displayName;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (expand !== undefined) {
                localVarQueryParameter['expand'] = expand;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {RoleDescription} roleDescription The role description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleDescriptionById: async (roleId: number, languageId: number, roleDescription: RoleDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'roleId' is not null or undefined
            assertParamExists('updateRoleDescriptionById', 'roleId', roleId)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('updateRoleDescriptionById', 'languageId', languageId)
            // verify required parameter 'roleDescription' is not null or undefined
            assertParamExists('updateRoleDescriptionById', 'roleDescription', roleDescription)
            const localVarPath = `/role-descriptions/roleId:{roleId},languageId:{languageId}`
                .replace(`{${"roleId"}}`, String(roleId))
                .replace(`{${"languageId"}}`, String(languageId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(roleDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleDescriptionsApi - functional programming interface
 * @export
 */
export const RoleDescriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleDescriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a role description.
         * @param {RoleDescription} roleDescription The role description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRoleDescription(roleDescription: RoleDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRoleDescription(roleDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRoleDescriptionById(roleId: number, languageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRoleDescriptionById(roleId, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleDescriptionById(roleId: number, languageId: number, fields?: string, expand?: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleDescriptionById(roleId, languageId, fields, expand, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a collection of role descriptions.
         * @param {number} [roleId] The unique numeric ID for identifying the role.
         * @param {number} [languageId] The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {string} [displayName] The name of the role.
         * @param {string} [description] The description of the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRoleDescriptions(roleId?: number, languageId?: number, displayName?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDescriptionCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRoleDescriptions(roleId, languageId, displayName, description, offset, limit, fields, expand, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {RoleDescription} roleDescription The role description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRoleDescriptionById(roleId: number, languageId: number, roleDescription: RoleDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRoleDescriptionById(roleId, languageId, roleDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleDescriptionsApi - factory interface
 * @export
 */
export const RoleDescriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleDescriptionsApiFp(configuration)
    return {
        /**
         * Create a role description.
         * @param {RoleDescription} roleDescription The role description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRoleDescription(roleDescription: RoleDescription, options?: any): AxiosPromise<void> {
            return localVarFp.createRoleDescription(roleDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRoleDescriptionById(roleId: number, languageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRoleDescriptionById(roleId, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDescriptionById(roleId: number, languageId: number, fields?: string, expand?: string, sort?: string, options?: any): AxiosPromise<RoleDescription> {
            return localVarFp.getRoleDescriptionById(roleId, languageId, fields, expand, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a collection of role descriptions.
         * @param {number} [roleId] The unique numeric ID for identifying the role.
         * @param {number} [languageId] The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {string} [displayName] The name of the role.
         * @param {string} [description] The description of the role.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
         * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
         * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRoleDescriptions(roleId?: number, languageId?: number, displayName?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options?: any): AxiosPromise<RoleDescriptionCollection> {
            return localVarFp.getRoleDescriptions(roleId, languageId, displayName, description, offset, limit, fields, expand, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a role description.
         * @param {number} roleId The unique numeric ID for identifying the role.
         * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
         * @param {RoleDescription} roleDescription The role description.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRoleDescriptionById(roleId: number, languageId: number, roleDescription: RoleDescription, options?: any): AxiosPromise<void> {
            return localVarFp.updateRoleDescriptionById(roleId, languageId, roleDescription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleDescriptionsApi - object-oriented interface
 * @export
 * @class RoleDescriptionsApi
 * @extends {BaseAPI}
 */
export class RoleDescriptionsApi extends BaseAPI {
    /**
     * Create a role description.
     * @param {RoleDescription} roleDescription The role description.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleDescriptionsApi
     */
    public createRoleDescription(roleDescription: RoleDescription, options?: any) {
        return RoleDescriptionsApiFp(this.configuration).createRoleDescription(roleDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a role description.
     * @param {number} roleId The unique numeric ID for identifying the role.
     * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleDescriptionsApi
     */
    public deleteRoleDescriptionById(roleId: number, languageId: number, options?: any) {
        return RoleDescriptionsApiFp(this.configuration).deleteRoleDescriptionById(roleId, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a role description.
     * @param {number} roleId The unique numeric ID for identifying the role.
     * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleDescriptionsApi
     */
    public getRoleDescriptionById(roleId: number, languageId: number, fields?: string, expand?: string, sort?: string, options?: any) {
        return RoleDescriptionsApiFp(this.configuration).getRoleDescriptionById(roleId, languageId, fields, expand, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a collection of role descriptions.
     * @param {number} [roleId] The unique numeric ID for identifying the role.
     * @param {number} [languageId] The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
     * @param {string} [displayName] The name of the role.
     * @param {string} [description] The description of the role.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {string} [fields] The comma-separated set of properties to be returned. If no properties are specified, all properties are returned.
     * @param {string} [expand] The comma-separated set of related resources referenced in the links to be returned. If no related resources are specified, no related resources are returned.
     * @param {string} [sort] The comma-separated set of properties which controls the order of the items being listed, prefixed by either (-) to sort by descending order, or optionally (+) to sort by ascending order. For example, sort&#x3D;name,-d which means, order the items based on the name value in ascending order, then by the id value in descending order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleDescriptionsApi
     */
    public getRoleDescriptions(roleId?: number, languageId?: number, displayName?: string, description?: string, offset?: number, limit?: number, fields?: string, expand?: string, sort?: string, options?: any) {
        return RoleDescriptionsApiFp(this.configuration).getRoleDescriptions(roleId, languageId, displayName, description, offset, limit, fields, expand, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a role description.
     * @param {number} roleId The unique numeric ID for identifying the role.
     * @param {number} languageId The language of the description. For a list of integer language identifiers, refer to the Knowledge Center.
     * @param {RoleDescription} roleDescription The role description.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleDescriptionsApi
     */
    public updateRoleDescriptionById(roleId: number, languageId: number, roleDescription: RoleDescription, options?: any) {
        return RoleDescriptionsApiFp(this.configuration).updateRoleDescriptionById(roleId, languageId, roleDescription, options).then((request) => request(this.axios, this.basePath));
    }
}
