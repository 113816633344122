/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export interface Organizations {
  id: number;
  legalId: string;
  displayName: string;
  billTo: string;
  shipTo: string;
  billtoAddress: string;
  billtoAddress2: string;
  shipToAddress: string;
  shipToAddress2: string;
}

// Business Segments
export const BUSINESS_SEGMENTS = {
  LTC: "LTC",
  ACUTE: "ACUTE",
  ALT: "ALT",
  NA: "NA",
  NONE: "NONE",
  STOCKLESS: "STOCKLESS"
}

export const ORG_ATTR_KEY = {
  CUSTOMER_PART_NUMBER: "CustomPartNumberKey",
}