import request from './request';

export const findInvoiceRequest = (invoiceNumber, poNumber, salesOrderNumber, dateTo, dateFrom) =>
request.get("/find", {
    params: {
      invoiceNumber: invoiceNumber,
      companyName: "",
      companyNumber: "0",
      dateFrom: dateFrom,
      dateTo: dateTo,
      invoiceTotalFrom: "0",
      invoiceTotalTo: "0",
      poNumber: poNumber,
      salesOrderNumber: salesOrderNumber,
      sortKey: "invoiceDate",
      sortOrder: "desc",
      page: "0",
      pageSize: "10"
    },

});