/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import * as ROUTES from "../../constants/routes";
//UI
import { StyledBox, StyledContainer } from "@hcl-commerce-store-sdk/react-component";
import MenubarMenuItem from "./MenubarMenuItem-chc";
import { Home } from "@material-ui/icons";
import MenuBarOrgContract from "./MenubarOrgContract";
import { useDashboardSections } from "../../_foundation/hooks/use-dashboard-sections-chc";
import MenubarMenuItemHover from "./MenubarMenuItemHover";
import { StyledHeaderIconButton } from "../../elements/header-icon-button/styled-header-icon-button";
import { Stack } from "@mui/system";

//custom
import { loginStatusSelector } from "../../redux/selectors/user";
import { CSR_HUB_URL } from "../../constants/common";
interface ExpandedMenuProps {
  pages?: any;
}

/**
 * ExpandedMenu component
 * expanded menu for desktop/tablet view
 * @param props
 */
const ExpandedMenu: React.FC<ExpandedMenuProps> = (props: any) => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState<any | null>(null);
  const { dashboardSections, isCSRRole } = useDashboardSections();
  //const { dashboardSections } = useDashboardSections();
  const { pages = [] } = props;
  const allCategories = {
    id: "allCategories",
    children: pages,
    name: t("AllCategoriesExpandedMenu.AllCategoriesLabel"),
  };
  const orgPage = {
    id: "orgContract",
    children: [],
    name: "",
  };
  const csHubPage = {
    id: "CSRHub",
    children: [],
    seo: { href: CSR_HUB_URL, target: "_blank", external: true},
    name: t("Header.Actions.CSRHub"),
  };
  const dashboardPage = {
    id: "dashboard",
    children: [],
    seo: { href: ROUTES.DASHBOARD },
    name: t("Dashboard.Title"),
  };
  const categories = [
    allCategories,
    dashboardPage,
    ...dashboardSections.map(({ title, pages }, index) => ({
      id: `section-${index}`,
      children: pages.map(({ title, link }) => ({
        id: link,
        children: [],
        name: title,
        seo: { href: link },
      })),
      name: title,
    })),
    orgPage,
    ...(isCSRRole ? [csHubPage]: []),
  ];
  const selectMenuItem = (cid: string | null) => {
    setSelectedMenuItem(categories.filter((c) => c.id === cid)[0] || null);
  };
  const userLoggedIn = useSelector(loginStatusSelector);

  React.useEffect(() => {
    setSelectedMenuItem(null);
  }, [location]);

  return (
    <StyledBox className="expanded-menu-container">
      <StyledContainer overflow="hidden">
        <Stack direction="row" flexWrap="nowrap" justifyContent="flex-start" spacing={3}>
          {categories
            ?.filter(({ id }) => id === "allCategories")
            .map((page) => (
              <Stack key={page.id} justifyContent="center" py={1}><MenubarMenuItem
                selectedMenuItem={selectedMenuItem}
                selectMenuItem={selectMenuItem}
                page={page}
              ></MenubarMenuItem></Stack>
            ))}

          <StyledBox display="flex" alignItems="center" justifyContent="center">
            <StyledHeaderIconButton link="/" icon={<Home />} />
          </StyledBox>

          {userLoggedIn ? categories
            ?.filter(({ id }) => id !== "allCategories" && id !== "orgContract")
            .map((page) => (
              <MenubarMenuItemHover
                key={page.id}
                selectedMenuItem={selectedMenuItem}
                selectMenuItem={selectMenuItem}
                page={page}
              ></MenubarMenuItemHover>
            )) : null}
          {userLoggedIn ? (
            <MenuBarOrgContract
              selectedMenuItem={selectedMenuItem}
              selectMenuItem={selectMenuItem}
              page={orgPage}
            />
          ) : null}
        </Stack>
      </StyledContainer>
    </StyledBox>
  );
};

export default ExpandedMenu;
