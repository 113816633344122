/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * OrganizationSummaryApi - axios parameter creator
 * @export
 */
export const OrganizationSummaryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get CHC Organization Summary.
         * @summary Get Organization Summary.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} profileName Profile Name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcOrganizationSummary: async (storeId: string, organizationId: string, profileName: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcOrganizationSummary', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('chcOrganizationSummary', 'organizationId', organizationId)
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('chcOrganizationSummary', 'profileName', profileName)
            const localVarPath = `/store/{storeId}/chc_organization/CHCOrganizationDataBean`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationSummaryApi - functional programming interface
 * @export
 */
export const OrganizationSummaryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationSummaryApiAxiosParamCreator(configuration)
    return {
        /**
         * Get CHC Organization Summary.
         * @summary Get Organization Summary.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} profileName Profile Name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcOrganizationSummary(storeId: string, organizationId: string, profileName: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcOrganizationSummary(storeId, organizationId, profileName, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationSummaryApi - factory interface
 * @export
 */
export const OrganizationSummaryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationSummaryApiFp(configuration)
    return {
        /**
         * Get CHC Organization Summary.
         * @summary Get Organization Summary.
         * @param {string} storeId store Id
         * @param {string} organizationId Organization Id.
         * @param {string} profileName Profile Name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcOrganizationSummary(storeId: string, organizationId: string, profileName: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcOrganizationSummary(storeId, organizationId, profileName, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationSummaryApi - object-oriented interface
 * @export
 * @class OrganizationSummaryApi
 * @extends {BaseAPI}
 */
export class OrganizationSummaryApi extends BaseAPI {
    /**
     * Get CHC Organization Summary.
     * @summary Get Organization Summary.
     * @param {string} storeId store Id
     * @param {string} organizationId Organization Id.
     * @param {string} profileName Profile Name.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationSummaryApi
     */
    public chcOrganizationSummary(storeId: string, organizationId: string, profileName: string, responseFormat?: string, options?: any) {
        return OrganizationSummaryApiFp(this.configuration).chcOrganizationSummary(storeId, organizationId, profileName, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
