/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Get Invoice and download from ibillder
export const ADDRESS_GET_REQUESTED = "ADDRESS_GET_REQUESTED";
export const ADDRESS_GET_SUCCESS = "ADDRESS_GET_SUCCESS";
export const ADDRESS_GET_ERROR = "ADDRESS_GET_ERROR";


