import { StyledContainer } from "@hcl-commerce-store-sdk/react-component";
import { Stack, Unstable_Grid2 as Grid } from "@mui/material";
import { FC } from "react";
import { DashboardSectionHeading } from "../../../widgets/dashboard-section-heading";
import { useTranslation } from "react-i18next";
import { useAccelerateProgram } from "../../../../_foundation/hooks/use-accelerate-program-chc";
import { AccelerateProgramProvider } from "../../../../_foundation/context/accelerate-program-context";
import { AccelerateAccount } from "../../../widgets/accelerate-account/AccelerateAccount";
import { AccelerateBuildOrder } from "../../../widgets/accelerate-build-order/AccelerateBuildOrder";
import { AccelerateChDollars } from "../../../widgets/accelerate-ch-dollars/AccelerateChDollars";
import { AccelerateNextPayout } from "../../../widgets/accelerate-next-payout/AccelerateNextPayout";
import { AccelerateChDollarsSummary } from "../../../widgets/accelerate-ch-dollars-summary/AccelerateChDollarsSummary";
import { AccelerateStatus } from "../../../widgets/accelerate-status/AccelerateStatus";
import { AccelerateResources } from "../../../widgets/accelerate-resources/AccelerateResources";
import { AccelerateStatusTracker } from "../../../widgets/accelerate-status-tracker/AccelerateStatusTracker";
import { AccelerateEarningTracker } from "../../../widgets/accelerate-earning-tracker/AccelerateEarningTracker";
import { AccelerateRewards } from "../../../widgets/accelerate-rewards/AccelerateRewards";

const AccelerateProgramPage: FC = () => {
  const { t } = useTranslation();
  const program = useAccelerateProgram();

  return (
    <AccelerateProgramProvider value={program}>
      <StyledContainer className="page vertical-padding-4" style={{ overflowX: "hidden" }}>
        <Grid container spacing={4}>
          <Grid xs={12}>
            <Stack direction={{ md: "row" }} spacing={{ xs: 3 }} justifyContent="space-between">
              {program.data?.EligibilityType === "SC" ? (
                <DashboardSectionHeading
                  title={t("AccountLinks.AccelerateProProgramText")}
                  subtitle={t("AccountLinks.AccelerateProProgramDescription")}
                  large
                />
              ) : (
                <DashboardSectionHeading
                  title={t("AccountLinks.AccelerateProgramText")}
                  subtitle={t("AccountLinks.AccelerateProgramDescription")}
                  large
                />
              )}

              <AccelerateAccount />
            </Stack>
          </Grid>

          <Grid xs={12} sm={6} md={3}>
            <AccelerateChDollars />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <AccelerateNextPayout />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <AccelerateChDollarsSummary />
          </Grid>
          <Grid xs={12} sm={6} md={3}>
            <AccelerateStatus />
          </Grid>

          <Grid xs={12}>
            <AccelerateBuildOrder />
          </Grid>
          <Grid container xs={12}>
            <Grid xs={12} md={3}>
              <AccelerateResources />
            </Grid>
            <Grid xs={12} md={9}>
              <AccelerateStatusTracker />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <AccelerateEarningTracker />
          </Grid>
          <Grid xs={12}>
            <AccelerateRewards />
          </Grid>
        </Grid>
      </StyledContainer>
    </AccelerateProgramProvider>
  );
};

export default AccelerateProgramPage;
