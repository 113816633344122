/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceMemberBeansOrganizationListDataBeanIBMOrganizationSummary } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCOrganizationApi - axios parameter creator
 * @export
 */
export const CHCOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user\'s entitled organizations.
         * @summary Get user\'s entitled organizations.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [accountCheck] 
         * @param {string} [explicitEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcOrganizationGetEntitledOrganizations: async (storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcOrganizationGetEntitledOrganizations', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/chc_organization/CHCOrganizationListDataBean`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (accountCheck !== undefined) {
                localVarQueryParameter['accountCheck'] = accountCheck;
            }

            if (explicitEntitlement !== undefined) {
                localVarQueryParameter['explicitEntitlement'] = explicitEntitlement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCOrganizationApi - functional programming interface
 * @export
 */
export const CHCOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user\'s entitled organizations.
         * @summary Get user\'s entitled organizations.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [accountCheck] 
         * @param {string} [explicitEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcOrganizationGetEntitledOrganizations(storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceMemberBeansOrganizationListDataBeanIBMOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcOrganizationGetEntitledOrganizations(storeId, responseFormat, accountCheck, explicitEntitlement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCOrganizationApi - factory interface
 * @export
 */
export const CHCOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCOrganizationApiFp(configuration)
    return {
        /**
         * Get user\'s entitled organizations.
         * @summary Get user\'s entitled organizations.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [accountCheck] 
         * @param {string} [explicitEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcOrganizationGetEntitledOrganizations(storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options?: any): AxiosPromise<ComIbmCommerceMemberBeansOrganizationListDataBeanIBMOrganizationSummary> {
            return localVarFp.chcOrganizationGetEntitledOrganizations(storeId, responseFormat, accountCheck, explicitEntitlement, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCOrganizationApi - object-oriented interface
 * @export
 * @class CHCOrganizationApi
 * @extends {BaseAPI}
 */
export class CHCOrganizationApi extends BaseAPI {
    /**
     * Get user\'s entitled organizations.
     * @summary Get user\'s entitled organizations.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [accountCheck] 
     * @param {string} [explicitEntitlement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCOrganizationApi
     */
    public chcOrganizationGetEntitledOrganizations(storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options?: any) {
        return CHCOrganizationApiFp(this.configuration).chcOrganizationGetEntitledOrganizations(storeId, responseFormat, accountCheck, explicitEntitlement, options).then((request) => request(this.axios, this.basePath));
    }
}
