/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { PackSlip } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCPackSlipApi - axios parameter creator
 * @export
 */
export const CHCPackSlipApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get pack slip from JDE
         * @summary Get pack slip as email.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [email] 
         * @param {string} [orderNumber] 
         * @param {string} [orderType] 
         * @param {string} [pckSlipArrayString] 
         * @param {string} [orderStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCSendPackSlip: async (storeId: string, responseFormat?: string, email?: string, orderNumber?: string, orderType?: string, pckSlipArrayString?: string, orderStatus?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCSendPackSlip', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/CHCorder/SendPackSlip`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (orderNumber !== undefined) {
                localVarQueryParameter['orderNumber'] = orderNumber;
            }

            if (orderType !== undefined) {
                localVarQueryParameter['orderType'] = orderType;
            }

            if (pckSlipArrayString !== undefined) {
                localVarQueryParameter['pckSlipArrayString'] = pckSlipArrayString;
            }

            if (orderStatus !== undefined) {
                localVarQueryParameter['orderStatus'] = orderStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCPackSlipApi - functional programming interface
 * @export
 */
export const CHCPackSlipApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCPackSlipApiAxiosParamCreator(configuration)
    return {
        /**
         * Get pack slip from JDE
         * @summary Get pack slip as email.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [email] 
         * @param {string} [orderNumber] 
         * @param {string} [orderType] 
         * @param {string} [pckSlipArrayString] 
         * @param {string} [orderStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCSendPackSlip(storeId: string, responseFormat?: string, email?: string, orderNumber?: string, orderType?: string, pckSlipArrayString?: string, orderStatus?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PackSlip>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCSendPackSlip(storeId, responseFormat, email, orderNumber, orderType, pckSlipArrayString, orderStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCPackSlipApi - factory interface
 * @export
 */
export const CHCPackSlipApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCPackSlipApiFp(configuration)
    return {
        /**
         * Get pack slip from JDE
         * @summary Get pack slip as email.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [email] 
         * @param {string} [orderNumber] 
         * @param {string} [orderType] 
         * @param {string} [pckSlipArrayString] 
         * @param {string} [orderStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCSendPackSlip(storeId: string, responseFormat?: string, email?: string, orderNumber?: string, orderType?: string, pckSlipArrayString?: string, orderStatus?: string, options?: any): AxiosPromise<PackSlip> {
            return localVarFp.cHCSendPackSlip(storeId, responseFormat, email, orderNumber, orderType, pckSlipArrayString, orderStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCPackSlipApi - object-oriented interface
 * @export
 * @class CHCPackSlipApi
 * @extends {BaseAPI}
 */
export class CHCPackSlipApi extends BaseAPI {
    /**
     * Get pack slip from JDE
     * @summary Get pack slip as email.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml.
     * @param {string} [email] 
     * @param {string} [orderNumber] 
     * @param {string} [orderType] 
     * @param {string} [pckSlipArrayString] 
     * @param {string} [orderStatus] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCPackSlipApi
     */
    public cHCSendPackSlip(storeId: string, responseFormat?: string, email?: string, orderNumber?: string, orderType?: string, pckSlipArrayString?: string, orderStatus?: string, options?: any) {
        return CHCPackSlipApiFp(this.configuration).cHCSendPackSlip(storeId, responseFormat, email, orderNumber, orderType, pckSlipArrayString, orderStatus, options).then((request) => request(this.axios, this.basePath));
    }
}
