import { FC } from "react";
import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";

const DashboardSectionHeading: FC<{ title: string; subtitle: string; large?: boolean }> = ({
  title,
  subtitle,
  large,
}) => (
  <Stack spacing="1">
    <StyledTypography
      variant={large ? "h2" : "h3"}
      component={large ? "h1" : "h2"}
      className="break-word"
    >
      {title}
    </StyledTypography>
    <StyledTypography variant="subtitle1" className="break-word">
      {subtitle}
    </StyledTypography>
  </Stack>
);

export default DashboardSectionHeading;
