/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";


//Foundation libraries
import {downloadFileRequest} from "../../../components/widgets/invoices-report-table/api";
import {searchIBInvoicesRequest} from "../../../components/widgets/invoices-report-table/api-fetch-invoices";
import {findInvoiceRequest} from "../../../components/widgets/invoices-report-table/api-find-invoice";
import FileSaver from "../../../components/widgets/invoices-report-table/file-saver";
//Redux
import * as ACTIONS from "../../action-types/invoice";
import {  } from "../../actions/invoice";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";

/**
 * Saga worker to invoke get download invoice
 */
export function* fetchDownloadFileRequest(action: any) {
  const { invoiceNumber, invoiceDate, customerNumber } = action.payload;
  try {
    const response = yield call(downloadFileRequest, invoiceNumber, invoiceDate, customerNumber );
    const invoiceListData = response.data;
    FileSaver(response.data, invoiceNumber+'.pdf');
    yield put({
      type: ACTIONS.INVOICE_GET_DOWNLOAD_SUCCESS,
      response: invoiceListData,
    });
  } catch (error) {
    yield put({ type: ACTIONS.INVOICE_GET_DOWNLOAD_ERROR, error });
    console.log(error);
  }
}
/**
 * Saga worker to invoke get invoice list
 */
export function* fetchInvoiceRequest(action: any) {
  const { shipToNumber, invoiceNumber, poNumber, salesOrderNumber, dateFrom, dateTo } = action.payload;
  try {
    const response = yield call(searchIBInvoicesRequest, shipToNumber, invoiceNumber, poNumber, salesOrderNumber, dateFrom, dateTo );
    const invoiceListData = response.map((item: any) => {
      if(!(item.salesOrderNumber.includes("SI") || item.salesOrderNumber.includes("CI"))){
        return item
      }
      return null;
    }).filter(Boolean);

    yield put({
      type: ACTIONS.INVOICE_GET_SUCCESS,
      response: invoiceListData,
    });

    if (response.length < 1){
      const msg = {
        key: "Invoices.INVOICE_ACCOUNT_NOTFOUND"
      };
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION(msg));

    }
    
  } catch (error) {
    yield put({ type: ACTIONS.INVOICE_GET_ERROR, error });
    console.log(error);
  }
  
}
/**
 * Saga worker to find invoices
 */
export function* findInvoicesRequest(action: any) {
  const { invoiceNumber, poNumber, salesOrderNumber, dateTo,  dateFrom } = action.payload;
  try {
    const response = yield call(findInvoiceRequest, invoiceNumber, poNumber, salesOrderNumber, dateTo,  dateFrom );
    const invoiceListData = response.data;

    yield put({
      type: ACTIONS.INVOICE_GET_SUCCESS,
      response: invoiceListData,
    });

    if (response.data.totalElements < 1){
      const msg = {
        key: "Invoices.INVOICE_ACCOUNT_NOTFOUND"
      };
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION(msg));
    }
    
  } catch (error) {
    yield put({ type: ACTIONS.INVOICE_GET_ERROR, error });
    console.log(error);
  }
}


