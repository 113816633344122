//UI
import { StyledButton, StyledHeaderActions } from "@hcl-commerce-store-sdk/react-component";

export const Buttonify = ({ children, ...props }) => {
  const { testId } = props;
  return (
    <StyledButton
      testId={testId}
      className="header-actionsButton"
      variant="text"
      color="secondary"
      {...props}
    >
      <StyledHeaderActions>{children}</StyledHeaderActions>{" "}
    </StyledButton>
  );
};
