/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CHCGetAllRequisitionListApi - axios parameter creator
 * @export
 */
export const CHCGetAllRequisitionListApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get users saved lists in all levels.
         * @summary Get users saved Lists.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [pageNumber] Page Number.
         * @param {string} [pageSize] Page Size.
         * @param {string} [orderBy] Order By.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcRequisitionListsBean: async (storeId: string, responseFormat?: string, pageNumber?: string, pageSize?: string, orderBy?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcRequisitionListsBean', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/chc_requisition_list/CHCRequisitionListDataBean`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCGetAllRequisitionListApi - functional programming interface
 * @export
 */
export const CHCGetAllRequisitionListApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCGetAllRequisitionListApiAxiosParamCreator(configuration)
    return {
        /**
         * Get users saved lists in all levels.
         * @summary Get users saved Lists.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [pageNumber] Page Number.
         * @param {string} [pageSize] Page Size.
         * @param {string} [orderBy] Order By.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcRequisitionListsBean(storeId: string, responseFormat?: string, pageNumber?: string, pageSize?: string, orderBy?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcRequisitionListsBean(storeId, responseFormat, pageNumber, pageSize, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCGetAllRequisitionListApi - factory interface
 * @export
 */
export const CHCGetAllRequisitionListApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCGetAllRequisitionListApiFp(configuration)
    return {
        /**
         * Get users saved lists in all levels.
         * @summary Get users saved Lists.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [pageNumber] Page Number.
         * @param {string} [pageSize] Page Size.
         * @param {string} [orderBy] Order By.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcRequisitionListsBean(storeId: string, responseFormat?: string, pageNumber?: string, pageSize?: string, orderBy?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcRequisitionListsBean(storeId, responseFormat, pageNumber, pageSize, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCGetAllRequisitionListApi - object-oriented interface
 * @export
 * @class CHCGetAllRequisitionListApi
 * @extends {BaseAPI}
 */
export class CHCGetAllRequisitionListApi extends BaseAPI {
    /**
     * Get users saved lists in all levels.
     * @summary Get users saved Lists.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [pageNumber] Page Number.
     * @param {string} [pageSize] Page Size.
     * @param {string} [orderBy] Order By.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCGetAllRequisitionListApi
     */
    public chcRequisitionListsBean(storeId: string, responseFormat?: string, pageNumber?: string, pageSize?: string, orderBy?: string, options?: any) {
        return CHCGetAllRequisitionListApiFp(this.configuration).chcRequisitionListsBean(storeId, responseFormat, pageNumber, pageSize, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}
