import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import { useDateFormatter } from "../../../_foundation/hooks/use-date-formatter-chc";
import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";
import { Paper } from "@material-ui/core";

const extractDateParts = (dateString: string) => {
  const englishFormat = /^([A-Za-z]+) (\d{1,2}), (\d{4})$/;
  const frenchFormat = /^(\d{1,2}) ([A-Za-zéû]+) (\d{4})$/;
  const englishMatch = dateString.match(englishFormat);
  const frenchMatch = dateString.match(frenchFormat);
  return englishMatch
    ? { day: englishMatch[2], month: englishMatch[1], year: englishMatch[3] }
    : frenchMatch
    ? {
        day: frenchMatch[1],
        month: frenchMatch[2],
        year: frenchMatch[3],
      }
    : null;
};

export const AccelerateNextPayout: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useContext(AccelerateProgramContext);
  const dateFormatter = useDateFormatter();
  const { month, day, year } = extractDateParts(dateFormatter(data?.NextPayoutDate)) ?? {
    month: "",
    day: "",
    year: "",
  };

  return (
    <StyledAccelerateCard>
      {loading ? null : (
        <Stack spacing={2}>
          <StyledTypography align="center" variant="h4">
            {t("DealerIncentiveProgram.PAYOUT")}
          </StyledTypography>
          <Paper elevation={0} style={{ overflow: "hidden" }}>
            <Stack>
              <StyledTypography
                align="center"
                style={{ padding: "5px", backgroundColor: "#faebb3" }}
                variant="overline"
              >
                {month}
              </StyledTypography>
              <StyledTypography
                align="center"
                style={{ padding: "5px 5px 0 5px", backgroundColor: "#f4f3f3", fontWeight: "bold" }}
                variant="h2"
              >
                {day}
              </StyledTypography>
              <StyledTypography
                align="center"
                style={{ padding: "0 5px 5px", backgroundColor: "#f4f3f3" }}
                variant="overline"
              >
                {year}
              </StyledTypography>
            </Stack>
          </Paper>
        </Stack>
      )}
    </StyledAccelerateCard>
  );
};
