/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put, select } from "redux-saga/effects";
//Foundation libraries
import organizationService from "../../../_foundation/apis/transaction/organization.service";

import chcOrganizationService from "../../../_foundation/apis/transaction/chc-organization.service";
import switchOrganizationService from "../../../_foundation/apis/transaction/switchOrganization.service";
import personService from "../../../_foundation/apis/transaction/person.service";
import notificationService from "../../../_foundation/apis/transaction/chc-notification.service";
//Redux
import {
  ENTITLED_ORG_ERROR_ACTION,
  ENTITLED_ORG_SUCCESS_ACTION,
  ENTITLED_ORG_SEGMENT_SUCCESS_ACTION,
  ENTITLED_ORG_SEGMENT_ERROR_ACTION,
  ENTITLED_ORG_LOYALTY_SUCCESS_ACTION,
  ENTITLED_ORG_LOYALTY_ERROR_ACTION,
  ORG_SWITCH_ERROR_ACTION,
  GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION,
  GET_ORGANIZATION_ADDRESS_ERROR_ACTION,
  GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION,
  GET_ORGANIZATION_BILLING_ADDRESS_ERROR_ACTION,
  GET_ACCELERATEPGMDATA_SUCCESS_ACTION,
  GET_ACCELERATEPGMDATA_ERROR_ACTION,
  GET_STANDING_ORDER_SUCCESS_ACTION,
  GET_STANDING_ORDER_ERROR_ACTION,
  GET_EMPLOYEE_PURCHASE_SUCCESS_ACTION,
  GET_EMPLOYEE_PURCHASE_ERROR_ACTION,
  ORG_SWITCH_SUCCESS_ACTION,
  ENTITLED_ORG_CPN_SUCCESS_ACTION,
  ENTITLED_ORG_CPN_ERROR_ACTION,
  NOTIFICATIONS_SUCCESS_ACTION,
  NOTIFICATIONS_ERROR_ACTION,
} from "../../actions/organization";
import { FETCH_ADMIN_USER_DETAILS_SUCCESS_ACTION } from "../../actions/user";
import { USER_CONTEXT_REQUEST_ACTION } from "../../actions/context";
import { FETCH_CONTRACT_REQUESTED_ACTION } from "../../actions/contract";
import {
  FETCHING_CART_ACTION,
  GET_PAYMETHODSCARD_ACTION,
} from "../../actions/order";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import {
  activeOrgSelector,
  userTypeSelector,
} from "../../selectors/organization";
import { isEmpty } from "lodash-es";
import { userIdSelector, forUserIdSelector } from "../../selectors/user";
import { ORG_ATTR_KEY } from "../../../constants/organization-list";
import {
  IBM_ASSIGNED_ROLE_DETAILS,
  BUYER_ADMIN_ROLE,
} from "../../../constants/common";
import { langSelector } from "../../../redux/selectors/language";

export function* getEntitledOrg(action: any) {
  try {
    const payload = action.payload;

    const response = yield call(
      chcOrganizationService.getEntitledOrganizations,
      payload
    );

    yield put(ENTITLED_ORG_SUCCESS_ACTION(response.data));
    yield* getSegment();
    yield* getLoyalty();
    yield* getCustomerPNAttribute();
    yield* getAcceleratePgmData();
    yield* getStandingOrderFlag();
    yield* getSEmployeePurchase();
    yield* getPerson();
    yield* getNotifications();
  } catch (e) {
    yield put(ENTITLED_ORG_ERROR_ACTION(e));
  }
}

export function* switchOrg(action: any) {
  try {
    const response = yield call(
      switchOrganizationService.changeOrganization,
      action.payload
    );
    const activeOrgId = action.payload?.query?.activeOrgId;
    yield put(FETCHING_CART_ACTION(action.payload));
    yield put(USER_CONTEXT_REQUEST_ACTION(action.payload));
    yield put(FETCH_CONTRACT_REQUESTED_ACTION(action.payload));
    yield put(GET_PAYMETHODSCARD_ACTION(action.payload));

    if (response.status === 200) {
      const msg = {
        key: "AccountSelection.Success",
      };
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION(msg));
    }
    yield* getSegment(activeOrgId);
    yield* getLoyalty(activeOrgId);
    yield* getCustomerPNAttribute(activeOrgId);
    yield* getAcceleratePgmData(activeOrgId);
    yield* getStandingOrderFlag(activeOrgId);
    yield* getSEmployeePurchase(activeOrgId);
    yield* getPerson();
    yield put(ORG_SWITCH_SUCCESS_ACTION(response?.data ?? {}));
  } catch (error) {
    yield put(ORG_SWITCH_ERROR_ACTION({ error }));
  }
}

export function* getOrganizationDetails(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(
      organizationService.findByOrganizationId,
      payload
    );
    yield put(GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION(response.data));
    yield* getOrganizationBilling();
  } catch (e) {
    yield put(GET_ORGANIZATION_ADDRESS_ERROR_ACTION(e));
  }
}

export function* getOrganizationBilling() {
  try {
    const userId = yield select(userIdSelector);
    const parameters: any = {
      userId: userId,
    };
    const response = yield call(
      chcOrganizationService.getShipToBillTo,
      parameters
    );
    const orgId = response.data.billToOrgId;
    const payload = {
      organizationId: orgId,
      profileName: "profileName=IBM_Organization_Short_Summary",
    };
    const response3 = yield call(
      chcOrganizationService.getChcOrgShortSummary,
      payload
    );
    yield put(GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS_ACTION(response3.data));
  } catch (e) {
    yield put(GET_ORGANIZATION_BILLING_ADDRESS_ERROR_ACTION(e));
  }
}

export function* getSegment(orgId?: string) {
  try {
    const activeOrgId = yield select(activeOrgSelector);
    const payload = {
      organizationId: !isEmpty(orgId) ? orgId : activeOrgId,
      attribute: "BusinessSegment",
    };
    const response = yield call(chcOrganizationService.orgAttribute, payload);
    yield put(ENTITLED_ORG_SEGMENT_SUCCESS_ACTION(response.data));
  } catch (e) {
    yield put(ENTITLED_ORG_SEGMENT_ERROR_ACTION(e));
  }
}
export function* getLoyalty(orgId?: string) {
  try {
    const activeOrgId = yield select(activeOrgSelector);
    const payload = {
      organizationId: !isEmpty(orgId) ? orgId : activeOrgId,
      attribute: "LoyaltyPoints",
    };
    const response = yield call(chcOrganizationService.orgAttribute, payload);
    yield put(
      ENTITLED_ORG_LOYALTY_SUCCESS_ACTION({ LoyaltyAttribute: response.data })
    );
  } catch (e) {
    yield put(ENTITLED_ORG_LOYALTY_ERROR_ACTION({ LoyaltyAttribute: { e } }));
  }
}

export function* getCustomerPNAttribute(orgId?: string) {
  try {
    const activeOrgId = yield select(activeOrgSelector);
    const payload = {
      organizationId: !isEmpty(orgId) ? orgId : activeOrgId,
      attribute: ORG_ATTR_KEY.CUSTOMER_PART_NUMBER,
    };
    const response = yield call(chcOrganizationService.orgAttribute, payload);
    yield put(ENTITLED_ORG_CPN_SUCCESS_ACTION({ CPNAttribute: response.data }));
  } catch (error) {
    yield put(ENTITLED_ORG_CPN_ERROR_ACTION({ CPNAttribute: { error } }));
  }
}

export function* getAcceleratePgmData(orgId?: string) {
  try {
    const activeOrgId = yield select(activeOrgSelector);
    const payload = {
      organizationId: !isEmpty(orgId) ? orgId : activeOrgId,
      attribute: "BusinessSegment",
      profileName: "CHC_Organization_Dealer_Incentive_Program",
    };
    const response = yield call(
      chcOrganizationService.getAcceleratePgmData,
      payload
    );
    yield put(GET_ACCELERATEPGMDATA_SUCCESS_ACTION(response.data));
  } catch (e) {
    yield put(GET_ACCELERATEPGMDATA_ERROR_ACTION(e));
  }
}

export function* getStandingOrderFlag(orgId?: string) {
  try {
    const activeOrgId = yield select(activeOrgSelector);
    const userType = yield select(userTypeSelector);
    const payload = {
      organizationId: !isEmpty(orgId) ? orgId : activeOrgId,
      profileName: "profileName=IBM_Organization_Short_Summary",
    };
    const response = yield call(
      chcOrganizationService.getChcOrgShortSummary,
      payload
    );
    const orderApproval = response.data?.orderApprovalNeeded;
    if (!orderApproval && userType === "R") {
      const standingValue = {
        allowStandingOrder: "True",
        orderApproval: orderApproval,
      };
      yield put(GET_STANDING_ORDER_SUCCESS_ACTION(standingValue));
    } else {
      const standingValue = {
        allowStandingOrder: "False",
        orderApproval: orderApproval,
      };
      yield put(GET_STANDING_ORDER_SUCCESS_ACTION(standingValue));
    }
  } catch (e) {
    yield put(GET_STANDING_ORDER_ERROR_ACTION(e));
    console.log(e);
  }
}

export function* getSEmployeePurchase(orgId?: string) {
  try {
    const activeOrgId = yield select(activeOrgSelector);
    const payload = {
      organizationId: !isEmpty(orgId) ? orgId : activeOrgId,
      attribute: "BusinessSegment",
    };
    const response = yield call(
      chcOrganizationService.checkEmployeeOrganization,
      payload
    );
    yield put(GET_EMPLOYEE_PURCHASE_SUCCESS_ACTION(response.data));
  } catch (e) {
    yield put(GET_EMPLOYEE_PURCHASE_ERROR_ACTION(e));
  }
}
export function* getPerson() {
  const userId = yield select(userIdSelector);
  const forUserId = yield select(forUserIdSelector);
  const payload = {
    userId: forUserId ?? userId,
    profileName: IBM_ASSIGNED_ROLE_DETAILS,
  };
  const response = yield call(personService.findByUserId, payload);
  yield* checkBuyerAdmin(response.data.rolesWithDetails);
}

export function* checkBuyerAdmin(roleDetail) {
  const roles: string[] = [];
  for (const value of roleDetail) {
    roles.push(String(value.roleId));
  }
  const isadmin = roles.includes(BUYER_ADMIN_ROLE) ? true : false;
  yield put(FETCH_ADMIN_USER_DETAILS_SUCCESS_ACTION({ isadmin }));
}

export function* getNotifications() {
  const langIdSelector = yield select(langSelector);
  const responseFormat = "json";
  const offset = "0";
  const limit = "3";
  const unreadOnly = "true";
  const langId = langIdSelector === "en_US" ? "-1" : "-2";
  try {
    const payload = {
      langId,
      responseFormat,
      offset,
      limit,
      unreadOnly,
    };
    const response = yield call(notificationService.getNotifications, payload);
    yield put(NOTIFICATIONS_SUCCESS_ACTION(response.data));
  } catch (e) {
    yield put(NOTIFICATIONS_ERROR_ACTION(e));
  }
}

export function* updateNotifications(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(
      notificationService.updateNotifications,
      payload
    );
    if(response.status === 200){
      yield* getNotifications();
    }
  } catch (e) {
    yield put(NOTIFICATIONS_ERROR_ACTION(e));
  }
}
