/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import chcGetPriceService from "../../../_foundation/apis/transaction/chc-price-server.service";
//Redux
import * as ACTIONS from "../../action-types/price-server";
/**
 * Saga worker to invoke get product list Price API for PDP
 */
export function* fetchProductListPriceForPDP(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(chcGetPriceService.getPricePDP, payload.parameters);
    if(payload?.parameters?.widget === "ProductB2BDetailsLayout-chc"){
      yield put({
        type: ACTIONS.PRODUCT_LIST_PRICE_FOR_PDP_GET_SUCCESS,
        response: response.data,
        payload: payload,
      });
    }else {
      yield put({
        type: ACTIONS.PRODUCT_LIST_PRICE_FOR_SHOPPING_CART_GET_SUCCESS,
        response: response.data,
        payload: payload,
      });
    }
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_PRICE_FOR_PDP_GET_ERROR, error });
  }
}

