/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, takeLatest } from "redux-saga/effects";
//Redux
import {
  FETCH_RECURRING_ORDER_ACTION,
  CANCEL_RECURRING_ACTION,
  PAUSE_STANDING_ORDER_ACTION,
  SCHEDULE_STANDING_ORDER_ACTION,
} from "../../actions/recurringOrder";
import * as WORKERS from "../workers/recurringOrder";
import * as ACTIONS from "../../action-types/recurringOrder";

export function* watchSaga() {
  yield takeLatest(
    [FETCH_RECURRING_ORDER_ACTION],
    WORKERS.fetchRecurringOrders
  );

  yield takeLatest(CANCEL_RECURRING_ACTION, WORKERS.cancelRecurringOrder);
  yield takeLatest(PAUSE_STANDING_ORDER_ACTION, pauseAndSumeAndFetch);
  yield takeLatest(ACTIONS.SCHEDULE_STANDING_ORDER_REQUESTED, scheduleAndFetch);
  yield takeLatest(ACTIONS.STANDING_ITEM_ADD_REQUESTED, WORKERS.addItem);
  yield takeLatest(ACTIONS.STANDING_ITEM_REMOVE_REQUESTED, removeItemAndFetch);
  yield takeLatest(ACTIONS.STANDING_ITEM_UPDATE_REQUESTED, updateItemAndFetch);
}

function* addItemAndFetch(action: any) {
  yield call(WORKERS.removeItem, action);
  //yield call(WORKERS.fetchRecurringOrders, action);
}

function* removeItemAndFetch(action: any) {
  yield call(WORKERS.removeItem, action);
  //yield call(WORKERS.fetchRecurringOrders, action);
}

function* updateItemAndFetch(action: any) {
  yield call(WORKERS.updateItem, action);
  // yield call(WORKERS.fetchCart, action);
}

function* pauseAndSumeAndFetch(action: any) {
  yield call(WORKERS.pauseRecurringOrder, action);
  yield call(WORKERS.fetchRecurringOrders, action);
}

function* scheduleAndFetch(action: any) {
  yield call(WORKERS.scheduleRecurringOrder, action);
  yield call(WORKERS.fetchRecurringOrders, action);
}
