/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const CANCEL_RECURRING_SUBSCRIPTION = "CANCEL_RECURRING_SUBSCRIPTION";
export const CANCEL_RECURRING_SUCCESS = "CANCEL_RECURRING_SUCCESS";
export const FETCH_RECURRING_ORDER = "FETCH_RECURRING_ORDER";
export const FETCH_RECURRING_ORDER_SUCCESS = "FETCH_RECURRING_ORDER_SUCCESS";
export const CANCEL_RECURRING_ERROR = "CANCEL_RECURRING_ERROR";
export const FETCH_RECURRING_ORDER_ERROR = "FETCH_RECURRING_ORDER_ERROR";
export const NOTIFY_CANCEL_RECURRING = "NOTIFY_CANCEL_RECURRING";

//Add item to standing order
export const STANDING_ITEM_ADD_REQUESTED = "STANDING_ITEM_ADD_REQUESTED";
export const STANDING_ITEM_ADD_SUCCESS = "STANDING_ITEM_ADD_SUCCESS";
export const STANDING_ITEM_ADD_ERROR = "STANDING_ITEM_ADD_ERROR";

//Remove item to standing order
export const STANDING_ITEM_REMOVE_REQUESTED = "STANDING_ITEM_REMOVE_REQUESTED";
export const STANDING_ITEM_REMOVE_SUCCESS = "STANDING_ITEM_REMOVE_SUCCESS";
export const STANDING_ITEM_REMOVE_ERROR = "STANDING_ITEM_REMOVE_ERROR";

//Update item in standing order
export const STANDING_ITEM_UPDATE_REQUESTED = "STANDING_ITEM_UPDATE_REQUESTED";
export const STANDING_ITEM_UPDATE_SUCCESS = "STANDING_ITEM_UPDATE_SUCCESS";
export const STANDING_ITEM_UPDATE_ERROR = "STANDING_ITEM_UPDATE_ERROR";

//Pause standing order

export const PAUSE_STANDING_ORDER_REQUESTED = "PAUSE_STANDING_ORDER_REQUESTED";
export const PAUSE_STANDING_ORDER_SUCCESS = "PAUSE_STANDING_ORDER_SUCCESS";
export const PAUSE_STANDING_ORDER_ERROR = "PAUSE_STANDING_ORDER_ERROR";

//schedule
export const SCHEDULE_STANDING_ORDER_REQUESTED =
  "SCHEDULE_STANDING_ORDER_REQUESTED";
export const SCHEDULE_STANDING_ORDER_SUCCESS =
  "SCHEDULE_STANDING_ORDER_SUCCESS";
export const SCHEDULE_STANDING_ORDER_ERROR = "SCHEDULE_STANDING_ORDER_ERROR";
