/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020,2021
 *
 *==================================================
 */
//Standard libraries
import { useRef, RefObject } from "react";
import styled from "styled-components";
import { ClickAwayListener } from "@material-ui/core";
//style
import {
  StyledBox,
  StyledButton,
  StyledTypography,
  StyledPopper,
} from "@hcl-commerce-store-sdk/react-component";
//custom
import OneTierMenu from "./OneTierMenu";
import { KeyboardArrowDown } from "@material-ui/icons";
import { StyledLink } from "../../elements/link";

const HoverButton = styled(StyledButton)`
  &.has-children {
    cursor: default;
    .MuiButton-label {
      color: ${(props) => props.theme.palette.info.contrastText};
    }
  }
  svg {
    margin-left: 0.5rem;
    color: ${(props) => props.theme.palette.neutral.dark};
  }
`;

const MenubarMenuItemHover = (props) => {
  const { page, selectMenuItem, selectedMenuItem } = props;
  const popperRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const handleClickAway = (event) => {
    const target = event.target;
    if (popperRef.current && (popperRef.current.contains(target) || popperRef.current === target)) {
      return;
    }
    if (selectedMenuItem?.id === page?.id) {
      selectMenuItem(null);
    }
  };

  const POPPER_ID = `MENU_POPPER_${page.id}`;
  const setWidth = (data) => {
    const { width } = data.offsets.reference;
    if (width > data.popper.width) {
      data.styles.width = width;
    }
    return data;
  };
  const onMouseOver = () => selectMenuItem(page.id);

  const content = (
    <HoverButton
      testId={`header-${page.id}`}
      variant="text"
      onMouseOver={onMouseOver}
      className={`${selectedMenuItem?.id === page.id ? "selected" : ""} ${
        page?.children?.length > 0 ? "has-children" : ""
      }`}
      ref={popperRef}
    >
      <StyledTypography>
        <strong>{page.name}</strong>
      </StyledTypography>
      {page?.children?.length > 0 ? <KeyboardArrowDown /> : null}
    </HoverButton>
  );

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <StyledBox mr={1} ml={1} display="flex" onMouseLeave={() => selectMenuItem(null)}>
        {page?.seo?.href ? (
          <StyledLink {...page?.seo} to={page?.seo?.href} style={{ display: "flex" }}>
            {content}
          </StyledLink>
        ) : (
          content
        )}
        {page?.children?.length > 0 ? (
          <StyledPopper
            id={POPPER_ID}
            data-testid={POPPER_ID}
            open={selectedMenuItem !== null && selectedMenuItem.id === page.id}
            anchorEl={popperRef.current}
            placement="bottom-start"
            disablePortal
            modifiers={{
              flip: {
                enabled: false,
              },
              preventOverflow: {
                enabled: false,
                boundariesElement: "scrollParent",
              },
              hide: {
                enabled: false,
              },
              updateWidth: {
                enabled: true,
                order: 875,
                fn: setWidth,
              },
            }}
          >
            <StyledBox>
              <OneTierMenu page={page} />
            </StyledBox>
          </StyledPopper>
        ) : null}
      </StyledBox>
    </ClickAwayListener>
  );
};

export default MenubarMenuItemHover;
