/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
 import styled from "styled-components";


   const StyledRowCard = styled(({ ...props }) => <div {...props} />)`
     ${({ theme }) => `
       border-radius: 16px;
       padding: 20px 26px;
       transition: background-color 0.2s ease-in-out;
       &:hover {
            background-color: ${theme.palette.background.default};
            cursor: pointer;
       }
       &.selected {
            background-color: ${theme.palette.background.default};
       }
     `}
   `;

 
 export { StyledRowCard };
 