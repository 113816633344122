/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { UserInfoDetail } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCUserRegApi - axios parameter creator
 * @export
 */
export const CHCUserRegApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get logonId and Organization DN from HCL DB
         * @summary Get UserId and Organization DN.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [orgName] 
         * @param {string} [zipCode] 
         * @param {string} [gCaptchaToken] 
         * @param {string} [isReferral] 
         * @param {string} [province] 
         * @param {string} [referralCode] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [address1] 
         * @param {string} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCUserRegInfo: async (storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, zipCode?: string, gCaptchaToken?: string, isReferral?: string, province?: string, referralCode?: string, email?: string, phone?: string, address1?: string, city?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCUserRegInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/chc_organization/validateUserInfo`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (orgName !== undefined) {
                localVarQueryParameter['orgName'] = orgName;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (gCaptchaToken !== undefined) {
                localVarQueryParameter['gCaptchaToken'] = gCaptchaToken;
            }

            if (isReferral !== undefined) {
                localVarQueryParameter['isReferral'] = isReferral;
            }

            if (province !== undefined) {
                localVarQueryParameter['province'] = province;
            }

            if (referralCode !== undefined) {
                localVarQueryParameter['referralCode'] = referralCode;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (address1 !== undefined) {
                localVarQueryParameter['address1'] = address1;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCUserRegApi - functional programming interface
 * @export
 */
export const CHCUserRegApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCUserRegApiAxiosParamCreator(configuration)
    return {
        /**
         * Get logonId and Organization DN from HCL DB
         * @summary Get UserId and Organization DN.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [orgName] 
         * @param {string} [zipCode] 
         * @param {string} [gCaptchaToken] 
         * @param {string} [isReferral] 
         * @param {string} [province] 
         * @param {string} [referralCode] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [address1] 
         * @param {string} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCUserRegInfo(storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, zipCode?: string, gCaptchaToken?: string, isReferral?: string, province?: string, referralCode?: string, email?: string, phone?: string, address1?: string, city?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfoDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCUserRegInfo(storeId, responseFormat, firstName, lastName, orgName, zipCode, gCaptchaToken, isReferral, province, referralCode, email, phone, address1, city, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCUserRegApi - factory interface
 * @export
 */
export const CHCUserRegApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCUserRegApiFp(configuration)
    return {
        /**
         * Get logonId and Organization DN from HCL DB
         * @summary Get UserId and Organization DN.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [orgName] 
         * @param {string} [zipCode] 
         * @param {string} [gCaptchaToken] 
         * @param {string} [isReferral] 
         * @param {string} [province] 
         * @param {string} [referralCode] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {string} [address1] 
         * @param {string} [city] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCUserRegInfo(storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, zipCode?: string, gCaptchaToken?: string, isReferral?: string, province?: string, referralCode?: string, email?: string, phone?: string, address1?: string, city?: string, options?: any): AxiosPromise<UserInfoDetail> {
            return localVarFp.cHCUserRegInfo(storeId, responseFormat, firstName, lastName, orgName, zipCode, gCaptchaToken, isReferral, province, referralCode, email, phone, address1, city, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCUserRegApi - object-oriented interface
 * @export
 * @class CHCUserRegApi
 * @extends {BaseAPI}
 */
export class CHCUserRegApi extends BaseAPI {
    /**
     * Get logonId and Organization DN from HCL DB
     * @summary Get UserId and Organization DN.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml.
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [orgName] 
     * @param {string} [zipCode] 
     * @param {string} [gCaptchaToken] 
     * @param {string} [isReferral] 
     * @param {string} [province] 
     * @param {string} [referralCode] 
     * @param {string} [email] 
     * @param {string} [phone] 
     * @param {string} [address1] 
     * @param {string} [city] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCUserRegApi
     */
    public cHCUserRegInfo(storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, zipCode?: string, gCaptchaToken?: string, isReferral?: string, province?: string, referralCode?: string, email?: string, phone?: string, address1?: string, city?: string, options?: any) {
        return CHCUserRegApiFp(this.configuration).cHCUserRegInfo(storeId, responseFormat, firstName, lastName, orgName, zipCode, gCaptchaToken, isReferral, province, referralCode, email, phone, address1, city, options).then((request) => request(this.axios, this.basePath));
    }
}
