/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//Custom libraries
import { useUploadReqisitionList } from "../../../_foundation/hooks/use-upload-requisition-lists";
import {
  CHC_WIDGETS_DIR,
  EMPTY_STRING,
  STATIC_ASSET_ROOT,
} from "../../../constants/common";
import addressUtil from "../../../utils/addressUtil";
//Foundation
import { localStorageUtil } from "../../../_foundation/utils/storageUtil";
import { LOCALE } from "../../../_foundation/constants/common";
//UI
import {
  StyledGrid,
  StyledButton,
  StyledDialogContentText,
  StyledFormControl,
  StyledSelect,
  StyledDialog,
} from "@hcl-commerce-store-sdk/react-component";
import DialogContent from "@mui/material/DialogContent/DialogContent";
//cookies
import Cookies from "js-cookie";
import { SURGERY_CENTER, DEALER, VETERINARY, LTC, HOSPITALS, DENTISTS, PHYSICIANS, DEFAULT } from "../../../constants/routes";
import { COOKIE_EXPIRE_DAYS } from "../../../constants/common";

import { useNavigate } from "react-router";

export const redirectWithSegmentParam = (segment) => {
  const currentURL = window.location.href;
  const value = encodeURI(segment);
  const kvp = document.location.search.substr(1).split("&");
  if (!kvp) {
    if (currentURL.indexOf("/chc") === -1) {
      //For appending langId and store to URL if it doesn't have.
      const urlBase = window.location.href.split("?")[0];
      window.location.href = urlBase + "?" + "segment-selected" + "=" + value;
    } else {
      document.location.search = "?" + "segment-selected" + "=" + value;
    }
  } else {
    let i = kvp.length;
    let x;
    while (i--) {
      x = kvp[i].split("=");
      if (x[0] === "segment-selected") {
        x[1] = value;
        kvp[i] = x.join("=");
        break;
      }
    }
    if (i < 0) {
      kvp[kvp.length] = ["segment-selected", value].join("=");
    }
    document.location.search = kvp.join("");
  }
};

/**
 * Create new order widget for inprogress orders
 *
 * @returns create inprogress order widget
 */
export default function VisitorSegmentation(props) {
  const { t } = useTranslation();
  const [segment, setSegment] = useState<string>(HOSPITALS);
  const handleClose = () => {
    props.setOpenWidget(false);
  };
  const navigate = useNavigate();
  const addWidget = () => {
    Cookies.set("segment-selected", segment, { expires: COOKIE_EXPIRE_DAYS });
    navigate(segment);
    props.setOpenWidget(false);
    //redirectWithSegmentParam(segment);
    
  };
  
  return (
    <div>
      <StyledDialog
        open={props.openWidget}
        maxWidth={"xs"}
        justify-content="centre"
        onClose={handleClose}
      >
        <DialogContent>
          <StyledGrid>
            <StyledDialogContentText>
              {t("Widget.Title")}
            </StyledDialogContentText>

            <StyledGrid
              container
              direction="column"
              item
              spacing={3}
              justifyContent="space-between"
            >
              <StyledGrid item xs={12} sm={6}>
                <StyledFormControl variant="outlined" fullWidth>
                  <StyledSelect
                    native
                    data-testid="segment"
                    intialvalue={HOSPITALS}
                    name="segment"
                    fullWidth
                    value={segment}
                    onChange={(e) => {
                      setSegment(e.target.value)}}
                  >
                    <option value={HOSPITALS}> {t("Widget.Acute")}</option>
                    <option value={LTC}>{t("Widget.LTC")}</option>
                    <option value={DEALER}>{t("Widget.Dealer")}</option>
                    <option value={SURGERY_CENTER}>{t("Widget.SurgeryCenter")}</option>
                    <option value={PHYSICIANS}>{t("Widget.Clinic")}</option>
                    <option value={VETERINARY}>{t("Widget.Veterinary")}</option>
                    <option value={DENTISTS}>{t("Widget.Dentist")}</option>
                    <option value={DEFAULT}>{t("Widget.Other")}</option>
                  </StyledSelect>
                </StyledFormControl>
              </StyledGrid>

              <StyledGrid
                container
                item
                spacing={2}
                justifyContent="flex-start"
              >
                <StyledButton
                  color="primary"
                  testId="ok-widget"
                  onClick={addWidget}
                >
                  {t("Widget.Ok")}
                </StyledButton>
                {/* <StyledButton color="primary" testId= "cancel-widget" >Cancel</StyledButton> */}
              </StyledGrid>
            </StyledGrid>
          </StyledGrid>
        </DialogContent>
      </StyledDialog>
    </div>
  );
}
