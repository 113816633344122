/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * CHCPaymentMethodAddCardApi - axios parameter creator
 * @export
 */
export const CHCPaymentMethodAddCardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user\'s card Details.
         * @summary Get user\'s card.
         * @param {string} storeId The store identifier.
         * @param {string} acctNumber The account number.
         * @param {string} expMonth Expiry month of creditcard.
         * @param {string} expYear Expiry year of creditcard.
         * @param {string} name The name of card.
         * @param {string} cvv Cvv of card.
         * @param {string} address1 Address of the cardholder.
         * @param {string} city City.
         * @param {string} state State.
         * @param {string} country Country.
         * @param {string} zipCode Zipcode.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [uRL] URL.
         * @param {string} [errorView] errorView.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcPersonAddNewCard: async (storeId: string, acctNumber: string, expMonth: string, expYear: string, name: string, cvv: string, address1: string, city: string, state: string, country: string, zipCode: string, responseFormat?: string, uRL?: string, errorView?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'storeId', storeId)
            // verify required parameter 'acctNumber' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'acctNumber', acctNumber)
            // verify required parameter 'expMonth' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'expMonth', expMonth)
            // verify required parameter 'expYear' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'expYear', expYear)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'name', name)
            // verify required parameter 'cvv' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'cvv', cvv)
            // verify required parameter 'address1' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'address1', address1)
            // verify required parameter 'city' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'city', city)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'state', state)
            // verify required parameter 'country' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'country', country)
            // verify required parameter 'zipCode' is not null or undefined
            assertParamExists('chcPersonAddNewCard', 'zipCode', zipCode)
            const localVarPath = `/store/{storeId}/chc_person/addNewCard`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (acctNumber !== undefined) {
                localVarQueryParameter['acctNumber'] = acctNumber;
            }

            if (expMonth !== undefined) {
                localVarQueryParameter['expMonth'] = expMonth;
            }

            if (expYear !== undefined) {
                localVarQueryParameter['expYear'] = expYear;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (cvv !== undefined) {
                localVarQueryParameter['cvv'] = cvv;
            }

            if (address1 !== undefined) {
                localVarQueryParameter['address1'] = address1;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (country !== undefined) {
                localVarQueryParameter['country'] = country;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (uRL !== undefined) {
                localVarQueryParameter['URL'] = uRL;
            }

            if (errorView !== undefined) {
                localVarQueryParameter['errorView'] = errorView;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCPaymentMethodAddCardApi - functional programming interface
 * @export
 */
export const CHCPaymentMethodAddCardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCPaymentMethodAddCardApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user\'s card Details.
         * @summary Get user\'s card.
         * @param {string} storeId The store identifier.
         * @param {string} acctNumber The account number.
         * @param {string} expMonth Expiry month of creditcard.
         * @param {string} expYear Expiry year of creditcard.
         * @param {string} name The name of card.
         * @param {string} cvv Cvv of card.
         * @param {string} address1 Address of the cardholder.
         * @param {string} city City.
         * @param {string} state State.
         * @param {string} country Country.
         * @param {string} zipCode Zipcode.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [uRL] URL.
         * @param {string} [errorView] errorView.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcPersonAddNewCard(storeId: string, acctNumber: string, expMonth: string, expYear: string, name: string, cvv: string, address1: string, city: string, state: string, country: string, zipCode: string, responseFormat?: string, uRL?: string, errorView?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcPersonAddNewCard(storeId, acctNumber, expMonth, expYear, name, cvv, address1, city, state, country, zipCode, responseFormat, uRL, errorView, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCPaymentMethodAddCardApi - factory interface
 * @export
 */
export const CHCPaymentMethodAddCardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCPaymentMethodAddCardApiFp(configuration)
    return {
        /**
         * Get user\'s card Details.
         * @summary Get user\'s card.
         * @param {string} storeId The store identifier.
         * @param {string} acctNumber The account number.
         * @param {string} expMonth Expiry month of creditcard.
         * @param {string} expYear Expiry year of creditcard.
         * @param {string} name The name of card.
         * @param {string} cvv Cvv of card.
         * @param {string} address1 Address of the cardholder.
         * @param {string} city City.
         * @param {string} state State.
         * @param {string} country Country.
         * @param {string} zipCode Zipcode.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [uRL] URL.
         * @param {string} [errorView] errorView.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcPersonAddNewCard(storeId: string, acctNumber: string, expMonth: string, expYear: string, name: string, cvv: string, address1: string, city: string, state: string, country: string, zipCode: string, responseFormat?: string, uRL?: string, errorView?: string, options?: any): AxiosPromise<string> {
            return localVarFp.chcPersonAddNewCard(storeId, acctNumber, expMonth, expYear, name, cvv, address1, city, state, country, zipCode, responseFormat, uRL, errorView, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCPaymentMethodAddCardApi - object-oriented interface
 * @export
 * @class CHCPaymentMethodAddCardApi
 * @extends {BaseAPI}
 */
export class CHCPaymentMethodAddCardApi extends BaseAPI {
    /**
     * Get user\'s card Details.
     * @summary Get user\'s card.
     * @param {string} storeId The store identifier.
     * @param {string} acctNumber The account number.
     * @param {string} expMonth Expiry month of creditcard.
     * @param {string} expYear Expiry year of creditcard.
     * @param {string} name The name of card.
     * @param {string} cvv Cvv of card.
     * @param {string} address1 Address of the cardholder.
     * @param {string} city City.
     * @param {string} state State.
     * @param {string} country Country.
     * @param {string} zipCode Zipcode.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [uRL] URL.
     * @param {string} [errorView] errorView.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCPaymentMethodAddCardApi
     */
    public chcPersonAddNewCard(storeId: string, acctNumber: string, expMonth: string, expYear: string, name: string, cvv: string, address1: string, city: string, state: string, country: string, zipCode: string, responseFormat?: string, uRL?: string, errorView?: string, options?: any) {
        return CHCPaymentMethodAddCardApiFp(this.configuration).chcPersonAddNewCard(storeId, acctNumber, expMonth, expYear, name, cvv, address1, city, state, country, zipCode, responseFormat, uRL, errorView, options).then((request) => request(this.axios, this.basePath));
    }
}
