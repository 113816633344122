/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Modify if needed!, the file is not generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import {
  OrdersApi,
  OrderDetailsApi,
  CHCPackSlipApi,
  PaymentMethodListApi,
  OrderUpdateApi,
  GetOrdPayInfoApi,
  UpdateActionDateApi,
  UpdateOrderStatusApi,
  SavePOApi,
  GetCommerceOrderIdApi,
} from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../../constants/site";
import { chunk } from "lodash-es";

const searchOrderApiInstance = new OrdersApi(
  undefined,
  site.transactionContext
);
const orderDetailApiInstance = new OrderDetailsApi(
  undefined,
  site.transactionContext
);
const orderPackSlipApiInstance = new CHCPackSlipApi(
  undefined,
  site.transactionContext
);
const PaymentMethodListApiInstance = new PaymentMethodListApi(
  undefined,
  site.transactionContext
);

const OrderUpdateApiApiInstance = new OrderUpdateApi(
  undefined,
  site.transactionContext
);

const GetOrdPayInfoApiInstance = new GetOrdPayInfoApi(
  undefined,
  site.transactionContext
);

const UpdateActionDateApiInstance = new UpdateActionDateApi(
  undefined,
  site.transactionContext
);

const UpdateOrderStatusApiInstance = new UpdateOrderStatusApi(
  undefined,
  site.transactionContext
);

const SavePOApiInstance = new SavePOApi(undefined, site.transactionContext);

const GetCommerceOrderApiInstance = new GetCommerceOrderIdApi(undefined, site.transactionContext);

const PAGE_SIZE = 100;

const chcSearchOrdersService = {
  /**
   * This APi fetches orders from JDE.
   * `@method`
   * `@name getOrderHistoryList`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get order history from price server.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to get order history.
   */
  findJDEOrders(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      billingAccount,
      accounts,
      duration,
      startDate,
      endDate,
      orderStatus,
      orderNumberType,
      orderNumber,
      productNumber,
      responseFormat,
      ...options
    } = parameters;

    return new Promise((resolve) =>
      Promise.all(
        chunk(accounts?.split(','), PAGE_SIZE).map((accounts) =>
        searchOrderApiInstance.cHCSearchOrders(
          storeId,
          accounts.join(','),
          responseFormat,
          billingAccount,
          duration,
          startDate,
          endDate,
          orderStatus,
          orderNumberType,
          orderNumber,
          productNumber,
          options
        )
        )
      ).then((responses: any) =>
        resolve({
          ...responses[0],
          data: responses.reduce(
            ({ orderHistoryDataBeans }, { data }) => ({
              orderHistoryDataBeans: [...orderHistoryDataBeans, ...(data.orderHistoryDataBeans ?? [])],
            }),
            { orderHistoryDataBeans: [] }
          ),
        })
      )
    );
  },

  /**
   * This APi fetches orders details from JDE.
   * `@method`
   * `@name getOrderDetails`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get order details from price server.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to get order details
   */
  findJDEOrderDetails(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      shippingAccount,
      statusLine,
      orderId,
      orderType,
      kubraPassCode,
      kubraPassCodeInvoice,
      responseFormat,
      billingAccount,
      ...options
    } = parameters;

    return orderDetailApiInstance.cHCSearchOrderDetail(
      storeId,
      shippingAccount,
      responseFormat,
      orderId,
      billingAccount,
      statusLine,
      orderType,
      kubraPassCode,
      kubraPassCodeInvoice,
      options
    );
  },

  /**
   * This APi sends packingslip information from JDE.
   * `@method`
   * `@name sendPackSlip`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get order details from price server.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to get order details
   */
  sendPackSlipDetails(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      responseFormat,
      email,
      orderNumber,
      orderType,
      pckSlipArrayString,
      orderStatus,
      ...options
    } = parameters;

    return orderPackSlipApiInstance.cHCSendPackSlip(
      storeId,
      responseFormat,
      email,
      orderNumber,
      orderType,
      pckSlipArrayString,
      orderStatus,
      options
    );
  },
  /**
   * This APi fetches PaymentMethodList.
   * `@method`
   * `@name PaymentMethodList`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get order details from price server.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to get order details
   */
  GetPaymentMethodList(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      responseFormat,
      ...options
    } = parameters;

    return PaymentMethodListApiInstance.cHCPaymentMethodList(
      storeId,
      responseFormat,
      options
    );
  },
  /**
   * This APi POST Order Update values
   * `@method`
   * `@name OrderUpdate`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat
   ** `@property {string} storeId (required)` The child property of `Parameters`.
   ** `@property {string} OrderId (required)` The child property of `Parameters`.
   **`@property {string} orderDesc
   **`@property {string} orderComment
   **`@property {string} field3
   **`@property {string} yearEnd
   **`@property {string} customDate
   **`@property {string} customDate
   */
  OrderUpdate(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      OrderId,
      responseFormat,
      orderDesc,
      orderComment,
      field3,
      yearEnd,
      customDate,
      ...options
    } = parameters;

    return OrderUpdateApiApiInstance.orderUpdate(
      storeId,
      OrderId,
      responseFormat,
      orderDesc,
      orderComment,
      field3,
      yearEnd,
      customDate,
      options
    );
  },

  GetOrdPayInfo(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      orderId,
      responseFormat,
      ...options
    } = parameters;

    return GetOrdPayInfoApiInstance.orderPayInfo(
      storeId,
      orderId,
      responseFormat,
      options
    );
  },

  UpdateActionDate(parameters: any): AxiosPromise<any> {
    console.log("Calling action date", parameters);
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      actionDate,
      subscriptionId,
      responseFormat,
      ...options
    } = parameters;

    return UpdateActionDateApiInstance.updateNextActionDate(
      storeId,
      actionDate,
      subscriptionId,
      responseFormat,
      options
    );
  },

  UpdateOrderStatus(parameters: any): AxiosPromise<any> {
    console.log("Called this function");
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      orderStatus,
      subscriptionId,
      responseFormat,
      resumeDate,
      ...options
    } = parameters;

    return UpdateOrderStatusApiInstance.updateStandingOrder(
      storeId,
      orderStatus,
      subscriptionId,
      responseFormat,
      resumeDate,
      options
    );
  },

  SavePO(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      orderId,
      poNumber,
      responseFormat,
      orderType,
      ...options
    } = parameters;

    return SavePOApiInstance.savePurchaseOrder(
      storeId,
      orderId,
      poNumber,
      responseFormat,
      orderType,
      options
    );
  },

  GetInternalOrderId(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const {
      storeId = siteInfo?.storeID,
      orderNumber,
      responseFormat,
      ...options
    } = parameters;

    return GetCommerceOrderApiInstance.getHCLCommerceOrderId(
      storeId,
      orderNumber,
      responseFormat,
      options
    );
  },
};

export default chcSearchOrdersService;
