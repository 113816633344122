/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
 export const FETCH_CHC_ORDER_DETAILS = "FETCH_CHC_ORDER_DETAILS";
 export const FETCH_CHC_ORDER_DETAILS_SUCCESS = "FETCH_CHC_ORDER_DETAILS_SUCCESS";
 export const FETCH_CHC_ORDER_DETAILS_RESET = "FETCH_CHC_ORDER_DETAILS_RESET";
 export const FETCH_CHC_ORDER_DETAILS_FAIL = "FETCH_CHC_ORDER_DETAILS_FAIL";
 export const FETCH_CHC_ORDER_ITEMS_SUCCESS = "FETCH_CHC_ORDER_ITEMS_SUCCESS";
 export const FETCH_CHC_ORDER_ITEMS_FAIL = "FETCH_CHC_ORDER_ITEMS_FAIL";
 