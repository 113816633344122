/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
import { createTheme } from "@material-ui/core/styles";
import { sharedOverrides, merge, responsiveFontSizes } from "./shared-theme";
import { palette } from "./color-palette";

const { main, light, dark } = palette.chc;

export const chcOverrides = {
  name: "chc",
  palette: {
    primary: {
      light: light,
      main: main,
      dark: dark,
      contrastText: "#fff",
    },
    secondary: {
      light: light,
      main: main,
      dark: dark,
      contrastText: "#fff",
    },
    info: {
      light: '#bfe5e8',
      main: '#90d4d9',
      dark: '#64c8ce',
      contrastText: "#000",
    },
    dark: {
      light: '#58r85b',
      main: '#000',
      dark: '#000',
      contrastText: "#fff",
    },
    neutral: {
      light: '#f5f5f5',
      main: palette.background.default,
      dark: '#bdbdbd',
      contrastText: "#000",
      contrastTextDark: "#948a85",
    }
  },
  button: {
    backgroundColor: main,
    "&:hover": {
      backgroundColor: main,
    },
  },
  child: {
    backgroundColor: main,
  },
  rippleVisible: {
    opacity: 0.5,
  },
  typography: {
    h2: {
      fontSize: 42,
    },
    h3: {
      fontSize: 30,
      fontWeight: 700,
    },
    h5: {
      fontSize: 20,
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 20,
      color: palette.text.disabled,
      lineHeight: 1.2,
    },
    subtitle2: {
      fontSize: 16,
      color: palette.text.disabled,
      fontWeight: 400,
    },
    overline: {
      color: palette.text.disabled,
    }
  }
};

const combinedOverides = merge(sharedOverrides, chcOverrides);
const theme = responsiveFontSizes(createTheme(combinedOverides));

export default theme;
