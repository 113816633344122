/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Redux
import { PARTNUM_OVR_ATTR } from "../../constants/common";
import { RootReducerState } from "../reducers";

const entitledOrgSelector = (state: RootReducerState) => {
  //return state.organization.entitledOrganizations;
  return state.organization.chcEntitledOrganizations;
};
const activeOrgSelector = (state: RootReducerState) => {
  return state.context.entitlement?.activeOrganizationId;
};

const activeSegment = (state: RootReducerState) => {
  return state.organization.attributeValue;
};
const loyaltyPoint = (state: RootReducerState) => {
  return state.organization.LoyaltyAttribute?.attributeValue ?? '';
};
const billingAddressIdSelector = (state: RootReducerState) => {
  return state.account.address?.addressId;
};

const organizationDetailsSelector = (state: RootReducerState) => {
  return state.organization.organizationDetails;
};

const billingIdSelector = (state: RootReducerState) => {
  return state.organization.organizationDetails?.billing;
};

const acceleratePgmSelector = (state: RootReducerState) => {
  return state.organization.dealerIncentiveProgram;
};

const userTypeSelector = (state: RootReducerState) => {
  return state.context.basicInfo?.registerType;
};

const isStandingOrderSelector = (state: RootReducerState) => {
  return state.organization.allowStandingOrder;
};

const isOrderApprovalSelector = (state: RootReducerState) => {
  return state.organization.orderApproval;
};

const isEmployeePurchaseSelector = (state: RootReducerState) => {
  return state.organization.employeeOrg;
};
const assignedUserRolesSelector = (state: RootReducerState) => {
  return state.organization.assignedRoles ?? [];
}

const orgChangeSelector = (state: RootReducerState) => {
  return state.organization.changeOrg ?? {};
}

const orgCPNKeySelector = (state: RootReducerState) => {
  const key = state.organization?.CPNAttribute?.attributeValue ?? ''
  return {key, attribute: `${PARTNUM_OVR_ATTR}_${key}`};
}

const legalIdSelector = (state: RootReducerState) => {
  return state.organization.legalId;
}

const notificationSelector = (state: RootReducerState) => {
  return state.organization.NotificationsSummary ?? {};
}


export {
  entitledOrgSelector,
  activeOrgSelector,
  organizationDetailsSelector,
  billingAddressIdSelector,
  activeSegment,
  loyaltyPoint,
  billingIdSelector,
  acceleratePgmSelector,
  userTypeSelector,
  isStandingOrderSelector,
  isEmployeePurchaseSelector,
  assignedUserRolesSelector,
  orgChangeSelector,
  orgCPNKeySelector,
  isOrderApprovalSelector,
  legalIdSelector,
  notificationSelector
};
