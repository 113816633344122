import {
  StyledProgressPlaceholder,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import { useDateFormatter } from "../../../_foundation/hooks/use-date-formatter-chc";

export const AccelerateAccount: FC = () => {


  const { t } = useTranslation();
  const { data, loading } = useContext(AccelerateProgramContext);
  const dateFormatter = useDateFormatter();
  return loading ? (
    <StyledProgressPlaceholder />
  ) : (
    <Stack alignItems="flex-end">
      <StyledTypography variant="h3">{data?.CustomerName}</StyledTypography>
      <StyledTypography variant="subtitle2" color="primary">
        {t("DealerIncentiveProgram.MEMBER_SINCE", [dateFormatter(data?.EnrollmentDate)])}
      </StyledTypography>
      <StyledTypography variant="subtitle2" color="inherit">
        {t("DealerIncentiveProgram.ID")}
        <strong>{data?.ABK}</strong>
      </StyledTypography>
    </Stack>
  );
};
