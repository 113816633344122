/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const headerConfig = {
  page: {
    name: "header",
  },
  espot: {
    eSpotName: "HeaderStoreLogo_Content",
  },
};

export const menuConfig = {
  page: {
    name: "menu",
  },
  espot: {
    eSpotName: "MenuStaticLink_Content",
  },
};
