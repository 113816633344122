/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { OrganizationRegistration } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCOrgRegApi - axios parameter creator
 * @export
 */
export const CHCOrgRegApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get SalesForce Response 
         * @summary Get SF response on CHC Organization registration.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [orgName] 
         * @param {string} [postalCode] 
         * @param {string} [gCaptchaToken] 
         * @param {string} [gstHstNumber] 
         * @param {string} [legalOrgName] 
         * @param {string} [orgType] 
         * @param {string} [gclidValue] 
         * @param {string} [mclidValue] 
         * @param {string} [email] 
         * @param {string} [phoneNo] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [otherInfo] 
         * @param {string} [city] 
         * @param {string} [province] 
         * @param {string} [isbusiness] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCOrganizationRegistration: async (storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, postalCode?: string, gCaptchaToken?: string, gstHstNumber?: string, legalOrgName?: string, orgType?: string, gclidValue?: string, mclidValue?: string, email?: string, phoneNo?: string, addressLine1?: string, addressLine2?: string, otherInfo?: string, city?: string, province?: string, isbusiness?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cHCOrganizationRegistration', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/chc_organization/registerOrganization`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['firstName'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['lastName'] = lastName;
            }

            if (orgName !== undefined) {
                localVarQueryParameter['orgName'] = orgName;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (gCaptchaToken !== undefined) {
                localVarQueryParameter['gCaptchaToken'] = gCaptchaToken;
            }

            if (gstHstNumber !== undefined) {
                localVarQueryParameter['gstHstNumber'] = gstHstNumber;
            }

            if (legalOrgName !== undefined) {
                localVarQueryParameter['legalOrgName'] = legalOrgName;
            }

            if (orgType !== undefined) {
                localVarQueryParameter['orgType'] = orgType;
            }

            if (gclidValue !== undefined) {
                localVarQueryParameter['gclidValue'] = gclidValue;
            }

            if (mclidValue !== undefined) {
                localVarQueryParameter['mclidValue'] = mclidValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phoneNo !== undefined) {
                localVarQueryParameter['phoneNo'] = phoneNo;
            }

            if (addressLine1 !== undefined) {
                localVarQueryParameter['addressLine1'] = addressLine1;
            }

            if (addressLine2 !== undefined) {
                localVarQueryParameter['addressLine2'] = addressLine2;
            }

            if (otherInfo !== undefined) {
                localVarQueryParameter['otherInfo'] = otherInfo;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (province !== undefined) {
                localVarQueryParameter['province'] = province;
            }

            if (isbusiness !== undefined) {
                localVarQueryParameter['isbusiness'] = isbusiness;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCOrgRegApi - functional programming interface
 * @export
 */
export const CHCOrgRegApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCOrgRegApiAxiosParamCreator(configuration)
    return {
        /**
         * Get SalesForce Response 
         * @summary Get SF response on CHC Organization registration.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [orgName] 
         * @param {string} [postalCode] 
         * @param {string} [gCaptchaToken] 
         * @param {string} [gstHstNumber] 
         * @param {string} [legalOrgName] 
         * @param {string} [orgType] 
         * @param {string} [gclidValue] 
         * @param {string} [mclidValue] 
         * @param {string} [email] 
         * @param {string} [phoneNo] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [otherInfo] 
         * @param {string} [city] 
         * @param {string} [province] 
         * @param {string} [isbusiness] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cHCOrganizationRegistration(storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, postalCode?: string, gCaptchaToken?: string, gstHstNumber?: string, legalOrgName?: string, orgType?: string, gclidValue?: string, mclidValue?: string, email?: string, phoneNo?: string, addressLine1?: string, addressLine2?: string, otherInfo?: string, city?: string, province?: string, isbusiness?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationRegistration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cHCOrganizationRegistration(storeId, responseFormat, firstName, lastName, orgName, postalCode, gCaptchaToken, gstHstNumber, legalOrgName, orgType, gclidValue, mclidValue, email, phoneNo, addressLine1, addressLine2, otherInfo, city, province, isbusiness, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCOrgRegApi - factory interface
 * @export
 */
export const CHCOrgRegApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCOrgRegApiFp(configuration)
    return {
        /**
         * Get SalesForce Response 
         * @summary Get SF response on CHC Organization registration.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml.
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [orgName] 
         * @param {string} [postalCode] 
         * @param {string} [gCaptchaToken] 
         * @param {string} [gstHstNumber] 
         * @param {string} [legalOrgName] 
         * @param {string} [orgType] 
         * @param {string} [gclidValue] 
         * @param {string} [mclidValue] 
         * @param {string} [email] 
         * @param {string} [phoneNo] 
         * @param {string} [addressLine1] 
         * @param {string} [addressLine2] 
         * @param {string} [otherInfo] 
         * @param {string} [city] 
         * @param {string} [province] 
         * @param {string} [isbusiness] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cHCOrganizationRegistration(storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, postalCode?: string, gCaptchaToken?: string, gstHstNumber?: string, legalOrgName?: string, orgType?: string, gclidValue?: string, mclidValue?: string, email?: string, phoneNo?: string, addressLine1?: string, addressLine2?: string, otherInfo?: string, city?: string, province?: string, isbusiness?: string, options?: any): AxiosPromise<OrganizationRegistration> {
            return localVarFp.cHCOrganizationRegistration(storeId, responseFormat, firstName, lastName, orgName, postalCode, gCaptchaToken, gstHstNumber, legalOrgName, orgType, gclidValue, mclidValue, email, phoneNo, addressLine1, addressLine2, otherInfo, city, province, isbusiness, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCOrgRegApi - object-oriented interface
 * @export
 * @class CHCOrgRegApi
 * @extends {BaseAPI}
 */
export class CHCOrgRegApi extends BaseAPI {
    /**
     * Get SalesForce Response 
     * @summary Get SF response on CHC Organization registration.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml.
     * @param {string} [firstName] 
     * @param {string} [lastName] 
     * @param {string} [orgName] 
     * @param {string} [postalCode] 
     * @param {string} [gCaptchaToken] 
     * @param {string} [gstHstNumber] 
     * @param {string} [legalOrgName] 
     * @param {string} [orgType] 
     * @param {string} [gclidValue] 
     * @param {string} [mclidValue] 
     * @param {string} [email] 
     * @param {string} [phoneNo] 
     * @param {string} [addressLine1] 
     * @param {string} [addressLine2] 
     * @param {string} [otherInfo] 
     * @param {string} [city] 
     * @param {string} [province] 
     * @param {string} [isbusiness] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCOrgRegApi
     */
    public cHCOrganizationRegistration(storeId: string, responseFormat?: string, firstName?: string, lastName?: string, orgName?: string, postalCode?: string, gCaptchaToken?: string, gstHstNumber?: string, legalOrgName?: string, orgType?: string, gclidValue?: string, mclidValue?: string, email?: string, phoneNo?: string, addressLine1?: string, addressLine2?: string, otherInfo?: string, city?: string, province?: string, isbusiness?: string, options?: any) {
        return CHCOrgRegApiFp(this.configuration).cHCOrganizationRegistration(storeId, responseFormat, firstName, lastName, orgName, postalCode, gCaptchaToken, gstHstNumber, legalOrgName, orgType, gclidValue, mclidValue, email, phoneNo, addressLine1, addressLine2, otherInfo, city, province, isbusiness, options).then((request) => request(this.axios, this.basePath));
    }
}
