/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020,2021
 *
 *==================================================
 */
//Standard libraries
import React, { useRef } from "react";
import styled from "styled-components";
import { ClickAwayListener } from "@material-ui/core";
//style
import {
  StyledBox,
  StyledButton,
  StyledTypography,
  StyledPopper,
} from "@hcl-commerce-store-sdk/react-component";
//custom
import AllCategoriesExpandedMenu from "./AllCategoriesExpandedMenu-chc";
import ExpandedMenuItem from "./ExpandedMenuItem";
import { UnfoldMore } from "@material-ui/icons";

const ExpandButton = styled(StyledButton)`
  position: relative;
  min-width: 210px;
  .MuiButton-label {
    justify-content: flex-start;
  }
  .MuiTypography-root {
    padding-right: 1rem;
    text-align: left;
  }
  svg {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const MenubarMenuItem = (props) => {
  const { page, selectMenuItem, selectedMenuItem } = props;
  const popperRef: React.RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const handleClickAway = (event) => {
    const target = event.target;
    if (popperRef.current && (popperRef.current.contains(target) || popperRef.current === target)) {
      return;
    }
    if (selectedMenuItem?.id === page?.id) {
      selectMenuItem(null);
    }
  };

  const POPPER_ID = `MENU_POPPER_${page.id}`;
  const setWidth = (data) => {
    const { width } = data.offsets.reference;
    if (width > data.popper.width) {
      data.styles.width = width;
    }
    return data;
  };
  const onMouseOver = () => selectMenuItem(page.id);

  return (
    <>
      <StyledBox
        display="flex"
        alignItems="center"
        style={{
          marginTop: "auto",
          marginBottom: "auto",
          alignSelf: "center",
        }}
      >
        {page.id === "allCategories" ? (
          <ExpandButton
            testId={`header-${page.id}`}
            variant="contained"
            size="small"
            color="primary"
            onClick={() => selectMenuItem(selectedMenuItem ? null : page?.id)}
            className={selectedMenuItem?.id === page.id ? "selected" : ""}
            ref={popperRef}
          >
            <StyledTypography>
              <strong>{page.name}</strong>
            </StyledTypography>
            <UnfoldMore />
          </ExpandButton>
        ) : (
          <StyledButton
            testId={`header-${page.id}`}
            variant="text"
            onMouseOver={onMouseOver}
            className="horizontal-padding-2 vertical-padding-2"
            ref={popperRef}
            href={page.href}
          >
            <StyledTypography
              className="expanded-menu-text"
              variant="body1"
              data-testid="menubar-menuitem-button"
            >
              {page.name}
            </StyledTypography>
          </StyledButton>
        )}
      </StyledBox>
      <StyledPopper
        id={POPPER_ID}
        data-testid={POPPER_ID}
        open={selectedMenuItem !== null && selectedMenuItem.id === page.id}
        anchorEl={popperRef.current}
        placement="bottom-start"
        disablePortal
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: false,
            boundariesElement: "scrollParent",
          },
          hide: {
            enabled: false,
          },
          updateWidth: {
            enabled: true,
            order: 875,
            fn: setWidth,
          },
        }}
        {...(page.id === "allCategories" ? {className:"categories-mega-menu-popper"} : {})}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledBox>
            {page.id === "allCategories" ? (
              <AllCategoriesExpandedMenu pages={page.children}></AllCategoriesExpandedMenu>
            ) : (
              <ExpandedMenuItem page={page}></ExpandedMenuItem>
            )}
          </StyledBox>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default MenubarMenuItem;
