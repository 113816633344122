import { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { StyledIconButton, StyledGrid } from "@hcl-commerce-store-sdk/react-component";
import { useNavigate } from "react-router-dom";
import StaticMenu from "./StaticMenu";

function MobileMenu(pages) {
  const [mainDrawerOpen, setMainDrawerOpen] = useState(false);
  const [drawerStack, setDrawerStack]: any = useState([]);
  const [currentCategory, setCurrentCategory]: any = useState(null);
  const categories = pages.pages;
  const navigate = useNavigate();

  const toogleMainDrawer = (open) => () => {
    setMainDrawerOpen(open);
    if (!open) {
      setDrawerStack([]);
      setCurrentCategory(null);
    }
  };

  const handleCategoryClickClose: any = () => {
    setMainDrawerOpen(false);
    setCurrentCategory(null);
    setDrawerStack([]);
  };

  const handleCategoryClick: any = (category) => {
    if (category?.children && category.children.length > 0) {
      setDrawerStack([...drawerStack, currentCategory]);
      setCurrentCategory(category);
    } else if (category?.seo?.href) {
      navigate(category.seo.href);
      handleCategoryClickClose();
    }
  };

  const handleBackClick = () => {
    const previousCategory = drawerStack.pop();
    setDrawerStack([...drawerStack]);
    setCurrentCategory(previousCategory);
  };

  return (
    <div>
      <button
        className="menu-hamburger"
        data-testid="menu-hamburger-element"
        onClick={toogleMainDrawer(true)}
      >
        <MenuIcon />
      </button>

      <Drawer
        anchor="left"
        open={mainDrawerOpen}
        onClose={toogleMainDrawer(false)}
        PaperProps={{
          sx: { width: "80%" },
        }}
      >
        <div style={{ height: "100%", top: "180px" }}>
          <List>
            {!currentCategory && categories.length > 0 ? (
              categories.map((category: any) => (
                <ListItem
                  button
                  key={category.id}
                  onClick={() => handleCategoryClick(category)}
                >
                  <ListItemText primary={category.name} />

                  {category.children && category.children.length > 0 && (
                    <ListItemIcon>
                      <KeyboardArrowRightIcon />
                    </ListItemIcon>
                  )}
                </ListItem>
              ))
            ) : (
              <>
                <StyledGrid item xs={12} sm="auto" container alignItems="center" justifyContent="left" className="width--auto">
              <StyledIconButton
                  color="primary"
                  size="small"
                  onClick={handleBackClick}
                  data-testid="back-icon-button"
                >
                  <ArrowBackIcon />
                </StyledIconButton>
                </StyledGrid>

                {currentCategory?.children.map((subcategory) => (
                  <ListItem
                    button
                    key={subcategory.id}
                    onClick={() => handleCategoryClick(subcategory)}
                  >
                    {" "}
                    <ListItemText primary={subcategory.name} />
                    {subcategory.children &&
                      subcategory.children.length > 0 && (
                        <ListItemIcon>
                          <KeyboardArrowRightIcon />
                        </ListItemIcon>
                      )}
                  </ListItem>
                ))}
              </>
            )}
          </List>
          <StaticMenu handleCategoryClickClose={handleCategoryClickClose} />
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={!!currentCategory}
        onClose={() => setCurrentCategory(null)}
        PaperProps={{
          sx: { width: "100%" },
        }}
      >
        <div style={{ width: "100%" }}>
          {currentCategory && (
            <><StyledGrid item xs={12} sm="auto" container alignItems="center" justifyContent="left" className="width--auto">
              <StyledIconButton
                  color="primary"
                  size="small"
                  onClick={handleBackClick}
                  data-testid="back-icon-button"
                >
                  <ArrowBackIcon />
                </StyledIconButton>
                </StyledGrid>
              <List>
                {currentCategory?.children.map((subcategory) => (
                  <ListItem
                    button
                    key={subcategory.id}
                    onClick={() => handleCategoryClick(subcategory)}
                  >
                    <ListItemText primary={subcategory.name} />

                    {subcategory.children &&
                      subcategory.children.length > 0 && (
                        <ListItemIcon>
                          <KeyboardArrowRightIcon />
                        </ListItemIcon>
                      )}
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
}
export default MobileMenu;
