/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";

//Foundation libraries
import searchAddressRequest from "../../../components/widgets/account-selection/api-fetch-address";

//Redux
import * as ACTIONS from "../../action-types/account-selection";
import {} from "../../actions/account-selection";

/**
 * Saga worker to invoke get addresse locations list
 */
export function* fetchAddressRequest(action: any) {
  const { searchTerm } = action.payload;
  try {
    const response = yield call(searchAddressRequest, searchTerm);
    const addressListData = response.data;
    yield put({
      type: ACTIONS.ADDRESS_GET_SUCCESS,
      response: addressListData,
    });
  } catch (error) {
    yield put({ type: ACTIONS.ADDRESS_GET_ERROR, error });
    console.log(error);
  }
}
