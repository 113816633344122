/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * GetOrdPayInfoApi - axios parameter creator
 * @export
 */
export const GetOrdPayInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get min Order fee for the order
         * @summary Get getOrdPayInfo.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPayInfo: async (storeId: string, orderId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('orderPayInfo', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('orderPayInfo', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/CHCorder/getOrdPayInfo`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetOrdPayInfoApi - functional programming interface
 * @export
 */
export const GetOrdPayInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetOrdPayInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * Get min Order fee for the order
         * @summary Get getOrdPayInfo.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderPayInfo(storeId: string, orderId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderPayInfo(storeId, orderId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetOrdPayInfoApi - factory interface
 * @export
 */
export const GetOrdPayInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetOrdPayInfoApiFp(configuration)
    return {
        /**
         * Get min Order fee for the order
         * @summary Get getOrdPayInfo.
         * @param {string} storeId The store identifier.
         * @param {string} orderId Order Id
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderPayInfo(storeId: string, orderId: string, responseFormat?: string, options?: any): AxiosPromise<string> {
            return localVarFp.orderPayInfo(storeId, orderId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetOrdPayInfoApi - object-oriented interface
 * @export
 * @class GetOrdPayInfoApi
 * @extends {BaseAPI}
 */
export class GetOrdPayInfoApi extends BaseAPI {
    /**
     * Get min Order fee for the order
     * @summary Get getOrdPayInfo.
     * @param {string} storeId The store identifier.
     * @param {string} orderId Order Id
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetOrdPayInfoApi
     */
    public orderPayInfo(storeId: string, orderId: string, responseFormat?: string, options?: any) {
        return GetOrdPayInfoApiFp(this.configuration).orderPayInfo(storeId, orderId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
