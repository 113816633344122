import request from "./request";
const CanadaPostKey = process.env.REACT_APP_CANADAPOSTKEY;
const searchAddressRequest = (searchTerm) =>
  request.get("/Interactive/Find/v2.10/json3.ws", {
    params: {
      key: CanadaPostKey,
      SearchTerm: searchTerm,
      Country: "CAN",
      MaxResults: 8,
    },
  });

export default searchAddressRequest;
