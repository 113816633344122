import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";
import styled from "styled-components";

const StyledCard = styled(({ ...props }) => <StyledAccelerateCard {...props} />)`
  ${({ theme }) => `
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: ${theme.palette.neutral.main};
    border-radius: 0.25rem; }
    
.icn-xs {
  height: 18px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-sm {
  height: 18px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-md {
  height: 28px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-lg {
  height: 38px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.icn-badge {
  height: 100px;
  width: auto;
  margin: 0 5px;
  display: inline-block; }

.status-tracker {
  width: 60%;
  margin: 20px auto;
  padding-top: 50px;
  padding-bottom: 140px;
  position: relative; }
  .status-tracker .progress {
    height: 5px; }
  .status-tracker .silver, .status-tracker .gold, .status-tracker .diamond {
    width: 80px;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: -40px; }
    .status-tracker .silver strong, .status-tracker .gold strong, .status-tracker .diamond strong {
      font-size: 14px; }
    .status-tracker .silver .icn-badge, .status-tracker .gold .icn-badge, .status-tracker .diamond .icn-badge {
      height: 120px; 
      margin-left : -20px;}
  .status-tracker .silver {
    left: 5%; }
  .status-tracker .gold {
    left: 25%; }
  .status-tracker .diamond {
    left: 100%; }
  .status-tracker .status {
    width: 100px;
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: -50px; }
  .status-tracker .marker {
    background-color: #fcd96d;
    z-index: 1;
    margin: 0 auto;
    margin-top: -12px;
    margin-bottom: 5px;
    height: 20px;
    width: 20px;
    border-radius: 25px;
    display: block; }
  .status-tracker .status {
    color: #fcd96d;
    font-size: 20px;
    font-weight: bold;
    margin-top: -50px;
    line-height: 1em; }
  `}
`;

export default StyledCard;
