/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import {
  Notifications,
  NotificationsApi,
  UpdateNotificationApi,
} from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { site } from "../../constants/site";


const notificationApiInstance = new NotificationsApi(
  undefined,
  site.transactionContext
);

const updateNotificationApiInstance = new UpdateNotificationApi(
  undefined,
  site.transactionContext
);
const notificationService = {
  /**
   * This APi fetches Notifications.
   * `@method`
   * `@name getShipFromDetail`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get ship from details.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to ship from details.
   */
   getNotifications(parameters: {langId: string, responseFormat?: string, offset?: string, limit?: string, unreadOnly?: string, options?: any}) {
    const siteInfo = getSite();
    const storeId =  siteInfo?.storeID || '';
    const {langId, responseFormat, offset, limit, unreadOnly, options  } = parameters;
    return notificationApiInstance.cHCnotification(
        storeId || siteInfo?.storeID || '',  
        langId,
        responseFormat,
        offset,
        limit,
        unreadOnly,
        options
    );
  },  
  updateNotifications(parameters: {messageId: string, status?: string, responseFormat?: string, options?: any}) {
    const siteInfo = getSite();
    const storeId =  siteInfo?.storeID || '';
    const { messageId, status = "read", responseFormat, options  } = parameters;
    return updateNotificationApiInstance.cHCnotificationUpdate(
        storeId,
        messageId,
        status,
        responseFormat,
        options
    );
  },  
};

export default notificationService;
