/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router";

import ContentRecommendationWidget from "../../commerce-widgets/content-recommendation-widget";
import { heroConfig } from "./heroSpotConstant";
import { useSite } from "../../../_foundation/hooks/useSite";
import { paramCase } from "change-case";
import { useTranslation } from "react-i18next";

interface HeroSpotProps {
  loggedIn: boolean;
}
const HeroSpot: React.FC<HeroSpotProps> = (props: any) => {
  const loggedIn = props.loggedIn;
  const { pathname } = useLocation();
  const [addHeroSpotLogin, setAddHeroSpotLogin] = useState(false);
  const [addHeroSpotLogout, setAddHeroSpotLogout] = useState(false);
  const valuePath = ["/physicians-clinics", "/hospitals", "/veterinary-clinics", "/acute", "/dentists", "/"];
  const { mySite } = useSite();
  const { t } = useTranslation();

  const HomepageHero = useCallback(
    ({slot}) => {
      return mySite != null ? (
        <div className="homepage-hero-banner">
          <ContentRecommendationWidget
            {...{
              widget: {
                id: `homepage-hero_Espot-${paramCase(heroConfig.espot.eSpotName)}`,
                widgetName: "content-recommendation-widget",
                name: `homepage-hero_${slot}_Espot`,
                properties: {
                  emsName: `homepage-hero_${slot}_Espot`,
                },
              },
              page: { name: "" },
            }}
          ></ContentRecommendationWidget>
        </div>
      ) : null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [t, mySite]
  );

  
  useEffect(() => {
    if (loggedIn){
      setAddHeroSpotLogout(false);
      if(valuePath.includes(pathname)){
        setAddHeroSpotLogin(true);
      }else {
        setAddHeroSpotLogin(false);
      }
    }
    else {
      setAddHeroSpotLogin(false);
      if(valuePath.includes(pathname)){
        setAddHeroSpotLogout(true);
      }else {
        setAddHeroSpotLogout(false);
      }
    }
  }, [pathname, loggedIn]);

  return (
    addHeroSpotLogin ? (
      <HomepageHero slot={"login"}/>
    ): addHeroSpotLogout ? (
      <HomepageHero slot={"logout"}/>
    ): null
  )
};

export default HeroSpot;
