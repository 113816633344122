/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { paramCase } from "change-case";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { footerConfig } from "./footerConstant";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { SessionErrorDialog } from "../widgets/session-error-modal";
import ConfirmationDialog from "../widgets/confirmation-dialog/ConfirmationDialog";
//UI
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube"
import {
  StyledGrid,
  StyledContainer,
  StyledTypography,
  StyledTooltip,
  StyledLink,
} from "@hcl-commerce-store-sdk/react-component";
//CUSTOM UI
import { StyledFooter } from "../../elements/footer";
import { Divider } from "@material-ui/core";
import { SignInDialog } from "../widgets/sign-in-dialog";
import { TERMS_CONDITIONS } from "../../constants/routes";

function Footer(props: any) {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const Logo = useCallback(() => {
    return mySite != null ? (
      <div className="footer-logo">
        <ContentRecommendationWidget
          {...{
            widget: {
              id: `footer-${paramCase(footerConfig.espot.eSpotName)}`,
              widgetName: "content-recommendation-widget",
              name: footerConfig.espot.eSpotName,
              properties: {
                emsName: footerConfig.espot.eSpotName,
              },
            },
            page: { name: "" },
          }}></ContentRecommendationWidget>
      </div>
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, mySite]);


  const CompanyLinks = () => {
    const companyLinks = [

      {
        linkText: t("Footer.CompanyLinks.Contactus"),
        linkURL: t("Footer.CompanyLinks.ContactusLink"),
      },
      {
        linkText: t("Footer.CompanyLinks.Careers"),
        linkURL: t("Footer.CompanyLinks.CareersLink"),
      },
      {
        linkText: t("Footer.CompanyLinks.AboutUs"),
        linkURL: t("Footer.CompanyLinks.AboutUsLink"),
      },
      {
        linkText: t("Footer.CompanyLinks.LeadershipTeam"),
        linkURL: t("Footer.CompanyLinks.LeadershipTeamLink"),
      },
      {
        linkText: t("Footer.CompanyLinks.ClinicalExpertise"),
        linkURL: t("Footer.CompanyLinks.ClinicalExpertiseLink"),
      },
      {
        linkText: t("Footer.CompanyLinks.InvestorRelations"),
        linkURL: t("Footer.CompanyLinks.InvestorRelationsLink"),
      }
    ];
    return (
      <div className="company">
        <StyledTypography style={{ color: 'white' }} variant="h6" >{t("Footer.CompanyLinks.Label")}</StyledTypography>
        <Divider style={{ border: '2px solid red' }} className="top-margin-2 bottom-margin-2" />
        <div>
          {companyLinks.map((v: any) => (
            <StyledTypography key={v.linkURL} className="bottom-margin-3">

              <a style={{ color: 'white' }} href={v.linkURL}>{v.linkText}</a>
              {/* <Link data-testid={v.linkURL} key={v.linkURL} to="/" onClick={(event) => event.preventDefault()}>
                  {v.linkText}
                </Link> */}

            </StyledTypography>
          ))}
        </div>
      </div>
    );
  };

  const ProductLinks = () => {
    const productLinks = [
      {
        linkText: t("Footer.ProductLinks.OurBrands"),
        linkURL: t("Footer.ProductLinks.OurBrandsLink"),
      },
      {
        linkText: t("Footer.ProductLinks.BrandsCarry"),
        linkURL: t("Footer.ProductLinks.BrandsCarryLink"),
      },
      {
        linkText: t("Footer.ProductLinks.Distribution"),
        linkURL: t("Footer.ProductLinks.DistributionLink"),
      },
      {
        linkText: t("Footer.ProductLinks.valueLinkText"),
        linkURL: t("Footer.ProductLinks.valueLink"),
      },
      {
        linkText: t("Footer.ProductLinks.Logistics"),
        linkURL: t("Footer.ProductLinks.LogisticsLink"),
      },
      {
        linkText: t("Footer.ProductLinks.PresourcePackManager"),
        linkURL: t("Footer.ProductLinks.PresourcePackManagerLink"),
      }
    ];
    return (
      <div className="products">
        <StyledTypography style={{ color: 'white' }} variant="h6">{t("Footer.ProductLinks.Label")}</StyledTypography>
        <Divider style={{ border: '2px solid red' }} className="top-margin-2 bottom-margin-2" />
        <div>
          {productLinks.map((v: any) => (
            <StyledTypography key={v.linkURL} className="bottom-margin-3">

              <a style={{ color: 'white' }} href={v.linkURL}>{v.linkText}</a>

            </StyledTypography>
          ))}
        </div>
      </div>
    );
  };

  const LegalLinks = () => {
    const legalLinks = [
      {
        linkText: t("Footer.LegalLinks.Safety"),
        linkURL: t("Footer.LegalLinks.SafetyLink"),
      },
      {
        linkText: t("Footer.LegalLinks.AODA"),
        linkURL: t("Footer.LegalLinks.AODALink"),
      },
      {
        linkText: t("Footer.LegalLinks.cookie"),
        linkURL: t("Footer.LegalLinks.cookieLink"),
      },
      {
        linkText: t("Footer.LegalLinks.privacy"),
        linkURL: t("Footer.LegalLinks.privacyLink"),
      }
    ];
    return (
      <div className="legal">
        <StyledTypography style={{ color: 'white' }} variant="h6"> {t("Footer.LegalLinks.Label")}</StyledTypography>
        <Divider style={{ border: '2px solid red' }} className="top-margin-2 bottom-margin-2" />
        <div>
          {legalLinks
            .map((v: any) => (
              <StyledTypography key={v.linkURL} className="bottom-margin-3">

                <a style={{ color: 'white' }} href={v.linkURL}>{v.linkText}</a>

              </StyledTypography>
            ))}
             <StyledLink style={{ color: 'white',fontWeight: 'normal' }} to={TERMS_CONDITIONS}>
                  {t("Footer.LegalLinks.terms")}
            </StyledLink>
           
        </div>
      </div>
    );
  };

  const ContactUsLink = () => {
    const contactUsLink = [
      {
        linkText: t("Footer.ContactUsLinkText"),
        linkURL: t("Footer.ContactUsLinkUrl"),
      }
    ];
    return (
      <div className="legal">
        <div>
          {contactUsLink
            .map((v: any) => (
              <StyledTypography key={v.linkURL} className="bottom-margin-3" variant="h6">
                <a style={{ color: 'white' }} href={v.linkURL} target="_blank">{v.linkText}</a>
              </StyledTypography>
            ))}
        </div>
      </div>
    );
  };
  const Copyright = () => {
    return (
      <div className="copyright bottom-margin-6 right-margin-3" >
        <StyledTypography variant="caption"  >
          &copy;<span> {t("Footer.Copyright")}</span>
        </StyledTypography>
      </div>
    );
  };

  return (
    <>
      <StyledFooter>
        <StyledContainer>
          <StyledGrid container spacing={2} justifyContent={"space-evenly"} className="footer-top">
            <StyledGrid item xs={12} sm={6} lg={4}>
              <Logo />
              <StyledGrid item xs={12} className="left-margin-6">
                <div className="footer-social-link">
                  <a style={{ color: 'white' }} href="https://ca.linkedin.com/company/cardinal-health-canada"> <LinkedInIcon /></a>
                </div>
                <div className="footer-social-link">
                  <a style={{ color: 'white' }} href="https://www.youtube.com/cardinalhealth"><YouTubeIcon /></a>
                </div>
                <div className="footer-social-link">
                  <a style={{ color: 'white' }} href="https://www.facebook.com/CardinalHealthInc"><FacebookIcon /></a>
                </div>
                <div className="footer-social-link">
                  <a style={{ color: 'white' }} href="https://twitter.com/cardinalhealth"> <TwitterIcon /></a>
                </div>
              </StyledGrid>
              <StyledGrid item xs={12} className="bottom-margin-1 top-margin-4 left-margin-6">
              <ContactUsLink/>
              </StyledGrid>
              <StyledGrid item xs={12} className="bottom-margin-1 top-margin-4 left-margin-6">
                <StyledTypography style={{ color: 'white' }} variant="h6" >{t("Footer.HospitalCare")}</StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={12} className="bottom-margin-3 left-margin-6">
                <StyledTypography style={{ color: 'white' }} variant="h6" >1 888 291 5033</StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={12} className="bottom-margin-1 left-margin-6">
                <StyledTypography style={{ color: 'white' }} variant="h6" >{t("Footer.ContinuingCare")}</StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={12} className="bottom-margin-3 left-margin-6">
                <StyledTypography style={{ color: 'white' }} variant="h6" >1 877 878 7778</StyledTypography>
              </StyledGrid>
            </StyledGrid>
            <StyledGrid item xs={8} sm={6} lg={2}>
              <CompanyLinks />
            </StyledGrid>
            <StyledGrid item xs={8} sm={6} lg={2}>
              <ProductLinks />
            </StyledGrid>
            <StyledGrid item xs={8} sm={6} lg={2}>
              <LegalLinks />
            </StyledGrid>

          </StyledGrid>
          <StyledGrid container style={{ color: 'white' }} justifyContent={"flex-end"} >

            <Copyright />

          </StyledGrid>
        </StyledContainer>
      </StyledFooter>
      <SessionErrorDialog />
      <SignInDialog/>
      <ConfirmationDialog />
    </>
  );
}

export default React.memo(Footer);
