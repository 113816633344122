/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";

//Foundation libraries
import adminUserApiService from "../../../_foundation/apis/transaction/user-and-organization-management-admin.service";

//Redux
import * as ACTIONS from "../../action-types/user-management-admin";
import { HANDLE_SUCCESS_MESSAGE_ACTION } from "../../actions/success";
import { UPDATE_USER_STATUS_REQUEST_SUCCESS } from "../../actions/user-management-admin";


/**
 * Saga worker to update user status
 */
export function* userUpdateRequest(action: any) {
  const payload = action.payload;
  try {
    const response = yield call(adminUserApiService.usersUpdateUser, payload);
    const dataValue = JSON.parse(response.config.data);
    if (response.status === 200) {
      let msg;
      if(payload.widget === "AddEditUserLayout"){
        if(payload.addNewUser){
          msg = {
            key: "AdminUser.userCreated",
            messageParameters: { "0": payload.logonId },
          };

        }else {
          msg = {
            key: "AdminUser.UpdateSuccessful",
            messageParameters: { "0": payload.logonId },
          };
        }
      }
      else {
        msg = {
          key: dataValue.status === "1" ? "AdminUser.enabledUser": "AdminUser.disabledUser",
        };
      }
      yield put(HANDLE_SUCCESS_MESSAGE_ACTION(msg));
      
    }
    const statusValue = payload.id + payload.body.status;
    yield put(UPDATE_USER_STATUS_REQUEST_SUCCESS(statusValue));
  } catch (error) {
    yield put({ type: ACTIONS.UPDATE_USER_STATUS_REQUEST_ERROR, error });
    console.log(error);
  }
}

export function* userResetPasswordRequest(action: any) {
  const payload = action.payload;
  try {
    const response = yield call(adminUserApiService.resetUserPassword, payload);
    if (response.status === 200) {
      if(payload.widget === "UserManagement-reset-pass-dialog"){
        const msg = {
          key: "success-message.PASSWORD_RESET_SUCCESS",
        };
        yield put(HANDLE_SUCCESS_MESSAGE_ACTION(msg));
        yield put({
          type: ACTIONS.RESET_USER_PASSWORD_REQUEST_SUCCESS,
        });
      }
    }
    
  } catch (error) {
    yield put({ type: ACTIONS.RESET_USER_PASSWORD_REQUEST_ERROR, error });
    console.log(error);
  }
}


