import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import ContentRecommendationWidget from "../../commerce-widgets/content-recommendation-widget/content-recommendation-widget";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import StyledCard from "./StyledCard";

export const AccelerateBuildOrder: FC = () => {
  const { t } = useTranslation();
  const { data } = useContext(AccelerateProgramContext);

  return (
    <StyledCard>
      <Stack>
        <StyledTypography variant="h4">{t("DealerIncentiveProgram.BUILD_ORDER")}</StyledTypography>
        <ContentRecommendationWidget
          {...{
            widget: {
              id: `AcceleratePgmShopEssential_${data?.EligibilityType}_Espot`,
              widgetName: "content-recommendation-widget",
              name: `AcceleratePgmShopEssential_${data?.EligibilityType}_Espot`,
              properties: {
                emsName: `AcceleratePgmShopEssential_${data?.EligibilityType}_Espot`,
              },
            },
            page: { name: "" },
          }}
        />
      </Stack>
    </StyledCard>
  );
};
