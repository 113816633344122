/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */


//Get price for product list for PDP
export const PRODUCT_LIST_PRICE_FOR_PDP_GET_REQUESTED = "PRODUCT_LIST_PRICE_FOR_PDP_GET_REQUESTED";
export const PRODUCT_LIST_PRICE_FOR_PDP_GET_SUCCESS = "PRODUCT_LIST_PRICE_FOR_PDP_GET_SUCCESS";
export const PRODUCT_LIST_PRICE_FOR_PDP_GET_ERROR = "PRODUCT_LIST_PRICE_FOR_PDP_GET_ERROR";
export const PRODUCT_LIST_PRICE_FOR_PDP_GET_RESET = "PRODUCT_LIST_PRICE_FOR_PDP_GET_RESET";
export const PRODUCT_LIST_PRICE_FOR_SHOPPING_CART_GET_SUCCESS = "PRODUCT_LIST_PRICE_FOR_SHOPPING_CART_GET_SUCCESS";


