/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import { UsersApi } from "@hcl-commerce-store-sdk/admin-cardinal-axios-transaction";
import { site } from "../../constants/site";

const getUsersApiInstance = new UsersApi(
  undefined,
  site.adminContext
);
const adminUserApiService = {
  /**
   * This allows an admin - to get manageable users.
   * `@method`
   * `@name usersGetManageableUsers`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get a collection of users the currently logged in administrator user can manage.
   ** `@property {string} storeId (required)` The child property of `Parameters`.
   */
   usersGetManageableUsers(parameters: any): AxiosPromise<any> {
    const { offset, limit, lastName,   firstName,  logonId, sort, searchString, searchScope="",
      searchType="",
      roleId="",
      organizationRoleId="",
      roleOrganizationId="",
      parentOrganizationId } = parameters;
    return getUsersApiInstance.usersGetManageableUsers(
      offset,
      limit,
      lastName,
      firstName,
      logonId,
      sort,
      searchString,
      searchScope,
      searchType,
      roleId,
      organizationRoleId,
      roleOrganizationId,
      parentOrganizationId
    );
  },
  usersUpdateUser(parameters: any): AxiosPromise<any> {
    const { id, body, ...options } = parameters;
    return getUsersApiInstance.usersUpdateUser(
      id,
      body,
      options
    );
  },
  resetUserPassword(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, id, ...options } = parameters;
    return getUsersApiInstance.usersResetUserPassword(
      id,
      storeId,
      options
    );
  },
  usersCreateUser(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { body, storeId = siteInfo?.storeID, options } = parameters;
    return getUsersApiInstance.usersCreateUser(
      body, 
      storeId, 
      options
    );
  },
}
export default adminUserApiService;
