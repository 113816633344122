/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { OrganizationValidation } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCOrganizationCHCOrganizationDescendantBeanApi - axios parameter creator
 * @export
 */
export const CHCOrganizationCHCOrganizationDescendantBeanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user\'s entitled organizations validation.
         * @summary Get user\'s entitled organizations validation.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId organization Id
         * @param {string} [userId] 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcOrganizationGetDescendantBean: async (storeId: string, organizationId: string, userId?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcOrganizationGetDescendantBean', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('chcOrganizationGetDescendantBean', 'organizationId', organizationId)
            const localVarPath = `/store/{storeId}/chc_organization/CHCOrganizationDescendantBean`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizationId !== undefined) {
                localVarQueryParameter['organizationId'] = organizationId;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCOrganizationCHCOrganizationDescendantBeanApi - functional programming interface
 * @export
 */
export const CHCOrganizationCHCOrganizationDescendantBeanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCOrganizationCHCOrganizationDescendantBeanApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user\'s entitled organizations validation.
         * @summary Get user\'s entitled organizations validation.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId organization Id
         * @param {string} [userId] 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcOrganizationGetDescendantBean(storeId: string, organizationId: string, userId?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganizationValidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcOrganizationGetDescendantBean(storeId, organizationId, userId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCOrganizationCHCOrganizationDescendantBeanApi - factory interface
 * @export
 */
export const CHCOrganizationCHCOrganizationDescendantBeanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCOrganizationCHCOrganizationDescendantBeanApiFp(configuration)
    return {
        /**
         * Get user\'s entitled organizations validation.
         * @summary Get user\'s entitled organizations validation.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId organization Id
         * @param {string} [userId] 
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcOrganizationGetDescendantBean(storeId: string, organizationId: string, userId?: string, responseFormat?: string, options?: any): AxiosPromise<OrganizationValidation> {
            return localVarFp.chcOrganizationGetDescendantBean(storeId, organizationId, userId, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCOrganizationCHCOrganizationDescendantBeanApi - object-oriented interface
 * @export
 * @class CHCOrganizationCHCOrganizationDescendantBeanApi
 * @extends {BaseAPI}
 */
export class CHCOrganizationCHCOrganizationDescendantBeanApi extends BaseAPI {
    /**
     * Get user\'s entitled organizations validation.
     * @summary Get user\'s entitled organizations validation.
     * @param {string} storeId The store identifier.
     * @param {string} organizationId organization Id
     * @param {string} [userId] 
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCOrganizationCHCOrganizationDescendantBeanApi
     */
    public chcOrganizationGetDescendantBean(storeId: string, organizationId: string, userId?: string, responseFormat?: string, options?: any) {
        return CHCOrganizationCHCOrganizationDescendantBeanApiFp(this.configuration).chcOrganizationGetDescendantBean(storeId, organizationId, userId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
