/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
// Entitled org

export const ENTITLED_ORG_REQUESTED = "ENTITLED_ORG_REQUESTED";
export const ENTITLED_ORG_REQUEST_SUCCESS = "ENTITLED_ORG_REQUEST_SUCCESS";
export const ENTITLED_ORG_REQUEST_ERROR = "ENTITLED_ORG_REQUEST_ERROR";

export const ENTITLED_ORG_SEGMENT_REQUEST_SUCCESS = "ENTITLED_ORG_SEGMENT_REQUEST_SUCCESS";
export const ENTITLED_ORG_SEGMENT_REQUEST_ERROR = "ENTITLED_ORG_SEGMENT_REQUEST_ERROR";

export const ENTITLED_ORG_LOYALTY_REQUEST_SUCCESS = "ENTITLED_ORG_LOYALTY_REQUEST_SUCCESS";
export const ENTITLED_ORG_LOYALTY_REQUEST_ERROR = "ENTITLED_ORG_LOYALTY_REQUEST_ERROR";

export const ENTITLED_ORG_CUSTOMER_PN_REQUEST_SUCCESS = "ENTITLED_ORG_CUSTOMER_PN_REQUEST_SUCCESS"; // Customer Part Number
export const ENTITLED_ORG_CUSTOMER_PN_REQUEST_ERROR = "ENTITLED_ORG_CUSTOMER_PN_REQUEST_ERROR"; // Customer Part Number


export const ORG_SWITCH_REQUESTED = "ORG_SWITCH_REQUESTED";
export const ORG_SWITCH_REQUEST_SUCCESS = "ORG_SWITCH_REQUEST_SUCCESS";
export const ORG_SWITCH_REQUEST_ERROR = "ORG_SWITCH_REQUEST_ERROR";

export const SET_USER_ASSGINED_ROLES = "SET_USER_ASSGINED_ROLES";

// Organization Details
export const GET_ORGANIZATION_ADDRESS = "GET_ORGANIZATION_ADDRESS";
export const GET_ORGANIZATION_ADDRESS_SUCCESS = "GET_ORGANIZATION_ADDRESS_SUCCESS";
export const GET_ORGANIZATION_ADDRESS_ERROR = "GET_ORGANIZATION_ADDRESS_ERROR";

export const GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS = "GET_ORGANIZATION_BILLING_ADDRESS_SUCCESS";
export const GET_ORGANIZATION_BILLING_ADDRESS_ERROR = "GET_ORGANIZATION_BILLING_ADDRESS_ERROR";

export const GET_ACCELERATEPGMDATA_SUCCESS = "GET_ACCELERATEPGMDATA_SUCCESS";
export const GET_ACCELERATEPGMDATA_ERROR = "GET_ACCELERATEPGMDATA_ERROR";

export const GET_STANDING_ORDER_SUCCESS = "GET_STANDING_ORDER_SUCCESS";
export const GET_STANDING_ORDER_ERROR = "GET_STANDING_ORDER_ERROR";

export const GET_EMPLOYEE_PURCHASE_SUCCESS = "GET_EMPLOYEE_PURCHASE_SUCCESS";
export const GET_EMPLOYEE_PURCHASE_ERROR = "GET_EMPLOYEE_PURCHASE_ERROR";

export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
export const NOTIFICATIONS_UPDATE = "NOTIFICATIONS_UPDATE";
export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";




