/* tslint:disable */
/* eslint-disable */
/**
 * Approval Status
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ApprovalStatus } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ApprovalStatusCollection } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ErrorResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ApprovalStatusApi - axios parameter creator
 * @export
 */
export const ApprovalStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Find an approval status record by its ID.
         * @param {string} id The unique numeric ID for identifying the approval status record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByApprovalStatusId: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findByApprovalStatusId', 'id', id)
            const localVarPath = `/approval-statuses/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find all approval status records available to the current user.
         * @param {string} [flowTypeId] Query by flow type identifier which must be one of the values listed below.  * 10001 - RFQ response  * 10002 - Order approval  * 10003 - Contract approval  * 10004 - Buyer approval  * 10005 - Seller org approval  * 10006 - Seller approval
         * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
         * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
         * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
         * @param {string} [startSubmitDate] Query by approval request start time formatted as standard ISO date.
         * @param {string} [endSubmitDate] Query by approval request end time formatted as standard ISO date.
         * @param {number} [status] Query by approval request status.
         * @param {string} [entityId] Query by approval request entity ID, such as order ID.
         * @param {string} [approverId] Query by approver ID. Only returns approval requests that can be approved by the current user.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovalStatuses: async (flowTypeId?: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitDate?: string, endSubmitDate?: string, status?: number, entityId?: string, approverId?: string, sort?: string, searchString?: string, offset?: number, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/approval-statuses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (flowTypeId !== undefined) {
                localVarQueryParameter['flowTypeId'] = flowTypeId;
            }

            if (submitterFirstName !== undefined) {
                localVarQueryParameter['submitterFirstName'] = submitterFirstName;
            }

            if (submitterMiddleName !== undefined) {
                localVarQueryParameter['submitterMiddleName'] = submitterMiddleName;
            }

            if (submitterLastName !== undefined) {
                localVarQueryParameter['submitterLastName'] = submitterLastName;
            }

            if (startSubmitDate !== undefined) {
                localVarQueryParameter['startSubmitDate'] = (startSubmitDate as any instanceof Date) ?
                    (startSubmitDate as any).toISOString().substr(0,10) :
                    startSubmitDate;
            }

            if (endSubmitDate !== undefined) {
                localVarQueryParameter['endSubmitDate'] = (endSubmitDate as any instanceof Date) ?
                    (endSubmitDate as any).toISOString().substr(0,10) :
                    endSubmitDate;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (approverId !== undefined) {
                localVarQueryParameter['approverId'] = approverId;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the status to approve (1) or reject (2) and add an optional comment to the approval status record. Note that only an approval record that is pending approval. For example, an approval status record with a status of 0 can be approved or rejected. Only the status and comment property values from the body are used when updating the approval record.
         * @param {string} id The unique numeric ID for identifying the approval status record.
         * @param {ApprovalStatus} body The request body for updating an approval status record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApprovalStatus: async (id: string, body: ApprovalStatus, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateApprovalStatus', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateApprovalStatus', 'body', body)
            const localVarPath = `/approval-statuses/{id}`
                .replace(`{${"id"}}`, String(id));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApprovalStatusApi - functional programming interface
 * @export
 */
export const ApprovalStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApprovalStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Find an approval status record by its ID.
         * @param {string} id The unique numeric ID for identifying the approval status record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findByApprovalStatusId(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovalStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findByApprovalStatusId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find all approval status records available to the current user.
         * @param {string} [flowTypeId] Query by flow type identifier which must be one of the values listed below.  * 10001 - RFQ response  * 10002 - Order approval  * 10003 - Contract approval  * 10004 - Buyer approval  * 10005 - Seller org approval  * 10006 - Seller approval
         * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
         * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
         * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
         * @param {string} [startSubmitDate] Query by approval request start time formatted as standard ISO date.
         * @param {string} [endSubmitDate] Query by approval request end time formatted as standard ISO date.
         * @param {number} [status] Query by approval request status.
         * @param {string} [entityId] Query by approval request entity ID, such as order ID.
         * @param {string} [approverId] Query by approver ID. Only returns approval requests that can be approved by the current user.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApprovalStatuses(flowTypeId?: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitDate?: string, endSubmitDate?: string, status?: number, entityId?: string, approverId?: string, sort?: string, searchString?: string, offset?: number, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovalStatusCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getApprovalStatuses(flowTypeId, submitterFirstName, submitterMiddleName, submitterLastName, startSubmitDate, endSubmitDate, status, entityId, approverId, sort, searchString, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the status to approve (1) or reject (2) and add an optional comment to the approval status record. Note that only an approval record that is pending approval. For example, an approval status record with a status of 0 can be approved or rejected. Only the status and comment property values from the body are used when updating the approval record.
         * @param {string} id The unique numeric ID for identifying the approval status record.
         * @param {ApprovalStatus} body The request body for updating an approval status record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApprovalStatus(id: string, body: ApprovalStatus, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApprovalStatus(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApprovalStatusApi - factory interface
 * @export
 */
export const ApprovalStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApprovalStatusApiFp(configuration)
    return {
        /**
         * 
         * @summary Find an approval status record by its ID.
         * @param {string} id The unique numeric ID for identifying the approval status record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByApprovalStatusId(id: string, options?: any): AxiosPromise<ApprovalStatus> {
            return localVarFp.findByApprovalStatusId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find all approval status records available to the current user.
         * @param {string} [flowTypeId] Query by flow type identifier which must be one of the values listed below.  * 10001 - RFQ response  * 10002 - Order approval  * 10003 - Contract approval  * 10004 - Buyer approval  * 10005 - Seller org approval  * 10006 - Seller approval
         * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
         * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
         * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
         * @param {string} [startSubmitDate] Query by approval request start time formatted as standard ISO date.
         * @param {string} [endSubmitDate] Query by approval request end time formatted as standard ISO date.
         * @param {number} [status] Query by approval request status.
         * @param {string} [entityId] Query by approval request entity ID, such as order ID.
         * @param {string} [approverId] Query by approver ID. Only returns approval requests that can be approved by the current user.
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
         * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApprovalStatuses(flowTypeId?: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitDate?: string, endSubmitDate?: string, status?: number, entityId?: string, approverId?: string, sort?: string, searchString?: string, offset?: number, limit?: number, options?: any): AxiosPromise<ApprovalStatusCollection> {
            return localVarFp.getApprovalStatuses(flowTypeId, submitterFirstName, submitterMiddleName, submitterLastName, startSubmitDate, endSubmitDate, status, entityId, approverId, sort, searchString, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the status to approve (1) or reject (2) and add an optional comment to the approval status record. Note that only an approval record that is pending approval. For example, an approval status record with a status of 0 can be approved or rejected. Only the status and comment property values from the body are used when updating the approval record.
         * @param {string} id The unique numeric ID for identifying the approval status record.
         * @param {ApprovalStatus} body The request body for updating an approval status record.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApprovalStatus(id: string, body: ApprovalStatus, options?: any): AxiosPromise<void> {
            return localVarFp.updateApprovalStatus(id, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApprovalStatusApi - object-oriented interface
 * @export
 * @class ApprovalStatusApi
 * @extends {BaseAPI}
 */
export class ApprovalStatusApi extends BaseAPI {
    /**
     * 
     * @summary Find an approval status record by its ID.
     * @param {string} id The unique numeric ID for identifying the approval status record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStatusApi
     */
    public findByApprovalStatusId(id: string, options?: any) {
        return ApprovalStatusApiFp(this.configuration).findByApprovalStatusId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find all approval status records available to the current user.
     * @param {string} [flowTypeId] Query by flow type identifier which must be one of the values listed below.  * 10001 - RFQ response  * 10002 - Order approval  * 10003 - Contract approval  * 10004 - Buyer approval  * 10005 - Seller org approval  * 10006 - Seller approval
     * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
     * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
     * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
     * @param {string} [startSubmitDate] Query by approval request start time formatted as standard ISO date.
     * @param {string} [endSubmitDate] Query by approval request end time formatted as standard ISO date.
     * @param {number} [status] Query by approval request status.
     * @param {string} [entityId] Query by approval request entity ID, such as order ID.
     * @param {string} [approverId] Query by approver ID. Only returns approval requests that can be approved by the current user.
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;name,-id will order the items first based on the name value in ascending order, and then by their ID value in descending order.
     * @param {string} [searchString] Limits search results to only include users whose first name, last name or logon ID matches the value of this parameter.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStatusApi
     */
    public getApprovalStatuses(flowTypeId?: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitDate?: string, endSubmitDate?: string, status?: number, entityId?: string, approverId?: string, sort?: string, searchString?: string, offset?: number, limit?: number, options?: any) {
        return ApprovalStatusApiFp(this.configuration).getApprovalStatuses(flowTypeId, submitterFirstName, submitterMiddleName, submitterLastName, startSubmitDate, endSubmitDate, status, entityId, approverId, sort, searchString, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the status to approve (1) or reject (2) and add an optional comment to the approval status record. Note that only an approval record that is pending approval. For example, an approval status record with a status of 0 can be approved or rejected. Only the status and comment property values from the body are used when updating the approval record.
     * @param {string} id The unique numeric ID for identifying the approval status record.
     * @param {ApprovalStatus} body The request body for updating an approval status record.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStatusApi
     */
    public updateApprovalStatus(id: string, body: ApprovalStatus, options?: any) {
        return ApprovalStatusApiFp(this.configuration).updateApprovalStatus(id, body, options).then((request) => request(this.axios, this.basePath));
    }
}
