/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
 * Do not modify, the file is generated.
 */
//Standard libraries
import { AxiosPromise } from "axios";
//Foundation libraries
import { getSite } from "../../hooks/useSite";
import {CHCCartApi } from "@hcl-commerce-store-sdk/cardinal-axios-transaction";
import { CartApi } from "@hcl-commerce-store-sdk/typescript-axios-transaction";
import { site } from "../../constants/site";

const chcCartApiInstance = new CHCCartApi(
  undefined,
  site.transactionContext
);

const cartApiInstance = new CartApi(undefined, site.transactionContext);

const chcCartService = {
  /**
   * This APi fetches ship from Detail from HCL DB.
   * `@method`
   * `@name getShipFromDetail`
   *
   * `@param {any} parameters` have following properties:
   ** `@property {string} responseFormat ` This class provides RESTful services to get ship from details.
   ** `@property {string} storeId (required)` The child property of `Parameters`.This class provides RESTful services to ship from details.
   */
   findShipFrom(parameters: any): AxiosPromise<any> {
    
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID, storeLocId, langId, responseFormat, ...options } = parameters;
    return chcCartApiInstance.cHCShipFrom(storeId, 
        storeLocId,  
        langId,    
        responseFormat, 
        options
    );
  },  
  calculate(parameters: any): AxiosPromise<any> {
    const siteInfo = getSite();
    const { storeId = siteInfo?.storeID } = parameters;
    return cartApiInstance.cartCalculateOrder1(storeId, undefined, {
      calculationUsageId: ['-1', '-2', '-3', '-4', '-5', '-6', '-7'] as any as string,
      updatePrices: '1',
      doPrice: 'N',
      doConfigurationValidation: 'Y',
    } as any);
  },
};

export default chcCartService;
