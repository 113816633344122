/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//Custom libraries
import * as ROUTES from "../../constants/routes";

//UI
import {
  StyledTypography,
  StyledList,
  StyledListItem,
  StyledListItemText,
  StyledListItemIcon,
  StyledLink,
} from "@hcl-commerce-store-sdk/react-component";
import { Divider } from "@material-ui/core";
import { Stack } from "@mui/material";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { forUserIdSelector, logonIdSelector } from "../../redux/selectors/user";

function AccountPopperContent(props: any): JSX.Element {
  const { isB2B, userName, handleClose, handleLogout } = props;
  const { t } = useTranslation();
  const forUserId = useSelector(forUserIdSelector);
  const login = useSelector(logonIdSelector)

  const WelcomeSection = () => {
    return (
      <StyledListItem>
        <StyledListItemText
          primary={
            <Stack>
              <StyledTypography className="account-welcome-popup" variant="body2">
              {t("Header.AccountPopper.Welcome", { ...userName })}
            </StyledTypography>
            <StyledTypography>
              {t("Header.AccountPopper.LoginId", { login })}
            </StyledTypography>
            </Stack>
            
          }
        ></StyledListItemText>
      </StyledListItem>
    );
  };

  const AccountSetting = () => {
    return (
      <StyledLink
        testId={`account-popper-${isB2B ? "dashboard" : "account"}`}
        to={isB2B ? ROUTES.DASHBOARD : ROUTES.ACCOUNT}
        id="acount-popper-myaccount-dashboard-link"
        onClick={handleClose}
      >
        <StyledListItem>
          <StyledListItemIcon>
            <AccountCircleIcon />
          </StyledListItemIcon>
          <StyledListItemText
            primary={<>{isB2B ? t("Dashboard.Title") : t("Header.AccountPopper.AccountSetting")}</>}
          ></StyledListItemText>
        </StyledListItem>
      </StyledLink>
    );
  };

  const LogoutSection = () => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <StyledLink testId={`account-popper-signout`} to="" onClick={(event) => handleLogout(event)}>
        <StyledListItem>
          <StyledListItemIcon>
            <ExitToAppIcon />
          </StyledListItemIcon>
          <StyledListItemText
            primary={<>{t("Header.AccountPopper.SignOut")}</>}
          ></StyledListItemText>
        </StyledListItem>
      </StyledLink>
    );
  };

  return (
    <StyledList disablePadding>
      <WelcomeSection />

      <Divider component="li" />
      <AccountSetting />

      {!forUserId && (
        <>
          <Divider component="li" />
          <LogoutSection />
        </>
      )}
    </StyledList>
  );
}

AccountPopperContent.propTypes = {
  isB2B: PropTypes.bool.isRequired,
  userName: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleOrgChange: PropTypes.func.isRequired,
  handleContractChange: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
};

export default AccountPopperContent;
