import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  StyledButton,
  StyledDialog,
  StyledDialogContent,
  StyledDialogContentText,
  StyledFormControl,
  StyledGrid,
  StyledInputLabel,
  StyledProgressPlaceholder,
  StyledSelect,
  StyledTextField,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Axios, { Canceler } from "axios";
//Redux
import { useDispatch } from "react-redux";
import getDisplayName from "react-display-name";
import { EXPIRY } from "../../../constants/order";

//import Cards from "react-credit-cards-2";
import "react-credit-cards-2/lib/styles.scss";
import { EMPTY_STRING } from "../../../constants/common";
import chcPaymentMethodService from "../../../_foundation/apis/transaction/chc-payment-method.service";

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
  }


export default function DialogAddCard(props) {

  const { t } = useTranslation();
  const widgetName = getDisplayName(DialogAddCard);
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const [name, setName] = useState<string>(EMPTY_STRING);
  const [acctNumber, setAcctNumber] = useState<string>(EMPTY_STRING);
  const [expMonth, setExpMonth] = useState<string>(EXPIRY.MONTHS[0]);
  const [expYear, setExpYear] = useState<string>(EXPIRY.YEARS[0]);
  const [cvv, setCvv] = useState<string>(EMPTY_STRING);
  const [address1, setAddress1] = useState<string>(EMPTY_STRING);
  const [city, setCity] = useState<string>(EMPTY_STRING);
  const [state, setState] = useState<string>(t("Dashboard.AddCard.Dialog.Province.Alberta"));
  const [zipCode, setZipCode] = useState<string>(EMPTY_STRING);
  const [errorMessage, setErrorMessage] = useState<string>(EMPTY_STRING);
  const [focused, setfocused] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  
  const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const payload = {
    ...payloadBase,
  };
  const handleClose = () => {
    props.setOpenAdd(false);
  };
  const handleAddAcount = () => {
   
    setLoading(true);
    const responseFormat = 'json';
    const uRL = 'success';
    const country = "CAN";
    const errorView = "null"
    chcPaymentMethodService.addCard({
      acctNumber, expMonth, expYear, name, cvv, address1, city, state, country, zipCode, responseFormat, uRL, errorView
    })
    .then((response) => { 
      if(response.status === 200) {        
        props.setOpenAdd(false);
        setLoading(false);
        window.location.reload();
      } else {
        setLoading(false);
        setOpenError(true);
       setErrorMessage(t("Dashboard.AddCard.ErrorMessage"));
      }
    });      
  };
 
  const canCreate = (): boolean => {
    if (
      name !== EMPTY_STRING &&
      (acctNumber.length === 16 || acctNumber.length ===15) &&
      ((parseInt(expMonth, 10) >= (new Date().getMonth() + 2) &&
      parseInt(expYear, 10) === new Date().getFullYear())||
      parseInt(expYear, 10) > new Date().getFullYear()) && 
     ( cvv.length === 4 || cvv.length ===3) &&
      address1 !== EMPTY_STRING &&
      state !== t("Dashboard.AddCard.Dialog.Province.Title") &&
      city !== EMPTY_STRING &&
      
      zipCode !== EMPTY_STRING 
    ) {
      
        return true;
     
    }
    return false;
  };

  const closeAndRedirect = () => {
    setOpenError(false);
    props.setOpenAdd(false);
    window.location.reload();
  };

  const handleInputFocus = ({ target }) => {
    setfocused(target.name);
  };

  // const handleCallback = ({ issuer }, isValid) => {
  //   if (isValid) {
  //     // paymentInfo.creditCardFormData.issuer = issuer.toUpperCase();
  //   }
  // };
  
  return (
    <div>
      <StyledDialog open={props.openAdd} onClose={handleClose}>
      <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
           {t("Dashboard.AddCard.Dialog.Title")}
        </BootstrapDialogTitle>
        <DialogContent dividers >
        <StyledGrid container item spacing= {3}>
          <StyledDialogContentText>
          {t("Dashboard.AddCard.Dialog.Subtitle")}
          </StyledDialogContentText>
         
         
            <StyledGrid container item spacing= {2}>
            
              {/* <StyledGrid item xs={12}>
                      <Cards
                        number={acctNumber}
                        expiry={
                        expMonth+expYear
                        }
                        cvc={cvv}
                        name={name}
                        focused={focused}
                        callback={handleCallback}
                      />
                      </StyledGrid>
                      <Divider className="horizontal-margin-2 vertical-margin-2" /> */}
                      <StyledGrid
                        container
                        item
                        spacing={2}
                        className="horizontal-padding-2 vertical-padding-3"
                      >
                        <StyledGrid item xs={12}>
                          <StyledTypography><strong> {t("Dashboard.AddCard.Dialog.CreditCard")} </strong></StyledTypography>
                        </StyledGrid>
                      <StyledGrid item xs={12} >
                        <StyledTextField
                          required
                          name="card_holder_name"
                          value={name}
                          
                          label={t("Dashboard.AddCard.Dialog.CardHolderName")}
                          type="tel"
                          onChange={(event) =>
                            setName(event.target.value)
                          }
                          onFocus={handleInputFocus}
                          inputProps={{ maxLength: 19 }}
                          fullWidth
                        />
                      </StyledGrid>
                        <StyledGrid item xs={12}>
                          <StyledTextField
                            required
                            name="account"
                            value={acctNumber}
                            label={t(
                                    "Dashboard.AddCard.Dialog.CardNumber"
                            )}
                            type="tel"
                            onChange={(event) =>
                                    setAcctNumber(event.target.value)
                            }
                            onFocus={handleInputFocus}
                              // error={
                              //   !isValidCardNumber(
                              //     // paymentInfo.creditCardFormData?.account
                              //   )
                              // }
                                
                            inputProps={{ maxLength: 16 }}
                            fullWidth
                          />
                        </StyledGrid>

                        <StyledGrid item xs={12} sm={8}>
                          <StyledGrid
                            container
                            spacing={2}
                            alignItems="flex-end"
                          >
                          <StyledGrid item xs={6} sm={5}>
                            <StyledFormControl variant="outlined">
                              <StyledInputLabel
                                shrink
                                htmlFor="expire_month"
                              >
                              {t("Dashboard.AddCard.Dialog.ExpDate")}
                              </StyledInputLabel>

                              <StyledSelect
                                required
                                native
                                data-testid="expiry-month"
                                id="expire_month"
                                name="expire_month"
                                value={expMonth}
                                onFocus={handleInputFocus}
                                onChange={(event) =>
                                    setExpMonth(event.target.value)
                                }
                                fullWidth
                              >
                              {EXPIRY.MONTHS.map(
                                (month: any, index: number) => (
                                <option value={month} key={month}>
                                  {month}
                                </option>
                                )
                              )}
                            </StyledSelect>
                          </StyledFormControl>
                        </StyledGrid>
                        <StyledGrid item xs={6} sm={5}>
                          <StyledFormControl variant="outlined">
                            <StyledSelect
                                native
                                required
                                data-testid="expiry-year"
                                name="expire_year"
                                value={expYear}
                                onFocus={handleInputFocus}
                                onChange={(event) =>
                                  setExpYear(event.target.value)
                                }
                                fullWidth
                              >
                              {EXPIRY.YEARS.map(
                              (year: any, index: number) => (
                              <option value={year} key={year}>
                                {year}
                              </option>
                              )
                              )}
                            </StyledSelect>
                          </StyledFormControl>
                        </StyledGrid>
                      </StyledGrid>
                    </StyledGrid>

                    <StyledGrid item xs={12} sm={4}>
                      <StyledTextField
                        required
                        name="cvc"
                        value={cvv}
                        label={t("PaymentMethodSelection.Labels.CVV")}
                        type="tel"
                        onFocus={handleInputFocus}
                        onChange={(event) =>
                          setCvv(event.target.value)
                        }
                        pattern="\d{3,4}"
                        // error={
                        //   !isValidCode(
                        //     // paymentInfo.creditCardFormData?.cvc
                        //   )
                        // }
                        // helperText={
                        //   !isValidCode(
                        //     // paymentInfo.creditCardFormData?.cvc
                        //   )
                        //     ? t("PaymentMethodSelection.Msgs.CVV")
                        //     : EMPTY_STRING
                        // }
                        inputProps={{ maxLength: 4 }}
                        fullWidth
                        />
                      </StyledGrid>
                    </StyledGrid>
                  </StyledGrid>

            <StyledGrid container item spacing= {2} >
              <StyledGrid item xs={12}>
                <StyledTypography><strong> {t("Dashboard.AddCard.Dialog.CardBillingAddress")} </strong></StyledTypography>
              </StyledGrid>
              <StyledGrid item xs={12}>
                <StyledTextField
                  required
                  inputProps={{
                  maxLength: 40,
                  }}
                  label={t("Dashboard.AddCard.Dialog.Address")}
                  name="address1"
                  fullWidth
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <StyledTextField
                  required
                  inputProps={{
                  maxLength: 40,
                  }}
                  label={t("Dashboard.AddCard.Dialog.City")}
                  name="city"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </StyledGrid>
              <StyledGrid item xs={12} sm={6} >
            
              <StyledFormControl variant="outlined"  fullWidth>
              <StyledInputLabel >
              {t("Dashboard.AddCard.Dialog.Province.Title")}
                </StyledInputLabel>
                <StyledSelect
                  native
                  data-testid="state"
                  intialvalue={t("Dashboard.AddCard.Dialog.Province.Title")}
                  name="state"
                  fullWidth
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value={t("Dashboard.AddCard.Dialog.Province.Alberta")}>{t("Dashboard.AddCard.Dialog.Province.Alberta")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.BritishColumbia")}>{t("Dashboard.AddCard.Dialog.Province.BritishColumbia")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.Manitoba")}>{t("Dashboard.AddCard.Dialog.Province.Manitoba")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.NewBrunswick")}>{t("Dashboard.AddCard.Dialog.Province.NewBrunswick")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.NewFoundland")}>{t("Dashboard.AddCard.Dialog.Province.NewFoundland")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.NorthwestTerritory")}>{t("Dashboard.AddCard.Dialog.Province.NorthwestTerritory")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.NovaScotia")}>{t("Dashboard.AddCard.Dialog.Province.NovaScotia")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.Nunavut")}>{t("Dashboard.AddCard.Dialog.Province.Nunavut")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.Ontario")}>{t("Dashboard.AddCard.Dialog.Province.Ontario")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.PrinceEdwardIsland")}>{t("Dashboard.AddCard.Dialog.Province.PrinceEdwardIsland")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.Quebec")}>{t("Dashboard.AddCard.Dialog.Province.Quebec")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.Saskatchewan")}>{t("Dashboard.AddCard.Dialog.Province.Saskatchewan")}</option>
                  <option value={t("Dashboard.AddCard.Dialog.Province.Yukon")}>{t("Dashboard.AddCard.Dialog.Province.Yukon")}</option>
                  </StyledSelect>
                  </StyledFormControl>
              </StyledGrid>
              <StyledGrid item xs={12} sm={6}>
                <StyledTextField
                  required
                  inputProps={{
                  maxLength: 40,
                  }}
                  label={t("Dashboard.AddCard.Dialog.PostalCode")}
                  name="PostalCode"
                  fullWidth
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                />
              </StyledGrid>
            </StyledGrid>
            {   loading ? (
                      <StyledGrid container justifyContent="center" className="top-margin-2">
                       <StyledProgressPlaceholder className="top-margin-2" />
                       </StyledGrid>
                     ) :(
            <StyledGrid container item spacing= {2} justifyContent="space-around" >
              <StyledButton color="primary" testId= "cancel-card" onClick={handleClose}>{t("Dashboard.AddCard.Dialog.Cancel")}</StyledButton>
              <StyledButton color="primary" testId= "add-card" onClick={handleAddAcount}  disabled={!canCreate()}>{t("Dashboard.AddCard.Dialog.Add")}</StyledButton>
            </StyledGrid>
            )}
          </StyledGrid>

        </DialogContent>
        <StyledDialog
                open={openError}
                onClose={closeAndRedirect}
                aria-labelledby="add-card-error-dialog"
                >
                   <DialogContent dividers >
                <StyledTypography variant="h6"> {t("Dashboard.AddCard.ErrorTitle")}</StyledTypography>
                <StyledDialogContent> {errorMessage}</StyledDialogContent>
             
                 
                    <StyledButton testId="add-card-error-ok" onClick={closeAndRedirect} color="primary">
                    {t("Dashboard.AddCard.Ok")}      
                    </StyledButton>
                 
                    </DialogContent>
              
              </StyledDialog>
        
      </StyledDialog>
    </div>
  );
}
