/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { useState, useEffect, useMemo, FC } from "react";
import { useSelector, useDispatch } from "react-redux";
import Axios, { Canceler } from "axios";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
//Foundation libraries
import { useSite } from "../../_foundation/hooks/useSite";
//Custom libraries
import { CONSTANTS } from "../../constants/order-item-table";
import FormattedPriceDisplay from "../../components/widgets/formatted-price-display";
import { PAGINATION_CONFIGS } from "../../configs/order";
import * as ROUTES from "../../constants/routes";
//Redux
import { currentContractIdSelector } from "../../redux/selectors/contract";
import * as orderActions from "../../redux/actions/order";
import {
  forUserIdSelector,
  loginStatusSelector,
  userIdSelector,
} from "../../redux/selectors/user";
import productsService from "../../_foundation/apis/search/chc-products.service";

//UI
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import TruckIcon from "@material-ui/icons/LocalShipping";

import CloseIcon from "@material-ui/icons/CloseOutlined";
import {
  StyledAvatar,
  StyledGrid,
  StyledTypography,
  StyledIconButton,
  TableColumnDef,
  useTableUtils,
  TableConstants,
  useCustomTable,
  StyledLink,
  StyledCircularProgress,
  StyledProductImage,
} from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";

//GA360
import AsyncCall from "../../_foundation/gtm/async.service";
import { cartSelector } from "../../redux/selectors/order";
import { useWinDimsInEM } from "./use-win-dims-in-em";
import {
  EMPTY_STRING,
  MANUFACTURER,
  SEARCHTERM,
  STRING_TRUE,
  XS_MOBILE_W,
} from "../../constants/common";
import { get, isEmpty } from "lodash-es";
import Closed from "@material-ui/icons/ChevronRight";
import Open from "@material-ui/icons/ExpandMoreOutlined";
import storeUtil from "../../utils/storeUtil";

import { useStoreLocatorValue } from "../context/store-locator-context";
import { sellersSelector } from "../../redux/selectors/sellers";
import productUtil from "../../utils/productUtil";
import dayjs from "dayjs";
import { ORDER_ITEM_STATUS } from "../../constants/order";
import cartUtil from "../../utils/cartUtil";
import ProductAttributesTable from "../../components/widgets/product-attributes-table/product-attributes-table";
import { StyledNumberInput } from "../../elements/number-input";
import { orgCPNKeySelector } from "../../redux/selectors/organization";
import { useImageWithFallback } from "../../components/commerce-widgets/cardinal-custom-widgets/hooks/use-fallback-image";
import OrderItemUOMCell from "../../components/widgets/order-item-table-chc/parts/OrderItemUOMCell";
import { getDateFormatter } from "../../utils/getDateFormatter";

type DrawerProps = { orderDetailPage: boolean };

const OpenDrawer: FC<DrawerProps> = ({ orderDetailPage }) => {
  const { t } = useTranslation();
  if (!orderDetailPage)
    return (
      <>
        <Closed />
        <StyledTypography variant="caption">
          {t(`OrderItemTable.Labels.Details`)}
        </StyledTypography>
      </>
    );
  else return <></>;
};
const CloseDrawer: FC<DrawerProps> = ({ orderDetailPage }) => {
  const { t } = useTranslation();
  if (!orderDetailPage)
    return (
      <>
        <Open />
        <StyledTypography variant="caption">
          {t(`OrderItemTable.Labels.Details`)}
        </StyledTypography>
      </>
    );
  else return <></>;
};

const DetailPanel = ({ rowData, orderDetailPage, ...props }) => {
  const { attributes: rawData } = rowData;
  const { t } = useTranslation();
  const { attribute: CPNAttribute } = useSelector(orgCPNKeySelector);
  const displayableAttrs = rawData?.filter(
    (a) => STRING_TRUE === a.displayable
  );
  const attrs = cartUtil.getOrderItemAttrs({
    attrs: displayableAttrs,
    orderItem: rowData,
    CPNAttribute,
  });

  const data = attrs?.map((attribute: any) => {
    return {
      label: attribute?.name,
      value: storeUtil.csValue(
        get(
          attribute,
          "values[0].value",
          t("CommerceEnvironment.inventoryStatus.NA")
        )
      ),
    };
  });
  return !isEmpty(attrs) ? <ProductAttributesTable data={data} /> : null;
};

function calculateAvailability(o: any, availability: Availability) {
  const { onlineInventory } = availability;
  const deliveryInventory = [...onlineInventory];
  const { partNumber } = o;
  try {
    const _avl = deliveryInventory.find((i) => i.partNumber === partNumber);
    if (_avl?.availability) {
      o["availability"] = _avl?.availability;
    }
  } catch (e) {
    console.log("fail to calculate availability", o, availability, e);
  }
}

interface PartNumberMap {
  [key: string]: Set<string>;
}

interface Availability {
  onlineInventory: any[];
}

/**
 * Order item table component
 * displays order item table with item info, inventory status, quantity and actions
 * allows for ready-only mode with no edits/actions
 * @param props
 */
export const useOrderItemTable = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { mySite } = useSite();
  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];
  const contractId = useSelector(currentContractIdSelector);
  const loginStatus = useSelector(loginStatusSelector);
  const isRecurringOrderFeatureEnabled = mySite?.isB2B && loginStatus;
  const { preShip, data: dataProps, cartPage = false } = props;
  const { tableState, setTableState } = useCustomTable();
  const { setValueForCell } = useTableUtils();
  const [actionData, setActionData] = useState<any>(null);
  const [uomList, setUomList] = useState<any>([]);
  const sellers = useSelector(sellersSelector);
  const reviewPage =
    useLocation().pathname === ROUTES.CHECKOUT + "/" + ROUTES.CHECKOUT_REVIEW;
  //const orderDetailPage = useLocation().pathname === ROUTES.ORDER_DETAILS + "/" + props.data[0].orderNumber;
  const orderDetailPage = useLocation().pathname.includes(ROUTES.ORDER_DETAILS);

  const storeId: string = mySite ? mySite.storeID : "";
  const payloadBase: any = {
    storeId: storeId,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };
  const { storeLocator } = useStoreLocatorValue();
  const selectedStore = useMemo(
    () => storeLocator.selectedStore,
    [storeLocator]
  );
  const [availability, setAvailability] = useState<Availability>();

  /**
   * Helper method to get the SKU's inventory details.
   * Stores the availabilty data in inventoryMap.
   * @param partNumbers
   */
  const getSkuInventory = (skus: any[]) => {
    const partNumbers = skus.map(({ partNumber }) => partNumber);
    const productIds = skus.map(({ id }) => id).join();
    const inventoryMap = new Map();
    const parameters: any = {
      storeId: storeId,
      partNumber: partNumbers,
      catalogId: mySite.catalogID,
      contractId: contractId,
      ...payloadBase,
    };
    if (selectedStore?.physicalStoreName) {
      parameters.physicalStoreName = selectedStore.physicalStoreName;
    }

    productsService
      .findProductsUsingGET(parameters)
      .then((res) => {
        const resultUOM = productUtil.getUOMInfo(res.data.contents, t);
        const onlineInventory = resultUOM;
        if (!orderDetailPage) {
          setAvailability({ onlineInventory });
          setUomList(resultUOM);
        }
      })
      .catch((e) => {
        if (e.status === 404) {
          const _productIds = productIds.split(",");
          for (const productIdElement of _productIds) {
            inventoryMap.set(productIdElement, false);
          }
          console.log("Could not retrieve Inventory Details", e);
        }

        console.log("Could not retrieve Inventory Details", e);
      });
  };

  /**
   * Initialize table data by making a copy
   * Material-table alters the input data, so data cannot be of immutable type
   * @returns Copy of the data prop
   */
  const data = useMemo(() => {
    /**
     * {partnumber: remainingInventory}
     * This is based on one partnumber only have one seller
     */

    const newData = (dataProps ?? []).map((oi) => {
      const _oi = { ...oi };
      if (availability) {
        calculateAvailability(_oi, availability);
      }
      return _oi;
    });
    return newData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps, availability]);

  const readOnly = props.readOnly !== undefined ? props.readOnly : true;
  const hasDetailPanel = props?.hasDetailPanel ?? true;
  const miniCartView =
    props.miniCartView !== undefined ? props.miniCartView : false;
  const defaultCurrencyID: string = mySite ? mySite.defaultCurrencyID : "";
  //update this flag as need later
  const pagination = !readOnly && !miniCartView;
  const handleMiniCartClose =
    props.handleMiniCartClose !== undefined ? props.handleMiniCartClose : null;
  const { seller } = props;

  /**
   * Initialize quantity data per order item
   * @returns quantities object for each order item
   */
  const initQuantityData = () => {
    const newData: any = {};
    const pnMap: PartNumberMap = {};
    if (dataProps) {
      //get all inventories
      dataProps.forEach((oi) => {
        const { partNumber, fulfillmentCenterOwnerId } = oi;
        if (sellers?.sellers?.some((s) => s.id === fulfillmentCenterOwnerId)) {
          let pSet: Set<string> = pnMap[fulfillmentCenterOwnerId];
          if (!pSet) {
            pSet = new Set<string>();
            pnMap[fulfillmentCenterOwnerId] = pSet;
          }
          pSet.add(partNumber);
        } else {
          let pSet: Set<string> = pnMap["online"];
          if (!pSet) {
            pSet = new Set<string>();
            pnMap["online"] = pSet;
          }
          pSet.add(partNumber);
        }
        if (oi.quantity) {
          try {
            const parsedQty = parseInt(oi.quantity);
            if (parsedQty > 0) {
              newData[oi.orderItemId] = parsedQty;
            }
          } catch (e) {
            console.log("Could not parse quantity", e);
          }
        }
      });
    }
    // Does not call Inventory detail API on order details page
    // if (!orderDetailPage) getSkuInventory(dataProps);
    return newData;
  };
  const [quantityList, setQuantityList] = useState<any>({});
  const defaultOptions = {
    toolbar: false,
    header: !miniCartView,
    paging: pagination,
    pageSize: PAGINATION_CONFIGS.pageLimit,
    pageSizeOptions: PAGINATION_CONFIGS.pageSizeOptions,
    actionsColumnIndex: -1,
    fixedColumns: {
      left: 0,
      right: 0,
    },
  };
  const options = props.options !== undefined ? props.options : defaultOptions;

  const columns = useMemo(
    () => {
      const cancels: Canceler[] = [];
      const payloadBase: any = {
        currency: defaultCurrencyID,
        contractId: contractId,
        widget: "Order Item Table",
        cancelToken: new CancelToken(function executor(c) {
          cancels.push(c);
        }),
      };
      const itemValCalc = ({ rowData: r }) => r.partNumber;
      const oaValCalc = ({ rowData }) => {
        return storeUtil.constructInventoryMessage(
          rowData,
          t,
          cartPage,
          selectedStore?.storeName
        );
      };
      const priceCalc = ({ rowData: r }) => Number(r.orderItemPrice);
      const quantityCalc = ({ rowData: r }) =>
        Number(quantityList[r.orderItemId]);
      const statusCalc = ({ rowData: r }) =>
        t(`Order.Status_${r.orderItemStatus}`);

      const QuantityDisplay = (props: any) => (
        <StyledTypography
          data-testid={`order-item-quantity-${props.rowData.partNumber}`}
        >
          {miniCartView && `${t("productDetail.Quantity")}: `}
          {quantityList[props.rowData.orderItemId]}
        </StyledTypography>
      );

      const OrderItemPrice = (props: any) => (
        <StyledTypography
          data-testid={`order-item-price-${props.rowData.partNumber}`}
          align={miniCartView ? "right" : "inherit"}
        >
          <FormattedPriceDisplay
            min={parseFloat(props.rowData.orderItemPrice)}
            currency={props.rowData.currency}
          />
        </StyledTypography>
      );
      const ODOrderItemPrice = (props: any) => (
        <StyledTypography
          data-testid={`order-item-price-${props.rowData.chcItemNumber}`}
          align={miniCartView ? "right" : "inherit"}
        >
          <FormattedPriceDisplay
            min={parseFloat(props.rowData.itemExtendedPrice)}
            currency={props.rowData.currency}
          />
        </StyledTypography>
      );
      const OrderUnitItemPrice = (props: any) => (
        <StyledTypography
          data-testid={`order-item-price-${props.rowData.partNumber}`}
          align={miniCartView ? "right" : "inherit"}
        >
          <FormattedPriceDisplay
            min={parseFloat(props.rowData.unitPrice)}
            currency={props.rowData.currency}
          />
        </StyledTypography>
      );
      const ThumbnailCell = ({ rowData, ...props }) => {
        const { src: thumbnail } = useImageWithFallback({
          src: rowData.thumbnail,
        });
        return (
          <>
            {rowData.seo && rowData.seo.href ? (
              <StyledLink
                to={rowData.seo?.href}
                onClick={handleMiniCartClose ? handleMiniCartClose : null}
              >
                <StyledAvatar
                  data-testid={`order-item-thumbnail-image-${rowData.partNumber}`}
                  alt={rowData.name}
                  src={thumbnail}
                  style={{ margin: "0", justifyContent: "flex-start" }}
                />
              </StyledLink>
            ) : (
              <>
                {!miniCartView ? (
                  <StyledLink
                    to={
                      ROUTES.SEARCH +
                      "?" +
                      SEARCHTERM +
                      "=" +
                      rowData?.partNumber
                    }
                  >
                    <StyledAvatar alt={rowData.name} src={thumbnail} />
                  </StyledLink>
                ) : (
                  <StyledAvatar alt={rowData.name} src={thumbnail} />
                )}
              </>
            )}
          </>
        );
      };

      const QuantityCell = ({ rowData, ...props }) => {
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const cart = useSelector(cartSelector);
        const itemMemberId = rowData.xitem_memberId;
        const { w } = useWinDimsInEM();
        const mobile = !miniCartView && w > XS_MOBILE_W ? true : undefined;
        const disabled =
          rowData.freeGift === "true" ||
          (cart?.buyerId !== userId && userId !== itemMemberId) ||
          reviewPage;

        /**
         * Dispatch quantity update action for order item
         * @param item The selected order item
         */
        const onQuantityUpdate = (quantityString: string, item: any) => {
          if (item) {
            try {
              const quantity = parseInt(quantityString);
              if (quantity > 0) {
                const payload = {
                  ...payloadBase,
                  quantity: quantity.toString(),
                  orderItemId: item.orderItemId,
                  xitem_field2: item.xitem_field2,
                  fetchCatentries: true,
                  orderId: cart?.orderId,
                };
                dispatch(orderActions.UPDATE_ITEM_ACTION(payload));

                // update this (even tho it's temporary) just so that once the dispatch
                //   action completes, we don't "temporarily" revert back to the old value
                quantityList[rowData.orderItemId] = quantity;
              }
            } catch (e) {
              console.log("Could not parse quantity");
            }
          }
        };
        return readOnly ? (
          <QuantityDisplay rowData={rowData} />
        ) : (
          <StyledNumberInput
            data-testid={`order-item-quantity-input-${rowData.partNumber}`}
            value={quantityList[rowData.orderItemId]}
            min={1}
            step={1}
            precision={0}
            disabled={disabled}
            stopLoadingOnUpdateValue={rowData}
            onBlur={(value) =>
              onQuantityUpdate((value ?? 0).toString(), rowData)
            }
            strict
          />
        );
      };

      const ODQuantityCell = ({ rowData, ...props }) => {
        return rowData.quantity;
      };
      const customerItemEmpty = (customerItemNumber) => {
        if (customerItemNumber === "") return true;
        else return false;
      };
      const ODItemDetailsCell = ({ rowData, ...props }) => {
        return (
          <>
            <StyledGrid container justifyContent="space-between">
              <StyledGrid>
                <StyledTypography>
                  {rowData.productDescription}
                </StyledTypography>
                <StyledTypography>
                  {rowData.chcItemNumber && t("Order.OrderCHCNumber")}
                  {rowData.chcItemNumber}
                </StyledTypography>
                <StyledTypography>
                  {rowData.supItemNumber && t("Order.ManufacturerNumber")}
                  {rowData.supItemNumber}
                </StyledTypography>
                {!customerItemEmpty && (
                  <StyledTypography>
                    {rowData.supItemNumber && t("Order.CustomerProductNumber")}
                    {rowData.customerItemNumber}
                  </StyledTypography>
                )}
              </StyledGrid>
            </StyledGrid>
          </>
        );
      };
      const ODLineCell = ({ rowData, ...props }) => {
        return Number(rowData.lineNumber);
      };
      const ItemDetailsCell = ({ rowData, ...props }) => {
        return (
          <Stack gap={0.5} p={0.5}>
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                {!miniCartView && (
                  <>
                    {!isEmpty(rowData?.manufacturer) && (
                      <StyledLink
                        to={
                          ROUTES.SEARCH +
                          "?" +
                          SEARCHTERM +
                          "=" +
                          encodeURIComponent(rowData?.manufacturer) +
                          `&${MANUFACTURER}=true`
                        }
                      >
                        {rowData?.manufacturer}
                      </StyledLink>
                    )}
                    <StyledTypography
                      variant="body2"
                      data-testid={`order-item-sku-${rowData.partNumber}`}
                    >
                      {rowData.partNumber}
                    </StyledTypography>
                  </>
                )}
                <StyledTypography
                  variant={"body1"}
                  style={{ wordBreak: "break-word" }}
                >
                  {rowData.seo && rowData.seo.href ? (
                    <StyledLink
                      to={rowData.seo?.href}
                      onClick={handleMiniCartClose}
                    >
                      {rowData.name ? rowData.name : rowData.partNumber}
                    </StyledLink>
                  ) : rowData.name ? (
                    <>
                      {!miniCartView ? (
                        <>
                          {!isEmpty(rowData?.name) && (
                            <StyledLink
                              to={
                                ROUTES.SEARCH +
                                "?" +
                                SEARCHTERM +
                                "=" +
                                rowData?.partNumber
                              }
                              className="product-link-regular"
                            >
                              {rowData.name}
                            </StyledLink>
                          )}
                        </>
                      ) : (
                        rowData.name
                      )}
                    </>
                  ) : (
                    rowData.partNumber
                  )}
                </StyledTypography>
                {rowData.freeGift === "true" && (
                  <StyledTypography variant="overline" color="textSecondary">
                    {t("OrderItemTable.Labels.Gift")}
                  </StyledTypography>
                )}
                {!miniCartView &&
                  isRecurringOrderFeatureEnabled &&
                  rowData.disallowRecurringOrder === "1" && (
                    <StyledTypography variant="overline" color="textSecondary">
                      {t("OrderItemTable.Labels.NonRecurring")}
                    </StyledTypography>
                  )}
              </Stack>
              {miniCartView && !reviewPage && (
                <StyledGrid item>
                  <DeleteActionCell {...{ rowData }} />
                </StyledGrid>
              )}
            </Stack>
            <Stack direction="row" gap={0.8}>
              {miniCartView && <ThumbnailCell {...{ rowData, ...props }} />}
              <Stack
                flexDirection={{ xs: "column", sm: "row" }}
                alignItems="flex-start"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {miniCartView && (
                  <>
                    <StyledTypography
                      data-testid={`order-item-sku-${rowData.partNumber}`}
                    >
                      <b>{t("OrderItemTable.Labels.CatalogueNumber")}:</b>{" "}
                      {rowData.partNumber}
                    </StyledTypography>
                    <QuantityCell {...{ rowData }} />
                    <StyledTypography>
                      {"UoM: "}
                      <UOMCell {...{ rowData }} />
                    </StyledTypography>
                    <OrderItemPrice rowData={rowData} />
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
        );
      };

      const DeleteActionCell = ({ rowData, ...props }) => {
        const cart = useSelector(cartSelector);
        const forUserId = useSelector(forUserIdSelector);
        const uId = useSelector(userIdSelector);
        const userId = forUserId ?? uId;
        const itemMemberId = rowData.xitem_memberId;
        const [clicked, setClicked] = useState<boolean>(false);
        const disabled =
          clicked ||
          rowData.freeGift === "true" ||
          (cart?.buyerId !== userId && userId !== itemMemberId);

        /**
         * Dispatch action to remove selected order item
         * @param item The selected order item
         */
        const removeItem = (item: any) => {
          const orderItemId = item.orderItemId;
          const payload = {
            ...payloadBase,
            orderItemId: orderItemId,
            fetchCatentries: true,
            orderId: cart?.orderId,
          };
          setClicked(true);
          dispatch(orderActions.REMOVE_ITEM_ACTION(payload));

          //GA360
          if (mySite.enableGA) {
            AsyncCall.sendRemoveFromCartEvent(item, {
              enableUA: mySite.enableUA,
              enableGA4: mySite.enableGA4,
            });
          }
        };

        return (
          <StyledIconButton
            disabled={disabled}
            color="primary"
            style={{ padding: "0.2rem" }}
            onClick={() => removeItem(rowData)}
            data-testid={`order-remove-item-button-${rowData.partNumber}`}
          >
            {miniCartView ? <CloseIcon /> : <DeleteOutlineIcon />}
          </StyledIconButton>
        );
      };

      const ItemStatusCell = ({ rowData: r }) => {
        const isWaitingShipment =
          r?.orderItemStatus === ORDER_ITEM_STATUS.WAITING_SHIPMENT;
        const { i18n } = useTranslation();
        const dateFormatter = getDateFormatter(i18n);
        return (
          <Stack gap={0.5}>
            <StyledTypography>
              {t(`Order.Status_${r.orderItemStatus}`)}
            </StyledTypography>
            {isWaitingShipment && (
              <>
                <StyledTypography>
                  {t(`Order.EstShippingDate`)}
                </StyledTypography>
                <StyledTypography>
                  {r?.estShipDate
                    ? dateFormatter.format(new Date(r.estShipDate))
                    : "N/A"}
                </StyledTypography>
              </>
            )}
          </Stack>
        );
      };

      const ItemAvailabilityCell = ({ rowData }) => {
        let onContractFlagMessage = "";

        if (rowData.xitem_field1 === "1") {
          onContractFlagMessage = t("OnContractFlags.SKULIST_ON_CONTRACT");
        }
        const itemProp = "image";
        const avl = {
          src: "/SapphireSAS/images/icon-contract.png",
        };
        const inventoryStatus = rowData?.orderItemInventoryStatus;
        const availableDate = rowData?.availableDate;
        let myDateString = "";
        let availabilityDesc;

        if (availableDate !== EMPTY_STRING) {
          const d = dayjs(availableDate);
          const year = d?.get("y");
          const month = ("0" + (d?.get("M") + 1)).slice(-2);
          const day = ("0" + d?.get("D")).slice(-2);
          myDateString = year + "-" + month + "-" + day;
        }
        if (
          inventoryStatus === "Available" ||
          inventoryStatus === "Allocated"
        ) {
          availabilityDesc = t("OrderItemTable.SHIP_IN_STOCK");
        } else if (inventoryStatus === "Backordered") {
          if (myDateString === EMPTY_STRING) {
            availabilityDesc = t("OrderItemTable.SHIP_BACKORDERED");
          } else {
            availabilityDesc = t("OrderItemTable.SHIP_BACKORDERED_ESTSHIP", {
              myDateString: myDateString,
            });
          }
        } else {
          availabilityDesc = t("OrderItemTable.SHIP_OUT_OF_STOCK");
        }
        return rowData.orderItemInventoryStatus && rowData.comments ? (
          <StyledTypography
            component="div"
            data-testid={`order-item-inventory-status-${rowData.partNumber}`}
          >
            {availabilityDesc}
            <p className="ships-from">
              {t("shipFromText.shipFrom")}{" "}
              {t("shipFromLocation.BranchPlantCode_" + rowData.comments)})
            </p>
            {onContractFlagMessage.length > 0 &&(
              <div className="oncontractIcon">
                {!onContractFlagMessage.includes("Promotional") && !onContractFlagMessage.includes("Prix") &&(
                <span>
                  <StyledProductImage
                    {...{
                      itemProp,
                      src: avl.src,
                      width: 18,
                      height: 16,
                      className: "oncontractImgIconlist",
                    }}
                  />
                </span>)}
                <span className="shopping-cart-flag">
                  &nbsp;{onContractFlagMessage}
                </span>
              </div>
            )}
          </StyledTypography>
        ) : (
          <StyledCircularProgress />
        );
      };

      const FetchPrice = (partNumber, tableValues, productId) => {
        uomList.forEach(sku);
        function sku(item) {
          if (item.partNumber === partNumber && item.id === productId) {
            const obj = {
              partNumber: partNumber,
              id: item.id,
              uom: item.uomValue,
            };
            tableValues.push(obj);
          }
        }
        return tableValues;
      };
      const ODUOMCell = ({ rowData, headers }) => {
        return rowData.unitOfMeasure;
      };
      const UOMCell = ({ rowData }) => {
        const partNumber = rowData.partNumber;
        const disp = (rowData?.uomInfo ?? [])?.map((u: any) => ({
          partNumber,
          id: u?.id,
          uom: u?.uomValue,
        }));
        let uom = [];
        if (disp?.length) {
          uom = disp.map(({ uom }) => uom);
        }
        const selecteduom = rowData.xitem_field2;
        const { t } = useTranslation();
        const [uomType, setUomType] = useState(selecteduom);
        const [loading, setLoading] = useState(false);
        const cart = useSelector(cartSelector);
        const conversionFactors = cartUtil.getUOMConversionFacts(
          rowData?.attributes
        );
        /**
         * Dispatch quantity update action for order item
         * @param item The selected order item
         */
        const onUomUpdate = (e, item: any) => {
          if (item) {
            try {
              const quantity = item.quantity;
              if (quantity > 0) {
                const payload = {
                  ...payloadBase,
                  quantity: quantity.toString(),
                  orderItemId: item.orderItemId,
                  fetchCatentries: true,
                  xitem_field2: e.target.value,
                  orderId: cart?.orderId,
                };
                setLoading(true);
                dispatch(orderActions.UPDATE_ITEM_ACTION(payload));
                //   action completes, we don't "temporarily" revert back to the old value
                setUomType(e.target.value);
              }
            } catch (e) {
              console.log("Could not parse quantity");
            }
          }
        };

        if (readOnly) {
          return <>{t("UOM." + selecteduom)}</>;
        }
        return (
          <OrderItemUOMCell
            {...{
              loading,
              conversionFactors,
              rowData,
              uom,
              uomType,
              t,
              onUomUpdate,
            }}
          />
        );
      };

      let columns: TableColumnDef[] = [];
      if (!orderDetailPage || miniCartView) {
        columns = [
          {
            title: "",
            idProp: "orderItemId",
            keyLookup: {
              key: CONSTANTS.thumbnail,
            },
            display: {
              cellStyle: {
                textAlign: "center",
              },
              template: ThumbnailCell,
            },
          },
          {
            title: t("OrderItemTable.Labels.ItemDetails"),
            keyLookup: {
              key: CONSTANTS.name,
            },
            sortable: { fn: itemValCalc },
            display: {
              template: ItemDetailsCell,
            },
          },
          {
            title: t("OrderItemTable.Labels.status"),
            keyLookup: {
              key: CONSTANTS.status,
            },
            sortable: { fn: statusCalc },
            display: {
              template: ItemStatusCell,
            },
          },
          {
            title: t("OrderItemTable.Labels.Status"),
            keyLookup: {
              key: CONSTANTS.orderItemInventoryStatus,
            },
            sortable: { fn: oaValCalc },
            display: {
              template: ItemAvailabilityCell,
            },
          },

          {
            title: t("OrderItemTable.Labels.Quantity"),
            keyLookup: {
              key: CONSTANTS.quantity,
            },
            sortable: { numeric: true, fn: quantityCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: QuantityCell,
            },
          },
          {
            title: t("Order.Unit"),
            keyLookup: {
              key: CONSTANTS.orderItemPrice,
            },
            sortable: { numeric: true, fn: priceCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: UOMCell,
            },
          },
          {
            title: t("Order.OrderUnitPrice"),
            keyLookup: {
              key: CONSTANTS.orderItemPrice,
            },
            sortable: { numeric: true, fn: priceCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: ({ rowData, ...props }) => (
                <OrderUnitItemPrice rowData={rowData} />
              ),
            },
          },
          {
            title: t("Order.TotalPrice"),
            keyLookup: {
              key: CONSTANTS.orderItemPrice,
            },
            sortable: { numeric: true, fn: priceCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: ({ rowData, ...props }) => (
                <OrderItemPrice rowData={rowData} />
              ),
            },
          },

          {
            title: t("OrderItemTable.Labels.Actions"),
            keyLookup: {
              key: CONSTANTS.orderItemActions,
            },

            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: DeleteActionCell,
            },
          },
        ];
      } else {
        columns = [
          {
            title: t("OrderItemTable.Labels.Line"),
            keyLookup: {
              key: CONSTANTS.name,
            },
            sortable: { fn: itemValCalc },
            display: {
              template: ODLineCell,
            },
          },
          {
            title: t("OrderItemTable.Labels.ItemDetails"),
            keyLookup: {
              key: CONSTANTS.name,
            },
            sortable: { fn: itemValCalc },
            display: {
              template: ODItemDetailsCell,
            },
          },
          {
            title: t("OrderItemTable.Labels.status"),
            keyLookup: {
              key: CONSTANTS.status,
            },
            sortable: { fn: statusCalc },
            display: {
              template: ItemStatusCell,
            },
          },
          {
            title: t("OrderItemTable.Labels.Quantity"),
            keyLookup: {
              key: CONSTANTS.quantity,
            },
            sortable: { numeric: true, fn: quantityCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: ODQuantityCell,
            },
          },
          {
            title: t("Order.Unit"),
            keyLookup: {
              key: CONSTANTS.orderItemPrice,
            },
            sortable: { numeric: true, fn: priceCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: ODUOMCell,
            },
          },
          {
            title: t("Order.OrderUnitPrice"),
            keyLookup: {
              key: CONSTANTS.orderItemPrice,
            },
            sortable: { numeric: true, fn: priceCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: ({ rowData, ...props }) => (
                <OrderUnitItemPrice rowData={rowData} />
              ),
            },
          },
          {
            title: t("Order.TotalPrice"),
            keyLookup: {
              key: CONSTANTS.orderItemPrice,
            },
            sortable: { numeric: true, fn: priceCalc },
            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: ({ rowData, ...props }) => (
                <ODOrderItemPrice rowData={rowData} />
              ),
            },
          },
          {
            title: t("OrderItemTable.Labels.Actions"),
            keyLookup: {
              key: CONSTANTS.orderItemActions,
            },

            display: {
              cellStyle: {
                textAlign: "left",
              },
              template: DeleteActionCell,
            },
          },
        ]; //}
      }

      if (readOnly) {
        columns = columns.filter(
          (col) => col.keyLookup.key !== CONSTANTS.orderItemActions
        );
      }

      // don't show status if order hasn't been shipped
      if (preShip) {
        columns = columns.filter(
          ({ keyLookup: { key: k } }) => k !== CONSTANTS.status
        );
      }

      if (miniCartView) {
        columns = columns.filter(
          (col) =>
            col.keyLookup.key !== CONSTANTS.orderItemInventoryStatus &&
            col.keyLookup.key !== CONSTANTS.quantity &&
            col.keyLookup.key !== CONSTANTS.orderItemPrice &&
            col.keyLookup.key !== CONSTANTS.orderItemActions &&
            col.keyLookup.key !== CONSTANTS.thumbnail
        );
      }
      // Hides panel if its configured to have a details panel
      if (hasDetailPanel) {
        // hide panel for any records with not attributes
        data
          .filter((r) => !r.attributes?.length)
          .forEach((r) =>
            setValueForCell(
              TableConstants.NOPANEL,
              true,
              r,
              columns,
              tableState,
              () => {
                return;
              }
            )
          );
      }

      return columns;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      t,
      mySite,
      miniCartView,
      handleMiniCartClose,
      isRecurringOrderFeatureEnabled,
      contractId,
      dispatch,
      CancelToken,
      readOnly,
      hasDetailPanel,
      quantityList,
      defaultCurrencyID,
      data,
      uomList,
    ]
  );

  const panelExpanderComps = useMemo(
    () => ({
      compShow: () => <OpenDrawer orderDetailPage={orderDetailPage} />,
      compHide: () => <CloseDrawer orderDetailPage={orderDetailPage} />,
    }),
    [orderDetailPage]
  );
  useEffect(() => {
    setQuantityList(initQuantityData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (seller) {
      setActionData({
        grids: [{ xs: true }],
        extraActions: [
          <div style={{ display: "flex", alignItems: "center" }}>
            <TruckIcon fontSize="large" className="right-margin-1" />
            <StyledTypography variant="h6">
              {t("productDetail.SellerSimple", { seller: seller.seller })}
            </StyledTypography>
          </div>,
        ],
      });
    }
  }, [seller]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    columns,
    data,
    options,
    detailPanel: ({ rowData, ...rest }) => (
      <DetailPanel {...{ rowData, orderDetailPage, ...rest }} />
    ),
    labels: {
      labelRowsSelect: t("OrderItemTable.Labels.PageSizeLabel"),
      labelDisplayedRows: t("OrderItemTable.Labels.RowCount"),
      firstTooltip: t("OrderItemTable.Labels.FirstPage"),
      previousTooltip: t("OrderItemTable.Labels.PreviousPage"),
      nextTooltip: t("OrderItemTable.Labels.NextPage"),
      lastTooltip: t("OrderItemTable.Labels.LastPage"),
    },
    t,
    miniCartView,
    handleMiniCartClose,
    panelExpanderComps,
    actionData,
  };
};
