/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Report API
 * This service provide an API for customer reports
 *
 * The version of the OpenAPI document: 9.1.11
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { AddOrganizationValidation } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CHCAddOrganizationApi - axios parameter creator
 * @export
 */
export const CHCAddOrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds Organization to users profile..
         * @summary Adds Organization.
         * @param {string} storeId The store identifier.
         * @param {string} accountNumber account number
         * @param {string} postalCode account postalcode
         * @param {string} uRL redirect url
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcAddOrganization: async (storeId: string, accountNumber: string, postalCode: string, uRL: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('chcAddOrganization', 'storeId', storeId)
            // verify required parameter 'accountNumber' is not null or undefined
            assertParamExists('chcAddOrganization', 'accountNumber', accountNumber)
            // verify required parameter 'postalCode' is not null or undefined
            assertParamExists('chcAddOrganization', 'postalCode', postalCode)
            // verify required parameter 'uRL' is not null or undefined
            assertParamExists('chcAddOrganization', 'uRL', uRL)
            const localVarPath = `/store/{storeId}/chc_organization/CHC_AddOrganization`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            if (postalCode !== undefined) {
                localVarQueryParameter['postalCode'] = postalCode;
            }

            if (uRL !== undefined) {
                localVarQueryParameter['URL'] = uRL;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CHCAddOrganizationApi - functional programming interface
 * @export
 */
export const CHCAddOrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CHCAddOrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds Organization to users profile..
         * @summary Adds Organization.
         * @param {string} storeId The store identifier.
         * @param {string} accountNumber account number
         * @param {string} postalCode account postalcode
         * @param {string} uRL redirect url
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chcAddOrganization(storeId: string, accountNumber: string, postalCode: string, uRL: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddOrganizationValidation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chcAddOrganization(storeId, accountNumber, postalCode, uRL, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CHCAddOrganizationApi - factory interface
 * @export
 */
export const CHCAddOrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CHCAddOrganizationApiFp(configuration)
    return {
        /**
         * Adds Organization to users profile..
         * @summary Adds Organization.
         * @param {string} storeId The store identifier.
         * @param {string} accountNumber account number
         * @param {string} postalCode account postalcode
         * @param {string} uRL redirect url
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chcAddOrganization(storeId: string, accountNumber: string, postalCode: string, uRL: string, responseFormat?: string, options?: any): AxiosPromise<AddOrganizationValidation> {
            return localVarFp.chcAddOrganization(storeId, accountNumber, postalCode, uRL, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CHCAddOrganizationApi - object-oriented interface
 * @export
 * @class CHCAddOrganizationApi
 * @extends {BaseAPI}
 */
export class CHCAddOrganizationApi extends BaseAPI {
    /**
     * Adds Organization to users profile..
     * @summary Adds Organization.
     * @param {string} storeId The store identifier.
     * @param {string} accountNumber account number
     * @param {string} postalCode account postalcode
     * @param {string} uRL redirect url
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CHCAddOrganizationApi
     */
    public chcAddOrganization(storeId: string, accountNumber: string, postalCode: string, uRL: string, responseFormat?: string, options?: any) {
        return CHCAddOrganizationApiFp(this.configuration).chcAddOrganization(storeId, accountNumber, postalCode, uRL, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
