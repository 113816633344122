import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";
import DetailLine from "./DetailLine";
import { AccountBalance } from "@material-ui/icons";
import { TrendingUp } from "@material-ui/icons";
import {MonetizationOn} from "@material-ui/icons";



export const AccelerateChDollarsSummary: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useContext(AccelerateProgramContext);
  return (
    <StyledAccelerateCard>
      {loading ? null : (
        <Stack spacing={3}>
          <DetailLine
            Icon={AccountBalance}
            label={t("DealerIncentiveProgram.CH_DOLLARS_EARNED")}
            value={String(data?.CHDollarsEarned || "0")}
          />
          <DetailLine
            Icon={TrendingUp}
            label={t("DealerIncentiveProgram.CH_DOLLARS_PROJECTED")}
            value={String(data?.ProjectedCHDTotal || "0")}
          />
          <DetailLine
            Icon={MonetizationOn}
            label={t("DealerIncentiveProgram.CH_DOLLARS_PAID_OUT")}
            value={String(data?.CHDollarsPaidOut || "0")}
          />
        </Stack>
      )}
    </StyledAccelerateCard>
  );
};
