/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { useCallback } from "react";
//UI

import { useTranslation } from "react-i18next";
import ContentRecommendationWidget from "../commerce-widgets/content-recommendation-widget";
import { menuConfig } from "./headerConstant";
import { useSite } from "../../_foundation/hooks/useSite";
import { paramCase } from "change-case";

const StaticMenu = (props: any) => {
  const { t } = useTranslation();
  const { mySite } = useSite();
  const { handleCategoryClickClose} = props;

  const onClick = () => {
    if(!handleCategoryClickClose) return;
    handleCategoryClickClose();
   };

   
  

  const HeaderStaticLink = useCallback(
    () => {
      
      return mySite != null ? (
        <div className="header-menu-static-link" onClick={onClick}>
          <ContentRecommendationWidget
            {...{
              widget: {
                id: `header-menu-${paramCase(menuConfig.espot.eSpotName)}`,
                widgetName: "content-recommendation-widget",
                name: `HeaderMenuStaticLink_Espot`,
                properties: {
                  emsName: `HeaderMenuStaticLink_Espot`,
                },
              },
              page: { name: "" }
            }}
          ></ContentRecommendationWidget>
        </div>
      ) : null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [t, mySite]
  );

  return (
    <HeaderStaticLink/>
  );
};

export default StaticMenu;
