import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import HTMLReactParser from "html-react-parser";
import StyledCard from "./StyledCard";
import { usePriceDisplay } from "../../../_foundation/hooks/use-price-display-chc";
import { useDateFormatter } from "../../../_foundation/hooks/use-date-formatter-chc";

export const AccelerateStatusTracker: FC = () => {
  const { t } = useTranslation();
  const priceDisplay = usePriceDisplay({ hideDecimal: true });
  const dateFormatter = useDateFormatter();
  const { data, threshold, imgDir } = useContext(AccelerateProgramContext);
  const eligibilityType = data?.EligibilityType ?? "default";
  const currentSpend = data?.CurrentYearYTDSpendTotal ?? 0;
  const currentYearYTDSpendPercentage = currentSpend
    ? Number(currentSpend) >= Number(threshold.diamond)
      ? 100
      : (Number(currentSpend) / Number(threshold.diamond)) * 100
    : 0;
  const payoutDateFormatted = dateFormatter(data?.NextPayoutDate);
  const endDateYearFormatted = Number(data?.NextPayoutDate?.split("-")[0]) + 1;

  return (
    <StyledCard color="dark">
      <Stack>
        <StyledTypography align="center" variant="h4">
          {t("DealerIncentiveProgram.STATUS_TRACKER")}
        </StyledTypography>
        <div className="status-tracker">
          <div className="status" style={{ left: `${currentYearYTDSpendPercentage}%` }}>
            {" "}
            <span>{priceDisplay(data?.CurrentYearYTDSpendTotal ?? "0")}</span>{" "}
            <span>
              <img
                src={`${imgDir}/dealerIncentive/icn-marker.svg`}
                className="icn-xs"
                alt="marker"
              />
            </span>{" "}
          </div>
          <div className="progress">
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: `${currentYearYTDSpendPercentage}%` }}
              aria-valuenow={currentYearYTDSpendPercentage}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
          <div className="silver">
            {" "}
            <span className="marker"></span>{" "}
            <strong>{priceDisplay(threshold?.silver ?? "0")}</strong>{" "}
            <img
              src={`${imgDir}/acceleratepro_${eligibilityType}/badge-silver.svg`}
              className="icn-badge"
              alt={t("DealerIncentiveProgram.TIER_SILVER")}
            />{" "}
          </div>
          <div className="gold">
            <span className="marker"></span> <strong>{priceDisplay(threshold?.gold ?? "0")}</strong>{" "}
            <img
              src={`${imgDir}/acceleratepro_${eligibilityType}/badge-gold.svg`}
              className="icn-badge"
              alt={t("DealerIncentiveProgram.TIER_GOLD")}
            />{" "}
          </div>
          <div className="diamond">
            <span className="marker"></span>{" "}
            <strong>{priceDisplay(threshold?.diamond ?? "0")}</strong>{" "}
            <img
              src={`${imgDir}/acceleratepro_${eligibilityType}/badge-diamond.svg`}
              className="icn-badge"
              alt={t("DealerIncentiveProgram.TIER_DIAMOND")}
            />
          </div>
        </div>
        {data?.RequiredSpendToNextStatus && Number(data?.RequiredSpendToNextStatus) > 0 ? (
          <StyledTypography align="center" className="text-center m-0 motivation-text">
            {HTMLReactParser(
              t("DealerIncentiveProgram.KEEP_GOING_DOLLARS", [
                priceDisplay(data?.RequiredSpendToNextStatus ?? "0"),
                payoutDateFormatted,
                endDateYearFormatted,
              ])
            )}
          </StyledTypography>
        ) : null}
      </Stack>
    </StyledCard>
  );
};
