import { DateRange, PickersShortcutsItem } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { i18n } from "i18next";

const dateFormatOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
export const getDateFormatter = (i18n: i18n) => new Intl.DateTimeFormat(i18n.languages[0], dateFormatOptions);


export const DEFAULT_DATE_RANGE: DateRange<Dayjs> = [dayjs().subtract(3, 'day'), dayjs()];

export const getDateShortcuts = ({t, hasClear}: {t: any, hasClear?: boolean}) => {
  const today = dayjs();
  const shortcutsItems = [
    {
      label: t('Order.DateShortcuts.Today'),
      getValue: () => [today, today],
    },
    {
      label: t('Order.DateShortcuts.Last3Days'),
      getValue: () => [today.subtract(3, 'day'), today],
    },
    {
      label: t('Order.DateShortcuts.Last7Days'),
      getValue: () => [today.subtract(7, 'day'), today],
    },
    {
      label: t('Order.DateShortcuts.Last30Days'),
      getValue: () => [today.subtract(30, 'day'), today],
    },
    {
      label: t('Order.DateShortcuts.Last60Days'),
      getValue: () => [today.subtract(60, 'day'), today],
    },
    {
      label: t('Order.DateShortcuts.Last90Days'),
      getValue: () => [today.subtract(90, 'day'), today],
    },
    {
      label: t('Order.DateShortcuts.Reset'),
      getValue: () => [...DEFAULT_DATE_RANGE]
    },
    ...(!hasClear? [] : [{
      label: t('Order.DateShortcuts.Clear'),
      getValue: () => [null, null]
    }])
  ] as PickersShortcutsItem<DateRange<Dayjs>>[];

  return [...shortcutsItems];
}