import { StyledContainer, StyledPaper } from "@hcl-commerce-store-sdk/react-component";
import { Box, Divider, Stack } from "@mui/material";
import { useLocation } from "react-router";
import { FC, useState, useCallback, useEffect } from "react";
import { DashboardSectionHeading } from "../../../widgets/dashboard-section-heading";
import { useTranslation } from "react-i18next";
import { NotificationsProvider } from "../../../../_foundation/context/notifications-context";
import { NotificationsList } from "../../../widgets/notifications-list";
import { useNotifications } from "../../../../_foundation/hooks/use-notifications-chc";
import { DashboardButton } from "../../../widgets/dashboard-button";
import { NotificationDetails } from "../../../widgets/notification-details";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { StyledTabs } from "../../../../elements/tabs";

const NotificationsPage: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { state } = useLocation() as { state: Record<string, string> };
  const [unreadOnly, setUnreadOnly] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);

  const notificationsData = useNotifications({
    unreadOnly,
    pageSize: 10,
  });
  const { loadMore, total, unreadTotal, notifications, setSelected, loading } = notificationsData;
  const tabTotal = unreadOnly ? unreadTotal : total;
  const handleTabChange = useCallback((index: number) => {
    setUnreadOnly(index === 0);
  }, []);
  useEffect(() => {
    if (loaded) {
      return;
    }
    if (notifications.length > 0) {
      setLoaded(true);
      if (state?.id) {
        setSelected(state.id);
      }
    }
  }, [notifications, state?.id, setSelected, loaded]);
  return (
    <StyledContainer className="page vertical-padding-4">
      <Stack spacing={4}>
        <DashboardSectionHeading
          title={t("Dashboard.Sections.Notifications.Title")}
          subtitle={t("Dashboard.Sections.Notifications.Subtitle")}
          large
        />
        <NotificationsProvider value={notificationsData}>
          <Stack direction={{ md: "row" }} spacing={{ xs: 3 }}>
            <Stack
              spacing={3}
              sx={{
                flex: 1,
              }}
            >
              <StyledPaper>
                <StyledTabs
                  name="notifications"
                  onChange={handleTabChange}
                  childrenList={[
                    {
                      title: t("Dashboard.Sections.Notifications.Unread"),
                      tabContent: (
                        <NotificationsList
                          selectedContent={isMobile ? <NotificationDetails /> : undefined}
                        />
                      ),
                    },
                    {
                      title: t("Dashboard.Sections.Notifications.All"),
                      tabContent: (
                        <NotificationsList
                          selectedContent={isMobile ? <NotificationDetails /> : undefined}
                        />
                      ),
                    },
                  ]}
                />
                {notifications.length < tabTotal && !loading ? (
                  <>
                    <Divider variant="fullWidth" sx={{ borderBottomStyle: "dashed" }} />
                    <Box p={2}>
                      <Stack alignItems="center">
                        <DashboardButton
                          color="secondary"
                          size="small"
                          variant="contained"
                          onClick={loadMore}
                        >
                          {"Load More"}
                        </DashboardButton>
                      </Stack>
                    </Box>
                  </>
                ) : null}
              </StyledPaper>
            </Stack>
            {isMobile ? null : (
              <Stack spacing={3} sx={{ flex: 1 }}>
                <NotificationDetails />
              </Stack>
            )}
          </Stack>
        </NotificationsProvider>
      </Stack>
    </StyledContainer>
  );
};

export default NotificationsPage;
