import request from './request';

export const downloadFileRequest = (invoiceNumber, invoiceDate, customerNumber ) =>
request.get("/downloadInvoice", {
    responseType: 'blob',
      headers: {
        'Accept': 'application/pdf'
      },
    //timeout: 10000,
    params: {
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        customerNumber: customerNumber
    }
});