/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020,2021
 *
 *==================================================
 */
//Standard libraries
import { FC, useRef, RefObject, useMemo } from "react";
import styled from "styled-components";
import { ClickAwayListener } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
//style
import {
  StyledBox,
  StyledButton,
  StyledTypography,
  StyledPopper,
  StyledProgressPlaceholder,
  StyledPaper,
  StyledGrid
} from "@hcl-commerce-store-sdk/react-component";
//custom
import * as ROUTES from "../../constants/routes";
import { useSelector } from "react-redux";
import { activeOrgSelector, activeSegment, entitledOrgSelector, loyaltyPoint } from "../../redux/selectors/organization";
import { contractSelector, currentContractIdSelector } from "../../redux/selectors/contract";
import { Stack } from "@mui/system";
import { LocationOn } from "@material-ui/icons";
import chcOrganizationService from "../../_foundation/apis/transaction/chc-organization.service";
import * as successActions from "../../redux/actions/success";

const limitCharacters = (str: string, limit: number) =>
  str.length > limit ? str.substring(0, limit - 3) + "..." : str;

const HoverButton = styled(StyledButton)`
  &.MuiButton-containedSecondary {
    padding: 1rem !important;
    background-color: ${(props) => props.theme.palette.primary.light};
    border: 0;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover,
    &:focus,
    &.selected {
      background-color: ${(props) => props.theme.palette.background.paper};
      color: ${(props) => props.theme.palette.primary.main};
      border: 0;
    }
    font-weight: 600;
  }
  svg {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const MenuBarOrgContract: FC<{
  page: { id: string; children: any[]; name: string };
  selectMenuItem: (cid: string | null) => void;
  selectedMenuItem: { id: string } | null;
}> = ({ page, selectMenuItem, selectedMenuItem }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const entitledOrgs = useSelector(entitledOrgSelector);
  const activeOrgId = useSelector(activeOrgSelector);
  const currentContractId = useSelector(currentContractIdSelector);
  const eligibleContracts = useSelector(contractSelector);
  const segment = useSelector(activeSegment);
  const loyaltypoints = useSelector(loyaltyPoint);
  const activeOrg = useMemo(
    () => entitledOrgs?.find((org) => org.organizationId === activeOrgId),
    [entitledOrgs, activeOrgId]
  );
  const activeContract = useMemo(
    () => eligibleContracts && currentContractId && eligibleContracts[currentContractId],
    [eligibleContracts, currentContractId]
  );

  const popperRef: RefObject<HTMLButtonElement> = useRef<HTMLButtonElement>(null);
  const handleClickAway = (event) => {
    const target = event.target;
    if (popperRef.current && (popperRef.current.contains(target) || popperRef.current === target)) {
      return;
    }
    if (selectedMenuItem?.id === page?.id) {
      selectMenuItem(null);
    }
  };
  const navigate = useNavigate();
 
  const POPPER_ID = `MENU_POPPER_${page.id}`;
  const setWidth = (data) => {
    const { width } = data.offsets.reference;
    if (width > data.popper.width) {
      data.styles.width = width;
    }
    return data;
  };

  const segmentLTC = () =>{
    const cookieVal = () => {
      const b = document.cookie.match(
        // eslint-disable-next-line no-useless-concat
        "(^|;)\\s*" + "WC_PhysicalStores" + "\\s*=\\s*([^;]+)"
      );

      return b ? b.pop() : "null";
    };
    const cookieValue = cookieVal();
    if(cookieValue == 'null') {
      const responseFormat = "application/json";
      chcOrganizationService.setPhysicalStoreCookie({activeOrgId,responseFormat}).then((response) => {
        
        if(response.data.errorCode != '0'){
          const successMessage = {
            key: "AccountLinks.AccountErrorMsg",
          };
          dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
        }
      }).catch((err) => {
        const successMessage = {
          key: "AccountLinks.AccountErrorMsg",
        };
        dispatch(successActions.HANDLE_SUCCESS_MESSAGE_ACTION(successMessage));
      });
    }
    if(segment === "LTC"){
   
      return true;
    }
    else {
      return false;
    }
  }

 

  return activeOrg ? (
    <>
      <StyledBox justifySelf="flex-end" display="flex" style={{ marginLeft: "auto" }}>
        <HoverButton
          testId={`header-${page.id}`}
          variant="contained"
          color="secondary"
          onClick={() => selectMenuItem(selectedMenuItem ? null : page?.id)}
          ref={popperRef}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <StyledTypography id="header-account-activeOrg" aria-describedby="header-account-org">
              <strong>{limitCharacters(activeOrg.displayName, 40)}</strong>
            </StyledTypography>
            <LocationOn />
          </Stack>
        </HoverButton>
      </StyledBox>
      <StyledPopper
        id={POPPER_ID}
        data-testid={POPPER_ID}
        open={selectedMenuItem !== null && selectedMenuItem.id === page.id}
        anchorEl={popperRef.current}
        placement="bottom-end"
        disablePortal
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          hide: {
            enabled: false,
          },
          updateWidth: {
            enabled: true,
            order: 875,
            fn: setWidth,
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledPaper>
            <StyledBox p={2}>
            {segmentLTC() && 
              <StyledTypography id="header-loyalty-activeOrg" aria-describedby="header-loyalty-org" gutterBottom>
                <strong> {t("Header.AccountPopper.LoyaltyPoints")}</strong> : {parseInt(loyaltypoints).toLocaleString()}
              </StyledTypography>}
              <StyledTypography variant="body2" id="header-account-org">
                {t("Header.AccountPopper.Organization")}
              </StyledTypography>
              <StyledTypography
                id="header-account-activeOrg"
                aria-describedby="header-account-org"
                gutterBottom
              >
                {activeOrg.displayName}
              </StyledTypography>
              <StyledTypography variant="body2" id="header-account-contract">
                {t("Header.AccountPopper.ShippingAddress")}
              </StyledTypography>
              <StyledTypography
                id="header-account-address"
                aria-describedby="header-account-address"
                gutterBottom
              >
                {activeOrg?.address1}, {activeOrg?.city}, {activeOrg?.state} {activeOrg?.zipCode},{" "}
                {activeOrg?.country}
              </StyledTypography>
              <StyledButton
                testId="header-accountPopper-edit"
                variant="contained"
                color="secondary"
                size="small"
                onClick={(e) => {
                  handleClickAway(e);
                  return navigate(ROUTES.SELECTACCOUNT);
                }}
              >
                {t("Header.AccountPopper.Edit")}
              </StyledButton>
            </StyledBox>
          </StyledPaper>
        </ClickAwayListener>
      </StyledPopper>
    </>
  ) : null;
};

export default MenuBarOrgContract;
function useEffect(arg0: () => void, arg1: any[]) {
  throw new Error("Function not implemented.");
}

