/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2022
 *
 *==================================================
 */
//Standard libraries
import { takeLatest } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../actions/invoice";
import * as WORKERS from "../workers/invoice";

/**
 * Invoice watch saga
 * watchers to intercept Invoice actions
 */
export function* watchSaga() {
  yield takeLatest(ACTIONS.GET_INVOICE_ACTION, WORKERS.fetchInvoiceRequest);
  yield takeLatest(ACTIONS.GET_INVOICE_FIND_ACTION, WORKERS.findInvoicesRequest);
  yield takeLatest(ACTIONS.GET_INVOICE_DOWNLOAD_ACTION, WORKERS.fetchDownloadFileRequest);
}
