import { StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { Stack } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AccelerateProgramContext } from "../../../_foundation/context/accelerate-program-context";
import { StyledAccelerateCard } from "../../../elements/accelerate-card/accelerate-card";

export const AccelerateChDollars: FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useContext(AccelerateProgramContext);
  return (
    <StyledAccelerateCard>
      {loading ? null : (
        <Stack alignItems="center" spacing={2}>
          <StyledTypography variant="h4" align="center">
            {t("DealerIncentiveProgram.BALANCE")}
          </StyledTypography>
          <StyledTypography variant="h1" component="h5" color="primary">
            {t("DealerIncentiveProgram.BALANCE_DOLLAR_AMOUNT", [data?.CurrentBalance ?? 0])}
          </StyledTypography>
        </Stack>
      )}
    </StyledAccelerateCard>
  );
};
