/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021, 2022
 *
 *==================================================
 */
import styled from "styled-components";
import MatButton from "@material-ui/core/Button";

const StyledButton = styled(MatButton)`
  ${({ theme }) => `
    font-weight: 500;

    &.MuiButton-containedPrimary.Mui-disabled {
      background: ${theme.palette.primary.dark};
      color: ${theme.palette.white};
      opacity: 0.5;
    }

    &.accordion-show-expanded {
      display: none;
    }

    .MuiAccordionSummary-expandIcon.Mui-expanded & {
      &.accordion-show-summary {
        display: none;
      }

      &.accordion-show-expanded {
        display:flex;
        transform: rotate(180deg);
      }
    }

    &.MuiButtonBase-root {
      border-radius: ${theme.shape.borderRadius}px;
      padding: ${theme.spacing(1)}px ${theme.spacing(2)}px;
      letter-spacing: 0.02rem;

      &:not(.MuiButton-text) {
        border-radius: ${theme.shape.borderRadius}px;
        padding: ${theme.spacing(0.5)}px ${theme.spacing(2)}px;
        letter-spacing: 0.01rem;
        box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.005);

        &:hover {
          box-shadow: 0px 1px 2px 2px rgba(0,0,0,0.0025);
        }
      }
    }

    &.secondary-color-text-button {
      color: ${theme.palette.text.secondary};
    }

    &.alert-color-text-button {
      color: ${theme.palette.text.alert};
    }

    &.MuiButton-text {
      font-size: 0.9rem;
      font-weight: 600;
      padding: 0;
      box-shadow: none;
      min-width: unset;
      &:not(.Mui-disabled):not(.secondary-color-text-button):not(.alert-color-text-button) {
        color: ${theme.palette.primary.main};
      }
      background: none;
    }

    &.MuiButton-containedPrimary {
      background-color: ${theme.palette.primary.dark};
      border: 1px solid ${theme.palette.primary.dark};
      color: ${theme.palette.background.paper};
      font-weight: bold;
      border-radius: 5px;
      &:hover {
        background-color: ${theme.palette.background.paper};
        color: ${theme.palette.primary.dark};
        border: 1px solid ${theme.palette.primary.dark};
      }

      &.Mui-disabled {
        background: ${theme.palette.primary.dark};
       
        color: ${theme.palette.background.paper};
        opacity: 0.5;
      }
    }

    &.MuiButton-outlinedPrimary {
      &.Mui-disabled {
        border: 1px solid ${theme.palette.primary.main};
        color: ${theme.palette.primary.main};
        opacity: 0.5;
      }
    }

    &.MuiButton-containedSecondary {
      background-color: ${theme.palette.background.default};
      border: 1px solid ${theme.palette.background.default};
      color: ${theme.palette.text.disabled};
      font-weight: bold;
      border-radius: 5px;
      &:hover {
        background-color: ${theme.palette.background.paper};
        color: ${theme.palette.primary.main};
        border: 1px solid ${theme.palette.background.default};
      }

      &.Mui-disabled {
        opacity: 0.5;
        border-color: ${theme.palette.grey[500]};
        color: ${theme.palette.grey[500]};
      }
    }

    &.accordion-show-expanded {
      display: none;
    }

    .MuiAccordionSummary-expandIcon.Mui-expanded &{
      &.accordion-show-summary {
        display: none;
      }

      &.accordion-show-expanded {
        display:flex;
        transform: rotate(180deg);
      }
    }

    &.border-solid-bold  {
      font-weight: bold;
      border: 1.35px solid;
    }

    &.confirm-action-button,
    &.cancel-action-button {
      background-color: ${theme.palette.background.paper};
      font-weight: 600;
    }

    &.confirm-action-button {
      border: ${theme.spacing(0.25)}px solid ${theme.palette.border.alert};
      color: ${theme.palette.text.alert};
    }

    &.cancel-action-button {
      border: ${theme.spacing(0.25)}px solid;
    }

    &.login-process-button {
      width: ${theme.spacing(31)}px;
      @media (max-width: 320px) {
        width: ${theme.spacing(25.5)}px;
      }
    }
    `}
`;

export default StyledButton;
