import { Typography } from "@material-ui/core";
import { ReactElement, forwardRef } from "react";
import { Badge } from "@material-ui/core";
//HCL
import { StyledButton, StyledLink } from "@hcl-commerce-store-sdk/react-component";
import { StyledIcon } from "../icon";
import { Stack } from "@mui/material";

const StyledHeaderIconButton = forwardRef(
  (
    {
      icon,
      label,
      unread,
      link,
      onClick,
      testId,
    }: {
      icon: ReactElement<any, any>;
      label?: string;
      unread?: boolean;
      link?: string;
      onClick?: any;
      testId?: string;
    },
    ref
  ) => {
    const content = (
      <StyledButton
        variant="text"
        color="secondary"
        ref={ref}
        onClick={onClick}
        testId={testId || "header-icon-button"}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Badge color="secondary" showZero invisible={!unread} variant="dot">
            <StyledIcon iconSize={22} icon={icon} shade="gray" backgroundSize={32} shape="square" />
          </Badge>
          {label ? (
            <Typography variant="body1" color="textPrimary">
              {label}
            </Typography>
          ) : null}
        </Stack>
      </StyledButton>
    );
    return link ? <StyledLink to={link}>{content}</StyledLink> : content;
  }
);

export { StyledHeaderIconButton };
