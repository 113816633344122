/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createReducer, AnyAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONS from "../action-types/price-server";
import initStates from "./initStates";

/**
 * Catalog reducer
 * handles states used by catalog related components
 * @param state State object managed by catalog reducer
 * @param action The dispatched action
 */
const priceServerReducer = createReducer(initStates.priceServer, (builder) => {
  builder.addCase(ACTIONS.PRODUCT_LIST_PRICE_FOR_PDP_GET_REQUESTED, (state, action: AnyAction) => {
    state.status = "pending";
  });
  builder.addCase(ACTIONS.PRODUCT_LIST_PRICE_FOR_PDP_GET_RESET, (state) => {
    state.status = "idle";
    state.data = {};
  });
  builder.addCase(ACTIONS.PRODUCT_LIST_PRICE_FOR_PDP_GET_ERROR, (state) => {
    state.status = "error";
  });
  builder.addCase(
    ACTIONS.PRODUCT_LIST_PRICE_FOR_PDP_GET_SUCCESS,
    (state, action: AnyAction) => {
      state.status = "success";
      state.EntitledPricePDP = action.response.EntitledPrice;
    }
  );
  builder.addCase(
    ACTIONS.PRODUCT_LIST_PRICE_FOR_SHOPPING_CART_GET_SUCCESS,
    (state, action: AnyAction) => {
      state.EntitledPriceShoppingCart = action.response.EntitledPrice;
    }
  );
});





export default priceServerReducer;
