import { createContext, useState, Dispatch, SetStateAction, useEffect } from "react";
import { useNotifications } from "../hooks/use-notifications-chc";
import { noop } from "lodash-es";

export const NotificationsContext = createContext<ReturnType<typeof useNotifications>>({
  notifications: [],
  selected: undefined,
  selectedId: undefined,
  setSelected: noop,
  total: 0,
  loadMore: noop,
  markAsRead: noop,
  loading: false,
  unreadTotal: 0,
});
export const NotificationsProvider = NotificationsContext.Provider;

export const NotificationsSiteContext = createContext<{
  unreadCount: number;
  setUnreadCount: Dispatch<SetStateAction<number>>;
}>({ unreadCount: 0, setUnreadCount: noop });
const NotificationsSiteProvider = NotificationsSiteContext.Provider;

export const NotificationsSiteContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { unreadTotal } = useNotifications({ unreadOnly: true, pageSize: 3 });
  const [unreadCount, setUnreadCount] = useState<number>(0);
  useEffect(() => {
    setUnreadCount(unreadTotal);
  }, [unreadTotal]);
  return (
    <NotificationsSiteProvider value={{ unreadCount: unreadCount || 0, setUnreadCount }}>
      {children}
    </NotificationsSiteProvider>
  );
};
